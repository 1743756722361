const marginAuto = {
    width: "88%",
    margin: 'auto',

}

const headerBoxCM = {
    bgcolor: '#F2F5F9',
    boxShadow: '0px 4px 30px #D2D6D9',
    position: 'fixed',
    zIndex: '999'
}

const boxInfoCM = {
    border: '1px solid #BCC9E0',
    background: '#FFFFFF',
    borderRadius: '8px',
    padding: '16px 68px 16px 24px',
    mr: '24px'
}

const boxInfoCMNew = {
    border: ' 1px solid #4DBFB8',
    background: '#FFFFFF',
    borderRadius: '8px',
    padding: '16px 24px',
    mr: '20px'
}

const informativeMessageBox = {
    width: '300px',
    maxHeight: '74px',
    background: '#EEECFB',
    border: '1px solid #978EE6',
    borderRadius: '8px',
    padding: '12px 14px'
}

const messageAlertStyle = {
    width: '559px',
    background: '#FFF8E1',
    border: '1px solid #FFB300',
    borderRadius: '8px',
    padding: '14px 16px'
}
const messageAlertFull = { 
    background: '#FFF8E1',
    border: '1px solid #FFB300',
    borderRadius: '8px',
    padding: '8px 14px'
}

const neededInformationAlert = {
    background: '#E53935',
    cursor: 'pointer',
    borderRadius: '8px',
    boxShadow: '0px 2px 2px rgba(229, 57, 53, 0.25)',
    padding: '10px 14px',
    ml: '24px',
    mb: '8px'
}

const boxCardStyleCM = {
    ...marginAuto,
    mb: '32px',
    p: '32px 24px',
    backgroundColor: 'white',
    borderRadius: '8px '
}

const boxCardStyleCMWhitoutP = {
    ...marginAuto,
    mb: '32px',
    p: '0px 0px',
    backgroundColor: 'white',
    borderRadius: '8px ',
    height: "350px"
}


const boxCardStyleCMWhitoutPnh = {
    ...marginAuto,
    mb: '32px',
    p: '0px 0px',
    backgroundColor: 'white',
    borderRadius: '8px ', 
}
const boxCardMidleSize = {
    width: '49%',
    mb: '32px',
    p: '24px 24px',
    backgroundColor: 'white',
    borderRadius: '8px '
}

const labelSelectTracingP = {
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "16px",
    letterSpacing: "0.0120588em",
    color: "#364855",
};

const boxCardBasic = {
    ...marginAuto,
    p: '24px',
    mb: '24px',
    backgroundColor: 'white',
    borderRadius: '8px ',
    boxShadow: '0px 2px 5px rgba(201, 207, 227, 0.75)'

}
const boxCardNormal = {
    ...marginAuto,
}

const boxCardNormalAlt = {
    backgroundColor: 'white',
    boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.15)',
    borderRadius: '8px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
}

const boxCardFilter = {
    ...marginAuto,
    pl: '24px',
    pb: '32px',
    mb: '24px',
    backgroundColor: 'white',
    borderRadius: '8px ',
    boxShadow: '0px 2px 5px rgba(201, 207, 227, 0.75)'

}

const boxCard = {   
    backgroundColor: 'white',
    borderRadius: '8px ',
    boxShadow: '0px 2px 5px rgba(201, 207, 227, 0.75)'

}
const boxCardFilterAlt = {
    ...marginAuto,
    pb: '32px',
    backgroundColor: 'none',
    boxShadow: 'none'

}
const formHeader = { 
    border:' 1px solid #CED4DA',
    borderRadius: '8px',
    padding: '16px 18px',
    backgroundColor: '#E6F6F5',
    boxShadow: 'none' 
}

const formLabel = { 
    border: '1px solid #CED4DA',
    borderRadius: '8px',
    padding: '24px',
    backgroundColor: '#FFFFFF',
    boxShadow: 'none' 
}
export {
    messageAlertFull,
    formLabel,
    formHeader,
    boxCard,
    headerBoxCM,
    boxInfoCM,
    informativeMessageBox,
    boxCardStyleCM,
    boxCardStyleCMWhitoutP,
    boxCardMidleSize,
    messageAlertStyle,
    neededInformationAlert,
    labelSelectTracingP,
    boxCardBasic,
    boxCardNormal,
    boxCardFilter,
    boxCardFilterAlt,
    boxCardNormalAlt,
    boxInfoCMNew,
    boxCardStyleCMWhitoutPnh
}
