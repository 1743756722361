import { TableCell, TableHead, TableRow, TableSortLabel } from "@mui/material";
import { DropdownIndicator } from "../../../components/Tables/dropdownIndicator";

const headCellCuentas = [
  {
    id: "id",
    label: "Nº Cuenta",
    width: "124px",
  },
  {
    id: "idCMD",
    label: "Nº C. Extranet",
    width: "148px",
  },
  {
    id: "numeroCuentaPrestador",
    label: "Nº C. Prestador",
    width: "156px",
  },
  {
    id: "rutPaciente",
    label: "Beneficiario",
    width: "150px",
  },
  {
    id: "rutPrestador",
    label: "Prestador",
    width: "150px",
  },
  {
    id: "estado",
    label: "Estado",
    width: "117px",
  },
  {
    id: "causal",
    label: "Causal",
    width: "100px",
  },
  {
    id: "fechaRecepcion",
    label: "Fecha Recepción",
    width: "172px",
  },
  {
    id: "fechaAsignacionUsuario",
    label: "Fecha Asignación",
    width: "172px",
  },
  {
    id: "fechaProbableLiquidacion",
    label: "Fecha compromiso",
    width: "172px",
  },

  {
    id: "diasRestantes",
    label: "Días Rest.",
    width: "123px",
  },
  {
    id: "montoTotalCobro",
    label: "Monto de cobro",
    width: "159px",
  },
  {
    id: "canalOrigen",
    label: "Canal",
    width: "124px",
  },
  {
    id: "usuarioAsignado",
    label: "Usuario Asignado",
    width: "124px",
  },
  {
    id: "accionesUsuario",
    label: "Gestion",
    width: "186px",
  },
  {
    id: "acciones",
    label: "Acciones",
    width: "200px",
  },
];

export const HeadCuentasMedicas = ({
  order,
  orderBy,
  onRequestSort,
  isAssignedTab,
  rol,
}: any) => {
  const createSortHandler = (id: any, label: string) => (event: any) => {
    onRequestSort(event, id, label);
  };

  const actionsHeaderText = () => {
    if (rol.includes("JEFE") && !isAssignedTab) return "Acciones";
    return "Historial";
  };

  return (
    <TableHead>
      <TableRow>
        {headCellCuentas.map((headCell: any, i: number) => {
          if (["accionesUsuario", "acciones"].includes(headCell.id)) {
            return (
              <TableCell
                key={headCell.id}
                className={`${headCell.id === "accionesUsuario" ? 'headerTableEspecial' : 'headerFirstSticky'} escritorioTrabajo`}
              >
                {headCell.id === "accionesUsuario"
                  ? headCell.label
                  : actionsHeaderText()}
              </TableCell>
            );
          } else {
            return (
              <TableCell
                className={
                  orderBy === headCell.id
                    ? "escritorioTrabajo activeSort"
                    : "escritorioTrabajo headerSort"
                }
                key={headCell.id}
                sortDirection={
                  orderBy === headCell.id ? order.toLowerCase() : false
                }
                width={headCell.width}
                sx={{ minWidth: headCell.width }}
              >
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={
                    orderBy === headCell.id ? order.toLowerCase() : "desc"
                  }
                  onClick={createSortHandler(headCell.id, headCell.label)}
                  IconComponent={() => (
                    <DropdownIndicator
                      active={orderBy === headCell.id}
                      order={order.toLowerCase()}
                    />
                  )}
                >
                  {headCell.label}
                </TableSortLabel>
              </TableCell>
            );
          }
        })}
      </TableRow>
    </TableHead>
  );
};
