import {
    Box,
    Grid,
    Stack,
} from "@mui/material";
import SkeletonBar from "../../../components/Skeletons/skeletonBar";
import { boxCard, formHeader, formLabel } from "../../../assets/styles/Box/boxs";

export const LoadIngresoCuenta = () => (
    <>
        <Box width={"89.5%"} ml={"4.5%"} mb="16px" data-testid="loaderAntGen">
            <SkeletonBar width={'auto'} height={40} />
        </Box>
        <Stack sx={formLabel} width={"89.5%"} ml={"4.5%"} direction={"row"} justifyContent={'space-between'} alignItems={'center'}>
            <SkeletonBar width={122} height={18} />
            <SkeletonBar width={184} height={50} />
        </Stack>
        <Box sx={boxCard} width={"89.5%"} ml={"4.5%"} mb={"24px"} >
            <Box sx={formHeader}>
                <SkeletonBar width={169} height={14} />
            </Box>
            <Grid mb="32px" container p="24px" >
                <Grid item xs={3} pr="24px">
                    <Stack direction={'column'} spacing={'4px'}>
                        <SkeletonBar width={169} height={14} />
                        <SkeletonBar width={'auto'} height={38} />
                    </Stack>
                </Grid>
                <Grid item xs={3} pr="24px">
                    <Stack direction={'column'} spacing={'4px'}>
                        <SkeletonBar width={169} height={14} />
                        <SkeletonBar width={'auto'} height={38} />
                    </Stack>
                </Grid>
                <Grid item xs={3} pr="24px">
                    <Stack direction={'column'} spacing={'4px'}>
                        <SkeletonBar width={169} height={14} />
                        <SkeletonBar width={'auto'} height={38} />
                    </Stack>
                </Grid>
                <Grid item xs={3}>
                    <Stack direction={'column'} spacing={'4px'}>
                        <SkeletonBar width={169} height={14} />
                        <SkeletonBar width={'auto'} height={38} />
                    </Stack>
                </Grid>
                <Grid item xs={3} pt="24px" pr="24px">
                    <Stack direction={'column'} spacing={'4px'}>
                        <SkeletonBar width={169} height={14} />
                        <SkeletonBar width={'auto'} height={38} />
                    </Stack>
                </Grid>
                <Grid item xs={3} pt="24px" pr="24px">
                    <Stack direction={'column'} spacing={'4px'}>
                        <SkeletonBar width={169} height={14} />
                        <SkeletonBar width={'auto'} height={38} />
                    </Stack>
                </Grid>
                <Grid item xs={3} pt="24px" pr="24px">
                    <Stack direction={'column'} spacing={'4px'}>
                        <SkeletonBar width={169} height={14} />
                        <SkeletonBar width={'auto'} height={38} />
                    </Stack>
                </Grid>
                <Grid item xs={3} pt="24px" pb="24px">
                    <Stack direction={'column'} spacing={'4px'}>
                        <SkeletonBar width={169} height={14} />
                        <SkeletonBar width={'auto'} height={38} />
                    </Stack>
                </Grid>
            </Grid>
        </Box>
        <Box sx={boxCard} width={"89.5%"} ml={"4.5%"} mb={"24px"} mt={"24px"}>
            <Box sx={formHeader}>
                <SkeletonBar width={169} height={14} />
            </Box>
            <Grid mb="32px" container p="24px" >
                <Grid item xs={6} pr="24px">
                    <Stack direction={'column'} spacing={'4px'}>
                        <SkeletonBar width={169} height={14} />
                        <SkeletonBar width={'auto'} height={38} />
                    </Stack>
                </Grid>
                <Grid item xs={6}  >
                    <Stack direction={'column'} spacing={'4px'}>
                        <SkeletonBar width={169} height={14} />
                        <SkeletonBar width={'auto'} height={38} />
                    </Stack>
                </Grid>
                <Grid item xs={6} pr="24px" pt="24px">
                    <Stack direction={'column'} spacing={'4px'}>
                        <SkeletonBar width={169} height={14} />
                        <SkeletonBar width={'auto'} height={38} />
                    </Stack>
                </Grid>
                <Grid item xs={6} pt="24px">
                    <Stack direction={'column'} spacing={'4px'}>
                        <SkeletonBar width={169} height={14} />
                        <SkeletonBar width={'auto'} height={38} />
                    </Stack>
                </Grid>
                <Grid item xs={12} pt="24px" pb="24px">
                    <Stack direction={'column'} spacing={'4px'}>
                        <SkeletonBar width={169} height={14} />
                        <SkeletonBar width={'auto'} height={38} />
                    </Stack>
                </Grid>
            </Grid>
        </Box>
        <Box sx={boxCard} width={"89.5%"} ml={"4.5%"} mb={"24px"} mt={"24px"}>
            <Box sx={formHeader}>
                <SkeletonBar width={169} height={14} />
            </Box>
            <Grid mb="32px" container p="24px" >
                <Grid item xs={3} pr="24px">
                    <Stack direction={'column'} spacing={'4px'}>
                        <SkeletonBar width={169} height={14} />
                        <SkeletonBar width={'auto'} height={38} />
                    </Stack>
                </Grid>
                <Grid item xs={3} pr="24px">
                    <Stack direction={'column'} spacing={'4px'}>
                        <SkeletonBar width={169} height={14} />
                        <SkeletonBar width={'auto'} height={38} />
                    </Stack>
                </Grid>
                <Grid item xs={3} pr="24px">
                    <Stack direction={'column'} spacing={'4px'}>
                        <SkeletonBar width={169} height={14} />
                        <SkeletonBar width={'auto'} height={38} />
                    </Stack>
                </Grid>
                <Grid item xs={3}>
                    <Stack direction={'column'} spacing={'4px'}>
                        <SkeletonBar width={169} height={14} />
                        <SkeletonBar width={'auto'} height={38} />
                    </Stack>
                </Grid>
                <Grid item xs={12} pr="24px" borderBottom={"1px solid #4DBFB8"} >
                    <Stack pb="17px" pt="24px">
                        <SkeletonBar width={169} height={14} />
                    </Stack>
                </Grid>
                <Grid item xs={3} pt="24px" pr="24px">
                    <Stack direction={'column'} spacing={'4px'}>
                        <SkeletonBar width={169} height={14} />
                        <SkeletonBar width={'auto'} height={38} />
                    </Stack>
                </Grid>
                <Grid item xs={3} pt="24px" pr="24px">
                    <Stack direction={'column'} spacing={'4px'}>
                        <SkeletonBar width={169} height={14} />
                        <SkeletonBar width={'auto'} height={38} />
                    </Stack>
                </Grid>
                <Grid item xs={3} pt="24px" pr="24px">
                    <Stack direction={'column'} spacing={'4px'}>
                        <SkeletonBar width={169} height={14} />
                        <SkeletonBar width={'auto'} height={38} />
                    </Stack>
                </Grid>
                <Grid item xs={3} pt="24px" pb="24px">
                    <Stack direction={'column'} spacing={'4px'}>
                        <SkeletonBar width={169} height={14} />
                        <SkeletonBar width={'auto'} height={38} />
                    </Stack>
                </Grid>
                <Grid item xs={3} pt="24px" pr="24px">
                    <Stack direction={'column'} spacing={'4px'}>
                        <SkeletonBar width={169} height={14} />
                        <SkeletonBar width={'auto'} height={38} />
                    </Stack>
                </Grid>
                <Grid item xs={3} pt="24px" pr="24px">
                    <Stack direction={'column'} spacing={'4px'}>
                        <SkeletonBar width={169} height={14} />
                        <SkeletonBar width={'auto'} height={38} />
                    </Stack>
                </Grid>
                <Grid item xs={12} pr="24px" borderBottom={"1px solid #4DBFB8"} >
                    <Stack pb="17px" pt="24px">
                        <SkeletonBar width={169} height={14} />
                    </Stack>
                </Grid>
                <Grid item xs={3} pt="24px" pr="24px">
                    <Stack direction={'column'} spacing={'4px'}>
                        <SkeletonBar width={169} height={14} />
                        <SkeletonBar width={'auto'} height={38} />
                    </Stack>
                </Grid>
                <Grid item xs={3} pt="24px" pr="24px" pb="24px">
                    <Stack direction={'column'} spacing={'4px'}>
                        <SkeletonBar width={169} height={14} />
                        <SkeletonBar width={'auto'} height={38} />
                    </Stack>
                </Grid>
            </Grid>
        </Box>
        <Box sx={boxCard} width={"89.5%"} ml={"4.5%"} mb={"24px"} mt={"24px"} >
            <Box sx={formHeader}>
                <SkeletonBar width={169} height={14} />
            </Box>
            <Grid mb="32px" container p="24px" >
                <Grid item xs={3} pr="24px">
                    <Stack direction={'column'} spacing={'4px'}>
                        <SkeletonBar width={169} height={14} />
                        <SkeletonBar width={'auto'} height={38} />
                    </Stack>
                </Grid>
                <Grid item xs={3} pr="24px">
                    <Stack direction={'column'} spacing={'4px'}>
                        <SkeletonBar width={169} height={14} />
                        <SkeletonBar width={'auto'} height={38} />
                    </Stack>
                </Grid>
                <Grid item xs={3} pr="24px">
                    <Stack direction={'column'} spacing={'4px'}>
                        <SkeletonBar width={169} height={14} />
                        <SkeletonBar width={'auto'} height={38} />
                    </Stack>
                </Grid>
                <Grid item xs={3}>
                    <Stack direction={'column'} spacing={'4px'}>
                        <SkeletonBar width={169} height={14} />
                        <SkeletonBar width={'auto'} height={38} />
                    </Stack>
                </Grid>
                <Grid item xs={3} pt="24px" pr="24px">
                    <Stack direction={'column'} spacing={'4px'}>
                        <SkeletonBar width={169} height={14} />
                        <SkeletonBar width={'auto'} height={38} />
                    </Stack>
                </Grid>
                <Grid item xs={3} pt="24px" pr="24px">
                    <Stack direction={'column'} spacing={'4px'}>
                        <SkeletonBar width={169} height={14} />
                        <SkeletonBar width={'auto'} height={38} />
                    </Stack>
                </Grid>
            </Grid>
        </Box>
        <Box sx={boxCard} width={"89.5%"} ml={"4.5%"} mb={"24px"} mt={"24px"} >
            <Box sx={formHeader}>
                <SkeletonBar width={169} height={14} />
            </Box>
            <Grid mb="32px" container p="24px" >
                <Grid item xs={3} pr="24px">
                    <Stack direction={'row'} justifyContent={'space-between'}>
                        <Stack direction={'column'} spacing={'4px'}>
                            <SkeletonBar width={110} height={14} />
                            <SkeletonBar width={110} height={38} />
                        </Stack>
                        <Box pt="18px"> <SkeletonBar width={110} height={38} /></Box>
                    </Stack>
                </Grid>
                <Grid item xs={3} pr="24px">
                    <Stack direction={'row'} justifyContent={'space-between'}>
                        <Stack direction={'column'} spacing={'4px'}>
                            <SkeletonBar width={110} height={14} />
                            <SkeletonBar width={110} height={38} />
                        </Stack>
                        <Box pt="18px"> <SkeletonBar width={110} height={38} /></Box>
                    </Stack>
                </Grid>
                <Grid item xs={3} pr="24px">
                    <Stack direction={'row'} justifyContent={'space-between'}>
                        <Stack direction={'column'} spacing={'4px'}>
                            <SkeletonBar width={110} height={14} />
                            <SkeletonBar width={110} height={38} />
                        </Stack>
                        <Box pt="18px"> <SkeletonBar width={110} height={38} /></Box>
                    </Stack>
                </Grid>
                <Grid item xs={3}>
                    <Stack direction={'row'} justifyContent={'space-between'}>
                        <Stack direction={'column'} spacing={'4px'}>
                            <SkeletonBar width={110} height={14} />
                            <SkeletonBar width={110} height={38} />
                        </Stack>
                        <Box pt="18px"> <SkeletonBar width={110} height={38} /></Box>
                    </Stack>
                </Grid>
                <Grid item xs={3} pt="24px" pr="24px">
                    <Stack direction={'row'} justifyContent={'space-between'}>
                        <Stack direction={'column'} spacing={'4px'}>
                            <SkeletonBar width={110} height={14} />
                            <SkeletonBar width={110} height={38} />
                        </Stack>
                        <Box pt="18px"> <SkeletonBar width={110} height={38} /></Box>
                    </Stack>
                </Grid>
                <Grid item xs={3} pt="24px" pr="24px">
                    <Stack direction={'row'} justifyContent={'space-between'}>
                        <Stack direction={'column'} spacing={'4px'}>
                            <SkeletonBar width={110} height={14} />
                            <SkeletonBar width={110} height={38} />
                        </Stack>
                        <Box pt="18px"> <SkeletonBar width={110} height={38} /></Box>
                    </Stack>
                </Grid>
                <Grid item xs={3} pt="24px" pr="24px">
                    <Stack direction={'row'} justifyContent={'space-between'}>
                        <Stack direction={'column'} spacing={'4px'}>
                            <SkeletonBar width={110} height={14} />
                            <SkeletonBar width={110} height={38} />
                        </Stack>
                        <Box pt="18px"> <SkeletonBar width={110} height={38} /></Box>
                    </Stack>
                </Grid>
            </Grid>
        </Box>
    </>
);

