import { useState } from "react";
import {
  Box,
  FormControl,
  FormControlLabel,
  Grid,
  RadioGroup,
  Typography,
} from "@mui/material";
import {
  SlimInput,
} from "../../../../assets/styles/Input/input";
import { SlimValueSelect2 } from "../../../../components/Selects/valueSelect";
import { boxCard, formHeader } from "../../../../assets/styles/Box/boxs";
import { formTextHeader, inputForm } from "../../../../assets/styles/Text/text";
import IconData from "../../../../assets/icons/addDatesIcon.svg";
import OtherIcon from "../../../../assets/icons/otherIconForm.svg";
import { GreenRadio } from "../../../../components/radioButton/CustomRadio";
import { SelectCoberturas } from "./SelectCoberturas";
import { SelectRegimen } from "./selectRegimen";
import { SelectHabitacion } from "./SelectHabitacion";
import { parseMiles } from "../../../../utils/monedaUtils";
import { DatePicker } from "../../../../components/DatePicker/DatePicker";

const DataFechaCobro = ({ dataCM, setDataCM, readOnly }: any) => {
  const [errorDataAdicional, setErrorDataAdicional] = useState<any>({});

  const onChangeTipoCobertura = (e: any) => {
    setDataCM({
      ...dataCM,
      tipoCobertura: {
        ...dataCM.tipoCobertura,
        active: e.target.value
      }
    })
  }

  const onChangeTipoRegimen = (e: any) => {
    setDataCM({
      ...dataCM,
      tipoRegimen: {
        ...dataCM.tipoRegimen,
        active: e.target.value
      }
    })
  }

  const onChangeTipoHabitacion = (e: any) => {
    setDataCM({
      ...dataCM,
      tipoHabitacion: {
        ...dataCM.tipoHabitacion,
        active: e.target.value
      }
    })
  }

  const onChange = (e: { target: { id: any; value: any; }; }) => {
    const id: string = e.target.id;
    let value = e.target.value;
    if (id === "cantidadIntervenciones" || id === "montoCobro") {
      value = value.replace(/[^0-9]/g, '');
    }
    if (id === "montoCobro") {
      if (parseInt(value) > 2000000000) {
        setErrorDataAdicional({ ...errorDataAdicional, montoCobro: "Monto máximo permitido se ha excedido" });
      } else {
        setErrorDataAdicional({ ...errorDataAdicional, montoCobro: undefined });
      }
    }
    if (!value) {
      value = undefined
    }
    setDataCM({ ...dataCM, [id]: value });
  }
  const tipoCuenta = [{ label: "Abierta", value: 1 }, { label: "Paquete", value: 2 }, { label: "Paquete + Adicionales", value: 3 }];

  return (
    <>
      <Box sx={boxCard} width={"89.5%"} ml={"4.5%"} mb={"24px"} >
        <Box sx={formHeader}>
          <Typography sx={formTextHeader}>
            <img src={IconData} alt="" /> {' '}Información adicional
          </Typography>
        </Box>
        <Grid mb="32px" container p="24px">
          <Grid item xs={3} pr="24px">
            <Box className="widthMax">
              <Typography sx={inputForm}   >
                Tipo cobertura informada prestador
              </Typography>
              <Typography display={'inline'} color={'#E53935 !important'}>
                *
              </Typography>
            </Box>

            {dataCM.tipoCobertura.items ?
              <SlimValueSelect2
                onChange={onChangeTipoCobertura}
                disabled={!dataCM.vigenciaPoliza || readOnly}
                value={dataCM.tipoCobertura.active}
                data={dataCM.tipoCobertura.items}
                titulo={"Seleccione un Tipo Cobertura"}
                name="tipoCobertura"
              />
              :
              <SelectCoberturas
                onChange={onChangeTipoCobertura}
                disabled={!dataCM.vigenciaPoliza || readOnly}
                value={dataCM.tipoCobertura.active}
              />
            }
          </Grid>
          <Grid item xs={3} pr="24px">
            <Typography sx={inputForm}  >
              Tipo régimen
            </Typography>
            <Typography display={'inline'} color={'#E53935 !important'}>
              *
            </Typography>
            {dataCM.tipoRegimen.items ?
              <SlimValueSelect2
                onChange={onChangeTipoRegimen}
                disabled={!dataCM.vigenciaPoliza || readOnly}
                value={dataCM.tipoRegimen.active}
                data={dataCM.tipoRegimen.items}
                titulo={"Seleccione un Tipo régimen"}
                name="tipoRegimen"
              /> :
              <SelectRegimen
                onChange={onChangeTipoRegimen}
                disabled={!dataCM.vigenciaPoliza || readOnly}
                value={dataCM.tipoRegimen.active}                
              />}
          </Grid>
          <Grid item xs={3} pr="24px">
            <Typography sx={inputForm}  >
              Tipo de habitación
            </Typography>
            <Typography display={'inline'} color={'#E53935 !important'}>
              *
            </Typography>
            {dataCM.tipoHabitacion.items ? <SlimValueSelect2
              onChange={onChangeTipoHabitacion}
              disabled={!dataCM.vigenciaPoliza || readOnly}
              value={dataCM.tipoHabitacion.active}
              data={dataCM.tipoHabitacion.items}
              titulo={"Seleccione un Tipo habitación"}
              name="tipoHabitacion"
            /> :
              <SelectHabitacion
                onChange={onChangeTipoHabitacion}
                disabled={!dataCM.vigenciaPoliza || readOnly}
                value={dataCM.tipoHabitacion.active}
              />}
          </Grid>
          <Grid item xs={3}  >
            <Typography sx={inputForm}>
              Cantidad de intervenciones
            </Typography>
            <Typography display={'inline'} color={'#E53935 !important'}>
              *
            </Typography>
            <SlimInput
              fullWidth
              disabled={dataCM.canalOrigen === "CMD" || !dataCM.vigenciaPoliza || readOnly}
              id="cantidadIntervenciones"
              data-testid="cantidadIntervenciones"
              placeholder="Número intervenciones"
              value={dataCM.cantidadIntervenciones?.toString() || ""}
              onChange={onChange}
            />
          </Grid>
          <Grid item xs={3} pr="24px" pt="24px" >
            <Typography sx={inputForm}  >
              Graduación de parto
            </Typography>
            <DatePicker
              value={dataCM.graduacionParto}
              disabled={!dataCM.vigenciaPoliza || readOnly}
              onChange={(newValue: any) => {
                setDataCM({ ...dataCM, graduacionParto: newValue });
              }}
              testid="fechaParto"
            />
          </Grid>
          <Grid item xs={3} pr="24px" pt="24px" pb="24px">
            <Typography sx={inputForm}  >
              Monto cobro
            </Typography>
            <Typography display={'inline'} color={'#E53935 !important'}>
              *
            </Typography>
            <SlimInput
              fullWidth
              placeholder="Ej: 99.999.999.999"
              value={parseMiles(dataCM.montoCobro || '')}
              id={"montoCobro"}
              data-testid={"montoCobro"}
              onChange={onChange}
              error={errorDataAdicional.montoCobro ? true : false}
              helperText={errorDataAdicional.montoCobro}
              disabled={!dataCM.vigenciaPoliza || readOnly}
            />
          </Grid>
        </Grid>
      </Box>

      <Box sx={boxCard} width={"89.5%"} ml={"4.5%"} mb={"24px"} >
        <Box sx={formHeader}>
          <Typography sx={formTextHeader}>
            <img src={OtherIcon} alt="" style={{ marginBottom: "-3px" }} /> {' '}Otros
          </Typography>
        </Box>
        <Grid mb="32px" container p="24px">
          <Grid item xs={3} pr="24px">
            <Typography sx={inputForm}  >
              Tipo de atención
            </Typography>
            <Typography display={'inline'} color={'#E53935 !important'}>
              *
            </Typography>
            <FormControl disabled={!dataCM.vigenciaPoliza || readOnly}>
              <RadioGroup
                row
                defaultValue="H"
                value={
                  dataCM.tipoAtencion || null
                }
                id="tipoAtencion"
                data-testid="tipoAtencion"
                onChange={(e) => onChange({ target: { value: e.target.value, id: "tipoAtencion" } })}
              >
                <FormControlLabel
                  value="A"
                  control={<GreenRadio data-testid="tipoAtencionAmbulatorio" />}
                  label="Ambulatorio"
                  id="tipoAtencion"
                />
                <FormControlLabel
                  value="H"
                  control={<GreenRadio />}
                  label="Hospitalario"
                  id="tipoAtencion"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item xs={3} pr="24px">
            <Typography sx={inputForm}  >
              Ley de urgencia
            </Typography>
            <Typography display={'inline'} color={'#E53935 !important'}>
              *
            </Typography><br></br>
            <FormControl disabled={!dataCM.vigenciaPoliza || readOnly}>
              <RadioGroup
                row
                value={
                  dataCM.leyUrgencia || null
                }
                id="leyUrgencia"
                data-testid="leyUrgencia"
                onChange={(e) => onChange({ target: { value: e.target.value, id: "leyUrgencia" } })}
              >
                <FormControlLabel value="S" control={<GreenRadio data-testid="leyUrgenciaS" />} label="Si" />
                <FormControlLabel value="N" control={<GreenRadio />} label="No" />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item xs={3} pr="24px">
            <Typography sx={inputForm}  >
              Cuenta Complementaria
            </Typography>
            <Typography display={'inline'} color={'#E53935 !important'}>
              *
            </Typography><br></br>
            <FormControl disabled={!dataCM.vigenciaPoliza || readOnly}>
              <RadioGroup
                row
                defaultValue={false}
                value={dataCM.cuentaComplementaria || null}
                id="cuentaComplementaria"
                data-testid="cuentaComplementaria"
                onChange={(e) => onChange({ target: { value: e.target.value, id: "cuentaComplementaria" } })}
              >
                <FormControlLabel value={"S"} control={<GreenRadio data-testid="cuentaComplementariaS" />} label="Si" />
                <FormControlLabel value={"N"} control={<GreenRadio />} label="No" />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item xs={3}  >
            <Typography sx={inputForm}  >
              SOAP
            </Typography>
            <Typography display={'inline'} color={'#E53935 !important'}>
              *
            </Typography><br></br>
            <FormControl disabled={!dataCM.vigenciaPoliza || readOnly}>
              <RadioGroup
                row
                value={dataCM.soap || null}
                name="soap"
                onChange={(e) => onChange({ target: { value: e.target.value, id: "soap" } })}
              >
                <FormControlLabel value="true" control={<GreenRadio data-testid="soapS"/>} label="Si" />
                <FormControlLabel value="false" control={<GreenRadio />} label="No" />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item xs={3} pt="24px" pr="24px">
            <Typography sx={inputForm}  >
              Preexistencia Declarada
            </Typography>
            <Typography display={'inline'} color={'#E53935 !important'}>
              *
            </Typography><br></br>
            <FormControl disabled={!dataCM.vigenciaPoliza || readOnly}>
              <RadioGroup
                row
                value={
                  dataCM.declaracionPreexistencia || null
                }
                name="declaracionPreexistencia"
                onChange={(e) => onChange({ target: { value: e.target.value, id: "declaracionPreexistencia" } })}
              >
                <FormControlLabel value={"S"} control={<GreenRadio data-testid="declaracionPreexistenciaS"/>} label="Si" />
                <FormControlLabel value={"N"} control={<GreenRadio />} label="No" />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item xs={3} pt="24px" pr="24px">
            <Typography sx={inputForm}  >
              Cobertura Restringida
            </Typography>
            <Typography display={'inline'} color={'#E53935 !important'}>
              *
            </Typography><br></br>
            <FormControl disabled={!dataCM.vigenciaPoliza || readOnly}>
              <RadioGroup
                row
                value={
                  dataCM.restriccionCobertura || null
                }
                id="restriccionCobertura"
                onChange={(e) => onChange({ target: { value: e.target.value, id: "restriccionCobertura" } })}
              >
                <FormControlLabel value={"S"} control={<GreenRadio data-testid="restriccionCoberturaS" />} label="Si" />
                <FormControlLabel value={"N"} control={<GreenRadio />} label="No" />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item xs={3} pt="24px" pr="24px" pb="24px">
            <Typography sx={inputForm}  >
              Tipo de cuenta
            </Typography>
            <Typography display={'inline'} color={'#E53935 !important'}>
              *
            </Typography>
            <SlimValueSelect2
              onChange={(e: any) => {
                setDataCM({ ...dataCM, tipoCuentaMedica: e.target.value });
              }}
              disabled={!dataCM.vigenciaPoliza || readOnly}
              value={dataCM.tipoCuentaMedica}
              data={tipoCuenta}
              titulo={"Seleccione un Tipo Cuenta"}
              name="tipoCuentaMedica"
            />
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

export default DataFechaCobro;
