import { useEffect, useState } from 'react'
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import AccountCircle from '@mui/icons-material/AccountCircle';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { Grid } from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';
import { appBarJob, appBarName, StyledAppBar, StyledDivider } from '../../assets/headerSx';
import { SignOutButton } from '../login/SignOutButton';
import { SignInButton } from '../login/SignInButton';
import {  useIsAuthenticated } from "@azure/msal-react";
import { useGetUser } from "../../hooks/getUser";
import { returnRolByJerarquia } from "../../utils/rol";

export default function Header() {
  const isAuthenticated = useIsAuthenticated();
  const [userRoles, setUserRoles] = useState<any>(null);

  const { rol, nombre } = useGetUser();

  useEffect(() => {
    setUserRoles(returnRolByJerarquia(rol))
  }, [rol])
  
  return (
    <Box sx={{ flexGrow: 1}} >
      <StyledAppBar position="fixed">
        <Toolbar sx={{height:'72px'}}>
          <Box sx={{ flexGrow: 1, marginTop: '6px ' }}>
            <img style={{padding: '0px 6px'}} src='../logo.png' alt='' className='img'/>
          </Box>
              
    
            <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
              { isAuthenticated ? <>
                <AccountCircle className='icon' fontSize='large'/>
                <Grid sx={{paddingLeft: '16px', paddingRight: '34px'}}>
                  <Typography sx={appBarName}>{nombre}</Typography>
                  <Typography sx={appBarJob}>{userRoles}</Typography>
                </Grid></> : <div/> }
                <StyledDivider sx={{marginRight: '33px'}} orientation="vertical" flexItem />
                <IconButton><NotificationsIcon color="secondary" fontSize='medium'/></IconButton>
                <StyledDivider sx={{marginLeft: '33px'}} orientation="vertical" flexItem />
                <Box pl="10px">
                {isAuthenticated ? <SignOutButton /> : <SignInButton />}
                </Box>
                {isAuthenticated && <IconButton><LogoutIcon color="secondary"></LogoutIcon></IconButton>}
            </Box>
        </Toolbar>
      </StyledAppBar>
    </Box>
  );
}
