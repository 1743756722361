import { createSvgIcon } from "@mui/material/utils";


const IconReport = createSvgIcon(
    <svg 
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 70 70"
    fill="none">
    <path d = "M3.96017 1.71021C3.96017 0.881778 3.2886 0.210205 2.46017 0.210205C1.63175 0.210205 0.960175 0.881778 0.960175 1.71021V50.2899C0.960175 51.1183 1.63175 51.7899 2.46017 51.7899H51.0398C51.8683 51.7899 52.5398 51.1183 52.5398 50.2899C52.5398 49.4614 51.8683 48.7899 51.0398 48.7899H46.7392V18.2055C46.7392 17.3771 46.0677 16.7055 45.2392 16.7055H34.714V9.8672C34.714 9.03877 34.0424 8.3672 33.214 8.3672H21.1924C20.3639 8.3672 19.6924 9.03877 19.6924 9.8672V31.5695H9.16692C8.3385 31.5695 7.66692 32.2411 7.66692 33.0695V48.7899H3.96017V1.71021ZM31.714 18.1968C31.714 18.1997 31.714 18.2026 31.714 18.2055C31.714 18.2085 31.714 18.2114 31.714 18.2143V48.7899H22.6924V11.3672H31.714V18.1968ZM34.714 19.7055H43.7392V48.7899H34.714V19.7055ZM19.6924 34.5695V48.7899H10.6669V34.5695H19.6924Z" 
    fill = "#00A499" />
    </svg>
    , 'Report'
);

export {
    IconReport,
};
