import { gql } from '@apollo/client';


export const getHistorialCuentaMedicaQuery = gql`
query getHistorialCuentaMedicaQuery($idSolicitud: Int ) { 
  getHistorialCuentaMedica(idSolicitud : $idSolicitud) {
    idSolicitud 
    estado 
    nota 
    causal {
      idCausal
      descripcionCausal
    }
    entidad 
    usuario 
    fechaDerivacion 
    entidadDerivacion 
    folioCuentaMedica 
    prestador 
    fechaInicioCobro 
    fechaTerminoCobro 
    montoTotalCobro 
    canalOrigen 
    fechaTransaccion 
    tituloNota 
    estadoEtl 
    }
}`;

export const mutationAsignarUsuario = gql`
mutation mutationAsignarUsuario($usuario: Usuario, $idCuentaMedica: Int){
  asignarUsuario(usuario: $usuario, idCuentaMedica: $idCuentaMedica){
    estado
    mensaje
  }
}`;

export const mutationReasignarUsuario = gql`
mutation mutationReasignarUsuario($input: reasignarInput) {
  reasignarUsuario(input: $input) {
    estado
    mensaje
  }
}`;

export const getUserByStatus = gql`
query getUserByStatus($status: Int!){
  getUserByStatus(status: $status){
      name
      email
  }
}
`;

export const getUsuarios = gql`
  query obtenerUsuarios{     
    obtenerUsuarios{
      email    
    }
  }
`;