const font = {
    fontFamily: "Interstate",

}

const boxTitle = {
    ...font,
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '22px',
    lineHeight: '24px',
    letterSpacing: '0.0120588em',
    color: '#203442',
}

const boxSubTitle = {
    ...font,
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '20px',
    lineHeight: '20px',
    color: '#364855',
}

const greenTitle = {
    ...font,
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '20px',
    color: '#00837A',
}

const alternativeTitle = {
    ...font,
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '24px',
    lineHeight: '32px',
    color: '#203442',
}

const cardTitle = {
    ...font,
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '24px',
    lineHeight: '36px',
    color: '#203442',
    marginBottom: '24px'
}

const cardSubTitle = {
    ...font,
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '20px',
    color: '#4D5D68',
    marginBottom: '32px'
}
const cardSubT = {
    ...font,
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '20px',
    color: '#4D5D68',
}
const modalSubT = {
    ...font,
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '2px',
    color: '#4D5D68',
}
const boldTitle = {
    ...font,
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '18px',
    lineHeight: '32px',
    color: '#203442',
}
const lineText = {
    ...font,
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '20px',
    color: '#364855',
    display: 'inline'
}
const lineBold = {
    ...font,
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '16px',
    lineHeight: '20px',
    color: '#364855',
    display: 'inline'
}
const lineBoldText = {
    ...font,
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '18px',
    lineHeight: '20px',
    color: '#364855',
    display: 'inline'
}
const subTable = {
    ...font,
    color: '#79858E',
    fontSize: '13px',
    fontWeight: '400',
    lineHeight: '16px',
    letterSpacing: " 0.0120588em"
}
const purpleLine = {
    ...font,
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '16px',
    lineHeight: '24px',
    color: '#5143D5',
    display: 'inline'
}

const grayName = {
    ...font,
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '13px',
    lineHeight: '16px',
    color: '#79858E',
}

const mailText = {
    ...font,
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '14px',
    color: '#4C4C4C',
    width: '100px',

}

const toolTitle = {
    ...font,
    fontWeight: 700,
    fontSize: '14px',
    lineHeight: '24px',
    color: '#FFFFFF',
}

const toolBody = {
    fontFamily: 'Rubik',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '24px',
    color: '#FFFFFF',
}

const greenDiv = {
    ...font,
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '16px',
    color: '#4C4C4C',
    backgroundColor: "#CCEDEB",
    border: "border: 1px solid #CCEDEB",
    padding: "8px",
    borderRadius: "8px",
    textAlign: "center"
}

const nombreHeader = {
    ...font,
    //fontWeight: 700,
    fontSize: '16px',
    lineHeight: '12px',
    color: '#364855',
    display: 'inline'
}

const textHeader = {
    ...font,
    fontWeight: 700,
    fontSize: '16px',
    lineHeight: '12px',
    color: '#364855',
    display: 'inline'
}

const formTextHeader = {
    ...font,
    fontWeight: 700,
    fontSize: '16px',
    lineHeight: '17.65px',
    color: '#364855',
}

const inputForm = {
    ...font,
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '17.5px',
    color: '#79858E',
    display: 'inline',
}

const formTitleFont = {
    ...font,
    fontWeight: 400,
    fontSize: '24px',
    lineHeight: '23.78px',
    color: '#364855',
}
const greenBigTitle = {
    ...font,
    fontWeight: 700,
    fontSize: '36px',
    lineHeight: '39.71px',
    color: '#4DBFB8',
}

const lineSubText = {
    ...font,
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '24px',
    color: '#364855',
}


const TextLine = {
    ...font,
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '16px',
    color: '#364855',
}
const warningGray = {
    ...font,
    color: '#4D5D68',
    fontSize: '16px',
    fontWeight: '400',
    lineHeight: '24px',
    letterSpacing: "1.21%",
    display: "inline"
}

const warningPurple = {
    ...font,
    color: '#5143D5',
    fontSize: '16px',
    fontWeight: '400',
    lineHeight: '24px',
    letterSpacing: "1.21%",
    display: "inline"
}

const modalTitle = {
    ...font,
    color: '#203442',
    fontSize: '18px',
    fontWeight: '700',
    lineHeight: '24px',
    letterSpacing: "0.0120588em",
    textAlign: "center"
}

const modalText = {
    ...font,
    color: '#4D5D68',
    fontSize: '16px',
    fontWeight: '400',
    lineHeight: '24px',
    letterSpacing: "0.0120588em",
    display: "inline"
}
const modalTextBold = {
    ...font,
    color: '#4D5D68',
    fontSize: '16px',
    fontWeight: '700',
    lineHeight: '24px',
    letterSpacing: "0.0120588em",
    display: "inline"
}
const titleAccordion = {
    ...font,
    color: '#4D5D68',
    fontSize: '14px',
    fontWeight: '600',
    lineHeight: '14px',
    letterSpacing: "0.0120588em",
}
const textAccordion = {
    ...font,
    color: '#4D5D68',
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: '14px',
    letterSpacing: "0.0120588em",
}

const textAccordionOrange = {
    ...font,
    color: '#FF8F00',
    fontSize: '12px',
    fontWeight: '700',
    lineHeight: '16px',
    letterSpacing: "0.0120588em",
}
const textAccordionRed = {
    ...font,
    color: '#FF0000',
    fontSize: '12px',
    fontWeight: '700',
    lineHeight: '16px',
    letterSpacing: "0.0120588em",
}

const textAccordionGreen = {
    ...font,
    color: '#01736C',
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: '14px',
    letterSpacing: "0.0120588em",
}

const modalSubTittle = {
    ...font,
    color: '#203442',
    fontSize: '16px',
    fontWeight: '700',
    lineHeight: '24px',
    letterSpacing: "0.0120588em", 
    display:'inline'
}

const boldTitleBox = {
    ...font,
    color: '#203442',
    fontSize: '20px',
    fontWeight: '700',
    lineHeight: '20px',
    letterSpacing: "0.0120588em",  
}

const bonifBold = {
    ...font,
    color: '#4C4C4C',
    fontSize: '16px',
    fontWeight: '700',
    lineHeight: '24px',
    letterSpacing: "0.0120588em", 
    display:"inline" 
}

const bonifText = {
    ...font,
    color: '#203442',
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: '24px',
    letterSpacing: "0.0120588em",  
    display:"inline" 
}
const bonifGreen = {
    ...font,
    color: '#01A49A',
    fontSize: '24px',
    fontWeight: '400',
    lineHeight: '24px',
    letterSpacing: "0.0120588em",  
    display:"inline" 
}

const bonifParen = {
    ...font,
    fontStyle: 'normal',
    fontWeight: 400,
    //fontSize: '12px',
    lineHeight: '24px',
    color: '#79858E',
    display:"inline"
}
const bonifParenBold = {
    ...font,
    display:"inline",
    fontWeight: 'bold'
}
export {
    bonifParen,
    bonifParenBold,
    bonifGreen,
    bonifText,
    bonifBold,
    boldTitleBox,
    modalSubTittle,
    textAccordion,
    titleAccordion,
    TextLine,
    modalTextBold,
    modalText,
    modalTitle,
    warningPurple,
    warningGray,
    formTitleFont,
    inputForm,
    formTextHeader,
    textHeader,
    nombreHeader,
    lineSubText,
    greenBigTitle,
    greenDiv,
    toolBody,
    toolTitle,
    mailText,
    grayName,
    lineBoldText,
    purpleLine,
    lineBold,
    lineText,
    boldTitle,
    boxTitle,
    boxSubTitle,
    greenTitle,
    alternativeTitle,
    cardTitle,
    cardSubTitle,
    cardSubT,
    subTable,
    modalSubT,
    textAccordionGreen,
    textAccordionOrange,
    textAccordionRed
}
