import { Typography, Box, Button, Grid, Divider } from "@mui/material";
import { boxCardFilter, boxCardNormal, boxCardStyleCMWhitoutP } from "../../../assets/styles/Box/boxs";
import { boxSubTitle, boxTitle } from "../../../assets/styles/Text/text";
import { EmptyStateMessage } from "../../../components/message/emptyStateMessage";
import { ValueSelect } from "../../../components/Selects/valueSelect";
import { TableReportes } from "./tableReportes/tableReportes";
import useReportesMaestros from "../hook/useReportesMaestros";
import { ModalLoader } from "../../../components/Modal/modalLoader";
import { NormalModal } from "../../../components/Modal/normalModal";
import IsLoading from "../../../components/isLoading/isLoading";
import { ErrorModal } from "../../../components/Modal/errorModal";
import SnackBar from "../../../components/Snackbar/Snackbar";

function ReportesMaestros() {
  const {
    years,
    selectedValues,
    handleOnChangeSelectMonths,
    handleOnChangeSelectYear,
    handleGenerarArchivo,
    isDisabledGenerarArchivo,
    isLoadingGenerarArchivo,
    listaReportes,
    loadingListaReportes,
    loadingActualizarListadoReportes,
    handleClickDescargar,
    isLoadingDescargaArchivo,
    modalErrorGenerar,
    setModalErrorGenerar,
    monthsSelects,
    modalConfirmarGenerar,
    setModalConfirmarGenerar,
    handleActualizarReporte,
    dataBar,
    setDataBar
  } = useReportesMaestros();
  return (
    <>
      {modalConfirmarGenerar ? (
        <NormalModal
          open={modalConfirmarGenerar}
          title={"Confirmación"}
          content={"Actualmente ya existe un archivo de Cuentas Medicas Emitidas para el período seleccionado. Desea generar una nueva extraccion y sobrescribir la anterior versión?"}
          primaryButton={{
            text: "Aceptar",
            onClick: () => handleActualizarReporte()
          }}
          secondaryButton={{
            text: "Cancelar",
            onClick: () => setModalConfirmarGenerar(false)
          }}
        />) : null}

      <ModalLoader
        open={isLoadingGenerarArchivo || isLoadingDescargaArchivo}
        title={isLoadingGenerarArchivo ? "Generando Archivo" : "Descargando Archivo"}
      />
      <SnackBar state={dataBar} setState={setDataBar}/>
      {modalErrorGenerar ? (
        <ErrorModal
          open={modalErrorGenerar}
          title={"Alerta"}
          content={"No se encontraron Cuentas Medicas Emitidas para el periodo seleccionado..."}
          primaryButton={{
            text: 'Aceptar',
            onClick: () => setModalErrorGenerar(false)
          }}
        />
      ) : null}

      <Box sx={boxCardNormal}>
        <Typography pt={7} pb={4} variant="h2">
          Planilla datos maestros
        </Typography>
      </Box>
      <Box sx={boxCardFilter} paddingTop="32px">
        <Grid container>
          <Grid item xs={5} pr="24px">
            <Typography variant="h6" pb="6px">
              Año de la cuenta
            </Typography>
            <ValueSelect
              defaultValue={0}
              data={years}
              name={"years"}
              titulo={"Seleccionar"}
              value={selectedValues.year}
              onChange={handleOnChangeSelectYear}
            />
          </Grid>
          <Grid item xs={5} pr="24px">
            <Typography variant="h6" pb="6px">
              Mes de la cuenta
            </Typography>
            <ValueSelect
              defaultValue={0}
              data={monthsSelects}
              name={"month"}
              titulo={"Seleccionar"}
              value={selectedValues.month}
              onChange={handleOnChangeSelectMonths}
            />
          </Grid>
          <Grid item xs={2} pr="24px" pb="2px" display="flex" alignItems="end">
            <Button
              sx={{ height: 54 }}
              fullWidth
              variant="contained"
              onClick={handleGenerarArchivo}
              disabled={isDisabledGenerarArchivo}
            >
              Generar archivo
            </Button>
          </Grid>
        </Grid>
      </Box>
      <Box sx={boxCardStyleCMWhitoutP}>
        <Box p="16px">
          <Typography p="8px" sx={boxTitle}>
            Historial de archivos generados
          </Typography>
          <Typography p="8px" sx={boxSubTitle}>
            Aquí puedes visitar todos los archivos generados por periodo.
          </Typography>
        </Box>
        <Divider />
        {loadingListaReportes || loadingActualizarListadoReportes ? (
          <IsLoading title={"Cargando Cuentas Medicas"} />
        ) : listaReportes.length === 0 ? (
          <EmptyStateMessage
            title={"Sin historial registrado"}
            subtitle={"Selecciona un periodo y genera archivo de gastos"}
          />
        ) : (
          <TableReportes
            data={listaReportes}
            handleClickDescargar={handleClickDescargar}
          />
        )}
      </Box>

    </>
  );
}

export default ReportesMaestros;
