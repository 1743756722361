import { Snackbar, Alert, AlertColor } from "@mui/material";
import { style, iconStyle } from "../../assets/styles/SnackBar/Styles";

export type stateProps = {
  abrir: boolean,
  severidad: AlertColor,
  mensaje: string
}

type SnackbarArgs = {
  state: stateProps,
  setState: any,
  vertical?: 'top' | 'bottom',
  horizontal?: 'center' | 'left' | 'right'
}

const SnackBar = ({ state, setState, vertical = 'top', horizontal = 'center' }: SnackbarArgs) => {
  const cerrarMensajero = (event: Event | React.SyntheticEvent<Element, Event>) => {
    setState({
      ...state,
      abrir: false,
    });
  };
  return (
    <Snackbar
      sx={{bottom:"auto !important", marginTop:"80px"}}
      anchorOrigin={{ vertical, horizontal }}
      data-testid="snackTest"
      open={state.abrir}
      autoHideDuration={6000}
      onClose={cerrarMensajero}
      key={vertical + horizontal}
    >
      <Alert
        data-testid="alertTest"
        onClose={cerrarMensajero}
        severity={state.severidad}
        sx={style(state.severidad)}
        iconMapping={iconStyle(state.severidad)}
      >
        {state.mensaje}
      </Alert>
    </Snackbar>
  );
}

export default SnackBar;
