import { useNavigate } from "react-router-dom";

function useRecepcionCuentaMedica(){
  const navigate = useNavigate();

  const handleRecepcionCuentaMedica = () => {
    navigate(`/Escritorio/AdministracionCM`,{ state: { idCuentaMedica: null } });
  }

  return {
    handleRecepcionCuentaMedica
  }
}

export default useRecepcionCuentaMedica