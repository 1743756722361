import { Box, Button, Modal, Typography } from '@mui/material';

import { styledNormalModal } from '../../assets/styles/Modal/modal';

export const ErrorModal = ({ open, onClose, title, content, primaryButton }: any) => {
    return (
        <Modal
            open={open}
            onClose={onClose}
            className="background-modal"
        >
            <Box sx={styledNormalModal}>
                <Box padding="32px 32px">
                    <Typography
                    display="flex"
                    justifyContent="center"
                        variant="h3"
                    >
                        {title}
                    </Typography>
                    <Typography 
                    display="flex"
                    justifyContent="center"
                    pt="24px">
                        {content}
                    </Typography>
                </Box>


                <Box
                    padding="24px"
                    display="flex"
                    justifyContent="center"
                >

                    <Button
                        className="medium-button ml-button"
                        onClick={primaryButton.onClick}
                        variant="contained"
                    >
                        {primaryButton.text}
                    </Button>

                </Box>
            </Box>
        </Modal>
    )
}
