import {
    IconButton,
    Menu,
    MenuItem,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Fragment, useState } from "react";
import { ModalHistorial } from "../../escritorioTrabajo/components/modalHistorial";
import { useNavigate } from "react-router-dom";

export const MenuConsultas = ({ cta }: any) => {
    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = useState(null);
    const [openHistorial, setOpenHistorial] = useState<boolean>(false);
    const openMenu = Boolean(anchorEl);

    const handleOpen = (event: any) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleOnOpenHistorial = () => {
        setOpenHistorial(true)
        setAnchorEl(null)
    }

    const handleOnCloseHistorial = () => {
        setOpenHistorial(false)
    }

    const handleRevisarCuenta = () => {
        navigate(`/Escritorio/AdministracionCM`, {
            state: {
              idCuentaMedica: cta.id,
              datosBeneficiario: {
                rut: cta.rutPaciente,
                nombre: cta.nombrePaciente,
                apellidoPaterno: cta.apellidoPaternoPaciente,
                apellidoMaterno: cta.apellidoMaternoPaciente,
              },        
              canalOrigen: cta.canalOrigen,
              readOnly: true,
              isConsulta: true
            }
          });
    }
    return (
        <Fragment>
            <ModalHistorial
                open={openHistorial}
                onClose={handleOnCloseHistorial}
                row={cta}
                idCuentaMedica={cta.canalOrigen === "CMD" ? cta.idCMD : cta.id}
            />
            <IconButton
                data-testid="openMenuItem"
                id="basic-button"
                aria-haspopup="true"
                onClick={handleOpen}
            >
                <MoreVertIcon sx={{ color: "#203442" }} />
            </IconButton>
            <Menu
                id={`menu-${cta.numeroCuentaPrestador}`}
                anchorEl={anchorEl}
                open={openMenu}
                onClose={handleClose}
                className="menuForm"
            >
                <MenuItem
                    className="menuForm"
                    onClick={() =>
                        handleRevisarCuenta()
                    }
                >
                    Ver cuenta
                </MenuItem>
                <MenuItem key={"hitCam"} className="menuForm" onClick={() => handleOnOpenHistorial()}>
                    Historial de cambios
                </MenuItem>
            </Menu>
        </Fragment>
    )
}