import {
    AuthenticatedTemplate,
    UnauthenticatedTemplate,
} from "@azure/msal-react";
import { TextCard } from "./TextCard";

export default function Bienvenida({ isAuthorized }: { isAuthorized: boolean }) {
    return (
        <div data-testid="bienvenida">
            <AuthenticatedTemplate>
                {isAuthorized ? null : (
                    <TextCard isUnautorize />
                )}
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
                <TextCard isLogin />
            </UnauthenticatedTemplate>
        </div>)
}
