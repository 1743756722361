import { Typography, Box, Grid, Stack, AlertColor } from "@mui/material";
import FilterIcon from "../../../assets/icons/filtreIcon.svg";
import DisableFilter from "../../../assets/icons/disableFiltre.svg";
import { boxCard } from "../../../assets/styles/Box/boxs";
import { boldTitle, lineText } from "../../../assets/styles/Text/text";
import { SecondaryButton } from "../../../components/Buttons/Button";
import { TableCuentasMedicas } from "./tableCuentasMedicas";
import { ModalFiltros } from "./modalFiltrosCtas";
import useEscritorio from "../hook/useEscritorio";
import useTablePagination from "../../../hooks/useTablePagination/useTablePagination";
import { ChipsCuentas } from "./chipsCuentas";
import ModalAssignMe from "./ModalAssignMe/ModalAssignMe";
import { useState } from "react";
import SnackBar from "../../../components/Snackbar/Snackbar";
import BotonCarga from "../../../components/Buttons/LoadingButton";

function TabCuentasM({ isAssignedTab, isAuth }: { isAssignedTab?: any, isAuth?: any }) {
  const [snackData, setSnackData] = useState({
    abrir: false,
    mensaje: "",
    severidad: "info" as AlertColor,
  });

  const sendMessage = (severity: AlertColor, message: string) => {
    setSnackData({
      abrir: true,
      mensaje: message,
      severidad: severity
    })
  }
  const {
    reloadTableEscritorio,
    refetch,
    listaEscritorio,
    count,
    loading,
    handleOnClickGestionarCuenta,
    loadingAsignarUsuario,
    openModalAsigarme,
    handleOnClickAsignarme,
    handleOpenModalAsignarme,
    handleOnCloseModalAsignarme,
    handleOnClickExportCuentas,
    loadingCuentasExportar,
    openModalFiltro,
    handleOnOpenModalFiltro,
    handleOnCloseModalFiltro,
    filtros,
    handleRevisarCuenta,
    errorResults,
    cuentaMedicaAsigancion,
    rol,
    causales,
    selectCausales,
    medicalRecordStatusList,
  } = useEscritorio(isAssignedTab, 'EscritorioTrabajoFiltro', sendMessage, isAuth);

  const {
    page,
    rowsPerPage,
    handleNewChangePage,
    handleNewChangeRowsPerPage,
    order,
    orderBy,
    handleRequestSort,
    changePage,
  } = useTablePagination({
    initialOrderBy: "id",
    fnReloadData: reloadTableEscritorio,
    initialRowsPerPage: 25,
  });

  return (
    <>
      {openModalAsigarme && (
        <ModalAssignMe
          cuentaMedica={cuentaMedicaAsigancion}
          open={openModalAsigarme}
          handleOnClose={handleOnCloseModalAsignarme}
          handleOnClickAsignarme={handleOnClickAsignarme}
          loading={loadingAsignarUsuario}
        />
      )}

      {openModalFiltro && (
        <ModalFiltros
          openModal={openModalFiltro}
          handleOnClose={handleOnCloseModalFiltro}
          dataFiltro={filtros || {}}
          findMD={reloadTableEscritorio}
          rowsPerPage={rowsPerPage}
          pageChange={changePage}
          isAuth={isAuth}
          causales={causales}
          selectCausales={selectCausales}
          isAssignedTab={isAssignedTab}
          medicalRecordStatusList={medicalRecordStatusList}
        />
      )}
      <SnackBar
        state={snackData}
        setState={setSnackData}
      />
      <Box sx={boxCard}>
        <Grid container p="24px">
          <Grid item xs={6}>
            <Typography sx={boldTitle}>Cuentas Médicas</Typography>
            <Typography sx={lineText}>
              Aquí puedes filtrar
              {!isAssignedTab ? `, asignar ` : ' '}
              y gestionar la información de cuentas médicas.
            </Typography>
          </Grid>
          <Grid item xs={6} alignSelf={"center"}>
            <Stack
              direction={"row"}
              justifyContent={"end"}
              spacing={"16px"}
              alignSelf={"center"}
            >
              <SecondaryButton
                startIcon={
                  loading ? (
                    <img src={DisableFilter} alt="" />
                  ) : (
                    <img src={FilterIcon} alt="" />
                  )
                }
                sx={{ padding: "16px 15px !important" }}
                onClick={handleOnOpenModalFiltro}
                disabled={loading}
              >
                Filtro
              </SecondaryButton>
              <BotonCarga
                testid="warningMC"
                type="secondary"
                download
                disabled={loading}
                sx={{ minWidth: "130px" }}
                toDo={handleOnClickExportCuentas}
                loading={loadingCuentasExportar}
                label={"Exportar"}
              />
            </Stack>
          </Grid>
          <ChipsCuentas
            filtros={filtros || {}}
            rowsPerPage={rowsPerPage}
            pageChange={changePage}
            findMD={reloadTableEscritorio}
          />
        </Grid>
        <TableCuentasMedicas
          listaEscritorio={listaEscritorio}
          count={count}
          rowsPerPage={rowsPerPage}
          page={page}
          handleNewChangePage={handleNewChangePage}
          handleNewChangeRowsPerPage={handleNewChangeRowsPerPage}
          loading={loading}
          order={order}
          orderBy={orderBy}
          filtros={filtros}
          handleRequestSort={handleRequestSort}
          handleOnClickGestionarCuenta={handleOnClickGestionarCuenta}
          handleOpenModalAsignarme={handleOpenModalAsignarme}
          handleRevisarCuenta={handleRevisarCuenta}
          errorResults={errorResults}
          rol={rol}
          isAssignedTab={isAssignedTab}
          isAuth={isAuth}
          setSnackData={sendMessage}
          refetch={refetch}
        />
      </Box>
    </>
  );
}

export default TabCuentasM;
