import { gql } from "@apollo/client";

export const aperturaEventoHospitalario = gql`
mutation ($idEventoHospitalario: Int){
    aperturaEventoHospitalario(idEventoHospitalario : $idEventoHospitalario) {
        estado
        mensaje
    }
}`;

export const queryEliminarEventoHospitalario = gql`
mutation ($idEventoHospitalario: Int){
  eliminarEventoHospitalario(idEventoHospitalario : $idEventoHospitalario) {
        estado
        mensaje
    }
}`;

export const queryAsignacionEventoHospitalario = gql`
mutation($idEventoHospitalario: Int, $idSolicitud: Int){
  asignacionEventoHospitalario(idEventoHospitalario : $idEventoHospitalario,  idSolicitud : $idSolicitud) {
        estado
        mensaje
    }
}`;

export const queryObtenerEventosHospitalarios = gql`
query queryObtenerEventosHospitalarios($rutPaciente: String, $rutPrestador: String, $fechaInicioHospitalizacion: String){  
    obtenerEventosHospitalarios(rutPaciente : $rutPaciente, rutPrestador : $rutPrestador, fechaInicioHospitalizacion : $fechaInicioHospitalizacion) {
        id
        rutPaciente
        rutPrestador 
        estado
        fechaEstado
        fechaInicioHospitalizacion
        fechaAltaHospitalizacion
        condicionEgreso
        horaAltaHospitalizacion
    }
}`;

export const mutationCrearEventoHospitalario = gql`
mutation mutationCrearEventoHospitalario($eventoHospitalario: EventoHospitalarioInput){
    crearEventoHospitalario(eventoHospitalario: $eventoHospitalario)
    {
        id 
        rutPaciente
        rutPrestador
        estado
        fechaEstado
        fechaInicioHospitalizacion
        fechaAltaHospitalizacion
        condicionEgreso
        horaAltaHospitalizacion
    }
}`;

export const getCuentasEvento = gql`
query getCuentasEH($idEvento: Int!, $idCuenta: Int!){
    getCuentasEH(idEvento : $idEvento, idCuenta: $idCuenta) {
        id
        numeroCuentaPrestador
        numeroCobroPrestador
        numeroEnvio
        fechaCobroDesde
        fechaCobroHasta
    }
}`;