import {
    Box,
    Grid,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@mui/material";
import SkeletonBar from "../../../components/Skeletons/skeletonBar";
import { stickyBodyLeft, stickyHead, styleHead } from "../../../assets/styles/Table/table";

export const LoadHeadLiquidadas = () => (
    <>
        <Grid container p="24px">
            <Grid item xs={12}>
                <SkeletonBar width={180} height={18} />
            </Grid>
            <Grid item xs={12} pt="8px">
                <SkeletonBar width={482} height={16} />
            </Grid>
        </Grid>
        <Stack pr="24px" pl="24px" direction={"row"} display={"flex"} justifyContent={"space-between"}>
            <Stack direction={"row"} pt="16px" justifyContent={"flex-end"} spacing={"16px"}>
                <SkeletonBar width={205} height={50} />
                <SkeletonBar width={205} height={50} />
                <SkeletonBar width={142} height={50} />
            </Stack>
            <Stack direction={"row"} pt="16px" justifyContent={"flex-end"} spacing={"16px"}>
                <SkeletonBar width={104} height={50} />
                <SkeletonBar width={205} height={50} />
            </Stack>
        </Stack>
    </>
);

export const LoadLiquidadas = () => (
    <Box  >
        <TableContainer sx={{ p: 0, pt: "18px" }}>
            <Table sx={{ minWidth: "1500px" }}>
                <TableHead>
                    <TableRow>
                        <TableCell
                            padding="checkbox" 
                            sx={stickyHead}
                        >
                            <div style={{ paddingLeft: "20px", paddingRight: "20px" }}>
                                <SkeletonBar width={20} height={20} />
                            </div>
                        </TableCell>

                        <TableCell
                             className="headerTable"
                             sx={{ minWidth: "150px" }}
                        >
                            <SkeletonBar width={80} height={14} />
                        </TableCell>
                        <TableCell
                            className="headerTable"
                            sx={{ minWidth: "150px" }}
                        >
                            <SkeletonBar width={80} height={14} />
                        </TableCell>
                        <TableCell
                            className="headerTable"
                            sx={{ minWidth: "150px" }}
                        >
                            <SkeletonBar width={80} height={14} />
                        </TableCell>
                        <TableCell
                             className="headerTable"
                             sx={{ minWidth: "150px" }}
                        >
                            <SkeletonBar width={80} height={14} />
                        </TableCell>
                        <TableCell
                            className="headerTable"
                            sx={{ minWidth: "150px" }}
                        >
                            <SkeletonBar width={80} height={14} />
                        </TableCell>
                        <TableCell
                            className="headerTableReasignar" 
                        >
                            <SkeletonBar width={80} height={14} />
                        </TableCell>
                        <TableCell
                            className="headerTableEspecial" 
                        >
                            <SkeletonBar width={80} height={14} />
                        </TableCell>
                        <TableCell
                            sx={styleHead}
                            className="headerFirstSticky"
                        >
                            <SkeletonBar width={80} height={14} />
                        </TableCell>
                    </TableRow>
                </TableHead>

                <TableBody>

                    <TableRow >
                        <TableCell
                            padding="checkbox"
                            className="tCell-p"
                            sx={stickyBodyLeft("0")}
                        >
                            <div style={{ paddingLeft: "20px", paddingRight: "20px" }}>
                                <SkeletonBar width={20} height={20} />
                            </div>
                        </TableCell>

                        <TableCell className="tCell-p" component="th" scope="row">
                            <SkeletonBar width={100} height={14} />
                        </TableCell>
                        <TableCell className="tCell-p" component="th" scope="row">
                            <SkeletonBar width={100} height={14} />
                        </TableCell>
                        <TableCell className="tCell-p" component="th" scope="row">
                            <SkeletonBar width={100} height={14} />
                        </TableCell>
                        <TableCell className="tCell-p" component="th" scope="row">
                            <SkeletonBar width={100} height={14} />
                        </TableCell>
                        <TableCell className="tCell-p" component="th" scope="row">
                            <SkeletonBar width={128} height={40} />
                        </TableCell>
                        <TableCell className="tableBodyReasignar"  >
                            <SkeletonBar width={92} height={24} />
                        </TableCell>
                        <TableCell className="tableBodyEspecial" >
                            <SkeletonBar width={92} height={40} />
                        </TableCell>
                        <TableCell className="tableBodyAcciones" >
                            <SkeletonBar width={28} height={14} />
                        </TableCell>
                    </TableRow>
                    <TableRow >
                        <TableCell
                            padding="checkbox"
                            className="tCell-p"
                            sx={stickyBodyLeft("0")}
                        >
                            <div style={{ paddingLeft: "20px", paddingRight: "20px" }}>
                                <SkeletonBar width={20} height={20} />
                            </div>
                        </TableCell>

                        <TableCell className="tCell-p" component="th" scope="row">
                            <SkeletonBar width={100} height={14} />
                        </TableCell>
                        <TableCell className="tCell-p" component="th" scope="row">
                            <SkeletonBar width={100} height={14} />
                        </TableCell>
                        <TableCell className="tCell-p" component="th" scope="row">
                            <SkeletonBar width={100} height={14} />
                        </TableCell>
                        <TableCell className="tCell-p" component="th" scope="row">
                            <SkeletonBar width={100} height={14} />
                        </TableCell>
                        <TableCell className="tCell-p" component="th" scope="row">
                            <SkeletonBar width={128} height={40} />
                        </TableCell>
                        <TableCell className="tableBodyReasignar"  >
                            <SkeletonBar width={92} height={24} />
                        </TableCell>
                        <TableCell className="tableBodyEspecial" >
                            <SkeletonBar width={92} height={40} />
                        </TableCell>
                        <TableCell className="tableBodyAcciones" >
                            <SkeletonBar width={28} height={14} />
                        </TableCell>
                    </TableRow>
                    <TableRow >
                        <TableCell
                            padding="checkbox"
                            className="tCell-p"
                            sx={stickyBodyLeft("0")}
                        >
                            <div style={{ paddingLeft: "20px", paddingRight: "20px" }}>
                                <SkeletonBar width={20} height={20} />
                            </div>
                        </TableCell>

                        <TableCell className="tCell-p" component="th" scope="row">
                            <SkeletonBar width={100} height={14} />
                        </TableCell>
                        <TableCell className="tCell-p" component="th" scope="row">
                            <SkeletonBar width={100} height={14} />
                        </TableCell>
                        <TableCell className="tCell-p" component="th" scope="row">
                            <SkeletonBar width={100} height={14} />
                        </TableCell>
                        <TableCell className="tCell-p" component="th" scope="row">
                            <SkeletonBar width={100} height={14} />
                        </TableCell>
                        <TableCell className="tCell-p" component="th" scope="row">
                            <SkeletonBar width={128} height={40} />
                        </TableCell>
                        <TableCell className="tableBodyReasignar"  >
                            <SkeletonBar width={92} height={24} />
                        </TableCell>
                        <TableCell className="tableBodyEspecial" >
                            <SkeletonBar width={92} height={40} />
                        </TableCell>
                        <TableCell className="tableBodyAcciones" >
                            <SkeletonBar width={28} height={14} />
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
        <Box justifyContent={"center"} p={"24px"}>
            <Stack direction={"row"} spacing={"24px"} justifyContent={"center"} alignItems={"center"}>
                <SkeletonBar width={108} height={14} />
                <SkeletonBar width={68} height={32} />
                <SkeletonBar width={88} height={32} />
            </Stack>

        </Box>
    </Box>
);

export const LoadEmitidas = () => (
    <Box  >
        <TableContainer sx={{ p: 0, pt: "18px" }}>
            <Table sx={{ minWidth: "1500px" }}>
                <TableHead>
                    <TableRow>
                        <TableCell
                            padding="checkbox"
                            className="tCell-p"
                            sx={stickyHead}
                        >
                            <div style={{ paddingLeft: "20px", paddingRight: "20px" }}>
                                <SkeletonBar width={20} height={20} />
                            </div>
                        </TableCell>

                        <TableCell
                            sx={styleHead}
                        >
                            <SkeletonBar width={80} height={14} />
                        </TableCell>
                        <TableCell
                            sx={styleHead}
                        >
                            <SkeletonBar width={80} height={14} />
                        </TableCell>
                        <TableCell
                            sx={styleHead}
                        >
                            <SkeletonBar width={80} height={14} />
                        </TableCell>
                        <TableCell
                            sx={styleHead}
                        >
                            <SkeletonBar width={80} height={14} />
                        </TableCell>
                        <TableCell
                            sx={styleHead}
                        >
                            <SkeletonBar width={80} height={14} />
                        </TableCell>
                        <TableCell
                            sx={styleHead}
                        >
                            <SkeletonBar width={80} height={14} />
                        </TableCell>
                        <TableCell
                            sx={styleHead}
                        >
                            <SkeletonBar width={80} height={14} />
                        </TableCell>
                        <TableCell
                            className="headerFirstSticky"
                            sx={styleHead}
                        >
                            <SkeletonBar width={80} height={14} />
                        </TableCell>
                    </TableRow>
                </TableHead>

                <TableBody>

                    <TableRow >
                        <TableCell
                            padding="checkbox"
                            className="tCell-p"
                            sx={stickyBodyLeft("0")}
                        >
                            <div style={{ paddingLeft: "20px", paddingRight: "20px" }}>
                                <SkeletonBar width={20} height={20} />
                            </div>
                        </TableCell>

                        <TableCell className="tCell-p" component="th" scope="row">
                            <SkeletonBar width={100} height={14} />
                        </TableCell>
                        <TableCell className="tCell-p" component="th" scope="row">
                            <SkeletonBar width={100} height={14} />
                        </TableCell>
                        <TableCell className="tCell-p" component="th" scope="row">
                            <SkeletonBar width={100} height={14} />
                        </TableCell>
                        <TableCell className="tCell-p" component="th" scope="row">
                            <SkeletonBar width={100} height={14} />
                        </TableCell>
                        <TableCell className="tCell-p" component="th" scope="row">
                            <SkeletonBar width={128} height={40} />
                        </TableCell>
                        <TableCell className="tCell-p" >
                            <SkeletonBar width={92} height={24} />
                        </TableCell>
                        <TableCell className="tCell-p">
                            <SkeletonBar width={92} height={40} />
                        </TableCell>
                        <TableCell className="tableBodyAcciones" sx={{ right: "-2px" }}>
                            <SkeletonBar width={28} height={14} />
                        </TableCell>
                    </TableRow>
                    <TableRow >
                        <TableCell
                            padding="checkbox"
                            className="tCell-p"
                            sx={stickyBodyLeft("0")}
                        >
                            <div style={{ paddingLeft: "20px", paddingRight: "20px" }}>
                                <SkeletonBar width={20} height={20} />
                            </div>
                        </TableCell>

                        <TableCell className="tCell-p" component="th" scope="row">
                            <SkeletonBar width={100} height={14} />
                        </TableCell>
                        <TableCell className="tCell-p" component="th" scope="row">
                            <SkeletonBar width={100} height={14} />
                        </TableCell>
                        <TableCell className="tCell-p" component="th" scope="row">
                            <SkeletonBar width={100} height={14} />
                        </TableCell>
                        <TableCell className="tCell-p" component="th" scope="row">
                            <SkeletonBar width={100} height={14} />
                        </TableCell>
                        <TableCell className="tCell-p" component="th" scope="row">
                            <SkeletonBar width={128} height={40} />
                        </TableCell>
                        <TableCell className="tCell-p" >
                            <SkeletonBar width={92} height={24} />
                        </TableCell>
                        <TableCell className="tCell-p">
                            <SkeletonBar width={92} height={40} />
                        </TableCell>
                        <TableCell className="tableBodyAcciones" sx={{ right: "-2px" }}>
                            <SkeletonBar width={28} height={14} />
                        </TableCell>
                    </TableRow>
                    <TableRow >
                        <TableCell
                            padding="checkbox"
                            className="tCell-p"
                            sx={stickyBodyLeft("0")}
                        >
                            <div style={{ paddingLeft: "20px", paddingRight: "20px" }}>
                                <SkeletonBar width={20} height={20} />
                            </div>
                        </TableCell>

                        <TableCell className="tCell-p" component="th" scope="row">
                            <SkeletonBar width={100} height={14} />
                        </TableCell>
                        <TableCell className="tCell-p" component="th" scope="row">
                            <SkeletonBar width={100} height={14} />
                        </TableCell>
                        <TableCell className="tCell-p" component="th" scope="row">
                            <SkeletonBar width={100} height={14} />
                        </TableCell>
                        <TableCell className="tCell-p" component="th" scope="row">
                            <SkeletonBar width={100} height={14} />
                        </TableCell>
                        <TableCell className="tCell-p" component="th" scope="row">
                            <SkeletonBar width={128} height={40} />
                        </TableCell>
                        <TableCell className="tCell-p" >
                            <SkeletonBar width={92} height={24} />
                        </TableCell>
                        <TableCell className="tCell-p">
                            <SkeletonBar width={92} height={40} />
                        </TableCell>
                        <TableCell className="tableBodyAcciones" sx={{ right: "-2px" }}>
                            <SkeletonBar width={28} height={14} />
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
        <Box justifyContent={"center"} p={"24px"}>
            <Stack direction={"row"} spacing={"24px"} justifyContent={"center"} alignItems={"center"}>
                <SkeletonBar width={108} height={14} />
                <SkeletonBar width={68} height={32} />
                <SkeletonBar width={88} height={32} />
            </Stack>

        </Box>
    </Box>
);
