import React from "react";
import { useMsal } from "@azure/msal-react";
import { Button } from "@mui/material";

function handleLogout(instance : any) {
    instance.logoutRedirect().catch((e : any) => {
        console.error(e);
    });
}


export const SignOutButton = () => {
    const { instance } = useMsal();

    return (
        <Button 
            variant="contained"
            onClick={() => handleLogout(instance)}
            disableRipple
            sx={{ boxShadow:'none'}}>
            Cerrar Sesión
        </Button>
    );
}