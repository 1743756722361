import { useState } from "react";
import { SlimValueSelect2 } from "../../../../components/Selects/valueSelect"
import { queryObtenerRegimen } from "../../../../services/administracionCM/administracionCMServices";
import { useQuery } from "@apollo/client";

export const SelectRegimen = ({ disabled, onChange, value }:any) => {
    const [dataTipoRegimen, setDataTipoRegimen] = useState<any[]>([]);
    useQuery(queryObtenerRegimen, {
        fetchPolicy: "no-cache",
        onCompleted: (res) => {
            setDataTipoRegimen(res.obtenerValoresParametros);
        }
    });
    return (
        <SlimValueSelect2
            onChange={onChange}
            disabled={disabled}
            value={value}            
            data={dataTipoRegimen}
            titulo={"Seleccione un Tipo"}
            name="tipoRegimen"
        />
    )
}