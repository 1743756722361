import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Typography,
} from "@mui/material";
import { grayName } from "../../../assets/styles/Text/text";
import {
    formatoCompletoNombrePaciente,
    formatRut,
    numbersWithDots
} from "../../../utils/index";
import { NoResult } from "../../../components/Tables/noResult";
import { verificaLargo } from "../../../components/Tooltip/Tooltip";
import { HeadConsultaCtas } from "./headConsultaCtas";
import { Paginador } from "../../../components/TablePagination/Paginador";
import { NoInformation } from "./noInfo";
import { EtiquetaEstado } from "../../escritorioTrabajo/components/EtiquetaEstado";
import { formatDate } from "../../../utils/dateUtils";
import { MenuConsultas } from "./MenuConsulta";

export const TableConsultaCtas = ({
    cuentas,
    contador,
    filtros,
    error,
    pagConsulta,
    setPagConsulta,
    lineasConsulta,
    setLineasConsulta,
    ordenConsulta, 
    dirOrdenConsulta,
    handleRequestSort
}: any) => {
    return (
        <Paper className="paper-tables" sx={{ borderRadius: "0 !important" }}>
            {error ?
                <NoResult
                    titulo={"No se pudo cargar la tabla"}
                    subtitulo={
                        "No fue posible mostrar la información debido a un problema de conexión. Intenta recargar la página para solucionar el problema."
                    }
                    noDivider={false}
                    error={true}
                /> :
                contador > 0 ?
                    <>
                        <TableContainer sx={{ pl: "0 !important", width: "auto" }}>
                            <Table aria-label="customized table">
                                <HeadConsultaCtas 
                                    onRequestSort={handleRequestSort}
                                    orderBy={ordenConsulta}
                                    order={dirOrdenConsulta}
                                />
                                <TableBody>
                                    {cuentas.map((cta: any) => {
                                        const nombrePaciente = formatoCompletoNombrePaciente(
                                            cta.nombrePaciente,
                                            cta.apellidoPaternoPaciente,
                                            cta.apellidoMaternoPaciente
                                        );

                                        return (
                                          <TableRow key={cta.id} hover>
                                            <TableCell>{cta.id}</TableCell>
                                            <TableCell>
                                              <NoInformation data={cta.idCMD} />
                                            </TableCell>
                                            <TableCell
                                              component="th"
                                              scope="row"
                                            >
                                              <NoInformation
                                                data={cta.numeroCuentaPrestador}
                                              />
                                            </TableCell>
                                            <TableCell
                                              component="th"
                                              scope="row"
                                            >
                                              <Typography>
                                                {formatRut(cta.rutPaciente)}
                                              </Typography>
                                              <Typography sx={grayName}>
                                                {verificaLargo(
                                                  nombrePaciente,
                                                  10
                                                )}
                                              </Typography>
                                            </TableCell>
                                            <TableCell>
                                              <Typography>
                                                {formatRut(
                                                  cta.prestador.rutPrestador
                                                )}
                                              </Typography>
                                              <Typography sx={grayName}>
                                                {verificaLargo(
                                                  cta.prestador.razonSocial,
                                                  10
                                                )}
                                              </Typography>
                                            </TableCell>
                                            <TableCell>
                                              {EtiquetaEstado(cta.estado)}
                                            </TableCell>
                                            <TableCell>
                                              {(cta.causal.descripcionCausal ===
                                                "null" ||
                                              cta.causal.descripcionCausal === null)
                                                ? "N/A"
                                                : verificaLargo(
                                                  cta.causal.descripcionCausal,
                                                  10
                                                )}
                                            </TableCell>
                                            <TableCell>
                                              <NoInformation
                                                data={cta.fechaRecepcion}
                                              />
                                            </TableCell>
                                            <TableCell>
                                              <NoInformation
                                                data={formatDate(
                                                  cta.fechaAsignacionUsuario
                                                )}
                                              />
                                            </TableCell>
                                            <TableCell>
                                              <NoInformation
                                                data={formatDate(
                                                  cta.fechaProbableLiquidacion
                                                )}
                                              />
                                            </TableCell>
                                            <TableCell>
                                              {cta.diasRestantes}
                                            </TableCell>

                                            <TableCell>
                                              {cta.montoTotalCobro ? (
                                                <Typography>
                                                  $
                                                  {numbersWithDots(
                                                    cta.montoTotalCobro.toString()
                                                  )}
                                                </Typography>
                                              ) : (
                                                <Typography className="disabled-text">
                                                  {"Sin información"}
                                                </Typography>
                                              )}
                                            </TableCell>
                                            <TableCell>
                                              {cta.canalOrigen}
                                            </TableCell>
                                            <TableCell
                                              component="th"
                                              scope="row"
                                            >
                                              <Typography>
                                                {cta.usuarioAsignado}
                                              </Typography>
                                            </TableCell>
                                            <TableCell
                                              className="tableBodyAcciones"
                                              align="center"
                                            >
                                              <MenuConsultas cta={cta} />
                                            </TableCell>
                                          </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <Paginador
                            data={contador}
                            pagina={pagConsulta}
                            setPagina={setPagConsulta}
                            itemPorPagina={lineasConsulta}
                            setLineasPagina={setLineasConsulta}
                            opciones={[25, 50, 100]}
                        />
                    </>
                    : Object.keys(filtros).length === 0 ?
                        <NoResult
                            titulo={"No se han recibido cuentas médicas"}
                            subtitulo={"Ingresa una cuenta médica"}
                            noDivider={false}
                            newFound={true}
                        /> :
                        <NoResult
                            titulo={"No se encontró información de cuentas médicas"}
                            subtitulo={"Inténtelo nuevamente usando otro criterio"}
                            noDivider={false}
                            newFound={true}
                        />
            }

        </Paper >
    );
};
