import { regex } from "./index";
/**
 * Toma una fecha en formato: YYYY-MM-DD y devuelve DD-MM-YY
 * @param {string} fecha - Fecha a formatear.
 * @returns {string} Nueva fecha formateada, misma fecha si es inválida.
 */
export const convertirFecha = (fecha: string): string => {
  const partes = fecha.split("-");
  if (partes.length === 3) {
    const [anio, mes, dia] = partes;
    return `${dia}-${mes}-${anio.slice(2)}`;
  }
  return fecha;
};

export const formatDate = (date: string) => {
  if (date !== null && date !== undefined && regex.emptyinput.test(date) === false) {
    const [year, month, day] = date.split("-")
    return `${day}-${month}-${year}`
  } else {
    return ""
  }
}


export const formatDateSlash = (date: string) => {
  if (date !== null && date !== undefined && regex.emptyinput.test(date) === false) {
    if (date.includes('-')) return date;
    const [year, month, day] = date.split("/")
    return `${day}-${month}-${year}`
  } else {
    return ""
  }
}
