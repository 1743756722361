import {
    DialogActions,
    DialogContent,
    FormControl,
    FormHelperText,
    Grid,
    Typography,
} from "@mui/material";
import { CustomModal, CustomModalTitle } from "../../../../components/Modal/customModal";
import { bonifBold, bonifParen, bonifParenBold, bonifText, modalTitle } from "../../../../assets/styles/Text/text";
import { CustomInput } from "../../../../assets/styles/Input/input";
import { formHeader } from "../../../../assets/styles/Box/boxs";
import { SecondaryButton } from "../../../../components/Buttons/Button";
import { useMutation } from "@apollo/client";
import { mutationForzarBonificacion } from "../../../../services/administracionCM/bonificacionServices";
import { Fragment, useEffect, useState } from "react";
import { parseMiles } from "../../../../utils/monedaUtils";
import BotonCarga from "../../../../components/Buttons/LoadingButton";
import { checkStatus } from "../../utils/checkStatus";
import { WarningModalCustom } from "../../../../components/Modal/warningModalCustom";
const tiposDocumentos = ["Prefactura", "Boleta", "Factura"];

const calculaPorcentajePorMonto = (monto: string, montoCobro: number, isInput?: boolean | undefined) => {
    if (monto) {
        const procentajeCalculado = 100 * parseInt(monto) / montoCobro
        return (Math.round(procentajeCalculado).toString());
    } else {
        return isInput ? "" : "0"
    }
}

export const ForzarBonificacion = ({ onOpen, onClose, row, showMessage, tipoCobertura, tipoDoc, refetch, isCuentaAuth, refetchDoc, authFilter }: any) => {
    const [porcentaje, setPorcentaje] = useState<string | null>("");
    const [montoForzado, setMontoForzado] = useState<string | null>(null);
    const [errores, setErrores] = useState<any>({});
    const [requiresAuth, setRequiresAuth] = useState<boolean>(false);
    const [warningAuth, setWarningAuth] = useState<boolean>(false);
    const [fnnForzar, { loading }] = useMutation(mutationForzarBonificacion);
    const procentajeCalculado = Math.round(100 * parseInt(row.montoBonificacionMinima || 1) / row.montoPrestacionCobro);

    const handleBtnForzar = () => {
        fnnForzar({
            variables: { id: row.id, montoBonificacionForzada: parseInt(montoForzado!), requireAuth: requiresAuth },
            onCompleted: (res) => {
                if (res.forzarBonificacion.respuesta.estado === 1) {
                    showMessage(res.forzarBonificacion.respuesta.mensaje, 'warning');
                } else {
                    if (requiresAuth && !isCuentaAuth) {
                        onClose();
                        setWarningAuth(true);
                    }
                    else {
                        finishProcess("Prestación forzada correctamente");
                    }
                }

            },
            onError: (err: any) => {
                console.log(err.message);
                showMessage("No se ha Podido Bonificar la Cuenta Medica. Por favor, inténtelo nuevamente", 'error');
                checkStatus(err.graphQLErrors[0]?.code || "");
            }
        });
    };

    const ingresaPorcentaje = (e: any) => {
        const commatodot = e.target.value.replace(",", ".");
        const value = commatodot.replace(/\D/g, ',');
        setErrores({});
        if (Number(value) <= 100 && Number(value) > 0) {
            setPorcentaje(value);
            const monto = parseFloat(value) * row.montoPrestacionCobro / 100
            setMontoForzado(monto.toString());
            if (monto < (row.montoBonificacionMinima || 1)) {
                setRequiresAuth(true);
                return;
            }
        }
        if (value === "") {
            setPorcentaje(value);
            setMontoForzado(null);
        }
        setRequiresAuth(false);
    }

    const ingresaMonto = (e: any) => {
        let value = e.target.value.replace(/[^0-9]/g, '');
        setMontoForzado(value);
        setPorcentaje(calculaPorcentajePorMonto(value, row.montoPrestacionCobro, true));

        if (value) {
            if (parseInt(value) >= 1 && parseInt(value) <= row.montoPrestacionCobro) {
                setErrores({});
                if (parseInt(value) < (row.montoBonificacionMinima || 1)) {
                    setRequiresAuth(true);
                    return;
                }
            } else {
                setErrores({ monto: `El monto ingresado no es válido, el mínimo es $1 y el máximo es ${parseMiles(row.montoPrestacionCobro)}` });
            }
        }
        setRequiresAuth(false);
    }

    const checkMonto = () => {
        if (montoForzado) {
            if (parseInt(montoForzado) >= 1 && parseInt(montoForzado) <= row.montoPrestacionCobro) {
                return false;
            } else {
                return true;
            }
        } else {
            return true;
        }
    }

    const cleanAndClose = () => {
        if (isCuentaAuth && !authFilter) {
            setPorcentaje("");
            setMontoForzado(null);
        }

        setErrores({});
        onClose();
        setRequiresAuth(false);
        setWarningAuth(false);
    }
    const finishProcess = (msg: string) => {
        showMessage(msg, 'success');
        refetch();
        refetchDoc();
        cleanAndClose();
    }

    useEffect(() => {
        if (!onOpen && row.montoBonificacionForzado && montoForzado !== row.montoBonificacionForzado) {
            setPorcentaje(calculaPorcentajePorMonto(row.montoBonificacionForzado, row.montoPrestacionCobro));
            setMontoForzado(row.montoBonificacionForzado);
        } else {
            if (!onOpen && !row.montoBonificacionForzado && montoForzado) {
                setPorcentaje("");
                setMontoForzado(null);
            }
        }
    }, [onOpen, montoForzado, row])

    return (
        <Fragment>
            <WarningModalCustom
                headWarning
                noSecondAction
                onClose={() => finishProcess("Se ha marcado exitosamente la prestación como Pte de autorización")}
                title="Solicitud de autorización"
                contentText={<Grid>
                    <Typography>
                        Para forzar una prestación por un<br />
                        monto menor al de la bonificación mínima obligatoria,<br />
                        usa el botón "solicitar autorización", para 1 o más prestaciones<br />
                        marcadas como Pte. autorización
                    </Typography>
                </Grid>}
                primaryButton="Cerrar"
                onOpen={warningAuth}
            />
            <CustomModal
                fullWidth
                maxWidth="md"
                open={onOpen}
                sx={{
                    "& .MuiBackdrop-root": {
                        backgroundColor: "rgba(0, 0, 0, 0.3)",
                    },
                }}
            >
                <CustomModalTitle>
                    <Typography sx={modalTitle} paddingTop="16px">
                        Forzar bonificación
                    </Typography>
                </CustomModalTitle>

                <DialogContent
                    dividers
                    sx={{ pl: "16px", }}
                    className="grayScroll"
                >
                    {/*<LoadBonificacionForzar />*/}
                    <Grid container border={"1px solid #D2D6D9"} borderRadius={"8px"}>
                        <Grid item xs={12} sx={{ borderBottom: "1px solid #D2D6D9" }} p="14px ">
                            <Typography sx={bonifBold}>Tipo Cobertura:</Typography>{' '}
                            <Typography sx={bonifText}>{tipoCobertura}</Typography>
                        </Grid>
                        <Grid item xs={12} sx={{ borderBottom: "1px solid #D2D6D9" }} p="14px ">
                            <Typography sx={bonifBold}>Cobertura Plan:</Typography>{' '}
                            <Typography sx={bonifText}>{row.idCoberturaPlan ? row.idCoberturaPlan : "Sin Cobertura Plan"}</Typography>
                        </Grid>
                        <Grid item xs={12} sx={{ borderBottom: "1px solid #D2D6D9" }} p="14px ">
                            <Typography sx={bonifBold}>Tipo Documento:</Typography>{' '}
                            <Typography sx={bonifText}>{tiposDocumentos[tipoDoc - 1]}</Typography>
                        </Grid>
                        <Grid item xs={12} sx={{ borderBottom: "1px solid #D2D6D9" }} p="14px ">
                            <Typography sx={bonifBold}>Prestación Isapre:</Typography>{' '}
                            <Typography sx={bonifText}>{row.codigoPrestacionIsapre ? row.codigoPrestacionIsapre + " - " + row.glosaPrestacionIsapre : "-"}</Typography>
                        </Grid>
                        <Grid item xs={12} sx={{ borderBottom: "1px solid #D2D6D9" }} p="14px ">
                            <Typography sx={bonifBold}>Item Prestación:</Typography>{' '}
                            <Typography sx={bonifText}>{row.idItemIsapre ? row.idItemIsapre + " - " + row.descripcionItemProfesional : "-"}</Typography>
                        </Grid>
                        <Grid item xs={12} sx={{ borderBottom: "1px solid #D2D6D9" }} p="14px ">
                            <Typography sx={bonifBold}>Prestación Prestador:</Typography>{' '}
                            <Typography sx={bonifText}>{row.codigoPrestacionPrestador || ""} - {row.glosaPrestacionPrestador || ""}</Typography>
                        </Grid>
                        <Grid item xs={12} sx={{ borderBottom: "1px solid #D2D6D9" }} p="14px ">
                            <Typography sx={bonifBold}>Monto Prestación:</Typography>{' '}
                            <Typography sx={bonifText}>{parseMiles(row.montoPrestacionCobro) || "$0"}</Typography>
                        </Grid>
                        <Grid item xs={12} sx={{ borderBottom: "1px solid #D2D6D9" }} p="14px ">
                            <Typography sx={bonifBold}>Monto Convenio:</Typography>{' '}
                            <Typography sx={bonifText}>{parseMiles(row.montoPrestacionCobro) || "$0"}</Typography>
                        </Grid>
                        <Grid item xs={12} sx={{ borderBottom: "1px solid #D2D6D9" }} p="14px ">
                            <Typography sx={bonifBold}>Monto Bonificado Plan:</Typography>{' '}
                            <Typography sx={bonifText}>{parseMiles(row.montoBonificadoPlan) || "$0"} ({calculaPorcentajePorMonto(row.montoBonificadoPlan, row.montoPrestacionCobro)}%)</Typography>
                        </Grid>
                        <Grid item xs={12} sx={{ borderBottom: "1px solid #D2D6D9" }} p="14px ">
                            <Typography sx={bonifBold}>Monto Bonificación topada:</Typography>{' '}
                            <Typography sx={bonifText}>{parseMiles(row.montoBonificacionTopada) || "$0"} ({calculaPorcentajePorMonto(row.montoBonificacionTopada, row.montoPrestacionCobro)}%)</Typography>
                        </Grid>
                        <Grid item xs={12} sx={{ borderBottom: "1px solid #D2D6D9" }} p="14px ">
                            <Typography sx={bonifBold}>Monto Copago:</Typography>{' '}
                            <Typography sx={bonifText}>{parseMiles(row.montoCopago) || "$0"} ({calculaPorcentajePorMonto(row.montoCopago, row.montoPrestacionCobro)}%)</Typography>
                        </Grid>
                    </Grid>
                    <Grid container sx={formHeader} mt="23px">
                        <Grid item xs={4} pr="16px">
                            <Typography variant="h6" pb="6px">
                                Porcentaje Bonificación Forzada
                            </Typography>
                            <CustomInput
                                fullWidth
                                placeholder="Ej: 10%"
                                data-testid="porcentajeInput"
                                value={porcentaje || ""}
                                onChange={ingresaPorcentaje}
                            />
                        </Grid>
                        <Grid item xs={4} pr="16px">
                            <FormControl error={errores.monto ? true : undefined}>
                                <Typography variant="h6" pb="6px">
                                    Monto Bonificación Forzada
                                </Typography>
                                <CustomInput
                                    fullWidth
                                    placeholder="Ej: $10.000"
                                    data-testid="montoInput"
                                    value={parseMiles(montoForzado) || ""}
                                    onChange={ingresaMonto}
                                />
                                <FormHelperText>{errores.monto || (requiresAuth && !isCuentaAuth ? <label style={{ color: requiresAuth ? "#5D45FF" : undefined }}>Se solicitará autorización</label> : "")}</FormHelperText>
                            </FormControl>

                        </Grid>
                        <Grid item xs={1} pt="30px" pr="16px">
                            <Typography variant="h4" pb="6px" sx={{ color: "#01a49a" }}>
                                {procentajeCalculado}%
                            </Typography>
                        </Grid>
                        <Grid item xs={1} pt="25px">
                            <Grid>
                                <Typography variant="h6" pb="6px" sx={bonifParen}>
                                    Bonificación
                                </Typography>
                                <Typography variant="h6" pb="6px" sx={bonifParenBold} pl="4px">
                                    mínima
                                    permitida
                                </Typography>
                                <Typography variant="h6" pb="6px" sx={{ ...bonifParen, color: "#01a49a" }} pl="4px">
                                    {parseMiles(row.montoBonificacionMinima || 1)}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions sx={{ margin: "5px 0 5px 0", justifyContent: "space-between" }}>
                    <SecondaryButton
                        variant="contained"
                        sx={{ p: "15px" }}
                        onClick={cleanAndClose}
                        disabled={loading}
                    >
                        Cancelar
                    </SecondaryButton>
                    <BotonCarga
                        toDo={handleBtnForzar}
                        loading={loading}
                        disabled={checkMonto()}
                        testid="forzarBtn"
                    >
                        Forzar bonificación
                    </BotonCarga>
                </DialogActions>
            </CustomModal>
        </Fragment>
    );
};
