import { IconButton } from "@mui/material"
import Trash from "../../../../assets/icons/trashTable.svg";
import { useMutation } from "@apollo/client";
import { deshabilitaPrestacion } from "../../../../services/administracionCM/administracionCMServices";
import { checkStatus } from "../../utils/checkStatus";

type eliminaPrestArgs = {
    idPrestacion: number,
    refetch: Function,
    muestraMensaje: Function,
    disabled?: boolean,
    isBonif: boolean
}

export const EliminarPrestacion = ({ idPrestacion, refetch, muestraMensaje, disabled, isBonif }: eliminaPrestArgs) => {
    const [eliminarPrest, { loading }] = useMutation(deshabilitaPrestacion, {
        variables: {
            idPrestacion: [idPrestacion],
            type: isBonif ? 2 : 1
        },
        onCompleted: () => {
            muestraMensaje("Prestación(es) eliminada(s) con éxito.", 'success');
            refetch();
        },
        onError: (err:any) => {
            console.log(err.message)
            muestraMensaje("Ocurrió un error al eliminar la(s) prestación(es). Por favor, inténtalo de nuevo.", 'error');
            checkStatus(err.graphQLErrors[0]?.code || "");
        }
    });
    return (
        <IconButton
            data-testid="btnEliminar"
            disabled={disabled || loading}
            onClick={() => eliminarPrest()}
        >
            <img src={Trash} />
        </IconButton>
    )
}