import { IconButton } from '@mui/material';
import ArrowDropUpRoundedIcon from '@mui/icons-material/ArrowDropUpRounded';
import ArrowDropDownRoundedIcon from '@mui/icons-material/ArrowDropDownRounded';

export const DropdownIndicator = ({ active, order }: any) => {
    const arrowIcon = order === 'asc' ? <ArrowDropUpRoundedIcon /> : <ArrowDropDownRoundedIcon />;
    return (
        <IconButton sx={{
            padding: '0px',
            marginLeft: '6px',
            '&:hover': {
                color: '#01A49A',
            },
            color: order === 'asc' ? '#01A49A' : '#63717B',
        }} >
            {active ? arrowIcon : null}
        </IconButton >
    )
};