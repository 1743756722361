import { useState } from "react"

interface Props {
  fnReloadData: any;
  initialOrderBy: string;
  initialRowsPerPage: number;
}

function useTablePagination( { initialOrderBy, fnReloadData, initialRowsPerPage } : Props){

  const [order, setOrder] = useState<string>("DESC")
  const [orderBy, setOrderBy] = useState<string>(initialOrderBy);
  const [orderColumn, setOrderColumn] = useState<string>("N° CM")
  const [page, setPage] = useState<number>(0)
  const [rowsPerPage, setRowsPerPage] = useState<number>(initialRowsPerPage)

  const changePage = (newPage: number) => {
    setPage(newPage)
  }

  const changeOrder = () => {
    if(order === "DESC"){
      setOrder("ASC")
    }else{
      setOrder("DESC")
    }
  }

  const changeOrderBy = (newOrder: string) => {
    setOrderBy(newOrder)
    setOrderColumn(newOrder)
  }

  const handleNewChangePage = async (e: any, newPage: number) => {
    changePage(newPage)
    const offset = newPage * rowsPerPage
    fnReloadData(offset, rowsPerPage)
  }

  const handleNewChangeRowsPerPage = async (e: any) => {
    const newRowsPerPage = parseInt(e.target.value, 10)
    setRowsPerPage(newRowsPerPage)
    const offset = 0
    changePage(0)
    fnReloadData(offset, newRowsPerPage)
  }

  const handleRequestSort = (event: any, property: string) => {
      const isDESC = order === 'DESC';
      const offset = page * rowsPerPage 
      setOrder((prevState: string) => prevState === "DESC" ? 'ASC' : 'DESC');  
      setOrderBy(property);
      setPage(0)
      const orderBy= {[property] : { position: 1, direction: isDESC ? "ASC" : "DESC"}}
      fnReloadData(offset, rowsPerPage, orderBy)
    }

  return {
    order,
    orderBy,
    orderColumn,
    page,
    rowsPerPage,
    changePage,
    handleNewChangePage,
    handleNewChangeRowsPerPage,
    changeOrder,
    changeOrderBy,
    handleRequestSort
  }
}

export default useTablePagination;