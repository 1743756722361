import { Checkbox, TableCell, TableHead, TableRow, TableSortLabel } from '@mui/material';
import useGetDataRedux from '../../hooks/useGetDataRedux/useGetDataRedux';

import { stickyHead, styleHead } from '../../assets/styles/Table/table';

export const EnhancedTableHead = ({ onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort, headCells, children, isEmision = false }: any) => {

    const createSortHandler = (id: any, label: string) => (event: any) => {
        onRequestSort(event, id, label);
    };

    const {revisarCuentaMedica} =  useGetDataRedux()

    return (
        <TableHead>
            <TableRow>

                {isEmision 
                ? null 
                : (<TableCell
                    padding="checkbox"
                    className="tCell-p"
                    sx={stickyHead}
                >
                    <Checkbox
                        color="primary"
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{
                            'aria-label': 'select all desserts',
                        }}
                        disabled={revisarCuentaMedica}
                    />
                </TableCell>)}
                {headCells.map((headCell: any) => (
                    <TableCell
                        key={headCell.id}
                        sortDirection={orderBy === headCell.id ? order : false}
                        sx={styleHead}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id, headCell.label)}
                        >
                            {headCell.label}
                        </TableSortLabel>
                    </TableCell>
                ))}
                {children}
            </TableRow>
        </TableHead>
    );
}
