import { Drawer, styled } from "@mui/material";


const drawerStyle = {
    background: '#FFFFFF',
    color: '#67737C',
    borderRight: 'none',
    top: '72px',
    paddingTop: '16px',
}

const textSelectedDrawer = {fontWeight: 'bold', color: '#00837A'};

const CustomDrawer = styled(Drawer, { shouldForwardProp: (prop: any) => prop !== 'open' })(
    ({ theme, open }) => ({
        flexShrink: 0,
        whiteSpace: 'nowrap',
        ...(open && {
            '& .MuiDrawer-paper': {
                ...drawerStyle,
                width: 240,
                transition: theme.transitions.create('width', {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.enteringScreen,
                }),
                overflowX: 'hidden',
            },
        }),
        ...(!open && {
            '& .MuiDrawer-paper': {
                ...drawerStyle,
                width: 60,
                transition: theme.transitions.create('width', {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.leavingScreen,
                }),
                overflowX: 'hidden',
            },
        }),
    }),
);

export {
    CustomDrawer,
    textSelectedDrawer
}