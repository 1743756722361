import { Fragment, useState } from "react";
import {
  DialogActions,
  DialogContent,
  FormControl,
  FormHelperText,
  Grid,
  Typography,
} from "@mui/material";
import DisableFilter from "../../../assets/icons/disableFiltre.svg";
import FilterIcon from "../../../assets/icons/filtreIcon.svg";
import { CustomModal, CustomModalTitle } from "../../../components/Modal/customModal";
import { boldTitle, cardSubT } from "../../../assets/styles/Text/text";
import { PrimaryButton, SecondaryButton } from "../../../assets/styles/Button/Button";
import { CustomInput } from "../../../assets/styles/Input/input";
import { CustomSelect2 } from "../../../components/Selects/customSelect";
import { formatRut, validRut } from "chilean-rutify";
import { DatePicker } from "../../../components/DatePicker/DatePicker";
import { NuevosEstadosCuentaMedica } from "../../../utils/enums";

type modalArgs = {
  dataFiltro: filterArgs;
  disabled: boolean;
  findMD: Function;
  tabIndex: string;
};

type ErrorArgs = {
  rutPaciente?: boolean;
  rutPrestador?: boolean;
};

type filterArgs = {
  id?: string;
  idCMD?: string;
  numeroCuentaPrestador?: String;
  rutPaciente?: String;
  rutPrestador?: String;
  fechaRecepcion?: Date;
  fechaRealLiquidacion?: Date;
  fechaEmision?: Date;
  estado?: Number;
  estadoEmision?: Number | null;
  tipo?: number;
};

const tipos = [{ label: "Electrónica", value: NuevosEstadosCuentaMedica.Emitida }, { label: "Manual", value: NuevosEstadosCuentaMedica.EmitidaManual }];

export const ModalFiltros = ({
  dataFiltro,
  disabled,
  findMD,
  tabIndex,
}: modalArgs) => {
  const [modal, setModal] = useState<boolean>(false);
  const [localFilters, setLocalFilters] = useState<filterArgs>(dataFiltro);
  const [error, setError] = useState<ErrorArgs>({});

  if (!modal && localFilters !== dataFiltro) {
    setLocalFilters(dataFiltro);
  }

  const onChange = (e: { target: { id: any; value: any } }) => {
    const id: string = e.target.id;
    let value = e.target.value;
    if (id === "id" || id === "idCMD" || id === "estadoEmision") {
      value = value.replace(/[^0-9]/g, "");
    }
    if (id === "rutPaciente" || id === "rutPrestador") {
      let rut = e.target.value.replace(/[^0-9kK-]/g, "").trim();
      value = rut.length > 2 ? formatRut(rut)!.toUpperCase() : rut;
      if (value && !validRut(value)) {
        setError({ ...error, [id]: "Rut inválido" });
      } else {
        let err = error;
        delete err[id];
        setError(err);
      }
    }
    if (!value) {
      value = undefined;
    }
    setLocalFilters({ ...localFilters, [id]: value });
  };

  const closeModalEsc = () => {
    setModal(false);
  };

  const cleanFilters = () => {
    setError({});
    setLocalFilters(dataFiltro);
    closeModalEsc();
  };

  const triggerSearch = () => {
    let parseFilters = {
      id: localFilters.id || undefined,
      idCMD: localFilters.idCMD || undefined,
      numeroCuentaPrestador: localFilters.numeroCuentaPrestador || undefined,
      rutPaciente: localFilters.rutPaciente || undefined,
      rutPrestador: localFilters.rutPrestador || undefined,
      fechaRecepcion: localFilters.fechaRecepcion || undefined,
      fechaRealLiquidacion: localFilters.fechaRealLiquidacion || undefined,
      fechaEmision: localFilters.fechaEmision || undefined,
      estado: localFilters.estado || undefined,
      estadoEmision: localFilters.estadoEmision || undefined,
    };
    findMD(parseFilters, tabIndex);
    closeModalEsc();
  };
  return (
    <Fragment>
      <SecondaryButton
        startIcon={
          disabled ? (
            <img src={DisableFilter} alt="" />
          ) : (
            <img src={FilterIcon} alt="" />
          )
        }
        sx={{ height: "50px" }}
        disabled={disabled}
        onClick={() => setModal(true)}
      >
        Filtro
      </SecondaryButton>
      <CustomModal
        fullWidth
        maxWidth="md"
        open={modal}
        onClose={cleanFilters}
        sx={{
          "& .MuiBackdrop-root": {
            backgroundColor: "rgba(0, 0, 0, 0.3)",
          },
        }}
      >
        <CustomModalTitle>
          <div style={{ textAlign: "left" }}>
            <Typography sx={boldTitle}>Selección de filtros </Typography>
            <Typography sx={cardSubT}>
              Seleccione los criterios de búsqueda para encontrar emisiones
              específicas.
            </Typography>
          </div>
        </CustomModalTitle>

        <DialogContent dividers>
          <Grid container>
            <Grid item xs={6} pr="24px" pb="16px">
              <CustomInput
                label="Nº Cuenta"
                fullWidth
                id="id"
                data-testid="id"
                value={localFilters.id || ""}
                onChange={onChange}
                InputLabelProps={{ style: { color: "#909AA1" } }}
              />
            </Grid>
            <Grid item xs={6} pb="16px">
              <CustomInput
                label="Nº Cuenta Extranet"
                fullWidth
                id="idCMD"
                data-testid="idCMD"
                value={localFilters.idCMD || ""}
                onChange={onChange}
                InputLabelProps={{ style: { color: "#909AA1" } }}
              />
            </Grid>
            <Grid item xs={6} pr="24px" pb="16px">
              <CustomInput
                label="Nº Cuenta Prestador"
                fullWidth
                id="numeroCuentaPrestador"
                value={localFilters.numeroCuentaPrestador || ""}
                onChange={onChange}
                InputLabelProps={{ style: { color: "#909AA1" } }}
              />
            </Grid>
            <Grid item xs={6} pb="16px">
              <FormControl error={error.rutPaciente || false} fullWidth>
                <CustomInput
                  label="RUT Beneficiario"
                  fullWidth
                  error={error.rutPaciente}
                  inputProps={{
                    maxLength: 12,
                    style: { textTransform: "uppercase" },
                  }}
                  InputLabelProps={{ style: { color: "#909AA1" } }}
                  id="rutPaciente"
                  data-testid="rutPaciente"
                  value={localFilters.rutPaciente || ""}
                  onChange={onChange}
                />
                <FormHelperText>{error.rutPaciente || ""}</FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={6} pr="24px" pb="16px">
              <FormControl error={error.rutPrestador || false} fullWidth>
                <CustomInput
                  label="RUT Prestador"
                  fullWidth
                  id="rutPrestador"
                  data-testid="rutPrestador"
                  error={error.rutPrestador}
                  inputProps={{
                    maxLength: 12,
                    style: { textTransform: "uppercase" },
                  }}
                  InputLabelProps={{ style: { color: "#909AA1" } }}
                  value={localFilters.rutPrestador || ""}
                  onChange={onChange}
                />
                <FormHelperText>{error.rutPrestador || ""}</FormHelperText>
              </FormControl>
            </Grid>
              <Grid item xs={6} pb="16px">
                <DatePicker
                  value={localFilters.fechaRecepcion || null}
                  onChange={(date:Date | null | undefined) =>
                    setLocalFilters({
                      ...localFilters,
                      fechaRecepcion: date || undefined,
                    })
                  }
                  placeholder="Fecha Recepción"
                />
              </Grid>
              <Grid item xs={6} pr="24px" pb="16px">
                <DatePicker
                  value={localFilters.fechaRealLiquidacion || null}
                  onChange={(date:Date | null | undefined) =>
                    setLocalFilters({
                      ...localFilters,
                      fechaRealLiquidacion: date || undefined,
                    })
                  }
                  placeholder="Fecha Liquidación"
                />
              </Grid>
              {tabIndex === "2" ? (
                <Grid item xs={6} pb="16px">
                  <DatePicker
                    value={localFilters.fechaEmision || null}
                    onChange={(date:Date | null | undefined) =>
                      setLocalFilters({
                        ...localFilters,
                        fechaEmision: date || undefined,
                      })
                    }
                    placeholder="Fecha Emisión"
                  />
                </Grid>
              ) : null}
            {tabIndex === "2" ? (
              <Grid item xs={6} pr="24px" pb="16px">
                <CustomSelect2
                  defaultValue={0}
                  id="tipo"
                  value={localFilters.estado}
                  onChange={(e: any) =>
                    setLocalFilters({
                      ...localFilters,
                      estado: e?.target?.value || undefined,
                    })
                  }
                  data={tipos}
                  placeholder={"Tipo"}
                />
              </Grid>
            ) : null}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid container direction="row" justifyContent="space-between">
            <SecondaryButton
              color="primary"
              sx={{ p: "15px 41px" }}
              onClick={cleanFilters}
            >
              Cancelar
            </SecondaryButton>
            <PrimaryButton
              onClick={triggerSearch}
              disabled={
                Object.keys(error).length > 0 ||
                localFilters === dataFiltro
              }
              sx={{ marginLeft: "24px", p: "15px 41px" }}
            >
              Filtrar
            </PrimaryButton>
          </Grid>
        </DialogActions>
      </CustomModal>
    </Fragment>
  );
};
