import { createSelector } from "reselect";
import { useSelector } from 'react-redux'

function useGetDataRedux(){
  const getData = createSelector(
    (state: any) => state,
    (state: any) => {
      const data = {
        fechaInicioCobro: state.actGenerales.data.fechaInicioCobro,
        fechaTerminoCobro: state.actGenerales.data.fechaTerminoCobro,
        rutPrestador: state.actGenerales3.data.rutPrestador,
        rutBeneficiario: state.actGenerales2.data.rutBeneficiario,
        nombreBeneficiario: state.actGenerales2.data.nombreBeneficiario,
        apellidoPaternoBeneficiario: state.actGenerales2.data.apellidoPaternoBeneficiario,
        apellidoMaternoBeneficiario: state.actGenerales2.data.apellidoMaternoBeneficiario,
        idCuentaMedica: state.idCuentaMedica.data,
        poliza: state.poliza.data,
        numeroCuentaPrestador: state.actGenerales3.data.numeroCuentaPrestador,
        fechaInicioHospitalizacion: state.actGenerales4.data.fechaInicioHospitalizacion,
        fechaEgresoHospitalizacion: state.actGenerales4.data.fechaEgresoHospitalizacion,
        horaInicioHospitalizacion: state.actGenerales4.data.horaInicioHospitalizacion,
        horaEgresoHospitalizacion: state.actGenerales4.data.horaEgresoHospitalizacion,
        condicionAltaPaciente: state.actGenerales4.data.condicionAltaPaciente,
        listadoFolios: state.folios.data.items,
        isDisabledReliquidacion: state.folios.data.isDisabledReliquidacion,
        reliquidacion: state.folios.data.reliquidacion,
        errorFolios: state.folios.data.errorFolios,
        folioActive: state.folios.data.active,
        isRecepcionCuentaMedicaByEmail: state.isRecepcionCuentaMedicaByEmail.data,
        idSolicitud: state.idSolicitud.data,
        revisarCuentaMedica: state.revisarCuentaMedica.data
      };
      return data;
    }
  );
  const data = useSelector(getData);

  return data
}

export default useGetDataRedux