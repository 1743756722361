import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';

const PrimaryButton = styled(Button)({
  color: "#FFFFFF",
  fontStyle: 'normal',
  fontWeight: 'bold',
  left: '0%',
  right: '0%',
  top: '0%',
  bottom: '0%',
  letterSpacing: '0.0021em',
  alignItems: 'center',
  textAlign: 'center',
  borderRadius: '8px',
  boxShadow: ' 0px 2px 2px rgba(121, 133, 142, 0.25)',
  backgroundColor: "#00837A",
  lineHeight: '20px',
  textTransform: 'none',
  fontFamily: 'interstate',
  '&:hover': {
    color: "#FFFFFF",
    backgroundColor: '#4DC0B8',
    boxShadow: '0px 4px 10px rgba(1, 115, 108, 0.35)'
  },
  '&:active': {
    color: "#FFFFFF",
    backgroundColor: "#00736B",
    boxShadow: '0px 2px 2px rgba(1, 164, 154, 0.25)'
  },
  "&:disabled": {
    color: "#67737C",
    boxShadow: '0px 2px 2px rgba(121, 133, 142, 0.25)',
    backgroundColor: "#E9EBEC"
  }
});

const SecondaryButton = styled(Button)({
  color: "#00837A",
  fontStyle: 'normal',
  fontWeight: 'bold',
  boxSizing: 'border-box',
  border: '1px solid #00837A',
  left: '0%',
  right: '0%',
  top: '0%',
  bottom: '0%',
  letterSpacing: '0.0021em',
  alignItems: 'center',
  textAlign: 'center',
  borderRadius: '8px',
  backgroundColor: "#FFFFFF",
  lineHeight: '20px',
  textTransform: 'none',
  fontFamily: 'interstate',
  '&:hover': {
    border: '1px solid #00837A',
    boxSizing: 'border-box',
    color: "#00837A",
    backgroundColor: '#E6F6F5',
  },
  '&:active': {
    color: "#FFFFFF",
    backgroundColor: "#00736B"
  },
  "&:disabled": {
    boxSizing: 'border-box',
    border: '1px solid #67737C',
    color: "#67737C",
    boxShadow: '0px 2px 2px rgba(121, 133, 142, 0.25)',
    backgroundColor: "#FFFFFF"
  }
});

const PrimaryButtonTable = styled(Button)({
  color: "#FFFFFF",
  padding: '10px 16px',
  fontStyle: 'normal',
  fontWeight: 'bold',
  left: '0%',
  right: '0%',
  top: '0%',
  bottom: '0%',
  letterSpacing: '0.0021em',
  alignItems: 'center',
  textAlign: 'center',
  borderRadius: '8px',
  boxShadow: ' 0px 2px 2px rgba(121, 133, 142, 0.25)',
  backgroundColor: "#00837A",
  lineHeight: '20px',
  textTransform: 'none',
  fontFamily: 'interstate',
  '&:hover': {
    color: "#FFFFFF",
    backgroundColor: '#4DC0B8',
    boxShadow: '0px 4px 10px rgba(1, 115, 108, 0.35)'
  },
  '&:active': {
    color: "#FFFFFF",
    backgroundColor: "#00736B",
    boxShadow: '0px 2px 2px rgba(1, 164, 154, 0.25)'
  },
  "&:disabled": {
    color: "#67737C",
    boxShadow: '0px 2px 2px rgba(121, 133, 142, 0.25)',
    backgroundColor: "#E9EBEC"
  }
});
const SecondaryButtonTable = styled(Button)({
  padding: '10px 16px',
  color: "#00837A",
  fontStyle: 'normal',
  fontWeight: 'bold',
  boxSizing: 'border-box',
  border: '1px solid #00837A',
  left: '0%',
  right: '0%',
  top: '0%',
  bottom: '0%',
  letterSpacing: '0.0021em',
  alignItems: 'center',
  textAlign: 'center',
  borderRadius: '8px',
  backgroundColor: "#FFFFFF",
  lineHeight: '20px',
  textTransform: 'none',
  fontFamily: 'interstate',
  '&:hover': {
    border: '1px solid #00837A',
    boxSizing: 'border-box',
    color: "#00837A",
    backgroundColor: '#E6F6F5',
  },
  '&:active': {
    color: "#FFFFFF",
    backgroundColor: "#00736B"
  },
  "&:disabled": {
    boxSizing: 'border-box',
    border: '1px solid #67737C',
    color: "#67737C",
    boxShadow: '0px 2px 2px rgba(121, 133, 142, 0.25)',
    backgroundColor: "#FFFFFF"
  }
});


const BotonReasignar = styled(Button)({
  height: '36px',
  padding: '8px 14px',
  color: "#FFFFF",
  background: "#4DC0B8",
  border: "1px solid #D0D5DD",
  boxSizing: "border-box",
  boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)",
  borderRadius: "8px",
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: '700',
  lineHeight: '20px',
  textTransform: 'none',
  '&:hover': {
    backgroundColor: '#4DC0B8',
    boxShadow: 'none'
  },
  '&:active': {
    color: "#FFFFFF",
    backgroundColor: "#4DC0B8",
    boxShadow: 'none'
  },
  "&:disabled": {
    border: '1px solid #67737C',
    color: "#67737C",
    boxShadow: '0px 2px 2px rgba(121, 133, 142, 0.25)',
    backgroundColor: "#FFFFFF"
  }
});

const BotonAsignar = styled(Button)({
  padding: '8px 14px',
  backgroundColor: '#CBC7F2',
  boxSizing: "border-box",
  color: 'white',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: '700',
  lineHeight: '20px',
  boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
  borderRadius: '8px',
  textTransform: 'none',
  '&:hover': {
    backgroundColor: '#CBC7F2',
    boxShadow: 'none'
  },
  '&:active': {
    color: "#FFFFFF",
    backgroundColor: "#CBC7F2",
    boxShadow: 'none'
  },
  "&:disabled": {
    border: '1px solid #67737C',
    color: "#67737C",
    boxShadow: '0px 2px 2px rgba(121, 133, 142, 0.25)',
    backgroundColor: "#FFFFFF"
  }
});

const BotonFiltro = styled(Button)({
  color: "#FFFFF",
  background: "#000000",
  border: "1px solid #D0D5DD",
  boxSizing: "border-box",
  boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)",
  borderRadius: "8px",
  fontStyle: "normal",
  fontWeight: "normal",
  fontSize: "12px",
  lineHeight: "16px",
  textTransform: 'none',
});

// Botones Estado
const BtnEnProceso = styled(Button)({
  padding: '8px',
  border: '1px solid #CBC7F2',
  color: '#01736C',
  backgroundColor: '#EEECFB',
  fontWeight: '400',
  fontSize: '12px',
  lineHeight: '16px',
  borderRadius: '8px',
  textTransform: 'none',
  width: '80px',
  '&:hover': {
    color: '#01736C',
    backgroundColor: '#EEECFB',
  },
  '&:active': {
    color: '#01736C',
    backgroundColor: "#EEECFB",
  },
  "&:disabled": {
    border: '1px solid #67737C',
    color: "#67737C",
    boxShadow: '0px 2px 2px rgba(121, 133, 142, 0.25)',
    backgroundColor: "#FFFFFF"
  }
});

const BtnEnRecep = styled(Button)({
  padding: '8px',
  border: '1px solid #CCEDEB',
  color: '#4C4C4C',
  backgroundColor: '#E6F6F5',
  fontWeight: '400',
  fontSize: '12px',
  lineHeight: '16px',
  borderRadius: '8px',
  textTransform: 'none',
  '&:hover': {
    backgroundColor: '#E6F6F5',
  },
  '&:active': {
    backgroundColor: "#E6F6F5",
  },
  "&:disabled": {
    border: '1px solid #67737C',
    color: "#67737C",
    boxShadow: '0px 2px 2px rgba(121, 133, 142, 0.25)',
    backgroundColor: "#FFFFFF"
  }
});

const BtnPendi = styled(Button)({
  padding: '8px',
  border: '1px solid #FFCDD2',
  color: '#4C4C4C',
  backgroundColor: '#FFEBEE',
  fontWeight: '400',
  fontSize: '12px',
  lineHeight: '16px',
  borderRadius: '8px',
  textTransform: 'none',
  '&:hover': {
    backgroundColor: '#FFEBEE',
  },
  '&:active': {
    backgroundColor: "#FFEBEE",
  },
  "&:disabled": {
    border: '1px solid #67737C',
    color: "#67737C",
    boxShadow: '0px 2px 2px rgba(121, 133, 142, 0.25)',
    backgroundColor: "#FFFFFF"
  }
});

const BtnEspecial = styled(Button)({
  width: '67px',
  height: '32px',
  border: '1px solid #00736B',
  borderRadius: '8px',
  fontSize: '14px',
  fontWeight: 700,
  letterSpacing: '0.0120588em',
  textTransform: 'none',
  display: 'flex',
  justifyContent: 'space-around'
});


export {
  PrimaryButton,
  SecondaryButton,
  BotonReasignar,
  BotonAsignar,
  BotonFiltro,
  BtnEnProceso,
  BtnEnRecep,
  BtnPendi,
  BtnEspecial,
  SecondaryButtonTable,
  PrimaryButtonTable
}
