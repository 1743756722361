import { Box, Button, Divider, Modal, Typography } from '@mui/material';

import { styledNormalModal } from '../../assets/styles/Modal/modal';

export const NormalModal = ({ open, onClose, title, content, primaryButton, secondaryButton, tertiaryButton, disabled }: any) => {
    return (
        <Modal
            open={open}
            // onClose={onClose}
            className="background-modal"
        >
            <Box sx={styledNormalModal}>
                <Box padding="32px 32px">
                    <Typography
                        variant="h3"
                    >
                        {title}
                    </Typography>
                    <Typography pt="24px">
                        {content}
                    </Typography>
                </Box>

                <Divider />

                <Box
                    padding="24px"
                    display="flex"
                    justifyContent="flex-end"
                >
                    {
                        tertiaryButton ?
                            <Button
                                className="medium-button"
                                sx={{justifySelf:"center"}}
                                variant="outlined"
                                disabled={disabled}
                                onClick={tertiaryButton.onClick}
                            >
                                {tertiaryButton.text}
                            </Button>
                        : null
                    }

                    {
                        secondaryButton ?
                            <Button
                                className="medium-button ml-button"
                                variant="outlined"
                                disabled={disabled}
                                onClick={secondaryButton.onClick}
                            >
                                {secondaryButton.text}
                            </Button>
                        : null
                    }

                    <Button
                        className="medium-button ml-button"
                        onClick={primaryButton.onClick}
                        disabled={disabled}
                        variant="contained"
                    >
                        {primaryButton.text}
                    </Button>

                </Box>
            </Box>
        </Modal>
    )
}
