import { Fragment } from "react";
import { Grid, Chip, Button } from "@mui/material";
import Cruz from "../../../assets/icons/cancelIcon.svg";

export function ChipConsultaCtas({ filtros, actualizaFiltros }: any) {
    const handleDelete = (filter: number) => {
        let tempFiltros = filtros;
        switch (filter) {
            case 1: delete tempFiltros.id;
                break;
            case 2: delete tempFiltros.idCMD;
                break;
            case 3: delete tempFiltros.numeroCuentaPrestador;
                break;
            case 4: delete tempFiltros.rutPaciente;
                break;
            case 5: delete tempFiltros.rutPrestador;
                break;
            case 6: delete tempFiltros.causal;
                break;
            case 7: delete tempFiltros.estadoEmision;
                break;
            case 8: delete tempFiltros.fechaRecepcion;
                break;
            case 9: delete tempFiltros.fechaAsignacion;
                break;
            case 10: delete tempFiltros.fechaCompromiso;
                break;
            case 11: delete tempFiltros.diasRestantes;
                break;
            case 12: delete tempFiltros.montoTotalCobro;
                break;
            case 13: delete tempFiltros.canalOrigen;
                break;
            case 14: delete tempFiltros.idUsuario;
                break;
        }
        actualizaFiltros(tempFiltros);
    };

    const deleteAll = () => {
        actualizaFiltros({});
    }
    return (
        <Fragment>
            {Object.keys(filtros).length > 0 ?
                <Fragment>
                    <Grid item xs={12} pt="15px">
                        <label className="filtroFont">Resultados filtrados por: </label>{" "}
                    </Grid>
                    <Grid item xs={12} pt="4px" >
                        {filtros.id ?
                            <Chip
                                sx={{ mr: "16px" }}
                                data-testid="delete-id-chip"
                                label="N° Cuenta"
                                variant="outlined"
                                onDelete={() => handleDelete(1)}
                                deleteIcon={
                                    <img
                                        src={Cruz}
                                        alt="cruz"
                                        data-testid="icon-delete-id"
                                    />
                                }
                            /> : null}
                        {filtros.idCMD ?
                            <Chip
                                sx={{ mr: "16px" }}
                                data-testid="delete-idCMD-chip"
                                label="N° C. Extranet"
                                variant="outlined"
                                onDelete={() => handleDelete(2)}
                                deleteIcon={
                                    <img
                                        src={Cruz}
                                        alt="cruz"
                                        data-testid="icon-delete-idCMD"
                                    />
                                }
                            /> : null}
                        {filtros.numeroCuentaPrestador ?
                            <Chip
                                sx={{ mr: "16px" }}
                                data-testid="delete-numeroCuentaPrestador-chip"
                                label="N° C. Prestador"
                                variant="outlined"
                                onDelete={() => handleDelete(3)}
                                deleteIcon={
                                    <img
                                        src={Cruz}
                                        alt="cruz"
                                        data-testid="icon-delete-numeroCuentaPrestador"
                                    />
                                }
                            /> : null}
                        {filtros.rutPaciente ?
                            <Chip
                                sx={{ mr: "16px" }}
                                data-testid="delete-rutPaciente-chip"
                                label="RUT Beneficiario"
                                variant="outlined"
                                onDelete={() => handleDelete(4)}
                                deleteIcon={
                                    <img
                                        src={Cruz}
                                        alt="cruz"
                                        data-testid="icon-delete-rutPaciente"
                                    />
                                }
                            /> : null}
                        {filtros.rutPrestador ?
                            <Chip
                                sx={{ mr: "16px" }}
                                data-testid="delete-rutPrestador-chip"
                                label="RUT Prestador"
                                variant="outlined"
                                onDelete={() => handleDelete(5)}
                                deleteIcon={
                                    <img
                                        src={Cruz}
                                        alt="cruz"
                                        data-testid="icon-delete-rutPrestador"
                                    />
                                }
                            /> : null}
                        {filtros.causal ?
                            <Chip
                                sx={{ mr: "16px" }}
                                data-testid="delete-causal-chip"
                                label="Causal"
                                variant="outlined"
                                onDelete={() => handleDelete(6)}
                                deleteIcon={
                                    <img
                                        src={Cruz}
                                        alt="cruz"
                                        data-testid="icon-delete-causal"
                                    />
                                }
                            /> : null}
                        {filtros.estadoEmision ?
                            <Chip
                                sx={{ mr: "16px" }}
                                data-testid="delete-estadoEmision-chip"
                                label="Estado"
                                variant="outlined"
                                onDelete={() => handleDelete(7)}
                                deleteIcon={
                                    <img
                                        src={Cruz}
                                        alt="cruz"
                                        data-testid="icon-delete-estadoEmision"
                                    />
                                }
                            /> : null}
                        {filtros.fechaRecepcion ?
                            <Chip
                                sx={{ mr: "16px" }}
                                data-testid="delete-fechaRecepcion-chip"
                                label="Fecha Recepción"
                                variant="outlined"
                                onDelete={() => handleDelete(8)}
                                deleteIcon={
                                    <img
                                        src={Cruz}
                                        alt="cruz"
                                        data-testid="icon-delete-fechaRecepcion"
                                    />
                                }
                            /> : null}
                        {filtros.fechaAsignacion ?
                            <Chip
                                sx={{ mr: "16px" }}
                                data-testid="delete-fechaAsignacion-chip"
                                label="Fecha Asignacion"
                                variant="outlined"
                                onDelete={() => handleDelete(9)}
                                deleteIcon={
                                    <img
                                        src={Cruz}
                                        alt="cruz"
                                        data-testid="icon-delete-fechaAsignacion"
                                    />
                                }
                            /> : null}
                        {filtros.fechaCompromiso ?
                            <Chip
                                sx={{ mr: "16px" }}
                                data-testid="delete-fechaCompromiso-chip"
                                label="Fecha Compromiso"
                                variant="outlined"
                                onDelete={() => handleDelete(10)}
                                deleteIcon={
                                    <img
                                        src={Cruz}
                                        alt="cruz"
                                        data-testid="icon-delete-fechaCompromiso"
                                    />
                                }
                            /> : null}
                        {filtros.diasRestantes ?
                            <Chip
                                sx={{ mr: "16px" }}
                                data-testid="delete-diasRestantes-chip"
                                label="Días Restantes"
                                variant="outlined"
                                onDelete={() => handleDelete(11)}
                                deleteIcon={
                                    <img
                                        src={Cruz}
                                        alt="cruz"
                                        data-testid="icon-delete-diasRestantes"
                                    />
                                }
                            /> : null}
                        {filtros.montoTotalCobro ?
                            <Chip
                                sx={{ mr: "16px" }}
                                data-testid="delete-montoTotalCobro-chip"
                                label="Monto de Cobro"
                                variant="outlined"
                                onDelete={() => handleDelete(12)}
                                deleteIcon={
                                    <img
                                        src={Cruz}
                                        alt="cruz"
                                        data-testid="icon-delete-montoTotalCobro"
                                    />
                                }
                            /> : null}
                        {filtros.canalOrigen ?
                            <Chip
                                sx={{ mr: "16px" }}
                                data-testid="delete-canalOrigen-chip"
                                label="Canal Origen"
                                variant="outlined"
                                onDelete={() => handleDelete(13)}
                                deleteIcon={
                                    <img
                                        src={Cruz}
                                        alt="cruz"
                                        data-testid="icon-delete-canalOrigen"
                                    />
                                }
                            /> : null}
                        {filtros.idUsuario ?
                            <Chip
                                sx={{ mr: "16px" }}
                                data-testid="delete-idUsuario-chip"
                                label="N° C. Prestador"
                                variant="outlined"
                                onDelete={() => handleDelete(14)}
                                deleteIcon={
                                    <img
                                        src={Cruz}
                                        alt="cruz"
                                        data-testid="icon-delete-idUsuario"
                                    />
                                }
                            /> : null}

                        <Button
                            color="inherit"
                            sx={{
                                textDecoration: "underline",
                                textDecorationThickness: "from-font",
                                pl: "12px",
                            }}
                            onClick={deleteAll}
                        >
                            Limpiar filtros
                        </Button>
                    </Grid>
                </Fragment>
                : null}
        </Fragment>
    );
}
