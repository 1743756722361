import { IconButton, Menu, MenuItem } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Fragment, useState } from "react";
import { ModalHistorial } from "./modalHistorial";
import { useGetUser } from "../../../hooks/getUser";
import { SecondaryButton } from "../../../components/Buttons/Button";
import LittleEye from "../../../assets/icons/littleEye.svg";
import { ModalReasignar } from "./modalReasignar/modalReasignar";

export const MenuCuentasMedicas = ({
  row,
  isAssignedTab,
  setSnackData,
  refetch
}: any) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [modalResolutivo, setModalResolutivo] = useState<boolean>(false);
  const [modalReasign, setModalReasign] = useState<boolean>(false)
  const openMenu = Boolean(anchorEl);
  const user = useGetUser();
  const handleOpen = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const openModalRes = () => {
    handleClose();
    setModalResolutivo(true);
  };

  const handleOpenModalReasignar = () => {
    setModalReasign(true)
    handleClose()
  }
  
  return (
    <Fragment>
      <ModalHistorial
        open={modalResolutivo}
        onClose={() => setModalResolutivo(false)}
        row={row}
      />
      {
        modalReasign &&
        <ModalReasignar
          open={modalReasign}
          handleOnClose={() => setModalReasign(false)}
          datosCuenta={row}
          setSnackData={setSnackData}
          refetch={refetch}
        />
      }
      {(isAssignedTab || !user.rol.includes("JEFE")) &&
        <SecondaryButton
          startIcon={<img src={LittleEye} alt="" />}
          sx={{ padding: "16px 15px !important" }}
          onClick={() => openModalRes()}
        >
          Ver
        </SecondaryButton>}
      {!isAssignedTab && user.rol.includes("JEFE") && (
        <>
          <IconButton
            data-testid="openMenuItem"
            id="basic-button"
            aria-haspopup="true"
            onClick={handleOpen}
          >
            <MoreVertIcon sx={{ color: "#203442" }} />
          </IconButton>
          <Menu
            id={row.id}
            anchorEl={anchorEl}
            open={openMenu}
            onClose={handleClose}
          >
            <MenuItem onClick={handleOpenModalReasignar} disabled={!user.rol.includes('JEFE') || row.usuarioAsignado === ''}>
              Reasignar
            </MenuItem>
            <MenuItem onClick={openModalRes}>Ver historial resolutivo</MenuItem>
          </Menu>
        </>
      )}
    </Fragment>
  );
};
