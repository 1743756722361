import { useState } from "react";
import {
  Box,
  Typography,
  Divider,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
} from "@mui/material";
import { boxCardStyleCM, boxCardStyleCMWhitoutP } from "../../../assets/styles/Box/boxs";
import IsLoading from "../../../components/isLoading/isLoading";
import LoadFilesTable from "./loadFilesTable/loadFilesTable";
import {
  mutationUploadDocumento,
  queryObtenerDocumentoIdCuentaMedica,
} from "../../../services/administracionCM/loadFIlesServices";
import { EmptyStateMessage } from "../../../components/message/emptyStateMessage";
import { useQuery, useMutation } from "@apollo/client";
import { useGetUser } from "../../../hooks/getUser";
import { UploadFile } from "./components/UploadFile";
import SnackBar from "../../../components/Snackbar/Snackbar";
import { checkStatus } from "../utils/checkStatus";

function LoadFiles({ dataCM, readOnly }: any) {

  const { idCuentaMedica } = dataCM;
  const { rut: rutBeneficiario } = dataCM.datosBeneficiario;
  const { numeroCuenta: numeroCuentaPrestador } = dataCM.datosPrestador;
  const [typeFile, setTypeFile] = useState<number>(0);
  const [data, setData] = useState<any[]>([]);
  const { email } = useGetUser()
  const [toastData, setToastData] = useState<any>({
    abrir: false,
    mensaje: "",
    severidad: "info"
  });

  const [
    uploadFile, { loading: loadingMutation },
  ] = useMutation(mutationUploadDocumento, {
    onCompleted: (res) => {
      generateMsg("success", "Archivo subido correctamente, revise tabla documentos");
      setData([...data, res.uploadDocumento]);
      setTypeFile(0);
      refetch();
    },
    onError: (err:any) => {
      generateMsg("error", 'Error al subir archivo, intente nuevamente');
      setTypeFile(0);
      checkStatus(err.graphQLErrors[0]?.code || "");
    }
  });

  const { loading: documentsLoading, refetch } =
    useQuery(queryObtenerDocumentoIdCuentaMedica,
      {
        variables: { id: Number(idCuentaMedica) },
        onCompleted: (res) => {
          setData(res.obtenerDocumentoIdCuentaMedica);
        }
      });

  const generateMsg = (severity: string, content: string) => {
    setToastData({
      abrir: true,
      mensaje: content,
      severidad: severity
    });
  };

  const selectTypeFile: { [key: number]: any } = {
    0: "Documentos de cobro",
    1: "Antecedentes Clinicos",
    2: "Antecedentes Adicionales Solicitados",
  };

  return (
    <>
      <SnackBar
        state={toastData}
        setState={setToastData}
      />
      <Box sx={boxCardStyleCM}>
        <UploadFile
          sendFile={uploadFile}
          selectTypeFile={selectTypeFile}
          generateMsg={generateMsg}
          setTypeFile={setTypeFile}
          typeFile={typeFile}
          usuario={email}
          rutBeneficiario={rutBeneficiario}
          idCuentaMedica={idCuentaMedica}
          numeroCuentaPrestador={numeroCuentaPrestador}
          loading={loadingMutation}
          revisarCuentaMedica={readOnly}
        />
      </Box>
      <Box sx={boxCardStyleCMWhitoutP}>
        <Typography variant="h5" p="15px 24px">
          Documentos Adjuntos
        </Typography>

        <Divider variant="fullWidth" />

        {documentsLoading ? (
          <IsLoading title={"Cargando documentos"} />
        ) : data.length > 0 ? (
          <Paper className="paper-tables-2">
            <TableContainer className="tContainer-padding-0" component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="customized table">
                <TableHead className="color-head-table">
                  <TableRow>
                    <TableCell>Folio</TableCell>
                    <TableCell>Nombre Archivo</TableCell>
                    <TableCell>Tipo de Documento</TableCell>
                    <TableCell>Ver Documento</TableCell>
                    <TableCell>Acción</TableCell>
                  </TableRow>
                </TableHead>

                <LoadFilesTable data={data} setData={setData} revisarCuentaMedica={readOnly} />
              </Table>
            </TableContainer>
          </Paper>
        ) : (
          <EmptyStateMessage
            title="Aún no Adjuntas un Documento"
            subtitle="Selecciona el Tipo de Documento para Empezar"
          />
        )}
      </Box>
    </>
  );
}

export default LoadFiles;
