import { styled, TextField } from "@mui/material";

const CustomInput = styled(TextField)(({ theme }) => ({
    '& .MuiInputBase-input': {
        padding: '13.5px 16px',
        '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
            WebkitAppearance: 'none',
        },
        //backgroundColor:"#FFFFFF",
        borderRadius: "8px"
    },
    '& .MuiOutlinedInput-root': {
        borderRadius: 8,
        position: 'relative',
        fontSize: 16,
        color: '#364855',
        marginRight: '8px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        WebkitAppearance: 'none',
        letterSpacing: '0.0120588em',
        textOverflow: 'ellipsis',
        '&.Mui-focused fieldset': {
            border: '1px solid #5143D5',
        },
        '&.Mui-disabled': {
            color: '#67737C',
            backgroundColor: '#E9EBEC'
        },
        '&.Mui-disabled fieldset': {
            borderColor: '#E9EBEC',
        },
    },

    '& .MuiFormHelperText-root': {
        fontWeight: '700',
        fontSize: '14px',
        lineHeight: '16px',
        color: '#E53935',
        letterSpacing: '0.0120588em'
    }
}));

const SlimInput = styled(TextField)(({ theme }) => ({
    '& .MuiInputBase-input': {
        padding: '7px 16px',
        '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
            WebkitAppearance: 'none',
        },
    },
    '& .MuiOutlinedInput-root': {
        borderRadius: 8,
        position: 'relative',
        fontSize: 16,
        color: '#364855',
        marginRight: '8px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        WebkitAppearance: 'none',
        letterSpacing: '0.0120588em',
        textOverflow: 'ellipsis',
        '&.Mui-focused fieldset': {
            border: '1px solid #5143D5',
        },
        '&.Mui-disabled': {
            color: '#67737C !important',
            backgroundColor: '#F2F5F9'
        },
        '&.Mui-disabled fieldset': {
            borderColor: '#F2F5F9',
        },
    },

    '& .MuiFormHelperText-root': {
        fontWeight: '700',
        fontSize: '14px',
        lineHeight: '16px',
        color: '#E53935',
        letterSpacing: '0.0120588em'
    }
}));

const CustomDateField = styled(TextField)({

    '& .MuiOutlinedInput-root': {
        height: '50px',
        borderRadius: '8px',
        '& fieldset': {
            borderColor: '#BCC2C6',
        },
        '&:hover fieldset': {
            borderColor: '#364855',
        },
        '&.Mui-focused fieldset': {
            border: '1px solid #5143D5',
        },
        "&::placeholder": {
            color: "#909AA1"
        },
        '&.Mui-disabled': {
            color: '#67737C',
            // backgroundColor: '#E9EBEC'
            backgroundColor: '#F2F5F9'
        },
        '&.Mui-disabled fieldset': {
            borderColor: '#E9EBEC',
        },
    },

});

export {
    CustomInput,
    CustomDateField,
    SlimInput
}
