export enum TabsValues {
  antecedentesGenerales= "1",
  eventoHospitalario= "2",
  docAdjuntos= "3",
  docCobro= "4",
  cobertura= "5",
  valorizacion= "6",
  bonificacion2= "7a",
  bonificacion= "7",
  historialResolutivo= "8",
  historialCM= "9",
  restriccionDPS="10",
  resolucion="11"
}