import { useTheme } from '@mui/material/styles';
import {
  CustomInputSelectActions,
  CustomSelectInput,
  menuFirstStyle,
  menuItemStyle,
  menuItemStyle2,
  menuSelectStyle
} from '../../assets/styles/Select/Select'
import {
  FormControl,
  FormHelperText,
  MenuItem,
  Select,
  Typography
} from '@mui/material';
import { newStatusFiltered } from '../../types';
import { DataUser } from '../../hooks/getUser';
import { canCMActionByRol } from '../../routes/verifyAccess';

export const SelectPersonalizado = ({ disabled, error, id, inputTest, isAcciones, options, placeholder, phvalue = "", placeholderHidden, setValue, value }: any) => {
  const theme = useTheme();

  const handleChange = (event:any) => {
    setValue(event.target.value);
  };

  return (
    <FormControl error={error ? true : undefined}>
      <Select
        displayEmpty
        sx={{ width: '100%' }}
        disabled={disabled}
        value={value || ""}
        onChange={handleChange}
        input={<CustomSelectInput />}
        MenuProps={menuSelectStyle}
        inputProps={{ 'aria-label': 'Without label', "data-testid": inputTest, "id": id }}
      >
        {placeholder ?
          <MenuItem disabled hidden={placeholderHidden} value={value || phvalue} sx={menuItemStyle}>
            <Typography sx={menuFirstStyle}>{placeholder}</Typography>
          </MenuItem>
          : null}
        {options.map((option: any) => (
          <MenuItem
            key={option.label}
            sx={menuItemStyle}
            disabled={option.disabled && option.disabled === true ? true : false}
            value={option.value}
            style={{ fontWeight: theme.typography.fontWeightMedium }}
          >
            {option.label}
          </MenuItem>
        ))}
      </Select>
      <FormHelperText>{error || ""}</FormHelperText>
    </FormControl>
  );
}

interface SelectPersonalizadoNuevosEstadosProps {
  disabled: boolean;
  id: string;
  inputTest: string;
  isAcciones: boolean;
  options: newStatusFiltered[];
  placeholder: string;
  placeholderHidden: boolean;
  setValue: (value: any) => void;
  value: any;
  user: DataUser;
}

export const SelectPersonalizadoNuevosEstados = ({
  disabled,
  id,
  inputTest,
  isAcciones,
  options,
  placeholder,
  placeholderHidden,
  setValue,
  value,
  user,
}: SelectPersonalizadoNuevosEstadosProps) => {
  const theme = useTheme();


  const handleChange = (event: any) => {
    setValue(event.target.value);
  };

  return (
    <FormControl>
      <Select
        displayEmpty
        sx={{ width: "100%" }}
        disabled={disabled}
        value={value || ""}
        onChange={handleChange}
        input={
          isAcciones ? <CustomInputSelectActions /> : <CustomSelectInput />
        }
        MenuProps={menuSelectStyle}
        inputProps={{
          "aria-label": "Without label",
          "data-testid": inputTest,
          id,
        }}
      >
        {placeholder ? (
          <MenuItem
            disabled
            value={value}
            sx={{
              ...menuItemStyle2,
              display: placeholderHidden ? 'none' : 'block',
            }}
          >
            {placeholder}
          </MenuItem>
        ) : null}
        {options.map((option: newStatusFiltered) => (
          <MenuItem
            key={option.idParametroInterno}
            sx={menuItemStyle2}
            disabled={
              !option.isEnabled || !canCMActionByRol(user.rol, option.idParametroInterno)
            }
            value={option.idParametroInterno}
            style={{ fontWeight: theme.typography.fontWeightMedium }}
          >
            {option.valorParametro}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};