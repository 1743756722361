import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { useState } from "react";
import { NoResult } from "../../../components/Tables/noResult";
import { styleHeadAlt } from "../../../assets/styles/Table/table";
import { grayName } from "../../../assets/styles/Text/text";
import { CustomTooltip } from "../../../components/Tooltip/tooltipbs";
import { Paginador } from "../../../components/TablePagination/Paginador";
import { useQuery } from "@apollo/client";
import { getHistorialCuentaMedicaQuery } from "../../../services/cm-services/escritorio-cm/escritorio-services";
import { LoadHistorialCtas } from "./skeletonCuentas";
import { EstadosHistorial } from "./estadosHistorial";


export const TableHistorial = ({ row }: any) => {
    const [dataHistorial, setDataHistorial] = useState<any>(null);
    const [count, setCount] = useState<number>(0);
    const [pagHisRes, setPagHisRes] = useState<number>(0);
    const [rowsPerPagHisRes, setRowsPerPagHisRes] = useState<number>(5);
    const { loading, error } = useQuery(getHistorialCuentaMedicaQuery,
        {
            variables: {
                idSolicitud: row.idCMD || row.id,
                canalOrigen: row.canalOrigen,
                offset: pagHisRes * rowsPerPagHisRes,
                limit: rowsPerPagHisRes
            },
            onCompleted: (res) => {
                setDataHistorial(res.getHistorialCuentaMedica.historialCuentaMedica);
                setCount(res.getHistorialCuentaMedica.count);
            }
        });

    const changeRowPerPag = (lines: number) => {
        setRowsPerPagHisRes(lines);
    }
    const changePag = (newPage: number) => {
        setPagHisRes(newPage);
    }

    const FechaHandler = ({ fecha }: any) => {
        if (fecha) {
            let dataFechas = fecha.split(" ");
            return (
                <>
                    {dataFechas[0]}
                    <Typography sx={grayName}>
                        {dataFechas[1].substring(0, dataFechas[1].length - 3)}
                    </Typography>
                </>
            )
        } else {
            return null
        }
    }
    return (
        <Paper className="paper-tables" sx={{ border: " 0.5px solid #4DC0B8 !important", borderRadius: "16px" }}>
            {loading ? <LoadHistorialCtas /> :
                error ? <NoResult titulo="Error"
                    subtitulo="Error al cargar el historial"
                    noDivider
                    error /> :
                    count > 0 ?
                        <TableContainer sx={{ pl: "0 !important" }}>
                            <Table aria-label="customized table" >
                                <TableHead>
                                    <TableRow >
                                        <TableCell sx={styleHeadAlt}   >
                                            Fecha y hora<br></br>
                                            Transacción
                                        </TableCell>
                                        <TableCell sx={styleHeadAlt} >
                                            Estado
                                        </TableCell>
                                        <TableCell sx={styleHeadAlt} >
                                            Causal
                                        </TableCell>
                                        <TableCell sx={styleHeadAlt} >
                                            Usuario
                                        </TableCell>
                                        <TableCell sx={styleHeadAlt} >
                                            Fecha <br></br> derivación
                                        </TableCell>
                                        <TableCell sx={styleHeadAlt} >
                                            Entidad<br></br>
                                            derivación
                                        </TableCell>
                                        <TableCell sx={styleHeadAlt}  >
                                            Nota
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {dataHistorial && dataHistorial.map((log: any, index: number) => {
                                        return (<TableRow key={index}>
                                            <TableCell  >
                                                <FechaHandler fecha={log.fechaTransaccion} />
                                            </TableCell>
                                            <TableCell>
                                                <EstadosHistorial estado={log.estado} />
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {log.causal?.descripcionCausal}
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {log.idUsuario}
                                                <Typography sx={grayName}>
                                                </Typography>
                                            </TableCell>
                                            <TableCell>
                                                <FechaHandler fecha={log.fechaDerivacion} />
                                            </TableCell>
                                            <TableCell>
                                                {log.entidadDerivacion}
                                            </TableCell>
                                            <TableCell>
                                                <CustomTooltip
                                                    title={log.nota}
                                                    arrow
                                                    placement="bottom">
                                                    <Typography>{log.tituloNota}</Typography>
                                                </CustomTooltip>
                                            </TableCell>
                                        </TableRow>)
                                    }
                                    )}
                                </TableBody>
                            </Table>
                            <Paginador
                                opciones={[5, 10, 15]}
                                data={count}
                                itemPorPagina={rowsPerPagHisRes}
                                pagina={pagHisRes}
                                setPagina={changePag}
                                setLineasPagina={changeRowPerPag}
                            />
                        </TableContainer> :
                        <NoResult
                            titulo="No se encontró historial"
                            subtitulo="No se han encontrado registros de historial para esta cuenta"
                            noDivider
                            newFound
                        />
            }
        </Paper>
    );
};