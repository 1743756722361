import { useMutation } from "@apollo/client";
import { mutationReversarEstadoCuentaMedica, mutationReversarEstadoCuentaMedicaEmitida } from "../../../services/emisionCuentas/emisionCuentas";
import { ModalReversar } from "./modalReversar";
import { useState } from "react";

export const ReversarCuenta = ({ open, close, row, showMessage, refetch, type }: any) => {
    const [textoObs, setTextoObs] = useState("");
    const [errorReversa, setErrorReversa] = useState(false);
    const [fnReversarCuenta, { loading: loadingReversarLiq }] = useMutation(mutationReversarEstadoCuentaMedica, {
        onCompleted: (res) => {
            const { estado, mensaje } = res.reversarEstadoCuentaMedica
            if (estado === 0) {
                // caso exitoso
                showMessage("La cuenta fue reversada a En proceso exitosamente", 'success');
                onClose();
                refetch();
            } else {
                if (estado === 3) {
                    // caso reversa en proceso
                    showMessage(mensaje, 'success');
                    onClose();
                    refetch();
                } else {
                    // caso fallido
                    setErrorReversa(true);
                }
            }
        },
        onError: (err) => {
            console.log(err.message);
            setErrorReversa(true);
        }
    });
    const [fnReversarCuentaEmitida, { loading: loadingReversarEmitida }] = useMutation(mutationReversarEstadoCuentaMedicaEmitida, {
        onCompleted: (res) => {
            const { estado, mensaje } = res.reversarCuentaMedicaEmitida
            switch (estado) {
                case 0:
                    showMessage("La cuenta fue reversada a liquidada exitosamente", 'success');
                    onClose();
                    refetch();
                    break;
                case 3:
                    showMessage(mensaje, 'success');
                    onClose();
                    refetch();
                    break;
                default:
                    setErrorReversa(true);
                    break;
            }
        },
        onError: (err) => {
            console.log(err.message);
            setErrorReversa(true);
        }
    });
    const handleReversarCuenta = () => {
        setErrorReversa(false);
        if (type === "1") {
            fnReversarCuenta({ variables: { idCuentaMedica: row.id, notaResolutiva: textoObs } });
        } else {
            fnReversarCuentaEmitida({ variables: { idCuentaMedica: row.id, notaResolutiva: textoObs } });
        }
    }

    const onClose = () => {
        setTextoObs("");
        setErrorReversa(false);
        close();
    }
    return (
        <ModalReversar
            open={open}
            onClose={onClose}
            handleOnClick={handleReversarCuenta}
            idCuentaMedica={row.id}
            type={type}
            valorTexto={textoObs}
            handleOnChange={(e) => setTextoObs(e?.target?.value || "")}
            error={errorReversa}
            isLoading={loadingReversarLiq || loadingReversarEmitida}
        />
    )
}