import { useState } from "react";
import { useLazyQuery } from "@apollo/client";
import { getManagementReportData } from "../../../services/reportes/reportes.service";
import { messages } from "../../../constants/messages";
import { parseToExcel } from "../reporteGestion/utilsMapperExcel";
import { format } from "date-fns";
import { createExcel } from "../../../utils/createExcel";

const {
  SNACKBAR: {
    MANAGEMENT_REPORT: { ERROR, IN_PROGRESS, SUCCESS },
  },
} = messages;

const useReporteGestion = (sendMessage: Function) => {
  const [loading, setLoading] = useState(false);

  const [getReportData, { fetchMore }] =
    useLazyQuery(getManagementReportData, {
      fetchPolicy: "network-only",
      onCompleted: (res) => fetchManagementReportData(res),
      onError: () => {
        sendMessage("error", ERROR);
        setLoading(false);
      },
    });

  const fetchManagementReportData = async (result: any) => {    
    let temp = result.getDataQueryMR;
    if (temp) {
      while (temp.data.length < temp.count) {        
        const { data } = await fetchMore({
          variables: {
            limit: 2500,
            offset: temp.data.length,
          },
        });
        const mergeSol = [...temp.data, ...data.getDataQueryMR.data];
        temp = {
          ...temp,
          data: mergeSol,
        };
      }
      const managementReport = temp.data;      
      generateMRFile(managementReport);
    }
  };

  const generateMRFile = async (data: any[]) => {
    const filename = `Reporte_Gestion_${format(
      new Date(),
      "yyyyMMddHHmm"
    )}.xlsx`;
    const xlsxData = await parseToExcel(data);
    const response = await createExcel(xlsxData, filename);
    if (response.status === 200) {
      sendMessage("success", SUCCESS);
    } else {
      sendMessage("error", ERROR);
    }
    setLoading(false);
  };

  const handleClickReporteGestion = () => {
    getReportData({
      variables: {
        limit: 2500,
        offset: 0,
      },
    });
    setLoading(true);
    sendMessage("info", IN_PROGRESS);
  };

  return {
    loading,
    handleClickReporteGestion,
  };
};

export default useReporteGestion;
