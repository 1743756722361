import { Workbook } from "exceljs";
import { saveAs } from "file-saver";
import { format } from "date-fns";

const estados = {
    1: "Exitoso",
    2: "Procesando",
    3: "Error",
    4: "Pendiente",
};

const basicTitle = [
    "N° Cuenta", "N° Cuenta Extranet", "N° Cuenta Prestador", "Rut Beneficiario",
    "Nombre Beneficiario", "Rut Prestador", "Nombre prestador", "Fecha Recepción", "Fecha Liquidación"
];

const liqTitle = [
    "Total Factura",
    "Total Bonificación", "Total Copago", "Estado"
];

const emTitle = [
    "Fecha Emisión","Total Factura",
    "Total Bonificación", "Total Copago",
    "Tipo"
];

const fileNameLiq = "CuentasLiquidadas";
const fileNameEm = "CuentasEmitidas";
export const exportCM = (data, showMessage, tabIndex) => {
    try {
        const cuentas = [];
        if (tabIndex === "2") {
            cuentas.push([...basicTitle, ...emTitle]);
        } else {
            cuentas.push([...basicTitle, ...liqTitle]);
        }
        data.forEach((cuentaMD) => {
            let estadoLiquidada = cuentaMD.emision ? parseInt(cuentaMD.emision.estado) : 4;
            let estadoLiquidadaText = estados[estadoLiquidada];
            let tipoText = cuentaMD.estado === 'Emitida Manual' ? 'Manual' : 'Electrónica';
            let nombrePaciente = cuentaMD.nombrePaciente + " " +
                (cuentaMD.apellidoPaternoPaciente ? cuentaMD.apellidoPaternoPaciente : "") + " " +
                (cuentaMD.apellidoMaternoPaciente ? cuentaMD.apellidoMaternoPaciente : "");
            const basicInfo = [
                cuentaMD.id,
                cuentaMD.idCMD,
                cuentaMD.numeroCuentaPrestador,
                cuentaMD.rutPaciente,
                nombrePaciente,
                cuentaMD.prestador.rutPrestador,
                cuentaMD.prestador.razonSocial,
                cuentaMD.fechaRecepcion,
                cuentaMD.fechaRealLiquidacion,
            ];
            const liquidada = [
                cuentaMD.totalFacturado,
                cuentaMD.totalBonificado,
                cuentaMD.totalCopago,
                estadoLiquidadaText
            ];
            const emitida = [
                cuentaMD.fechaEmision,
                cuentaMD.totalFacturado,
                cuentaMD.totalBonificado,
                cuentaMD.totalCopago,
                tipoText
            ];
            if (tabIndex === "2") {
                cuentas.push([...basicInfo, ...emitida]);
            } else {
                cuentas.push([...basicInfo, ...liquidada]);
            }

        });
        const excelCuentas = new Workbook();
        excelCuentas.creator = "CTA";
        excelCuentas.addWorksheet(tabIndex === "2" ? fileNameEm :fileNameLiq);
        const libroCuentas = excelCuentas.getWorksheet(1);
        libroCuentas.addRows(cuentas);
        excelCuentas.xlsx.writeBuffer().then((data) => {
            const blob = new Blob([data], {
                type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            });
            const name = `${tabIndex === "2" ? fileNameEm :fileNameLiq}_${format(new Date(), "dd-MM-yyyy")}.xlsx`;
            saveAs(blob, name);
            showMessage("Archivo generado correctamente, revise descargas", 'success');
            return;
        });
        return;
    } catch (err) {
        showMessage("Error al procesar las Cuentas", 'error');
    }
}