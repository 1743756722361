import { useState } from "react";
import { Box, CircularProgress, Grid, IconButton, Typography } from "@mui/material";
import AvatarGreen from "../../../../assets/icons/perfilForm.svg";
import { boxCard, formHeader } from "../../../../assets/styles/Box/boxs";
import { getdatosBeneficiarioQuery } from "../../../../services/administracionCM/administracionCMServices";
import { useLazyQuery } from "@apollo/client";
import { formTextHeader, inputForm } from "../../../../assets/styles/Text/text";
import { SlimInput } from "../../../../assets/styles/Input/input";
import { SelectCIE10 } from "./SelectCie10";
import { validRut, formatRut } from "chilean-rutify";

const DataBeneficiario = ({ dataCM, setDataCM, readOnly, findDupes, setDupeCheck }: any) => {
  const [errorBenef, setErrorBenef] = useState<any>({});
  const [fngetdatosBeneficiarioQuery, { loading }] = useLazyQuery(getdatosBeneficiarioQuery, { fetchPolicy: "no-cache" });

  const onChange = (e: { target: { id: any; value: any; }; }) => {
    const id: string = e.target.id;
    let value = e.target.value;
    if (id === "rut") {
      setDupeCheck(false);
      let rut = e.target.value.replace(/[^0-9kK-]/g, "").trim();
      value = rut.length > 2 ? formatRut(rut)!.toUpperCase() : rut;
      if (value && !validRut(value)) {
        setErrorBenef({ ...errorBenef, [id]: "Rut inválido" });
      } else {
        let err = errorBenef;
        delete err[id];
        setErrorBenef(err);
        if (value && validRut(value)) {
          handleRutBenef(value)
        }
      }
      setDataCM({
        ...dataCM,
        datosBeneficiario: {
          ...dataCM.datosBeneficiario,
          [id]: value,
          nombre: "",
          apellidoPaterno: "",
          apellidoMaterno: ""
        },
        fechaHospitalizacion: {
          fechaInicio: null,
          fechaEgreso: null,
          condicionEgreso: null
        },
        poliza:{
          id: null,
          numero: null,
          version: null,
          fechaInicioVigencia: null,
          fechaTerminoVigencia: null,
          codigoPlanSalud: null,
          glosaPlanSalud: null
        }
      });
      return;
    }

    setDataCM({
      ...dataCM,
      datosBeneficiario: {
        ...dataCM.datosBeneficiario,
        [id]: value
      }
    });
  }

  const onChangedataDiagnosticoPrimario = (data: any) => {
    setDataCM({
      ...dataCM,
      datosBeneficiario: {
        ...dataCM.datosBeneficiario,
        diagnosticoPrimario: data
      },
    });
  };

  const onChangeDataDiagnosticoSecundario = (event: any) => {
    setDataCM({
      ...dataCM,
      datosBeneficiario: {
        ...dataCM.datosBeneficiario,
        diagnosticoSecundario: event
      },
    });
  };

  const handleRutBenef = (rut: string) => {
    const parsedRut = rut.replace(/[^0-9kK-]/g, "");
    fngetdatosBeneficiarioQuery({
      variables: { rut: parsedRut },
      onCompleted: (res) => {
        if (res.datosBeneficiario) {
          setDataCM({
            ...dataCM,
            datosBeneficiario: {
              ...dataCM.datosBeneficiario,
              rut: parsedRut,
              nombre: res?.datosBeneficiario.nombre || "S/N",
              apellidoPaterno: res.datosBeneficiario.apellidoPaterno || "",
              apellidoMaterno: res.datosBeneficiario.apellidoMaterno || ""
            },
            fechaHospitalizacion: {
              fechaInicio: null,
              fechaEgreso: null,
              condicionEgreso: null
            },
            poliza:{
              id: null,
              numero: null,
              version: null,
              fechaInicioVigencia: null,
              fechaTerminoVigencia: null,
              codigoPlanSalud: null,
              glosaPlanSalud: null
            }
          });
          if (dataCM.datosPrestador.numeroCuenta && dataCM.datosPrestador.numeroCobro) {
            findDupes({
              variables: {
                numeroCobro: dataCM.datosPrestador.numeroCobro,
                numeroCuenta: dataCM.datosPrestador.numeroCuenta,
                rutPaciente: parsedRut
              }
            })
          }
        } else {
          setErrorBenef({
            ...errorBenef,
            rut: "Beneficiario no existe"
          })
        }
      },
      onError: (err) => {
        console.log(err);
        setErrorBenef({
          ...errorBenef,
          rut: "Error al obtener datos Beneficiario"
        })
      }
    })
  }

  const nombreBenef = dataCM.datosBeneficiario.nombre ? `${dataCM.datosBeneficiario.nombre} ${dataCM.datosBeneficiario.apellidoPaterno} ${dataCM.datosBeneficiario.apellidoMaterno}` : "";
  return (
    <Box sx={boxCard} width={"89.5%"} ml={"4.5%"} mb={"24px"} >
      <Box sx={formHeader}>
        <Typography sx={formTextHeader}>
          <img src={AvatarGreen} alt="" />{' '} Datos del beneficiario
        </Typography>
      </Box>
      <Grid mb="32px" container p="24px">
        {dataCM.idCuentaMedica ? null :
          <Grid item xs={6} pr="24px">
            <Typography sx={inputForm}  >
              RUT
            </Typography>
            <Typography display={'inline'} color={'#E53935 !important'}>
              *
            </Typography>
            <SlimInput
              fullWidth
              placeholder="Ej: 99.999.999-K"
              value={formatRut(dataCM.datosBeneficiario.rut || "")}
              id={"rut"}
              onChange={onChange}
              error={errorBenef.rut}
              helperText={errorBenef.rut || ""}
              disabled={readOnly || loading}
              InputProps={{
                endAdornment: (
                  <IconButton>
                    {loading ?
                      <CircularProgress data-testid="cargandoDoc" size={20} />
                      : null
                    }
                  </IconButton>
                )
              }}
            />
          </Grid>
        }
        {dataCM.idCuentaMedica ? null :
          <Grid item xs={6} pr="24px">
            <Typography sx={inputForm}  >
              Nombre del Beneficiario
            </Typography>
            <Typography display={'inline'} color={'#E53935 !important'}>
              *
            </Typography>
            <SlimInput
              fullWidth
              placeholder="Ej: Luis Andrés Cortés Andrade"
              value={nombreBenef}
              id={"nombre"}
              disabled
            />
          </Grid>}
        <Grid item xs={6} pr="24px">
          <Typography sx={inputForm}  >
            Código diagnóstico principal
          </Typography>
          <Typography display={'inline'} color={'#E53935 !important'}>
            *
          </Typography>
          <SelectCIE10
            value={dataCM?.datosBeneficiario?.diagnosticoPrimario}
            onChange={onChangedataDiagnosticoPrimario}
            disabled={readOnly}
          />
        </Grid>
        <Grid item xs={6} pr="24px">
          <Typography sx={inputForm}  >
            Código diagnóstico secundario
          </Typography>
          <Typography display={'inline'} color={'#E53935 !important'}>
            *
          </Typography>
          <SelectCIE10
            value={dataCM?.datosBeneficiario?.diagnosticoSecundario}
            onChange={onChangeDataDiagnosticoSecundario}
            disabled={readOnly}
          />
        </Grid>
        <Grid item xs={12} pt="24px" pb="24px">
          <Typography sx={inputForm}  >
            Diagnóstico médico (Máximo 300 carácteres)
          </Typography>
          <SlimInput
            fullWidth
            multiline
            rows={4}
            placeholder="Diagnóstico Médico"
            value={dataCM?.datosBeneficiario.diagnosticoMedico || ""}
            onChange={onChange}
            id="diagnosticoMedico"
            inputProps={{
              maxLength: "300",
              pattern: "[A-Za-z0-9]+",
            }}
            disabled={readOnly}
          />
        </Grid>
      </Grid>
    </Box>
  );
}

export default DataBeneficiario;
