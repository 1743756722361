import { gql } from "@apollo/client";

export const queryObtenerCuentasEmitidas = gql`
query queryObtenerValoresParametros($tipoParametro: TipoParametroInput, $otro: String){
    obtenerValoresParametros(tipoParametro : $tipoParametro, otro : $otro){
      idParametroInterno
      valorParametro
    }
}`;

export const mutationEmitirCuentas = gql`
  mutation($id: [Int!]!, $nota: String){
    emitirCuentasMedicas(idsCuentaMedica: $id, comentario: $nota){
      estado
      mensaje
    }
  }`;

export const mutationBonificar = gql`
  mutation bonificar($id: Int){
    bonificar(idCuentaMedica: $id){
      respuesta {
        estado
        mensaje
      }
    }
  }`;

export const mutationGenerarPDF = gql`
  mutation ($idCuentaMedica: Int, $tipoDocumento: String){
    generarFormulario(idCuentaMedica: $idCuentaMedica, tipoDocumento:$tipoDocumento){
      estado
      codigo
      message
    }
  }`;

export const mutationRefresh = gql`
  mutation ($idCuentaMedica: Int){
    actualizarEstadosEmisionCuentas(idCuentaMedica: $idCuentaMedica){
      estado
      mensaje
    }
  }`;

export const mutationReversarEstadoCuentaMedica = gql`
  mutation ($idCuentaMedica: Int, $notaResolutiva: String){
    reversarEstadoCuentaMedica(idCuentaMedica: $idCuentaMedica, notaResolutiva: $notaResolutiva){
      estado
      mensaje
    }
  }`;

export const mutationReversarEstadoCuentaMedicaEmitida = gql`
  mutation ($idCuentaMedica: Int, $notaResolutiva: String){
    reversarCuentaMedicaEmitida(idCuentaMedica: $idCuentaMedica, notaResolutiva: $notaResolutiva){
      estado
      mensaje
    }
  }`;