import { GreenAltButton, LineButton, PurpleButton } from "../../../components/Buttons/Button";
import { useGetUser } from "../../../hooks/getUser";

export const AsignacionUsuario = ({
    usuarioAsignado,
    row,
    isAuth,
    handleOnClickGestionarCuenta,
    handleOpenModalAsignarme,
    handleRevisarCuenta }: any) => {
    const { email, rol } = useGetUser();

    if (row.estado === "Pendiente de Autorización" && !isAuth && usuarioAsignado !== email) {
        return (
            <LineButton onClick={() => handleRevisarCuenta(row)}>
                Revisar
            </LineButton>
        );
    }

    if (usuarioAsignado === email) {
        return (
            <GreenAltButton onClick={() => handleOnClickGestionarCuenta(row)}>
                Gestionar
            </GreenAltButton>
        );
    }

    if (!usuarioAsignado && !['AUDITOR'].some(r => rol.includes(r))) {
        return (
            <PurpleButton onClick={() => handleOpenModalAsignarme(row.id, row.idCMD)}>
                Asignarme
            </PurpleButton>
        );
    }

    return (
        <LineButton onClick={() => handleRevisarCuenta(row)}>
            Revisar
        </LineButton>
    );
};