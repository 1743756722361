import {
    Alert,
    Box,
    Collapse,
    Grid,
    GridSize,
    SxProps,
    Theme,
} from "@mui/material";
import { iconStyle, style } from "../../assets/styles/SnackBar/Styles";
type AlertArgs = {
    titulo?: string
    subtitulo?: string
    mensaje?: string
    open: boolean
    setOpen?: Function
    severidad: any
    noClose?: any
    children?: any
    sx?: SxProps<Theme>
    xs?: boolean | GridSize;
}
export default function Aviso({ titulo, subtitulo, open, severidad, sx = {}, xs, children }: AlertArgs) {
    return (
        <Grid xs={xs || 12}>
            <Box sx={{ width: "100%" }}>
                <Collapse in={open}>
                    <Grid sx={{ paddingTop: open ? "16px" : "0px" }}>
                        <Alert
                            severity={severidad}
                            sx={{...style(severidad), ...sx}}
                            iconMapping={iconStyle(severidad)}
                            data-testid="avisoTest"
                        >
                            {children ?
                                children
                                :
                                <>
                                    <strong>
                                        {titulo}
                                    </strong>
                                    <br />
                                    <label>
                                        {subtitulo}
                                    </label>
                                </>
                            }
                        </Alert>
                    </Grid>
                </Collapse>
            </Box>
        </Grid>
    );
} 