import { Checkbox, Stack, Typography, IconButton, Menu, MenuItem, Grid } from "@mui/material";
import { Accordion, AccordionSummary, AccordionDetails } from "../../../../components/accordion/accordion";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { titleAccordion, textAccordion, textAccordionGreen, textAccordionOrange, textAccordionRed } from "../../../../assets/styles/Text/text";
import TableValorizacion from "./valorizacionTable";
import { Fragment, useState } from "react";
import infoIcon from "../../../../assets/icons/infoAccordeon.svg";
import { ModalPrestacion } from "./modalNuevaPrestacion";
import { ModalNuevoDocumento } from "./modalNuevoDoc";
import { ModalDeleteValorizar } from "./modalDeleteValorizar";
import { parseMiles } from "../../../../utils/monedaUtils";
import { CustomTooltip } from "../../../../components/Tooltip/tooltipbs";

type inputDocumentoCobro = {
    docuCobro: any,
    prestacion: any,
    dataCM: any,
    refetch: Function,
    muestraMensaje: Function,
    disabled?: boolean,
    isBonif?: boolean,
    checkValue: number[],
    setCheck: Function,
    checkPrest: number[],
    setCheckPrest: Function,
    isCuentaAuth?: any,
    authFilter: boolean
}

const verificaCobertura = (tipoCobertura: number) => {
    switch (tipoCobertura) {
        case 1: return 'GES';
        case 2: return 'CAEC';
        case 3: return 'PLAN';
        case 4: return 'GES-CAEC'
        default: return '';
    }
}

const estadoValorizacion = (estado: number) => {
    switch (estado) {
        case 51: return "No valorizado";
        case 52: return "En valorización";
        case 53: return "Valorizado";
        case 54: return "Error al valorizar";
        default: return "DESCONOCIDO";
    }
}

const estadoBonificacion = (estado: number) => {
    switch (estado) {
        case 51: return "No Bonificado";
        case 52: return "En Bonificacion";
        case 53: return "Bonificado";
        case 54: return "Error al bonificar";
        case 55: return "Bonificado Manual";
        default: return "DESCONOCIDO";
    }
}

const estadoValText = (estado: number) => {
    switch (estado) {
        case 51: return textAccordionOrange;
        case 52: return textAccordionGreen;
        case 53: return textAccordionGreen;
        case 54: return textAccordionRed;
        default: return textAccordionOrange;
    }
}

export const DocumentoCobro = ({
    docuCobro,
    prestacion,
    dataCM,
    refetch,
    muestraMensaje,
    disabled,
    isBonif,
    checkValue,
    setCheck,
    checkPrest,
    setCheckPrest,
    isCuentaAuth,
    authFilter }: inputDocumentoCobro) => {
    const { idCuentaMedica } = dataCM;
    const cobertura = verificaCobertura(docuCobro.cobertura && docuCobro.cobertura.tipoCobertura) || "";
    const [expanded, setExpanded] = useState<boolean>(false);
    const [modalPrestacion, setModalPrestacion] = useState<boolean>(false);
    const [modalDocumento, setModalDocumento] = useState({ open: false, accion: '' });
    const [modalEliminarDocumento, setModalEliminarDocumento] = useState<boolean>(false);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();
        event.preventDefault();
        setAnchorEl(event.currentTarget);
    };
    const handleClose = (event: any) => {
        event.stopPropagation();
        event.preventDefault();
        setAnchorEl(null);
    };

    const handleCheck = (e: any) => {
        e.stopPropagation();
        e.preventDefault();
        if (checkValue.includes(docuCobro.id)) {
            setCheck(checkValue.filter(val => val !== docuCobro.id));
        } else {
            setCheck([...checkValue, docuCobro.id]);
        }
    }

    const nuevaPrestacion = (e: any) => {
        e.stopPropagation();
        e.preventDefault();
        setModalPrestacion(true);
        handleClose(e);
    }

    const modalDocumentoVerDetalle = (e: any) => {
        e.stopPropagation();
        e.preventDefault();
        setModalDocumento({
            open: true,
            accion: 'verDetalle'
        });
        handleClose(e);
    }

    const modalDocumentoEditar = (e: any) => {
        e.stopPropagation();
        e.preventDefault();
        setModalDocumento({
            open: true,
            accion: 'editar'
        });
        handleClose(e);
    }

    const modalDocumentoDuplicar = (e: any) => {
        e.stopPropagation();
        e.preventDefault();
        setModalDocumento({
            open: true,
            accion: 'duplicar'
        });
        handleClose(e);
    }

    const modalDocumentoEliminar = (e: any) => {
        e.stopPropagation();
        e.preventDefault();
        setModalEliminarDocumento(true);
        handleClose(e);
    }

    const btnFetch = () => {
        const btn = document.getElementById(`btnCobro${docuCobro.id}`);
        if (btn) {
            btn.click();
            refetch();
        } else {
            refetch();
        }
    }

    return (
        <Fragment>
            <ModalPrestacion
                onOpen={modalPrestacion}
                onClose={() => setModalPrestacion(false)}
                idCuentaMedica={dataCM.idCuentaMedica}
                fechaHospitalizacion={dataCM.fechaHospitalizacion.fechaInicio}
                idCobro={docuCobro.id}
                idCobertura={docuCobro.cobertura.id}
                isGES={[1, 4].includes(docuCobro.cobertura.tipoCobertura)}
                muestraMensaje={muestraMensaje}
                refetch={btnFetch}
                totalDoc={docuCobro.monto}
                totalPres={docuCobro.totalPrestaciones}
            />
            {modalDocumento.open ? (
                <ModalNuevoDocumento
                    dataCM={dataCM}
                    accion={modalDocumento.accion}
                    data={docuCobro}
                    onOpen={modalDocumento.open}
                    onClose={() => setModalDocumento({ open: false, accion: '' })}
                    muestraMensaje={muestraMensaje}
                    refetch={refetch}
                />
            ) : null}
            <ModalDeleteValorizar
                idCuentaMedica={idCuentaMedica}
                data={docuCobro}
                onOpen={modalEliminarDocumento}
                onClose={() => setModalEliminarDocumento(false)}
                muestraMensaje={muestraMensaje}
                refetch={refetch}
            />
            <Accordion expanded={expanded} className="ValorizacionAcc" slotProps={{ transition: { unmountOnExit: true } }}>
                <AccordionSummary
                    aria-controls="panel1d-content"
                    id="panel1d-header"
                    sx={{ pl: 0 }}
                    onClick={() => setExpanded(!expanded)}
                >
                    <Checkbox
                        color="secondary"
                        sx={{ ml: "10px" }}
                        onClick={handleCheck}
                        checked={checkValue.includes(docuCobro.id)}
                        data-testid="checkbox"
                        key={docuCobro.id}
                        disabled={docuCobro.estadoValorizacion === 52}
                    />
                    <Stack direction={"column"}>
                        <Typography sx={titleAccordion}>Estado Doc.</Typography>
                        <Stack alignItems={"center"} direction={"row"} spacing={"10px"}>
                            <img src={infoIcon} />
                            <Typography sx={estadoValText(isBonif ? docuCobro.estadoBonificacion : docuCobro.estadoValorizacion)} pt="2px">
                                {isBonif ? estadoBonificacion(docuCobro.estadoBonificacion) : estadoValorizacion(docuCobro.estadoValorizacion)}
                            </Typography>
                        </Stack>
                    </Stack>
                    <Stack direction={"column"}>
                        <Typography sx={titleAccordion}>Prestador</Typography>
                        <Typography sx={textAccordion} pt="2px">{(docuCobro.rutPrestador) || ""}</Typography>
                    </Stack>
                    <Stack direction={"column"}>
                        <Typography sx={titleAccordion}>Cobertura</Typography>
                        <Typography sx={textAccordion} pt="2px">{cobertura}</Typography>
                    </Stack>
                    <Stack direction={"column"}>
                        <Typography sx={titleAccordion}>Total prestaciones</Typography>
                        <Typography sx={textAccordionGreen} pt="2px">{docuCobro.detallePrestaciones.contador}</Typography>
                    </Stack>
                    <Stack direction={"column"} sx={{width: '92px'}}>
                            <Grid direction={'row'}>
                                <Typography sx={titleAccordion}>Estado</Typography>
                                <Typography sx={docuCobro.monto === docuCobro.totalPrestaciones ? textAccordionGreen : textAccordionRed} pt="2px">{docuCobro.monto === docuCobro.totalPrestaciones ? 'CUADRADO' : 'NO CUADRADO'}</Typography>
                            </Grid>
                    </Stack>
                    <Stack direction={"column"}>
                        <Typography sx={titleAccordion}>Total Informado</Typography>
                        <Typography sx={textAccordion} pt="2px">{parseMiles(docuCobro.monto || 0)}</Typography>                        
                    </Stack>
                    <Stack direction={"column"}>
                        <Typography sx={titleAccordion}>Total Calculado</Typography>                        
                        <Typography sx={textAccordion} pt="2px">{parseMiles(docuCobro.totalPrestaciones || 0)}</Typography>
                    </Stack>
                    <IconButton
                        onClick={handleClick}
                        data-testid="dotMenu"
                        sx={{ mr: "30px" }}
                    >
                        <MoreVertIcon />
                    </IconButton>
                    <Menu
                        className="menuForm"
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        MenuListProps={{
                            'aria-labelledby': 'basic-button',
                        }}
                    >
                        {isBonif ? null : <MenuItem onClick={nuevaPrestacion} disabled={disabled || docuCobro.estadoValorizacion === 52}>Nueva prestación</MenuItem>}
                        <MenuItem onClick={modalDocumentoVerDetalle} >Ver detalle</MenuItem>
                        {isBonif ? null : <MenuItem onClick={modalDocumentoEditar} disabled={disabled || docuCobro.estadoValorizacion === 52 || docuCobro.cobertura.tipoCobertura === 4}>Editar documento</MenuItem>}
                        {isBonif ? null : <MenuItem onClick={modalDocumentoDuplicar} disabled={disabled || docuCobro.estadoValorizacion === 52 || docuCobro.cobertura.tipoCobertura === 4}>Duplicar documento</MenuItem>}
                        {isBonif ? null : <MenuItem onClick={modalDocumentoEliminar} disabled={disabled || docuCobro.estadoValorizacion === 52}>Eliminar</MenuItem>}
                    </Menu>
                </AccordionSummary>
                <AccordionDetails>
                    <TableValorizacion
                        prestacion={prestacion}
                        idCuenta={docuCobro.idCuentaMedica}
                        idCobro={docuCobro.id}
                        idCobertura={docuCobro.cobertura.id}
                        folioCobertura={docuCobro.cobertura.folioCobertura}
                        tipoCobertura={cobertura}
                        refetchDoc={refetch}
                        muestraMensaje={muestraMensaje}
                        abrirNuevaPrest={() => setModalPrestacion(true)}
                        disabled={disabled}
                        isBonif={isBonif}
                        tipoDoc={docuCobro.tipoDocumento}
                        estadoValorizacion={docuCobro.estadoValorizacion}
                        estadoBonificacion={docuCobro.estadoBonificacion}
                        checkPrest={checkPrest}
                        setCheckPrest={setCheckPrest}
                        isCuentaAuth={isCuentaAuth}
                        authFilter={authFilter}
                    />
                </AccordionDetails>
            </Accordion>
        </Fragment>
    )
}