import { useState } from "react";
import { SlimValueSelect2 } from "../../../../components/Selects/valueSelect"
import { queryObtenerTipoCobertura } from "../../../../services/administracionCM/administracionCMServices";
import { useQuery } from "@apollo/client";

export const SelectCoberturas = ({ disabled, onChange, value }:any) => {
    const [dataTipo, setDataTipo] = useState<any[]>([]);
    useQuery(queryObtenerTipoCobertura, {
        fetchPolicy: "no-cache",
        onCompleted: (res) => {
            setDataTipo(res.obtenerValoresParametros);
        }
    });
    return (
        <SlimValueSelect2
            onChange={onChange}
            disabled={disabled}
            value={value}
            data={dataTipo}
            titulo={"Seleccione un Tipo"}
            name="tipoCobertura"
        />
    )
}