import { Grid, Typography } from "@mui/material"
import { Box, Stack } from "@mui/system"
import { boxCardStyleCMWhitoutPnh } from "../../../../assets/styles/Box/boxs"
import { useState } from "react"
import { CuentasEventos } from "./TableCuentasEvento"
import { Accordion, AccordionDetails, AccordionSummary } from "../../../../components/accordion/accordion"

export const HeaderEventoHospitalario = ({ idEventoHospitalario, idCuenta }: { idEventoHospitalario: number, idCuenta: number }) => {
    const [expanded, setExpanded] = useState<boolean>(false);
    return (
        <Box sx={boxCardStyleCMWhitoutPnh}>
            <Grid container>
                <Grid item xs={12}>
                    <Accordion expanded={expanded} slotProps={{ transition: { unmountOnExit: true } }}>
                        <AccordionSummary
                            aria-controls="panel1d-content"
                            id="panel1d-header"
                            data-testid="accordionTEST"
                            sx={{ pl: 0 }}
                            onClick={() => setExpanded(!expanded)}
                        >
                            <Stack direction={"column"}>
                                <Typography variant="h5" sx={{pl: "15px"}} >Cuentas vinculadas y Fechas de cobro</Typography>
                            </Stack>
                        </AccordionSummary>
                        <AccordionDetails>
                            <CuentasEventos idEventoHospitalario={idEventoHospitalario} idCuenta={idCuenta}/>
                        </AccordionDetails>
                    </Accordion>
                </Grid>
            </Grid>
        </Box>
    )
}