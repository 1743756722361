import { Workbook } from "exceljs";
import { saveAs } from "file-saver";
import { format } from "date-fns";

export const exportConsultaCuentas = async (data, handleMessage) => {
    try {
        const cuentas = [];
        if (data.length === 0) {
            handleMessage("No hay datos para exportar", "info");
        }
        cuentas.push(["N° Cuenta", "N° Cuenta Extranet", "N° Cuenta Prestador", "Rut Beneficiario",
            "Nombre Beneficiario", "Rut Prestador", "Nombre prestador", "estado", "causal", "Fecha Recepción", "Fecha Asigación", "Fecha Compromiso", "Días Restantes", "Monto de Cobro",
            "Canal", "Usuario Asignado", "Nota", "Usuario"]);
        data.forEach((cuenta) => {
            let nombrePaciente = cuenta.nombrePaciente + " " +
                (cuenta.apellidoPaternoPaciente ? cuenta.apellidoPaternoPaciente : "") + " " +
                (cuenta.apellidoMaternoPaciente ? cuenta.apellidoMaternoPaciente : "");
            let nota = cuenta.historial ? cuenta.historial.nota : "";
            let usuario = cuenta.historial ? cuenta.historial.idUsuario : "";
            cuentas.push([
                cuenta.id,
                cuenta.idCMD,
                cuenta.numeroCuentaPrestador,
                cuenta.rutPaciente,
                nombrePaciente,
                cuenta.prestador.rutPrestador,
                cuenta.prestador.razonSocial,
                cuenta.estado,
                (cuenta.causal.descripcionCausal === "null" || cuenta.causal.descripcionCausal === null)
                    ? "N/A"
                    : cuenta.causal.descripcionCausal,
                cuenta.fechaRecepcion,
                cuenta.fechaAsignacionUsuario,
                cuenta.fechaProbableLiquidacion,
                cuenta.diasRestantes,
                cuenta.montoTotalCobro,
                cuenta.canalOrigen,
                cuenta.usuarioAsignado,
                nota,
                usuario
            ])
        });
        const consultaMD = new Workbook();
        consultaMD.creator = "CTA";
        consultaMD.addWorksheet("ConsultaCuentas");
        const libroCuentas = consultaMD.getWorksheet(1);
        libroCuentas.addRows(cuentas);
        await consultaMD.xlsx.writeBuffer().then((data) => {
            const blob = new Blob([data], {
                type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            });
            const name = `Cuentas_${format(new Date(), "yyyyMMdd")}.xlsx`;
            saveAs(blob, name);
            handleMessage("Archivo generado correctamente, revise descargas", "success");
            return;
        });
        return;
    } catch (err) {
        console.log(err)
        handleMessage("Error al procesar las Cuentas", "error");
        return;
    }
}
