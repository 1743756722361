import { useState } from "react";
import { useQuery } from "@apollo/client";
import { obtenerProfesionales } from "../../services/administracionCM/administracionCMServices";
import { CustomSelect2 } from "../Selects/customSelect";

type profesional = {
    id: number,
    valor1?: string,
    idCodigo?: number,
    label?: string,
    value?: number
}

type props = {
    disabled?: boolean,
    onChange: Function,
    value: number | null
}
export const SelectTipoProfesional = ({ disabled, onChange, value }: props) => {
    const [profesionales, setProfesionales] = useState<profesional[]>([{ id: 0, label: "Cargando...", value: 0 }]);
    useQuery(obtenerProfesionales, {
        fetchPolicy: "cache-first",
        onCompleted: (res) => {
            const temp: profesional[] = [];
            if (res.obtenerTiposProfesional) {
                res.obtenerTiposProfesional.forEach((tipo: profesional) => {
                    temp.push({ id: tipo.id, label: tipo.idCodigo + " - " + tipo.valor1, value: tipo.idCodigo })
                })
            }
            setProfesionales(temp);
        },
        onError: (err) => {
            console.log(err)
            setProfesionales([{ id: 0, label: "Error...", value: 0 }]);
        }
    });
    return (
        <CustomSelect2
            onChange={onChange}
            disabled={disabled}
            value={value || 0}
            data={profesionales}
            placeholder={"Seleccione"}
            phvalue={null}
            name="tipoProfesional"
            id="tipoProfesional"
            inputTest="tipoProfesional"
        />
    )
}