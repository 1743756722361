import { Grid, Box, Typography, Divider, CircularProgress } from "@mui/material";
import { useDropzone } from 'react-dropzone';
import { CustomSelect } from "../../../../components/Selects/customSelect";
import Attached from "../../../../assets/icons/attached.svg";
import { SecondaryButton } from "../../../../components/Buttons/Button";
import "../../../../assets/cargaArchivo.css";

export const UploadFile = ({
  rutBeneficiario,
  idCuentaMedica,
  numeroCuentaPrestador,
  sendFile,
  selectTypeFile,
  generateMsg,
  setTypeFile,
  typeFile,
  usuario,
  loading,
  revisarCuentaMedica
}) => {
  const transformarBase64 = (file) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = ({ target }) => {
      const toBase64 = target.result.toString();
      const base64WithoutPrefix = toBase64.slice(`data:${file.type};base64,`.length);
      sendFile({
        variables: {
          tipoDocumento: selectTypeFile[typeFile - 1].toUpperCase(),
          contenidoDocumento: base64WithoutPrefix,
          idCuentaMedica: parseInt(idCuentaMedica),
          numeroCuenta: numeroCuentaPrestador,
          usuario: usuario,
          rutBeneficiario: rutBeneficiario,
          nombreDocumento: file.name
        }
      });
    }
  }

  const handleOnChangeTypeFile = (newTypeFile) => {
    setTypeFile(newTypeFile.target.value);
  };

  const fillSelectTypeFileNames = () => {
    return Object.keys(selectTypeFile).map(function (key) {
      return selectTypeFile[key];
    });
  };

  const handleButtonAttachDisabled = () => {
    return typeFile !== 0 ? false : true;
  };

  const onDrop = (accFiles, rejFiles) => {
    if (accFiles[0]) {
      transformarBase64(accFiles[0]);
    } else {
      generateMsg("error", "Archivo ingresado no es png, jpg, pdf o pesa más de 2MB");
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    maxFiles: 1,
    disabled: handleButtonAttachDisabled() || loading,
    accept: {
      'image/gif': ['.gif'],
      'image/jpeg': ['.jpeg', '.jpg'],
      'image/png': ['.png'],
      'application/pdf': ['.pdf']
    },
    maxSize: 2000 * 1024, // 2MB
  });

  return (
    <Grid data-testid="uploadTest">
      <Divider variant="fullWidth" />
      <Box pt="24px">
        <Typography variant="h6" fontSize="18px" pb="4px">
          Adjuntar Documento
        </Typography>
        <Box display="flex" alignItems="flex-end" pt="20px">
          <Box width="290px" mr="24px">
            <Typography pb="4px" variant="h6">
              Tipo de Documento
            </Typography>

            <CustomSelect
              onChange={(newTypeFile) =>
                handleOnChangeTypeFile(newTypeFile)
              }
              value={typeFile || 0}
              data={fillSelectTypeFileNames()}
              titulo={"Seleccione un tipo"}
              name="fileType"
              disabled={revisarCuentaMedica}
            />
          </Box>
          <div {...getRootProps()}>
            <input data-testid="fileDropButton" {...getInputProps()} />
            <SecondaryButton
              className="large-button"
              disabled={handleButtonAttachDisabled() || loading || revisarCuentaMedica}
            >
              {loading ? <CircularProgress className="spinnerGreen" size={30}/> :
                <><img src={Attached} className="white-color-svg" alt="" />
                  Adjuntar Archivo</>}
            </SecondaryButton>
          </div>
          <label className="tipoFormatoMin" style={{ paddingLeft: "16px" }}>
            Formato aceptados: .pdf, .jpg, .png<br></br>
            Peso máximo de 2MB
          </label>
        </Box>
      </Box>
    </Grid>
  )
}