import {
  TableCell,
  TableHead,
  TableRow,
  Checkbox,
  TableSortLabel,
} from "@mui/material";
import { stickyHead } from "../../../assets/styles/Table/table";
import { DropdownIndicator } from "../../../components/Tables/dropdownIndicator";

const headCellsLiquidada = [
  {
    id: "id",
    label: "Nº Cuenta ",
    width: "92px",
  },
  {
    id: "idCMD",
    label: "Nº C. Extranet",
    width: "116px",
  },
  {
    id: "numeroCuentaPrestador",
    label: "Nº C. Prestador",
    width: "127px",
  },
  {
    id: "rutPaciente",
    label: "Beneficiario",
    width: "172px",
  },
  {
    id: "rutPrestador",
    label: "Prestador",
    width: "110px",
  },
  {
    id: "fechaRecepcion",
    label: "Fecha Recepción",
    width: "150px",
  },
  {
    id: "fechaRealLiquidacion",
    label: "Fecha Liquidación",
    width: "150px",
  },
];

const acciones = [
  {
    id: "no-id",
    label: "Acciones",
  },
];
const totales = [
  {
    id: "totalFacturado",
    label: "Total factura",
    width: "150px",
  },
  {
    id: "totalBonificado",
    label: "Total Bonificación",
    width: "120px",
  },
  {
    id: "totalCopago",
    label: "Total Copago",
    width: "120px",
  },
];
const emision = {
  id: "estado",
  label: "Emisión",
};
const estado = {
  id: "estado",
  label: "Estado",
};

const tipo = {
  id: "tipo",
  label: "Tipo",
};

const liquidada = [
  emision,
  {
    id: "emitirCuenta",
    label: "Emitir Cuenta",
  },
];

const emitida = [
  {
    id: "fechaEmision",
    label: "Fecha Emisión",
    width: "200px",
  },
];

export const TableHeadLiquidadas = ({
  numSelected,
  isReversa,
  rowCount,
  onSelectAllClick,
  tabIndex,
  order,
  orderBy,
  createSortHandler,
}: any) => {
  const headerTable =
    tabIndex === "2"
      ? isReversa
        ? [
          ...headCellsLiquidada,
          ...emitida,
          ...totales,
          estado,
          tipo,
          ...acciones,
        ]
        : [...headCellsLiquidada, ...emitida, ...totales, tipo, ...acciones]
      : [...headCellsLiquidada, ...totales, ...liquidada, ...acciones];
  return (
    <TableHead>
      <TableRow>
        { tabIndex === "1" &&
          <TableCell padding="checkbox" className="tCell-p" sx={stickyHead}>
            <Checkbox
              color="primary"
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{ "aria-label": "select all desserts" }}
            />
          </TableCell>
        }
        {headerTable.map((headCell: any) => {
          if (headCell.id === "estado") {
            return (
              <TableCell
                key={headCell.id}
                className={
                  "headerTableReasignar"
                }
              >
                {headCell.label}
              </TableCell>
            );
          } else if (headCell.id === "tipo") {
            return (
              <TableCell
                key={headCell.id}
                className={"headerTableEspecial"}
              >
                {headCell.label}
              </TableCell>
            );
          } else if (headCell.id === "emitirCuenta") {
            return (
              <TableCell key={headCell.id} className="headerTableEspecial">
                {headCell.label}
              </TableCell>
            );
          } else if (headCell.id === "no-id") {
            return (
              <TableCell key={headCell.id} className="headerFirstSticky">
                {headCell.label}
              </TableCell>
            );
          } else {
            return (
              <TableCell
                className={
                  orderBy === headCell.id
                    ? "escritorioTrabajo activeSort"
                    : "escritorioTrabajo headerSort"
                }
                key={headCell.id}
                sortDirection={
                  orderBy === headCell.id ? order.toLowerCase() : undefined
                }
                width={headCell.width}
                sx={{ minWidth: headCell.width }}
              >
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={
                    orderBy === headCell.id ? order.toLowerCase() : undefined
                  }
                  onClick={() => createSortHandler(headCell.id)}
                  IconComponent={() => (
                    <DropdownIndicator
                      active={orderBy === headCell.id}
                      order={order.toLowerCase()}
                    />
                  )}
                >
                  {headCell.label}
                </TableSortLabel>
              </TableCell>
            );
          }
        })}
      </TableRow>
    </TableHead>
  );
};
