import { InputBase } from '@mui/material';
import { styled } from '@mui/material/styles';


const CustomSelectInput = styled(InputBase)(({ theme }) => ({
    '& .MuiInputBase-input': {
        borderRadius: '8px',
        position: 'relative',
        width: '100%',
        backgroundColor: theme.palette.background.paper,
        border: '1px solid #BCC2C6',
        fontSize: 16,
        color: '#364855',
        padding: '14px 0px 12px 15px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        WebkitAppearance: 'none',
        letterSpacing: '0.0120588em',
        '&:focus': {
            borderColor: '#5D5FEF',
            borderRadius: '8px',
            // boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
        },
        '&:error': {
            borderRadius: 8,
            color: '#ced4da',
            borderColor: '#ced4da',
        },
        
        '&.Mui-disabled': {
            color: '#67737C',
            backgroundColor: '#E9EBEC'
        },
        '&.Mui-disabled fieldset': {
            borderColor: '#E9EBEC',
        },
    }
}));

const CustomInputSelectActions = styled(InputBase)(({ theme }) => ({
    '& .MuiInputBase-input': {
        borderRadius: 8,
        position: 'relative',
        width: '100%',
        backgroundColor: "#00837A",
        border: '1px solid #00837A',
        fontSize: 16,
        color: '#FFFFFF',
        padding: '15px 0px 16px 16px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        WebkitAppearance: 'none',
        letterSpacing: '0.0120588em',
        '&:focus': {
            borderRadius: 8,
            borderColor: '#5D5FEF',
        },
        '&.Mui-disabled': {
            color: '#67737C',
            backgroundColor: '#E9EBEC',
            borderColor: '#E9EBEC',
        },
        '&.Mui-disabled fieldset': {
            borderColor: '#E9EBEC',
        },
    }
}));

const CustomInputSelect = styled(InputBase)(({ theme }) => ({
    '& .MuiInputBase-input': {
        borderRadius: 8,
        position: 'relative',
        width: '100%  !important',
        backgroundColor: '#FFFFFF',
        border: '1px solid #BCC2C6',
        fontSize: 16,
        color: '#364855',
        padding: '15px 0px 16px 16px  !important',
        paddingRight: '24px !important',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        WebkitAppearance: 'none  !important',
        letterSpacing: '0.0120588em  !important',
        '&:focus': {
            borderRadius: 8,
            borderColor: '#5D5FEF',
            // boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',ced4da
        },
        '&:error': {
            borderRadius: 8,
            color: '#ced4da',
            borderColor: '#ced4da',
        },
    }
}));

const SlimSelectInput = styled(InputBase)(({ theme }) => ({
    '& .MuiInputBase-input': {
        borderRadius: '8px',
        position: 'relative',
        width: '100%',
        backgroundColor: theme.palette.background.paper,
        border: '1px solid #BCC2C6',
        fontSize: 16,
        color: '#364855',
        overflow:'hidden',
        padding: '7px 0px 7px 14px',
        textOverflow: 'elipsis',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        WebkitAppearance: 'none',
        letterSpacing: '0.0120588em',
        '&:focus': {
            borderColor: '#5D5FEF',
            borderRadius: '8px',
            // boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
        },
    }
}));
const CustomTableSelect = styled(InputBase)(({ theme }) => ({
    '& .MuiInputBase-input': {
        borderRadius: '8px',
        position: 'relative',
        width: '100%',
        backgroundColor: theme.palette.background.paper,
        border: '1px solid #BCC2C6',
        fontSize: 16,
        color: '#364855',
        padding: '15px 0px 16px 16px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        WebkitAppearance: 'none',
        letterSpacing: '0.0120588em',
        '&:focus': {
            borderColor: '#5D5FEF',
            borderRadius: '8px',
            // boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
        },
    }
}));

const ITEM_HEIGHT = 48;
const menuSelectStyle = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4,
            borderRadius: 8,
        },
        sx: {
            '&.MuiPaper-root::-webkit-scrollbar': {
                width: '5px'
            },
            '&.MuiPaper-root::-webkit-scrollbar-track': {
                background: 'white',
                borderRadius: '100px',
                margin: '10px',
            },
            '&.MuiPaper-root::-webkit-scrollbar-thumb': {
                background: '#BCC2C6',
                borderRadius: '100px',
            },
        }
    },
};

const menuItemStyle = {
    fontWeight: '700',
    letterSpacing: '0.0120588em',
    color: '#364855',
    borderBottom: '1px solid #E9EBEC',
    height: '48px',
    textTransform: 'capitalize'
}

const menuItemStyle2 = {
    fontWeight: '400 !important',
    letterSpacing: '0.0120588em !important',
    color: '#364855 !important',
    borderBottom: '1px solid #E9EBEC !important',
    height: '48px !important',
    paddingLeft: '16px !important',
    '&:focus': {
        backgroundColor: '#E6F6F5 !important'
    },
    '&:active': {
        backgroundColor: '#E6F6F5 !important'
    },
    '&:target': {
        backgroundColor: '#E6F6F5 !important'
    }
}

const menuFirstStyle = {
    fontWeight: '400',
    letterSpacing: '0.0120588em',
    color: '#909AA1 !important', 
    textTransform: 'capitalize',
    textOverflow: 'ellipsis',
    overflow: 'hidden'
}

export {
    menuFirstStyle,
    CustomSelectInput,
    CustomInputSelectActions,
    CustomInputSelect,
    menuSelectStyle,
    menuItemStyle,
    menuItemStyle2,
    CustomTableSelect,
    SlimSelectInput
}