import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useState } from "react";
import { styleHeadAlt } from "../../../../assets/styles/Table/table";
import { IPreexistingCondition } from "../../../../interfaces/preexistingCondition";
import { convertirFecha } from "../../../../utils/dateUtils";
import { Paginador } from "../../../../components/TablePagination/Paginador";

export const TablePreexistencias = ({
  preexistingConditions,
}: {
  preexistingConditions: IPreexistingCondition[];
}) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  return (
    <Paper
      className="paper-tables"
      sx={{ border: " 0.5px solid #F8F9F9 !important", borderRadius: "16px" }}
    >
      <TableContainer sx={{ pl: "0 !important" }}>
        <Table aria-label="customized table">
          <TableHead>
            <TableRow>
              <TableCell sx={styleHeadAlt}>Causal</TableCell>
              <TableCell sx={styleHeadAlt}>F. inicio exclusión</TableCell>
              <TableCell sx={styleHeadAlt}>F. fin exclusión</TableCell>
              <TableCell sx={styleHeadAlt}>Plazo exclusión</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {preexistingConditions
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((preCond) => (
                <TableRow key={`precond-${JSON.stringify(preCond)}`}>
                  <TableCell>{preCond.causal}</TableCell>
                  <TableCell>
                    {convertirFecha(preCond.fechaInicioExclusion)}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {convertirFecha(preCond.fechaFinExclusion)}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {preCond.plazoExclusion}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
        <Paginador
          data={preexistingConditions.length}
          itemPorPagina={rowsPerPage}
          opciones={[5, 7, 10]}
          pagina={page}
          setLineasPagina={setRowsPerPage}
          setPagina={setPage}
        />
      </TableContainer>
    </Paper>
  );
};
