import { Fragment, useState } from "react";
import {
    Autocomplete,
    Box,
    Checkbox,
    Chip,
    CircularProgress,
    FormControl,
    FormHelperText,
    Grid,
    Stack,
    Tab,
    Tabs,
    Typography
} from "@mui/material";
import { useQuery } from "@apollo/client";
import { TabPanel } from "../Tab/Tab";
import { CustomInput } from "../../assets/styles/Input/input";
import {
    formatRut,
    validRut
} from "chilean-rutify";
import { queryTodosPrestadores } from "../../services/administracionCM/docCobroServices";
import "../../assets/styles/BuscaPrestador/buscaPrest.css";
import { TextLine } from "../../assets/styles/Text/text";
import { SelectPersonalizado } from "../Selects/SelectPersonalizado";

export function manejaDirecciones(prestadores: any[]) {
    let array: any = []
    prestadores.forEach(element => {
        array.push({
            direccion: element.nombreCalle + " " + element.numeroCalle + ", " + element.region,
            value: element.luat,
            razonSocial: element.razonSocial,
            rut: element.rut,
            label: element.luat + " - " + element.nombreCalle + " " + element.numeroCalle + ", " + element.region,
        });
    });
    return array
}

const BuscaPrestador = ({
    razonSocial,
    rutInsti,
    luat,
    direccion,
    label,
    convenio,
    error,
    asignaPrestador,
    casoInvalido,
    isModal,
    disabled,
    setConvenio,
    isEditaPres
}: any) => {
    const [tabValue, setTabValue] = useState(0);
    const [direcciones, setDirecciones] = useState<any[]>([]);
    const [prestadores, setPrestadores] = useState<any[]>([]);
    const [inputVal, setInputVal] = useState(razonSocial || "");
    const { data, loading } = useQuery(queryTodosPrestadores, {
        fetchPolicy: "cache-first",
        onCompleted: (res) => {
            filtraValores(res.todosPrestadores);
        },
        onError: (err) => {
            console.log(err.message)
        }
    });

    function filtraValores(dataPrestadores: any) {
        const temp: any = [];
        dataPrestadores.forEach((pres: any) => {
            if (!temp.find((savedPres: any) => savedPres.razonSocial === pres.razonSocial)) {
                temp.push(pres);
            }
        })
        temp.sort((a: any, b: any) => {
            const nameA = a.razonSocial.toUpperCase();
            const nameB = b.razonSocial.toUpperCase();
            if (nameA < nameB) {
                return -1;
            }
            if (nameA > nameB) {
                return 1;
            }
            return 0;
        });
        setPrestadores(temp);
        verificaPreselect(dataPrestadores);
    }

    function verificaPreselect(dataPrestadores: any) {
        if (rutInsti) {
            const tempDirecciones = manejaDirecciones(dataPrestadores.filter((pres: any) => formatRut(pres.rut) === formatRut(rutInsti)));
            if (tempDirecciones.length > 0) {
                setDirecciones(tempDirecciones);
            }
        }
    }

    const handleChange = (event: any, newValue: any) => {        
        asignaPrestador(null, null, null, null, null);
        setDirecciones([]);
        setInputVal("");
        setTabValue(newValue);        
    };

    function checkPrestadores(nombrePres: string) {
        setInputVal(nombrePres)
        if (nombrePres) {
            if (data && data.todosPrestadores) {
                if (!data.todosPrestadores.find((pres: any) => pres.razonSocial.toUpperCase().includes(nombrePres.toUpperCase()))) {                    
                    asignaPrestador(null, null, nombrePres, null, null);
                }
            } else {                
                asignaPrestador(null, null, nombrePres, null, null);
            }
        }
    }

    function establecePrestador(option: any) {
        if (option) {
            const tempDirecciones = manejaDirecciones(data.todosPrestadores.filter((pres: any) => pres.razonSocial === option.razonSocial));
            setDirecciones(tempDirecciones);
            setInputVal(option.razonSocial);

            if (tempDirecciones.length === 1) {                
                asignaPrestador(tempDirecciones[0].direccion, tempDirecciones[0].value, option.razonSocial, formatRut(option.rut), tempDirecciones[0].label);
            } else {                
                asignaPrestador(null, null, option.razonSocial, formatRut(option.rut), null);
            }
        } else {            
            asignaPrestador(null, null, null, null, null);
            setDirecciones([]);
        }
    }

    function procesaRut(e: { target: { value: string; }; }) {
        let rut = (e.target.value.replace(/[^0-9kK-]/g, "").trim()) || "";
        // @ts-ignore: Object is possibly 'undefined'.
        rut = rut.length > 2 ? formatRut(rut).toUpperCase() : rut;        
        asignaPrestador(null, null, null, rut, null);
        setDirecciones([]);
        if (validRut(rut) && data && data.todosPrestadores) {
            buscarPrestador(rut);
        }
    }
    function buscarPrestador(rut: string) {
        const tempDirecciones = manejaDirecciones(data.todosPrestadores.filter((pres: any) => pres.rut === rut.replace(/[^0-9kK-]/g, "")));
        if (tempDirecciones.length > 0) {
            setDirecciones(tempDirecciones);

            if (tempDirecciones.length === 1) {                
                asignaPrestador(tempDirecciones[0].direccion, tempDirecciones[0].value, tempDirecciones[0].razonSocial, rut, tempDirecciones[0].label);
            } else {                
                asignaPrestador(null, null, tempDirecciones[0].razonSocial, rut, null);
            }
        } else {
            setDirecciones([]);
        }
    }

    const estableceDireccion = (value: number) => {
        const prest = direcciones.find((savedPres) => savedPres.value === value);        
        asignaPrestador(prest.direccion, prest.value, razonSocial, rutInsti, prest.label);
    }

    return (
        <Fragment>
            {isModal ?
                <>
                    <Grid item xs={12} >
                        <Typography className="AltTitulo1">Datos del prestador</Typography>
                        <Typography className="AltTitulo2">
                            Busca la institución por RUT o por Razón social.
                        </Typography>

                    </Grid>
                    <Stack direction="row" spacing={1} sx={{ paddingLeft: "16px" }}>
                        <Chip
                            label="Razón social"
                            color="secondary"
                            className={tabValue === 0 ? "chipSelected" : "chipNotSelect"}
                            onClick={() => handleChange(null, 0)}
                        />
                        <Chip
                            label="RUT"
                            color="secondary"
                            className={tabValue === 1 ? "chipSelected" : "chipNotSelect"}
                            disabled={loading}
                            onClick={() => handleChange(null, 1)}
                        />
                    </Stack>
                </> : null}
            {isModal ?
                null
                :
                <Grid item xs={12}>
                    <Box
                        className="nav"
                        sx={{
                            display: "flex",
                            flexWrap: "wrap",
                            justifyContent: "start",
                            padding: "0 0 0 0",
                        }}
                    >
                        <Tabs
                            value={tabValue}
                            onChange={handleChange}
                            aria-label="basic tabs example"
                        >
                            <Tab label="Razón social" className="tabGreen" />
                            <Tab label="RUT" disabled={loading || disabled} data-testid="rutTabT" className="tabGreen" />
                        </Tabs>
                    </Box>
                </Grid>}

            <Grid item xs={12}>
                <Grid item xs={12} pt="24px">
                    <Checkbox
                        disabled={disabled}
                        onChange={() => setConvenio()}
                        checked={convenio}
                    />
                    <Typography sx={TextLine} display={"inline"}>Prestador sin convenio o sin registro</Typography>
                </Grid>
                <TabPanel value={tabValue} index={0}>
                    <Grid
                        container
                        spacing={isModal ? undefined : 2}
                    >
                        <Grid item xs={isModal ? 12 : 6}>
                            <label className="labelForm">Razón social</label>
                            <FormControl sx={{ width: "100%" }}>
                                <Autocomplete
                                    id="autocompleteBuscaPres"
                                    data-testid="autocompleteBuscaPres"
                                    disabled={disabled || loading || isEditaPres}
                                    options={prestadores}
                                    loading={loading}
                                    loadingText={"Cargando..."}
                                    noOptionsText={"Sin coincidencias"}
                                    autoHighlight
                                    clearText={"Limpiar"}
                                    clearOnBlur={false}
                                    value={prestadores.find((savedPres) => savedPres.razonSocial === razonSocial) || null}
                                    onChange={(event, option) => {
                                        establecePrestador(option);
                                    }}
                                    inputValue={inputVal}
                                    onInputChange={(event, valor) => checkPrestadores(valor)}
                                    getOptionLabel={(option) => option.razonSocial}
                                    renderOption={(props, option) => (
                                        <Box
                                            component="li"
                                            sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                                            {...props}
                                            key={option.internalRolId}
                                            value={option.razonSocial}
                                        >
                                            {option.razonSocial}
                                        </Box>
                                    )}
                                    renderInput={(params) => (
                                        <CustomInput
                                            {...params}
                                            placeholder="Ej: Nombre empresa S.A."
                                            data-testid="inputRZAutoCom"
                                            InputProps={{
                                                ...params.InputProps,
                                                endAdornment: (
                                                    <Fragment>
                                                        {loading ? <CircularProgress data-testid="loadPrest" color="inherit" size={20} /> : null}
                                                        {params.InputProps.endAdornment}
                                                    </Fragment>
                                                ),
                                            }}
                                        />
                                    )}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <label className="labelForm">RUT institución</label>
                            <FormControl sx={{ width: "98%!important", margin: "0 1% 0 0" }} error={rutInsti ? !validRut(rutInsti) : undefined}>
                                <CustomInput
                                    fullWidth
                                    data-testid="rutInstitucion"
                                    name="rut"
                                    id="rutInsti"
                                    placeholder="Ej: 11.111.111-1"
                                    disabled={disabled || !convenio || isEditaPres}
                                    inputProps={{ maxLength: 12 }}
                                    error={rutInsti ? !validRut(rutInsti) : undefined}
                                    // @ts-ignore: Object is possibly 'undefined'.
                                    onChange={(e: { target: { value: string; }; }) => asignaPrestador(direccion, null, razonSocial, e.target.value && e.target.value.length > 2 ? formatRut(e.target.value.trim()).toUpperCase() : e.target.value, label)}
                                    value={rutInsti || ""}
                                />
                                <FormHelperText>{rutInsti && !validRut(rutInsti) ? "Rut inválido" : ""}</FormHelperText>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <label className="labelForm">Dirección de atención (Isapre)</label>
                            <FormControl sx={{ width: "98%!important", margin: "0 1% 0 0" }}>
                                {convenio ?
                                    <CustomInput
                                        fullWidth
                                        data-testid="direccion"
                                        id="direccion"
                                        onChange={(e: any) => {asignaPrestador(e.target.value, null, razonSocial, rutInsti, e.target.value)}}
                                        placeholder="Ej: Av Las Garmendias"
                                        disabled={disabled || !convenio}
                                        value={direccion || ""}
                                    />
                                    : <SelectPersonalizado
                                        inputTest="DireccionTest"
                                        id="direccionPrest"
                                        disabled={disabled || casoInvalido || !razonSocial || direcciones.length === 0}
                                        value={luat || 888}
                                        phvalue={luat === 888 ? "888" : ""}
                                        setValue={estableceDireccion}
                                        options={direcciones}
                                        placeholder="Seleccione"
                                    />
                                }
                            </FormControl>
                        </Grid>
                    </Grid>
                </TabPanel>
                <TabPanel value={tabValue} index={1}>
                    <Grid
                        container
                        spacing={2}
                    >
                        <Grid item xs={6}>
                            <label className="labelForm">Razón social</label>
                            <FormControl sx={{ width: "99%!important", margin: "0 1% 0 0" }}>
                                <CustomInput
                                    fullWidth
                                    data-testid="razonSocial"
                                    id="razonSocial"
                                    onChange={(e: { target: { value: any; }; }) => asignaPrestador(direccion, luat, e.target.value, rutInsti, label)}
                                    placeholder="Seleccione"
                                    disabled={disabled || !convenio}
                                    value={razonSocial || ""}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <label className="labelForm">RUT institución</label>
                            <FormControl sx={{ width: "98%!important", margin: "0 1% 0 0" }} error={rutInsti ? !validRut(rutInsti) : undefined}>
                                <CustomInput
                                    fullWidth
                                    data-testid="rutInstitucion"
                                    error={rutInsti ? !validRut(rutInsti) : undefined}
                                    name="rut"
                                    id="rutInsti"
                                    placeholder="Ej: 11.111.111-1"
                                    disabled={disabled || casoInvalido}
                                    onChange={procesaRut}
                                    value={rutInsti || ""}
                                    inputProps={{ maxLength: 12 }}
                                />
                                <FormHelperText>{rutInsti && !validRut(rutInsti) ? "Rut inválido" : ""}</FormHelperText>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <label className="labelForm">Dirección de atención (Isapre)</label>
                            <FormControl sx={{ width: "98%!important", margin: "0 1% 0 0" }}>
                                {convenio ?
                                    <CustomInput
                                        fullWidth
                                        data-testid="direccion"
                                        id="direccion"
                                        onChange={(e: any) => {asignaPrestador(e.target.value, null, razonSocial, rutInsti, e.target.value)}}                                        
                                        placeholder="Ej: Av Las Garmendias"
                                        disabled={disabled || !convenio}
                                        value={direccion || ""}
                                    />
                                    : <SelectPersonalizado
                                        inputTest="DireccionTest"
                                        id="direccionPrest"
                                        disabled={disabled || casoInvalido || !validRut(rutInsti || "") || direcciones.length === 0}
                                        value={luat || 888}
                                        phvalue={luat === 888 ? "888" : ""}
                                        setValue={estableceDireccion}
                                        options={direcciones}
                                        placeholder="Seleccione"
                                    />
                                }
                            </FormControl>
                        </Grid>
                    </Grid>
                </TabPanel>
                <span className="errorSpan">{error.rutInsti || ""}</span>
            </Grid>
        </Fragment>
    );
}

export default BuscaPrestador;