import {
  AlertColor,
  Box,
  DialogActions,
  DialogContent,
  Grid,
  Typography,
} from "@mui/material";
import SnackBar from "../../../../components/Snackbar/Snackbar";
import { useState } from "react";
import { SecondaryButton } from "../../../../components/Buttons/Button";
import {
  CustomModal,
  CustomModalTitle,
} from "../../../../components/Modal/customModal";
import {
  modalTitle,
  warningGray,
  warningPurple,
} from "../../../../assets/styles/Text/text";
import icon_warningOrange from "../../../../assets/icons/icon_warningOrange.svg";
import { CustomSelect2 } from "../../../../components/Selects/customSelect";
import { CustomInput } from "../../../../assets/styles/Input/input";
import {
  obtenerEstadosCausalesPorSiguienteEstado,
  updateCMStatus,
} from "../../../../services/administracionCM/administracionCMServices";
import { useMutation, useQuery } from "@apollo/client";
import { useGetUser } from "../../../../hooks/getUser";
import { NuevosEstadosCuentaMedica } from "../../../../utils/enums";
import {
  actionReasonsByCurrentStatus,
  gointToActionReasonsByCurrentStatus,
  nounActionReasonsByCurrentStatus,
} from "./utils";
import { IncompleteInformationResolucion } from "../../resolucion/incompleteInformationResolucion";
import BotonCarga from "../../../../components/Buttons/LoadingButton";
import { checkStatus } from "../../utils/checkStatus";

interface ModalReasonsProps {
  isOpen: boolean;
  handleClose: () => void;
  idCM: number;
  currentStatus: number;
  nextStatus: number | null;
  refreshCuentaMedica: () => void;
}

export default function ModalReasons({
  isOpen,
  handleClose,
  idCM,
  currentStatus,
  nextStatus,
  refreshCuentaMedica,
}: ModalReasonsProps) {
  const [reasonSelected, setReasonSelected] = useState<any>(null);
  const [reasons, setReasons] = useState<any[]>([]);
  const [hasValidationErrors, setHasValidationErrors] =
    useState<boolean>(false);
  const [validationErrors, setValidationErrors] = useState<any>({});
  const [note, setNote] = useState<string>("");
  const [snackData, setSnackData] = useState({
    abrir: false,
    mensaje: "",
    severidad: "info" as AlertColor,
  });
  const { email } = useGetUser();

  const { loading, error } = useQuery(
    obtenerEstadosCausalesPorSiguienteEstado,
    {
      variables: {
        idEstadoCuentaMedica: currentStatus,
        idProximoEstadoCuentaMedica: nextStatus,
      },
      onCompleted(data) {
        if (data.obtenerEstadosCausalesPorSiguienteEstado) {
          setReasons(
            data.obtenerEstadosCausalesPorSiguienteEstado.map(
              (reason: any) => ({
                value: reason.idCausal,
                label: reason.descripcionCausal,
              })
            )
          );
        }
      },
    }
  );

  const [handleUpdateCMStatus, { loading: loadingUpdateCMStatus }] =
    useMutation(updateCMStatus);

  const onClickClose = () => {
    setHasValidationErrors(false);
    setValidationErrors({});
    setNote("");
    setReasonSelected(null);
    handleClose();
  };

  const onClickConfirm = () => {
    handleUpdateCMStatus({
      variables: {
        updateData: {
          idCuentaMedica: idCM,
          estado: nextStatus,
          causal: reasonSelected,
          notaResolutiva: note,
          idUsuario: email,
        },
      },
    })
      .then(({ data }) => {
        if (data.updateCMStatus) {
          if (data.updateCMStatus.estado === 0) {
            setSnackData({
              abrir: true,
              mensaje: "El estado se ha cambiado con éxito",
              severidad: "success" as AlertColor,
            });
            refreshCuentaMedica();
            onClickClose();
          } else {
            setHasValidationErrors(true);
            setValidationErrors(data.updateCMStatus);
            refreshCuentaMedica()
          }
        }
      })
      .catch((error: any) => {
        console.log(error.graphQLErrors[0].code)
        setSnackData({
          abrir: true,
          mensaje:
            error.graphQLErrors[0].code === "FORBIDDEN" ?
              error.graphQLErrors[0].message :
              "Ocurrio un error al cambiar de estado. Por favor, intenta mas tarde",
          severidad: "error" as AlertColor,
        });
        checkStatus(error.graphQLErrors[0].code);
      });
  };

  if (nextStatus === null) return null;

  const showReasons =
    (currentStatus === NuevosEstadosCuentaMedica.Recepcionada &&
      nextStatus === NuevosEstadosCuentaMedica.Anulada) ||
    (currentStatus === NuevosEstadosCuentaMedica.Visación &&
    nextStatus === NuevosEstadosCuentaMedica.Anulada) ||
    (currentStatus === NuevosEstadosCuentaMedica.Bonificación &&
      nextStatus === NuevosEstadosCuentaMedica.Anulada) ||
    (currentStatus === NuevosEstadosCuentaMedica.Visación &&
      (nextStatus === NuevosEstadosCuentaMedica.Rechazada ||
        nextStatus === NuevosEstadosCuentaMedica.Devuelta ||
        nextStatus === NuevosEstadosCuentaMedica.EmitidaManual)) ||
    (currentStatus === NuevosEstadosCuentaMedica.Valorización &&
      (nextStatus === NuevosEstadosCuentaMedica.Visación ||
        nextStatus === NuevosEstadosCuentaMedica.Devuelta)) ||
    (currentStatus === NuevosEstadosCuentaMedica.Bonificación &&
      (nextStatus === NuevosEstadosCuentaMedica.Visación ||
        nextStatus === NuevosEstadosCuentaMedica.Valorización ||
        nextStatus === NuevosEstadosCuentaMedica.Rechazada));

  return (
    <>
      <SnackBar state={snackData} setState={setSnackData} />
      <IncompleteInformationResolucion
        open={hasValidationErrors}
        onClose={onClickClose}
        msgAlerts={validationErrors}
        updateCMTitle={actionReasonsByCurrentStatus(currentStatus, nextStatus)}
        updateCMAction={nounActionReasonsByCurrentStatus(
          currentStatus,
          nextStatus
        )}
        nextStatus={nextStatus}
      />
      <CustomModal
        fullWidth
        maxWidth="sm"
        open={isOpen}
        sx={{
          "& .MuiBackdrop-root": {
            backgroundColor: "rgba(0, 0, 0, 0.3)",
          },
        }}
      >
        <CustomModalTitle>
          <Typography sx={modalTitle} paddingTop="16px">
            ¿Estás seguro que deseas{" "}
            {actionReasonsByCurrentStatus(currentStatus, nextStatus)} la cuenta?
          </Typography>
        </CustomModalTitle>
        <DialogContent dividers>
          <Box sx={{ textAlign: "center", pl: "24px", pr: "24px", pb: "16px" }}>
            <img
              alt="alert"
              src={icon_warningOrange}
              style={{ marginBottom: "16px" }}
              onClick={onClickClose}
            />
            <br></br>
            <Typography sx={warningGray}>
              {gointToActionReasonsByCurrentStatus(currentStatus, nextStatus)}{" "}
              la cuenta
            </Typography>{" "}
            <Typography sx={warningPurple} pb="16px">
              Nº {idCM}
            </Typography>
          </Box>
          <Grid container>
            {showReasons && (
              <Grid item xs={12}>
                <Typography variant="h6" pb="6px">
                  Selecciona un motivo
                </Typography>
                {error ? (
                  <p>Error al cargar causales</p>
                ) : (
                  <>
                    {loading ? (
                      <p>Cargando Opciones</p>
                    ) : (
                      <CustomSelect2
                        onChange={(reason: any) =>
                          setReasonSelected(reason.target.value)
                        }
                        disabled={loading || loadingUpdateCMStatus}
                        value={reasonSelected}
                        data={reasons}
                        name="reasons"
                        id="reasons"
                        inputTest="reasons"
                        titulo={"Seleccione"}
                        placeholder={"Seleccione"}
                      />
                    )}
                  </>
                )}
              </Grid>
            )}
            <Grid item xs={12} pt="16px">
              <label className="observacionBold">
                Nota resolutiva{" "}
                <label className="modalObservacion">(opcional)</label>
              </label>
              <CustomInput
                fullWidth
                multiline
                inputProps={{
                  maxLength: 300,
                }}
                onChange={(e: any) => setNote(e.target.value)}
                value={note}
                disabled={loading || loadingUpdateCMStatus}
              />
              <div className="contador">
                <label className="textoContador"></label>
                <label className="textoContador">{note.length}/300</label>
              </div>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ textAlign: "center", margin: "5px 0 5px 0" }}>
          <Grid container direction="row" justifyContent={"space-between"}>
            <SecondaryButton
              sx={{ marginRight: "24px", p: "15px 42px" }}
              disabled={loading || loadingUpdateCMStatus}
              onClick={onClickClose}
            >
              Cancelar
            </SecondaryButton>
            <BotonCarga
              testid="btnUpdateCMStatus"
              toDo={onClickConfirm}
              disabled={showReasons && (reasonSelected === null && nextStatus !== NuevosEstadosCuentaMedica.Anulada)}
              loading={loading || loadingUpdateCMStatus}
              type="secondary"
              label="Confirmar"
              sx={{ whiteSpace: "pre" }}
            />
          </Grid>
        </DialogActions>
      </CustomModal >
    </>
  );
}
