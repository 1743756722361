import {
  AlertColor,
  Box,
  DialogActions,
  DialogContent,
  Grid,
  Typography,
} from "@mui/material";
import {
  CustomModal,
  CustomModalTitle,
} from "../../../../components/Modal/customModal";
import {
  modalText,
  modalTextBold,
  modalTitle,
} from "../../../../assets/styles/Text/text";
import { SecondaryButton } from "../../../../components/Buttons/Button";
import { useMutation } from "@apollo/client";
import { deshabilitaPrestacion } from "../../../../services/administracionCM/administracionCMServices";
import { checkStatus } from "../../utils/checkStatus";
import BotonCarga from "../../../../components/Buttons/LoadingButton";

type ModalEliminarPrestacionesProps = {
  isOpen: boolean;
  onClose: () => void;
  loadingWidth?: string;
  detailsIds: number[];
  muestraMensaje: (mensaje: string, severidad: AlertColor) => void;
  refetch: Function;
};

export const ModalEliminarPrestaciones = ({
  isOpen,
  onClose,
  detailsIds,
  muestraMensaje,
  refetch
}: ModalEliminarPrestacionesProps) => {

  const [eliminarPrest, { loading: isLoading }] = useMutation(deshabilitaPrestacion, {
    variables: {
      idPrestacion: detailsIds
    },
    onCompleted: () => {
      muestraMensaje("Prestación(es) eliminada(s) con éxito.", 'success');
      refetch();
      onClose();
    },
    onError: (err: any) => {
      console.log(err.message)
      muestraMensaje("Ocurrió un error al eliminar la(s) prestación(es). Por favor, inténtalo de nuevo.", 'error');
      checkStatus(err.graphQLErrors[0]?.code || "");
    }
  });

  return (
    <CustomModal
      fullWidth
      maxWidth="sm"
      open={isOpen}
      sx={{
        "& .MuiBackdrop-root": {
          backgroundColor: "rgba(0, 0, 0, 0.3)",
        },
      }}
    >
      <CustomModalTitle>
        <Typography sx={modalTitle} paddingTop="16px">
          Eliminar prestaciones
        </Typography>
      </CustomModalTitle>

      <DialogContent
        dividers
        sx={{ textAlign: "center", pl: "24px", pr: "24px" }}
      >
        <Box pt="12px" pb="12px">
          <Typography sx={modalText}>¿Estas seguro de </Typography>{" "}
          <Typography sx={modalTextBold}>eliminar {detailsIds.length}</Typography>{" "}
          <Typography sx={modalText}>prestaciones?</Typography>
        </Box>
      </DialogContent>

      <DialogActions sx={{ textAlign: "center", margin: "5px 0 5px 0" }}>
        <Grid container direction="row" justifyContent={"space-between"}>
          <SecondaryButton sx={{ marginRight: "24px", p: "15px" }} onClick={onClose} disabled={isLoading}>
            Cancelar
          </SecondaryButton>
          <BotonCarga
            testid="btnEliminar"
            toDo={eliminarPrest}
            loading={isLoading}
            label={"Eliminar"}
            sx={{ p: "15px" }}
          />
        </Grid>
      </DialogActions>
    </CustomModal>
  );
};
