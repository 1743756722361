import { createSvgIcon } from "@mui/material/utils";

const DownloadWhite = createSvgIcon(
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M15.7057 9.14652L10.3099 14.4465C10.1385 14.6144 9.86146 14.6144 9.6901 14.4465L4.29427 9.14652C4.12292 8.97866 4.12292 8.70723 4.29427 8.53938L5.00885 7.83938C5.18385 7.66795 5.46458 7.67152 5.63229 7.84652L9.04844 11.3144V2.42866C9.04844 2.19295 9.24531 2.00009 9.48594 2.00009H10.5068C10.7474 2.00009 10.9443 2.19295 10.9443 2.42866V11.3144L14.3604 7.84652C14.5318 7.67509 14.8125 7.67152 14.9839 7.83938L15.6984 8.53938C15.8771 8.70723 15.8771 8.97866 15.7057 9.14652V9.14652ZM16.5625 16.1429H3.4375C3.19687 16.1429 3 16.3358 3 16.5715V17.5715C3 17.8072 3.19687 18.0001 3.4375 18.0001H16.5625C16.8031 18.0001 17 17.8072 17 17.5715V16.5715C17 16.3358 16.8031 16.1429 16.5625 16.1429Z" fill="white" />
    </svg>
    , ''
);

const DownloadGreen = createSvgIcon(
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M13.2687 7.14643L7.87288 12.4464C7.70153 12.6143 7.42445 12.6143 7.25309 12.4464L1.85726 7.14643C1.6859 6.97857 1.6859 6.70714 1.85726 6.53929L2.57184 5.83929C2.74684 5.66786 3.02757 5.67143 3.19528 5.84643L6.61143 9.31429V0.428571C6.61143 0.192857 6.8083 0 7.04893 0H8.06976C8.31038 0 8.50726 0.192857 8.50726 0.428571V9.31429L11.9234 5.84643C12.0948 5.675 12.3755 5.67143 12.5468 5.83929L13.2614 6.53929C13.4401 6.70714 13.4401 6.97857 13.2687 7.14643ZM14.1255 14.1429H1.00049C0.759863 14.1429 0.562988 14.3357 0.562988 14.5714V15.5714C0.562988 15.8071 0.759863 16 1.00049 16H14.1255C14.3661 16 14.563 15.8071 14.563 15.5714V14.5714C14.563 14.3357 14.3661 14.1429 14.1255 14.1429Z" fill="#01736C" />
    </svg>

    , ''
);

const DownloadGray = createSvgIcon(
    <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12.7057 7.14643L7.3099 12.4464C7.13854 12.6143 6.86146 12.6143 6.6901 12.4464L1.29427 7.14643C1.12292 6.97857 1.12292 6.70714 1.29427 6.53929L2.00885 5.83929C2.18385 5.66786 2.46458 5.67143 2.63229 5.84643L6.04844 9.31429V0.428571C6.04844 0.192857 6.24531 0 6.48594 0H7.50677C7.7474 0 7.94427 0.192857 7.94427 0.428571V9.31429L11.3604 5.84643C11.5318 5.675 11.8125 5.67143 11.9839 5.83929L12.6984 6.53929C12.8771 6.70714 12.8771 6.97857 12.7057 7.14643ZM13.5625 14.1429H0.4375C0.196875 14.1429 0 14.3357 0 14.5714V15.5714C0 15.8071 0.196875 16 0.4375 16H13.5625C13.8031 16 14 15.8071 14 15.5714V14.5714C14 14.3357 13.8031 14.1429 13.5625 14.1429Z" fill="#79858E" />
    </svg>

    , ''
);
const IconCheckSnack = createSvgIcon(
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
    >
        <circle cx="12" cy="12" r="10" fill="white" />
        <path
            d="M12 2C6.47714 2 2 6.47714 2 12C2 17.5229 6.47714 22 12 22C17.5229 22 22 17.5229 22 12C22 6.47714 17.5229 2 12 2ZM12 20.7097C7.21516 20.7097 3.29032 16.8365 3.29032 12C3.29032 7.2152 7.16351 3.29032 12 3.29032C16.7848 3.29032 20.7097 7.16351 20.7097 12C20.7097 16.7848 16.8365 20.7097 12 20.7097ZM17.7109 9.62254L10.4335 16.8416C10.2437 17.0298 9.93738 17.0285 9.74919 16.8388L6.28637 13.348C6.09819 13.1583 6.0994 12.8519 6.28911 12.6637L6.63266 12.323C6.82238 12.1348 7.12875 12.136 7.31694 12.3257L10.0982 15.1295L16.6886 8.59198C16.8783 8.40379 17.1847 8.405 17.3729 8.59472L17.7136 8.93827C17.9019 9.12798 17.9006 9.43435 17.7109 9.62254V9.62254Z"
            fill="#33691E"
        />
    </svg>,
    "Success"
);
const IconWarningSnack = createSvgIcon(
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
    >
        <circle cx="12" cy="12" r="10" fill="white" />
        <path
            d="M12 3.29032C16.7831 3.29032 20.7097 7.16431 20.7097 12C20.7097 16.8101 16.8141 20.7097 12 20.7097C7.19177 20.7097 3.29032 16.816 3.29032 12C3.29032 7.19343 7.18556 3.29032 12 3.29032ZM12 2C6.47754 2 2 6.47915 2 12C2 17.5241 6.47754 22 12 22C17.5225 22 22 17.5241 22 12C22 6.47915 17.5225 2 12 2ZM11.5367 6.83871H12.4633C12.7384 6.83871 12.9582 7.06782 12.9467 7.34274L12.6645 14.1169C12.6537 14.3761 12.4404 14.5806 12.181 14.5806H11.819C11.5596 14.5806 11.3463 14.3761 11.3355 14.1169L11.0532 7.34274C11.0418 7.06782 11.2616 6.83871 11.5367 6.83871ZM12 15.3871C11.3765 15.3871 10.871 15.8926 10.871 16.5161C10.871 17.1397 11.3765 17.6452 12 17.6452C12.6235 17.6452 13.129 17.1397 13.129 16.5161C13.129 15.8926 12.6235 15.3871 12 15.3871Z"
            fill="#FF8F00"
        />
    </svg>,
    "Warning"
);

const IconErrorSnack = createSvgIcon(
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
    >
        <circle cx="10" cy="10" r="8" fill="white" />
        <path
            d="M10 0C4.47581 0 0 4.47581 0 10C0 15.5242 4.47581 20 10 20C15.5242 20 20 15.5242 20 10C20 4.47581 15.5242 0 10 0ZM10 18.7097C5.21371 18.7097 1.29032 14.8347 1.29032 10C1.29032 5.21371 5.16532 1.29032 10 1.29032C14.7863 1.29032 18.7097 5.16532 18.7097 10C18.7097 14.7863 14.8347 18.7097 10 18.7097ZM13.8226 7.20564L11.0282 10L13.8226 12.7944C14.0121 12.9839 14.0121 13.2903 13.8226 13.4798L13.4798 13.8226C13.2903 14.0121 12.9839 14.0121 12.7944 13.8226L10 11.0282L7.20564 13.8226C7.01613 14.0121 6.70968 14.0121 6.52016 13.8226L6.17742 13.4798C5.9879 13.2903 5.9879 12.9839 6.17742 12.7944L8.97177 10L6.17742 7.20564C5.9879 7.01613 5.9879 6.70968 6.17742 6.52016L6.52016 6.17742C6.70968 5.9879 7.01613 5.9879 7.20564 6.17742L10 8.97177L12.7944 6.17742C12.9839 5.9879 13.2903 5.9879 13.4798 6.17742L13.8226 6.52016C14.0081 6.70968 14.0081 7.01613 13.8226 7.20564Z"
            fill="#B71C1C"
        />
    </svg>,
    "Error"
);
const IconInfoSnack = createSvgIcon(
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
    >
        <circle cx="10" cy="10" r="8" fill="white" />
        <path
            d="M10 1.29032C14.7831 1.29032 18.7097 5.16431 18.7097 10C18.7097 14.8101 14.8141 18.7097 10 18.7097C5.19177 18.7097 1.29032 14.816 1.29032 10C1.29032 5.19343 5.18556 1.29032 10 1.29032ZM10 0C4.47754 0 0 4.47915 0 10C0 15.5241 4.47754 20 10 20C15.5225 20 20 15.5241 20 10C20 4.47915 15.5225 0 10 0ZM8.54839 13.871H9.03226V9.03226H8.54839C8.28117 9.03226 8.06452 8.8156 8.06452 8.54839V8.22581C8.06452 7.95859 8.28117 7.74194 8.54839 7.74194H10.4839C10.7511 7.74194 10.9677 7.95859 10.9677 8.22581V13.871H11.4516C11.7188 13.871 11.9355 14.0876 11.9355 14.3548V14.6774C11.9355 14.9446 11.7188 15.1613 11.4516 15.1613H8.54839C8.28117 15.1613 8.06452 14.9446 8.06452 14.6774V14.3548C8.06452 14.0876 8.28117 13.871 8.54839 13.871ZM10 4.19355C9.28738 4.19355 8.70968 4.77125 8.70968 5.48387C8.70968 6.19649 9.28738 6.77419 10 6.77419C10.7126 6.77419 11.2903 6.19649 11.2903 5.48387C11.2903 4.77125 10.7126 4.19355 10 4.19355Z"
            fill="#392F95"
        />
    </svg>,
    "Info"
);
const HospitalAlt = createSvgIcon(
    <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M4 7.625V6.375C4 6.16791 4.16791 6 4.375 6H5.625C5.83209 6 6 6.16791 6 6.375V7.625C6 7.83209 5.83209 8 5.625 8H4.375C4.16791 8 4 7.83209 4 7.625ZM8.375 8H9.625C9.83209 8 10 7.83209 10 7.625V6.375C10 6.16791 9.83209 6 9.625 6H8.375C8.16791 6 8 6.16791 8 6.375V7.625C8 7.83209 8.16791 8 8.375 8ZM6 10.625V9.375C6 9.16791 5.83209 9 5.625 9H4.375C4.16791 9 4 9.16791 4 9.375V10.625C4 10.8321 4.16791 11 4.375 11H5.625C5.83209 11 6 10.8321 6 10.625ZM8.375 11H9.625C9.83209 11 10 10.8321 10 10.625V9.375C10 9.16791 9.83209 9 9.625 9H8.375C8.16791 9 8 9.16791 8 9.375V10.625C8 10.8321 8.16791 11 8.375 11ZM14 14.875V16H0V14.875C0 14.6679 0.167906 14.5 0.375 14.5H0.984375V2.65734C0.984375 2.29431 1.32016 2 1.73438 2H4.5V0.75C4.5 0.335781 4.83578 0 5.25 0H8.75C9.16422 0 9.5 0.335781 9.5 0.75V2H12.2656C12.6798 2 13.0156 2.29431 13.0156 2.65734V14.5H13.625C13.8321 14.5 14 14.6679 14 14.875ZM2.48438 14.4688H6V12.375C6 12.1679 6.16791 12 6.375 12H7.625C7.83209 12 8 12.1679 8 12.375V14.4688H11.5156V3.5H9.5V4.25C9.5 4.66422 9.16422 5 8.75 5H5.25C4.83578 5 4.5 4.66422 4.5 4.25V3.5H2.48438V14.4688ZM8.3125 2H7.5V1.1875C7.5 1.13777 7.48025 1.09008 7.44508 1.05492C7.40992 1.01975 7.36223 1 7.3125 1H6.6875C6.63777 1 6.59008 1.01975 6.55492 1.05492C6.51975 1.09008 6.5 1.13777 6.5 1.1875V2H5.6875C5.63777 2 5.59008 2.01975 5.55492 2.05492C5.51975 2.09008 5.5 2.13777 5.5 2.1875V2.8125C5.5 2.86223 5.51975 2.90992 5.55492 2.94508C5.59008 2.98025 5.63777 3 5.6875 3H6.5V3.8125C6.5 3.86223 6.51975 3.90992 6.55492 3.94508C6.59008 3.98025 6.63777 4 6.6875 4H7.3125C7.36223 4 7.40992 3.98025 7.44508 3.94508C7.48025 3.90992 7.5 3.86223 7.5 3.8125V3H8.3125C8.36223 3 8.40992 2.98025 8.44508 2.94508C8.48025 2.90992 8.5 2.86223 8.5 2.8125V2.1875C8.5 2.13777 8.48025 2.09008 8.44508 2.05492C8.40992 2.01975 8.36223 2 8.3125 2Z" fill="#67737C" />
    </svg>,
    "Close"
  );
export {
    HospitalAlt,
    DownloadWhite,
    DownloadGreen,
    DownloadGray,
    IconCheckSnack,
    IconErrorSnack,
    IconInfoSnack,
    IconWarningSnack
}