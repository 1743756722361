import { useState } from "react";
import { Typography, Box, Grid, AlertColor } from "@mui/material";
import { boxCard, boxCardNormal } from "../../assets/styles/Box/boxs";
import { greenBigTitle, lineSubText } from "../../assets/styles/Text/text";
import { SecondaryButton } from "../../components/Buttons/Button";
import FilterIcon from "../../assets/icons/filtreIcon.svg";
import DisableFilter from "../../assets/icons/disableFiltre.svg";
import DocIcon from "../../assets/icons/ofcinaIcon.svg";
import { TableConsultaCtas } from "./components/tableConsultaCtas";
import { ChipConsultaCtas } from "./components/ChipsConsultaCtas";
import { ModalFiltrosConsulta } from "./components/modalFiltrosConsulta";
import { LoadHeadConsulta, LoadTableConsulta } from "./components/skeletonsConsulta";
import { useQuery } from "@apollo/client";
import { CuentasMedicasQuery } from "../../services/cm-services/escritorio-cm/escritorio-services";
import { format } from "date-fns";
import { ExportBtn } from "./components/exportBtn";
import SnackBar, { stateProps } from "../../components/Snackbar/Snackbar";

export const ConsultaCuenta = () => {
    const [cuentas, setCuentas] = useState([]);
    const [contCuentas, setContCuentas] = useState<number>(0);
    const [isFirstLoad, setFirstLoad] = useState<boolean>(true);
    const [modalFiltros, setModalFiltros] = useState<boolean>(false);
    const [filtros, setFiltros] = useState({});
    const [dataConsultaFiltro, setDataConsultaFiltro] = useState({});
    const [pagConsulta, setPagConsulta] = useState(0);
    const [lineasConsulta, setLineasConsulta] = useState(25);
    const [ordenConsulta, setOrdenConsulta] = useState("id");
    const [dirOrdenConsulta, setDirOrdenConsulta] = useState("DESC");
    const [orderData, setOrderData] = useState<any>({id: { position: 1, direction: "DESC"}});
    const [snackBar, setSnackBar] = useState<stateProps>({
        abrir: false,
        mensaje: "",
        severidad: "success"
    });

    const { loading, error } = useQuery(CuentasMedicasQuery, {
        variables: { offset: pagConsulta * lineasConsulta, limit: lineasConsulta, filterBy: filtros, orderBy: orderData, buscadorCuentasFlag: true },
        onCompleted: (data) => {
            setFirstLoad(false);
            setCuentas(data.obtenerCuentasMedicas.cuentasMedicas);
            setContCuentas(data.obtenerCuentasMedicas.count);
        },
        onError: (err) => {
            console.log(err)
            setFirstLoad(false);
            setCuentas([]);
            setContCuentas(0);
        }
    });

    const handleRequestSort = (event: any, property: string) => {
        const isDESC = dirOrdenConsulta === 'DESC';
        setDirOrdenConsulta((prevState: string) => prevState === "DESC" ? 'ASC' : 'DESC');  
        setOrdenConsulta(property);
        setPagConsulta(0);
        const orderBy= {[property] : { position: 1, direction: isDESC ? "ASC" : "DESC"}}
        setOrderData(orderBy);
      }
      
    const showMessage = (mensaje: string, severidad: AlertColor) => {
        setSnackBar({
            abrir: true,
            mensaje: mensaje,
            severidad: severidad
        })
    }

    const actualizaFiltros = (filterby: any) => {
        let parseFiltros = {};
        if (filterby.id) {
            parseFiltros = { ...parseFiltros, id: parseInt(filterby.id) };
        }
        if (filterby.idCMD) {
            parseFiltros = { ...parseFiltros, idCMD: parseInt(filterby.idCMD) };
        }
        if (filterby.numeroCuentaPrestador) {
            parseFiltros = { ...parseFiltros, numeroCuentaPrestador: filterby.numeroCuentaPrestador };
        }
        if (filterby.rutPaciente) {
            parseFiltros = { ...parseFiltros, rutPaciente: filterby.rutPaciente.replace(/[^0-9kK-]/g, "").trim() };
        }
        if (filterby.rutPrestador) {
            parseFiltros = { ...parseFiltros, rutPrestador: filterby.rutPrestador.replace(/[^0-9kK-]/g, "").trim() };
        }
        if (filterby.causal) {
            parseFiltros = { ...parseFiltros, causal: filterby.causal.id };
        }
        if (filterby.estadoEmision) {
            parseFiltros = { ...parseFiltros, estado: filterby.estadoEmision };
        }
        if (filterby.fechaRecepcion) {
            parseFiltros = { ...parseFiltros, fechaRecepcion: format(filterby.fechaRecepcion, "yyyy-MM-dd") };
        }
        if (filterby.fechaAsignacion) {
            parseFiltros = { ...parseFiltros, fechaAsignacionUsuario: format(filterby.fechaAsignacion, "yyyy-MM-dd") };
        }
        if (filterby.fechaCompromiso) {
            parseFiltros = { ...parseFiltros, fechaProbableLiquidacion: format(filterby.fechaCompromiso, "yyyy-MM-dd") };
        }
        if (filterby.diasRestantes) {
            parseFiltros = { ...parseFiltros, diasRestantes: parseInt(filterby.diasRestantes) };
        }
        if (filterby.montoTotalCobro) {
            parseFiltros = { ...parseFiltros, montoTotalCobro: parseInt(filterby.montoTotalCobro) };
        }
        if (filterby.canalOrigen) {
            parseFiltros = { ...parseFiltros, canalOrigen: filterby.canalOrigen };
        }
        if (filterby.idUsuario) {
            parseFiltros = { ...parseFiltros, idUsuario: filterby.idUsuario };
        }
        setPagConsulta(0);
        setFiltros(parseFiltros);
        setDataConsultaFiltro(filterby);
    }

    return (
        <Box sx={boxCardNormal}>
            <Typography pt={7} pb={4} variant="h2">
                Consulta de cuentas
            </Typography>
            <ModalFiltrosConsulta
                modal={modalFiltros}
                close={() => setModalFiltros(false)}
                dataConsultaFiltro={dataConsultaFiltro}
                actualizaFiltros={actualizaFiltros}
            />
            <SnackBar state={snackBar} setState={setSnackBar} />
            <Box sx={boxCard}>
                {isFirstLoad ? <LoadHeadConsulta /> :
                    <Grid container p="24px 15px">
                        <Grid item xs={1} >
                            <img src={DocIcon} alt="" />
                        </Grid>
                        <Grid item xs={6} pl="24px" alignSelf={"center"}>
                            <Typography sx={greenBigTitle}>Tabla de consulta</Typography>
                            <Typography sx={lineSubText}>
                                Aquí podrás buscar información de cuentas y usar filtros para resultados más específicos.
                            </Typography>
                        </Grid>
                        <Grid item xs={5} alignSelf={"end"} textAlign={"end"} >
                            <SecondaryButton
                                startIcon={
                                    loading ? (
                                      <img src={DisableFilter} alt="" />
                                    ) : (
                                      <img src={FilterIcon} alt="" />
                                    )
                                  }
                                sx={{ p: "15px !important", mr: "16px" }}
                                disabled={loading}
                                onClick={() => setModalFiltros(true)}
                            >
                                Filtro
                            </SecondaryButton>
                            <ExportBtn
                                disabled={loading}
                                filtros={filtros}
                                showMessage={showMessage}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <ChipConsultaCtas filtros={dataConsultaFiltro} actualizaFiltros={actualizaFiltros} />
                        </Grid>
                    </Grid>
                }
                {loading ?
                    <LoadTableConsulta /> :
                    <TableConsultaCtas
                        cuentas={cuentas}
                        contador={contCuentas}
                        filtros={filtros}
                        error={error}
                        pagConsulta={pagConsulta}
                        setPagConsulta={setPagConsulta}
                        lineasConsulta={lineasConsulta}
                        setLineasConsulta={setLineasConsulta}
                        handleRequestSort={handleRequestSort}
                        ordenConsulta={ordenConsulta}
                        dirOrdenConsulta={dirOrdenConsulta}

                    />
                }
            </Box>
        </Box>
    );
};

export default ConsultaCuenta;
