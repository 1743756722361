import { useState } from "react";
import { Box, CircularProgress, Grid, IconButton, Typography } from "@mui/material";
import { SlimInput } from "../../../../assets/styles/Input/input";
import { boxCard, formHeader } from "../../../../assets/styles/Box/boxs";
import { getDataPrestadorByRut } from "../../../../services/administracionCM/administracionCMServices";
import { useLazyQuery } from "@apollo/client";
import { formTextHeader, inputForm, modalTextBold, warningGray, warningPurple } from "../../../../assets/styles/Text/text";
import IconEdif from "../../../../assets/icons/iconEdif.svg";
import { validRut, formatRut } from "chilean-rutify";
import { WarningModalCustom } from "../../../../components/Modal/warningModalCustom";
import Aviso from "../../../../components/Alert/Aviso";

const WarningModalContent = ({ dataCM }: any) => {
  return (
    <>
      <br></br>
      <Typography sx={warningGray}>
        Al ingresar un Nº de cuenta
      </Typography>{" "}
      <Typography sx={modalTextBold} >
        duplicado,
      </Typography>{" "}
      <Typography sx={warningGray}>
        el Nº de cobro
      </Typography>{" "}<br></br>
      <Typography sx={warningPurple} pb="16px">
        {dataCM.datosPrestador.numeroCobro || ""}
      </Typography>{" "}
      <Typography sx={modalTextBold} >
        no quedará disponible
      </Typography>{" "}
      <Typography sx={warningGray}>
        en el ET y se
      </Typography>{" "}
      <Typography sx={modalTextBold} >
        reemplazará
      </Typography><br></br>
      <Typography sx={warningGray}>
        por la nueva cuenta con un nuevo Nº de envío
      </Typography>{" "}
    </>
  );
};

const WarningModalContentError = () => {
  return (
    <>
      <br></br>
      <Typography sx={warningGray} data-testid="dataError">
        No es posible registrar esta cuenta, ya que el Nº de cobro y
        <br></br>cuenta prestador se encuentra en proceso de liquidación o emisión.
      </Typography>
    </>
  );
};

const DataPrestador = ({
  dataCM,
  setDataCM,
  readOnly,
  setLockDupe,
  setDupeCheck,
  findDupes,
  isError,
  isDupe,
  setIsDupe,
  openWarning,
  setOpenWarning
}: any) => {
  const [error, setError] = useState<any>({});
  const [lockRZ, setLockRZ] = useState<boolean>(true);
  const [fnGetDataPrestadorByRut, { loading }] = useLazyQuery(getDataPrestadorByRut);

  const onChange = (e: { target: { id: any; value: any; }; }) => {
    const id: string = e.target.id;
    let value = e.target.value;
    let editaPres = {}
    if (id === "id" || id === "idCMD") {
      value = value.replace(/[^0-9]/g, '');
    }
    if (id === "rutMedicoTratante" || id === "rutPrestador") {
      let rut = e.target.value.replace(/[^0-9kK-]/g, "").trim();
      value = rut.length > 2 ? formatRut(rut)!.toUpperCase() : rut;
      if (id === "rutPrestador") {
        editaPres = { razonSocial: null };
        setLockRZ(true);
      }
      if (value && !validRut(value)) {
        setError({ ...error, [id]: "Rut inválido" });
      } else {
        let err = error;
        delete err[id];
        setError(err);
        if (id === "rutPrestador" && validRut(rut)) {
          handleRutPrestador(value);
        }
      }
    }
    if (!value) {
      value = undefined
    }
    if (id === "razonSocial") {
      setError({ ...error, rutPrestador: null });
    }
    if ((id === "numeroCuenta" || id === "numeroCobro")) {
      setIsDupe(false);
      setLockDupe(false);
      setDupeCheck(false);
      if (id === "numeroCuenta" && value && value.length > 0 && dataCM.datosPrestador.numeroCobro && dataCM.datosPrestador.numeroCobro.length > 0 && dataCM.datosBeneficiario.rut && validRut(dataCM.datosBeneficiario.rut)) {
        findDupes({
          variables: {
            numeroCobro: dataCM.datosPrestador.numeroCobro,
            numeroCuenta: value,
            rutPaciente: dataCM.datosBeneficiario.rut
          }
        })
      }
      if (id === "numeroCobro" && value && value.length > 0 && dataCM.datosPrestador.numeroCuenta && dataCM.datosPrestador.numeroCuenta.length > 0 && dataCM.datosBeneficiario.rut && validRut(dataCM.datosBeneficiario.rut)) {
        findDupes({
          variables: {
            numeroCobro: value,
            numeroCuenta: dataCM.datosPrestador.numeroCuenta,
            rutPaciente: dataCM.datosBeneficiario.rut
          }
        })
      }
    }
    setDataCM((prevState: any) => ({
      ...prevState, datosPrestador: { ...prevState.datosPrestador, [id]: value, ...editaPres },
    }));
  }

  const handleRutPrestador = (rut: String) => {
    let parsedRut = rut.replace(/[^0-9kK-]/g, "").trim();
    fnGetDataPrestadorByRut({
      variables: { rut: parsedRut },
      onCompleted: (res) => {
        if (res.datosPrestador) {
          const { razonSocial, tieneConvenio } = res.datosPrestador;

          if (!tieneConvenio) {
            setError({
              ...error,
              rutPrestador: "Prestador Sin Convenio"
            });
            setLockRZ(true);
          } else {
            setDataCM((prevState: any) => ({
              ...prevState,
              datosPrestador: {
                ...prevState.datosPrestador,
                rutPrestador: rut,
                razonSocial: razonSocial,
              },
            }));
            setLockRZ(true);
          }
        } else {
          setError({
            ...error,
            rutPrestador: "Prestador no registrado"
          });
          if (dataCM.canalOrigen === "EMAIL") {
            setLockRZ(false);
          }
        }
      },
      onError: (err) => {
        setError({
          ...error,
          rutPrestador: "Error al obtener datos Prestador"
        });
        setLockRZ(true);
      }
    })

  };

  return (
    <Box sx={boxCard} width={"89.5%"} ml={"4.5%"} mb={"24px"} >
      <WarningModalCustom
        errorModal
        noSecondAction
        primaryButton="Aceptar"
        contentText={isError ? <WarningModalContentError /> : <WarningModalContent dataCM={dataCM} />}
        title="Cuenta duplicada"
        headWarning={isError ? true : false}
        onOpen={openWarning}
        onConfirm={() => setOpenWarning(false)}
        onClose={() => setOpenWarning(false)}
        loadingWidth={"120px"}
      />
      <Box sx={formHeader}>
        <Typography sx={formTextHeader}>
          <img src={IconEdif} alt="" />{' '} Datos Del Prestador
        </Typography>
      </Box>
      <Grid mb="32px" container p="24px">
        <Grid item xs={3} pr="24px">
          <Typography sx={inputForm}  >
            RUT
          </Typography>
          <Typography display={'inline'} color={'#E53935 !important'}>
            *
          </Typography>
          <SlimInput
            fullWidth
            placeholder="Ej: 99.999.999-K"
            data-testid="rutPrestador"
            onChange={onChange}
            id={"rutPrestador"}
            value={formatRut(dataCM?.datosPrestador.rutPrestador || "")}
            error={error.rutPrestador}
            helperText={error.rutPrestador || ""}
            disabled={readOnly || loading}
            InputProps={{
              endAdornment: (
                <IconButton>
                  {loading ?
                    <CircularProgress data-testid="cargandoDoc" size={20} />
                    : null
                  }
                </IconButton>
              )
            }}
          />
        </Grid>
        <Grid item xs={3} pr="24px">
          <Typography sx={inputForm}  >
            Razón social
          </Typography>
          <SlimInput
            fullWidth
            placeholder="Ej: Clínica alemana"
            value={dataCM?.datosPrestador.razonSocial ? dataCM?.datosPrestador.razonSocial : ""}
            disabled={lockRZ}
            onChange={onChange}
            id={"razonSocial"}
          />
        </Grid>
        <Grid item xs={3} pr="24px">
          <Typography sx={inputForm}  >
            RUT médico tratante
          </Typography>
          <SlimInput
            fullWidth
            placeholder="Ej: 22.222.222-2"
            onChange={onChange}
            id={"rutMedicoTratante"}
            value={formatRut(dataCM?.datosPrestador.rutMedicoTratante || "")}
            error={error.rutMedicoTratante}
            helperText={error.rutMedicoTratante || ""}
            disabled={readOnly}
          />
        </Grid>
        <Grid item xs={3} >
          <Typography sx={inputForm}  >
            Nombre médico tratante
          </Typography>
          <SlimInput
            fullWidth
            placeholder="Ej: Luis Andrés Cortés Andrade"
            id={"nombreMedicoTratante"}
            value={dataCM?.datosPrestador.nombreMedicoTratante || ""}
            onChange={onChange}
            disabled={readOnly}
          />
        </Grid>
        <Grid item xs={3} pt="24px" pr="24px" pb="24px">
          <Typography sx={inputForm}  >
            Canal recepción
          </Typography>
          <SlimInput
            fullWidth
            value={dataCM?.canalOrigen}
            disabled
          />
        </Grid>
        <Grid item xs={3} pt="24px" pr="24px">
          <Typography sx={inputForm}  >
            Nº de Cuenta prestador
          </Typography>
          <Typography display={'inline'} color={'#E53935 !important'}>
            *
          </Typography>
          <SlimInput
            fullWidth
            placeholder="Ej: SM15002"
            id={"numeroCuenta"}
            value={dataCM?.datosPrestador.numeroCuenta}
            onChange={onChange}
            disabled={dataCM?.canalOrigen === "CMD" || readOnly || loading}
          />
        </Grid>
        <Grid item xs={3} pt="24px" pr="24px">
          <Typography sx={inputForm}  >
            Nº de Cobro prestador
          </Typography>
          <Typography display={'inline'} color={'#E53935 !important'}>
            *
          </Typography>
          <SlimInput
            fullWidth
            type={"number"}
            placeholder="Ej: 15002"
            id={"numeroCobro"}
            value={dataCM?.datosPrestador.numeroCobro}
            onChange={onChange}
            disabled={dataCM?.canalOrigen === "CMD" || readOnly || loading}
          />
        </Grid>
        <Grid item xs={3} pt="24px">
          <Typography sx={inputForm}  >
            Nº de envío
          </Typography>
          <Typography display={'inline'} color={'#E53935 !important'}>
            *
          </Typography>
          <SlimInput
            fullWidth
            type={"number"}
            placeholder="Ej: 15002"
            name={"numeroEnvio"}
            value={dataCM?.datosPrestador.numeroEnvio}
            disabled
          />
        </Grid>
        <Aviso
          open={isDupe}
          noClose
          severidad={isError ? 'error' : 'info'}
        >
          {isError ?
            "El Nº de cuenta y el Nº cobro ya existen en otra cuenta con estado liquidada o emitida."
            : "La nueva cuenta volverá obsoleta a la cuenta en proceso."
          }
        </Aviso>
      </Grid>
    </Box>
  );
}

export default DataPrestador;
