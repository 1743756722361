import { DialogActions, DialogContent, Grid, Typography } from "@mui/material";
import { CustomModal, CustomModalTitle } from "../../../../components/Modal/customModal";
import { boldTitle, lineText, purpleLine } from "../../../../assets/styles/Text/text";
import { SecondaryButton } from "../../../../assets/styles/Button/Button";
import { useMutation } from "@apollo/client";
import { mutationReasignarUsuario } from "../../../../services/escritorio/escritorioServices";
import { useState } from "react";
import BotonCarga from "../../../../components/Buttons/LoadingButton";
import { SelectCausalUsers } from "./SelectCausalUsers";
import { SelectFilteredUsers } from "./SelectUsuarios";

interface Props {
  open: boolean;
  handleOnClose: () => void;
  datosCuenta: any;
  setSnackData: Function
  refetch: Function
}

export const ModalReasignar = ({ open, handleOnClose, datosCuenta, setSnackData, refetch }: Props) => {
  const [dataReasignar, setDataReasignar] = useState({
    usuario: null,
    idCuentaMedica: datosCuenta.id,
    causal: null
  })

  const [reasignarUsuario, { loading }] = useMutation(mutationReasignarUsuario);

  const handleReasignar = () => {
    reasignarUsuario({
      variables: {
        input: dataReasignar
      },
      onCompleted: (data) => {
        if (data.reasignarUsuario) {
          setSnackData("success", "La cuenta ha sido reasignada correctamente");
          setDataReasignar({
            usuario: null,
            idCuentaMedica: datosCuenta.id,
            causal: null
          });
          refetch()
          handleOnClose();
        }
      },
      onError: (error: any) => {
        console.log("error al reasignar esta cuenta", error);
        setSnackData("error", "Ocurrio un error al reasignar la cuenta. Por favor, intenta mas tarde");
        handleOnClose();
      }
    });

  }
  return (
    <>
      <CustomModal
        fullWidth
        maxWidth="sm"
        open={open}
        sx={{
          "& .MuiBackdrop-root": {
            backgroundColor: "rgba(0, 0, 0, 0.3)",
          },
        }}
      >
        <CustomModalTitle>
          <Typography sx={boldTitle}>Reasignar cuenta médica</Typography>
        </CustomModalTitle>
        <DialogContent dividers>
          <div style={{ textAlign: "center", paddingBottom: "16px" }}>
            <div style={{ marginTop: "24px" }}>
              <Typography sx={lineText}>
                Estas reasignando
              </Typography>
              <Typography sx={purpleLine}> CM {datosCuenta.id} - {datosCuenta.idCMD}</Typography>
              <Typography sx={lineText}>  Perfil</Typography>
              <Typography sx={purpleLine}> {datosCuenta.usuarioAsignado}</Typography>
              <br></br>
              <Grid container textAlign={"left"} pt="24px">
                <Grid item xs={12}>
                  <Typography variant="h6" pb="6px">
                    Usuario
                  </Typography>
                  <SelectFilteredUsers
                    datosCuenta={datosCuenta}
                    value={dataReasignar.usuario || ""}
                    onChange={(user: any) =>
                      setDataReasignar({
                        ...dataReasignar,
                        usuario: user.target.value
                      })}
                    disabled={loading}
                  />
                </Grid>
                <Grid item xs={12} pt="20px">
                  <Typography variant="h6" pb="6px">
                    Causal
                  </Typography>
                  <SelectCausalUsers
                    value={dataReasignar.causal || ""}
                    onChange={(reason: any) =>
                      setDataReasignar({
                        ...dataReasignar,
                        causal: reason.target.value
                      })
                    }
                    disabled={loading}
                  />
                </Grid>
              </Grid>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Grid container direction="row" justifyContent="space-between">
            <SecondaryButton
              color="primary"
              sx={{ p: "15px 41px" }}
              onClick={handleOnClose}
              disabled={loading}
            >
              Cancelar
            </SecondaryButton>
            <BotonCarga
              loading={loading}
              toDo={handleReasignar}
              label="Reasignar"
              testid="btnReasignar"
              disabled={!dataReasignar.causal || !dataReasignar.usuario}
            />
          </Grid>
        </DialogActions>
      </CustomModal>
    </>
  );
};
