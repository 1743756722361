import { Typography, Box, Tab, Tabs, Stack } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import { boxCardNormal } from "../../assets/styles/Box/boxs";
import { TabContext, TabPanel } from "@mui/lab";
import { useState } from "react";
import TabCuentasM from "./components/tabCuentasM";
import { PrimaryButton } from "../../assets/styles/Button/Button";
import { TabsEscritorioValues } from "./tabsEscritorioValues";
import useRecepcionCuentaMedica from "./hook/useRecepcionCuentaMedica";
import { accessREPCM, isRolJ } from "../../routes/verifyAccess";
import { useGetUser } from "../../hooks/getUser";

export default function EscritorioTrabajo() {
  const [tabIndex, setTabIndex] = useState<string>(
    TabsEscritorioValues.totalidad
  );

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setTabIndex(newValue);
  };

  const { handleRecepcionCuentaMedica } = useRecepcionCuentaMedica();
  const user = useGetUser();
  return (
    <>
      <Box>
        <CssBaseline>
          <Box sx={boxCardNormal}>
            <Typography pt={7} pb={4} variant="h2">
              Escritorio de Trabajo
            </Typography>
            <TabContext value={tabIndex}>
              <Stack direction={"row"} justifyContent={"space-between"}>
                <Tabs value={tabIndex} onChange={handleChange}>
                  <Tab
                    value={TabsEscritorioValues.totalidad}
                    label="Todas las cuentas"
                    className="tabGreen"
                  />
                  <Tab
                    value={TabsEscritorioValues.asignadas}
                    label="Cuentas asignadas"
                    className="tabGreen"
                  />
                  {isRolJ(user) ? <Tab
                    value={TabsEscritorioValues.pteAuth}
                    label="Pte. de Autorización"
                    className="tabGreen"
                  /> : null}
                </Tabs>
                <PrimaryButton onClick={handleRecepcionCuentaMedica} disabled={!accessREPCM(user)}>
                  Recepcionar CM
                </PrimaryButton>
              </Stack>

              <TabPanel
                value={TabsEscritorioValues.totalidad}
                sx={{ p: 0, mt: "16px", mb: "16px" }}
              >
                <TabCuentasM />
              </TabPanel>
              <TabPanel
                value={TabsEscritorioValues.asignadas}
                sx={{ p: 0, mt: "16px", mb: "16px" }}
              >
                <TabCuentasM isAssignedTab />
              </TabPanel>
              <TabPanel
                value={TabsEscritorioValues.pteAuth}
                sx={{ p: 0, mt: "16px", mb: "16px" }}
              >
                <TabCuentasM isAuth />
              </TabPanel>
            </TabContext>
          </Box>
        </CssBaseline>
      </Box>
    </>
  );
}
