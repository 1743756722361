export const returnRolByJerarquia = (userRoles: any) => {
  const jerarquia = [
    { nombre: "DIGITADOR", rango: 2 },
    { nombre: "VISADOR", rango: 3 },
    { nombre: "LIQUIDADOR", rango: 4 },
    { nombre: "SUPERVISOR", rango: 5} ,
    { nombre: "JEFE", rango: 6 },
  ]

  const userRolesByJerarquia = jerarquia.filter((rol: any) => userRoles.includes(rol.nombre));

  const userRolesSorted = userRolesByJerarquia.sort((a: any, b: any) => {
    return b.rango - a.rango
  })

  const maxUserRoles = userRolesSorted.filter((item: any, index: number) => index < 3)

  return maxUserRoles.map(item => item.nombre.toLowerCase()).join(", ")

}

export const returnRolesByJerarquiaOrActualRol = (roles: any, rolActual: string) => {
  const jerarquia = [
    { nombre: "DIGITADOR", rango: 2 },
    { nombre: "VISADOR", rango: 3 },
    { nombre: "LIQUIDADOR", rango: 4 },
    { nombre: "SUPERVISOR", rango: 5} ,
    { nombre: "JEFE", rango: 6 },
  ]

  let maxValue = 0;
  let rolMax = "";

  if(roles.length > 0 ){
    roles.forEach((rol: any) => {
      jerarquia.forEach((item: any) => {
        if(item.nombre.toLowerCase() === rol.toLowerCase() && item.rango > maxValue) {
          maxValue = item.rango
          rolMax = item.nombre
        }
      })
    })
    return rolMax
  }else{
    return rolActual
  }
}

export const returnMaxRol = (roles: string[]) => {
  const jerarquiaRoles: any = {
    "DIGITADOR": 2,
    "VISADOR": 3,
    "LIQUIDADOR": 4,
    "SUPERVISOR": 5,
    "JEFE": 6
  };

  let rolMasAlto = null;
  let valorMasAlto = 0;

  roles.forEach((rol) => {
    const valorRol = jerarquiaRoles[rol];
    if (valorRol && valorRol > valorMasAlto) {
      rolMasAlto = rol;
      valorMasAlto = valorRol;
    }
  });

  return rolMasAlto;
}

const switchJefeSupervisorLiquidador = (userRol: string) => {
  switch(userRol.toUpperCase()){
    case "JEFE":
      return true
    case "SUPERVISOR": 
      return true
    case "LIQUIDADOR":
      return true
    case "VISADOR":
      return false
    case "DIGITADOR":
      return false
    default:
      return false
  }
}

const switchJefeSupervisor = (userRol: string) => {
  switch(userRol.toUpperCase()){
    case "JEFE":
      return true
    case "SUPERVISOR": 
      return true
    case "LIQUIDADOR":
      return false
    case "VISADOR":
      return false
    case "DIGITADOR":
      return false
    default:
      return false
  }
}

export const encontrarRolJefeOSupervisor = (rol: string[]) => rol.some((userRol: string) => switchJefeSupervisor(userRol))

export const encontrarRolJefeOSupervisorOLiquidador = (roles: string[]) => roles.some((userRol: string) => switchJefeSupervisorLiquidador(userRol))