import { useState } from "react";
import { AlertColor, Box, Grid, Typography } from "@mui/material";
import DocIcon from "../../../assets/icons/ofcinaIcon.svg";
import useReporteGestion from "../hook/useReporteGestion";
import { boxCardFilter, boxCardNormal } from "../../../assets/styles/Box/boxs";
import { greenBigTitle } from "../../../assets/styles/Text/text";
import BotonCarga from "../../../components/Buttons/LoadingButton";
import SnackBar from "../../../components/Snackbar/Snackbar";

const ReporteGestion = () => {  
  const [snackData, setSnackData] = useState({
    abrir: false,
    mensaje: "",
    severidad: "info" as AlertColor,
  });  

  const sendMessage = (severity: AlertColor, message: string) => {
    setSnackData({
      abrir: true,
      mensaje: message,
      severidad: severity
    })
  }

  const { loading, handleClickReporteGestion } = useReporteGestion(sendMessage);

  return (
    <>
    <SnackBar 
      state={snackData}
      setState={setSnackData}      
    />
      <Box sx={boxCardNormal}>
        <Typography pt={7} pb={4} variant="h2">
          Reportería
        </Typography>
      </Box>

      <Box sx={boxCardFilter}>
        <Grid container>
          <Grid item xs={1} >
            <img src={DocIcon} alt="" />
          </Grid>
          <Grid xs={9} pt="24px">
          <Typography sx={greenBigTitle}>Reportería</Typography>            
            <Typography variant="h3" component="div">
              Aquí podrás generar y descargar reportes de gestión consolidados a
              la fecha.
            </Typography>
          </Grid>
          <Grid item xs={2} pr="24px" pb="2px" display="flex" alignItems="end">
          <BotonCarga
              testid="btnManagementReport"
              toDo={handleClickReporteGestion}
              disabled={false}
              loading={loading}
              type="primary"
              variant="contained"
              label="Reporte de gestión"              
              sx={{ height: 54, whiteSpace: "pre" }}
            />
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

export default ReporteGestion;
