import { StyledloadingButton } from './StyledButton';
import { DownloadGray, DownloadGreen, DownloadWhite } from '../../assets/icons/iconosEscencial';

type BotonCargaArgs = {
  disabled?: boolean,
  label?: string,
  loading?: boolean,
  download?: any,
  classname?: string,
  noClick?: boolean,
  name?: string,
  variant?: "text" | "outlined" | "contained",
  type?: string,
  setLoading?: Function,
  sx?: Object,
  testid?: string,
  toDo: Function,
  children?: any,
  ripple?: any
}
export const BotonCarga = ({
  disabled,
  label,
  loading,
  download,
  classname,
  noClick,
  name,
  variant,
  type,
  setLoading,
  sx,
  testid,
  toDo,
  children,
  ripple
}: BotonCargaArgs) => {

  function handleClick() {
    if (setLoading) {
      setLoading(true);
    }
    toDo();
  }

  const ButtonLoading = StyledloadingButton(type);
  const downloadIcon = type ? <DownloadGreen sx={{ pt: "2px" }} /> : <DownloadWhite sx={{ pt: "2px" }} />;
  const disabledCondition = disabled ? <DownloadGray sx={{ pt: "2px" }} /> : downloadIcon;
  const hadIcon = download ? disabledCondition : "";

  return (
    <ButtonLoading
      data-testid={testid}
      onClick={noClick ? undefined : handleClick}
      loading={loading}
      name={name}
      variant={variant}
      className={classname}
      sx={sx}
      disabled={disabled}
      disableRipple={ripple}
    >
      {
        loading ?
          "" :
          <>
            {hadIcon}{label}{children}
          </>}
    </ButtonLoading>
  );
};

export default BotonCarga;

