import { useState } from "react";
import { CustomSelect2 } from "../../../../components/Selects/customSelect";
import { useQuery } from "@apollo/client";
import { getUserByStatus } from "../../../../services/escritorio/escritorioServices";
import { NuevosEstadosCuentaMedica } from "../../../../utils/enums";
import { Skeleton } from "@mui/material";

type Props = {
    value: any;
    onChange: Function;
    datosCuenta: any;
    disabled?: boolean;
}
export const SelectFilteredUsers = ({ value, onChange, datosCuenta, disabled }: Props) => {
    const [validUsers, setValidUsers] = useState<{ label: string, value: string }[]>([]);

    const { loading } = useQuery(getUserByStatus, {
        variables: { status: NuevosEstadosCuentaMedica[datosCuenta.estado] },
        fetchPolicy: "network-only",
        onCompleted: (res) => {
            const aux = res.getUserByStatus.map((u: any) => ({ label: u.email, value: u.email }))
            setValidUsers(aux)
        },
        onError: (err) => {
            console.log(err.message)
            setValidUsers([{ label: "ERROR", value: "" }])
        }
    });

    return (
        <>
            {
                loading ?
                    <Skeleton data-testid="loaderSU" height={80} sx={{ width: '100%' }}  /> :
                    <CustomSelect2
                        onChange={onChange}
                        value={value}
                        placeholder={"Seleccione una opción"}
                        data={validUsers}
                        name="usuario"
                        titulo={"Seleccione una opcion"}
                        disabled={disabled}
                    />
            }

        </>
    )
}