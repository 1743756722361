import { useQuery } from "@apollo/client"
import { obtenerCodigosCIE10 } from "../../../../services/administracionCM/administracionCMServices"
import { SlimInput } from "../../../../assets/styles/Input/input"
import { Autocomplete, Box } from "@mui/material"
import { useState } from "react"

export const SelectCIE10 = ({ disabled, value, onChange }: any) => {
    const [datacie10, setDataCie10] = useState<any[]>([]);
    const { loading } = useQuery(obtenerCodigosCIE10, {
        fetchPolicy: "cache-first",
        variables: {
            pageSize: 20000,
            pageNumber: 0
        },
        onCompleted: (res) => {
            setDataCie10(res.obtenerCodigosCIE10 || [{codigo:"", descripcion:"Error al obtener CIE-10"}]);
        },
        onError: (err)=> {
            console.log(err);
            setDataCie10([{codigo:"", descripcion:"Error al obtener CIE-10"}]);
        }
    })

    return (
        <Autocomplete
            id="cie10"
            data-testid="cie10"
            loading={loading}
            disabled={disabled}
            sx={{ width: "100%" }}
            options={datacie10}
            autoHighlight
            isOptionEqualToValue={(option, value) => option.codigo === value.codigo}
            value={value || null}
            clearText={"Limpiar"}
            onChange={(event, option) => {
                onChange(option);
            }}
            getOptionLabel={(option) => (option.codigo + " - " + option.descripcion)}
            renderOption={(props, option) => (
                <Box
                    component="li"
                    sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                    {...props}
                    key={option.codigo}
                    value={option.codigo}
                >
                    {option.codigo} - {option.descripcion}
                </Box>
            )}
            renderInput={(params) => (
                <SlimInput
                    {...params}
                    placeholder="Escribe un CIE10"
                    inputProps={{
                        ...params.inputProps,
                        autoComplete: "new-password", // disable autocomplete and autofill
                    }}
                />
            )}
        />
    )
}