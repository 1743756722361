import { Workbook } from "exceljs";
import { saveAs } from "file-saver";
import { format } from "date-fns";

export const exportCuentasEscritorio = (data, handleMessage, isOnlyAssigned) => {
    try {
        const cuentas = [];
        cuentas.push(["N° Cuenta", "N° Cuenta Extranet", "N° Cuenta Prestador", "Rut Beneficiario",
            "Nombre Beneficiario", "Rut Prestador", "Nombre prestador", "estado", "causal" ,"Fecha Recepción", "Fecha Asigación","Fecha Compromiso", "Días Restantes","Monto de Cobro",
            "Canal","Usuario Asignado"]);
        data.forEach((cuentaMD) => {
            let nombrePaciente = cuentaMD.nombrePaciente + " " +
                (cuentaMD.apellidoPaternoPaciente ? cuentaMD.apellidoPaternoPaciente : "") + " " +
                (cuentaMD.apellidoMaternoPaciente ? cuentaMD.apellidoMaternoPaciente : "");
            cuentas.push([
                cuentaMD.id,
                cuentaMD.idCMD,
                cuentaMD.numeroCuentaPrestador,
                cuentaMD.rutPaciente,
                nombrePaciente,
                cuentaMD.prestador.rutPrestador,
                cuentaMD.prestador.razonSocial,
                cuentaMD.estado,
                (cuentaMD.causal.descripcionCausal === "null" || cuentaMD.causal.descripcionCausal === null) 
                    ? "N/A"
                    : cuentaMD.causal.descripcionCausal,
                cuentaMD.fechaRecepcion,
                cuentaMD.fechaAsignacionUsuario,
                cuentaMD.fechaProbableLiquidacion,
                cuentaMD.diasRestantes,
                cuentaMD.montoTotalCobro,
                cuentaMD.canalOrigen,
                cuentaMD.usuarioAsignado,
            ])
        });
        const excelCuentas = new Workbook();
        excelCuentas.creator = "CTA";
        excelCuentas.addWorksheet("Cuentas Escritorio");
        const libroCuentas = excelCuentas.getWorksheet(1);
        libroCuentas.addRows(cuentas);
        excelCuentas.xlsx.writeBuffer().then((data) => {
            const blob = new Blob([data], {
                type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            });
            const name = `${isOnlyAssigned ? 'MisCuentas' : 'CuentasEscritorio'}_${format(new Date(), "dd-MM-yyyy")}.xlsx`;
            saveAs(blob, name);
            handleMessage('success',"Archivo generado correctamente, revise descargas");
            return;
        });
        return;
    } catch (err) {
        handleMessage('error',"Error al procesar las Cuentas");
    }
}
