import { Box, CssBaseline } from "@mui/material";
import React from "react";
import Header from "./components/header/HeaderApp";
import { SideBar } from "./components/SideBar/SideBar";
import { Main } from "./assets/styles/MainContent/mainContent";
import RoutesConfig from "./routes";
import "./index.css"
import { useIsAuthenticated } from "@azure/msal-react";
import { useGetUser } from "./hooks/getUser";
export const App = () => {
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  const isAuthenticated = useIsAuthenticated();
  const user = useGetUser();
  
  const hasAccess = () =>
    user.rol.some((rol) =>
      [
        "JEFE",
        "VISADOR",
        "LIQUIDADOR",
        "ADMINISTRATIVO",
        "VALORIZADOR",
        "AUDITOR",
      ].includes(rol)
    );

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <CssBaseline />
      <Header />

      {isAuthenticated && hasAccess() ? <SideBar
        open={open}
        handleDrawerClose={handleDrawerClose}
        handleDrawerOpen={handleDrawerOpen}
      /> : null}

      <Main open={open}>
        <RoutesConfig />
      </Main>
    </Box>
  );
};
