import {
    FormControl,
    FormHelperText,
    Grid,
    IconButton,
    Typography
} from "@mui/material";
import ClearIcon from '@mui/icons-material/Clear';
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { es } from "date-fns/locale";
import { CustomDateField } from "../../assets/styles/Input/input";

type DatePickerInput = {
    value: any,
    onChange: any,
    minDate?: any,
    maxDate?: any,
    disabled?: boolean,
    placeholder?: string,
    name?: string,
    testid?: string,
    error?: any,
    noClear?: any
}

const InputDate = ({ value, testid, noClear, disabled, onChange, clear, ...params }: any) => {
    return (
        <Grid sx={{ position: "relative", display: "inline-block" }} >
            <CustomDateField
                sx={{ mr: "16px" }}
                {...params}
                inputProps={{
                    ...params.inputProps,
                    "data-testid": testid,
                    readOnly: true,
                    placeholder: "DD / MM / AAAA",
                    sx: {
                        "&::placeholder": {
                            color: "#909AA1",
                            fontWeight: 400,
                            opacity: 1, // otherwise firefox shows a lighter color
                        },
                    },
                }}
                value={value}
                onChange={onChange}
                onKeyDown={(e) => {
                    e.preventDefault();
                }}
                disabled={disabled}
                className="full-width"
                helperText={
                    params.error ? "Ingrese una fecha Valida" : ""
                }
            />
            {value && !noClear &&
                <IconButton
                    style={{ position: "absolute", top: ".5rem", margin: "auto", right: "2rem" }}
                    sx={{ opacity: ".5" }}
                    disabled={disabled}
                    onClick={clear}
                >
                    <ClearIcon />
                </IconButton>
            }
        </Grid>
    )
};

export const DatePicker = ({
    value,
    onChange,
    minDate,
    maxDate,
    disabled,
    placeholder,
    testid,
    name,
    error,
    noClear
}: DatePickerInput) => {
    return (
        <FormControl sx={{ width: "100%!important", margin: "0" }} error={error ? true : undefined}>
            <LocalizationProvider
                dateAdapter={AdapterDateFns}
                adapterLocale={es}
            >
                <DesktopDatePicker
                    className="form-control inputText"
                    minDate={minDate}
                    maxDate={maxDate}
                    disabled={disabled}
                    value={value || null}
                    name={name}
                    label={placeholder ? <Typography sx={{ color: '#909AA1 !important' }}>{placeholder}</Typography> : undefined}
                    onChange={onChange}
                    //inputProps={{ "data-testid": testid, "id": id }}
                    slotProps={{
                        popper: { placement: 'bottom-end' },
                        textField: { value, testid, noClear, disabled, onChange, clear: () => onChange(null) } as any
                    }}
                    slots={{
                        textField: InputDate as any
                    }}
                />
            </LocalizationProvider>
            <FormHelperText>{error || ""}</FormHelperText>
        </FormControl>
    );
}