import { DesktopTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { es } from "date-fns/locale";
import { SlimInput } from "../../assets/styles/Input/input";

type TimePickerInput = {
  value: any;
  onChange: any;
  minTime?: any;
  maxTime?: any;
  disabled?: boolean;
  testid?: string;
  noClear?: any;
};

const DesktopTimePickerTextField = ({
  value,
  testid,
  noClear,
  disabled,
  onChange,
  ...params
}: any) => {
  return (
    <SlimInput
      {...params}
      inputProps={{
        ...params.inputProps,
        placeholder: "HH : MM",
      }}
      fullWidth
      value={value}
      onChange={onChange}
      // onKeyDown={(e) => {
      //   e.preventDefault();
      // }}
      disabled={disabled}
    />
  );
};

export const TimePicker = ({
  value,
  onChange,
  minTime,
  maxTime,
  disabled,
  testid,
  noClear,
}: TimePickerInput) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={es}>
      <DesktopTimePicker
        value={value}
        minTime={minTime}
        maxTime={maxTime}
        onChange={onChange}
        disabled={disabled}
        disableIgnoringDatePartForTimeValidation={true}
        timeSteps={{ hours: 1, minutes: 1 }}
        slotProps={{
          popper: { placement: "bottom-end" },
          textField: {
            value,
            testid,
            noClear,
            disabled,
            onChange,
          } as any,
        }}
        slots={{
          textField: DesktopTimePickerTextField as any,
        }}
      />
    </LocalizationProvider>
  );
};
