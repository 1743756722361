import { Box, Button, Modal, Typography } from "@mui/material";

import alert from "../../../assets/icons/alert-message.svg";
import { styledModalIncompleteInfo } from "../../../assets/styles/Modal/modal";
import { messageAlertStyle } from "../../../assets/styles/Box/boxs";

const filtrarMsgInfoNecesaria = (
  title: string,
  msg: string,
  isSplit?: boolean
) => {
  const msgParse = isSplit ? (msg && msg.split("/")) || [] : [];
  return msg !== "Validación Correcta" && msg !== null ? (
    <>
      <Typography fontWeight="700">{title}</Typography>
      {isSplit ? (
        msgParse.map((text) => <Typography pb="10px" key={text}>{text}</Typography>)
      ) : (
        <Typography pb="10px">{msg}</Typography>
      )}
    </>
  ) : null;
};

export const IncompleteInformationResolucion = ({
  open,
  onClose,
  msgAlerts,
  updateCMTitle,
  updateCMAction,
}: any) => {
  return (
    <Modal
      aria-labelledby="unstyled-modal-title"
      aria-describedby="unstyled-modal-description"
      open={open}
      className="background-modal"
    >
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        sx={styledModalIncompleteInfo}
      >
        <Box mb="12px">
          <img height="42px" src={alert} alt="alert" />
        </Box>
        <Typography variant="h4" fontSize="24px" mb="16px">
          No se puede {updateCMTitle ?? "liquidar"} esta Cuenta Médica
        </Typography>

        <Typography textAlign="center">
          Tienes información pendiente en alguna de estas secciones. Te
          sugerimos verificarlas para poder continuar con la{" "}
          {updateCMAction ?? "liquidación"}.
        </Typography>

        <Box
          display="flex"
          alignItems="flex-start"
          mt="22px"
          mb="40px"
          sx={messageAlertStyle}
        >
          <Box
            height="20px"
            mr="18px"
            mt="3px"
            sx={{ bgcolor: "#FFFFFF", borderRadius: "100%" }}
          >
            <img height="20px" src={alert} alt="alert"></img>
          </Box>

          <Box>
            {msgAlerts?.estado === 1 && (
              <>
                {filtrarMsgInfoNecesaria(
                  "Antecedentes Generales",
                  msgAlerts?.mensaje?.antecedentesGenerales
                )}
                {filtrarMsgInfoNecesaria(
                  "Evento Hospitalario",
                  msgAlerts?.mensaje?.eventoHospitalario
                )}
                {filtrarMsgInfoNecesaria(
                  "Documentos Adjuntos",
                  msgAlerts?.mensaje?.documentosAdjuntos
                )}
                {filtrarMsgInfoNecesaria(
                  "Bonificación",
                  msgAlerts?.mensaje?.bonificacion,
                  true
                )}
                {filtrarMsgInfoNecesaria(
                  "Valorización",
                  msgAlerts?.mensaje?.documentoCobro,
                  true
                )}
                {filtrarMsgInfoNecesaria(
                  "Atencion",
                  msgAlerts?.mensaje?.texto,
                  false
                )}
              </>
            )}
            {msgAlerts?.estado === 2 &&
              filtrarMsgInfoNecesaria(
                "Bonificar",
                msgAlerts?.mensaje?.documentoCobro,
                true
              )}
            {msgAlerts?.estado === 3 &&
              filtrarMsgInfoNecesaria(
                "Liquidar",
                msgAlerts?.mensaje?.documentoCobro,
                true
              )}
          </Box>
        </Box>

        <Button variant="contained" onClick={onClose}>
          Entendido
        </Button>
      </Box>
    </Modal>
  );
};
