import {
    DialogActions,
    DialogContent,
    Grid,
    Typography,
} from "@mui/material";
import { CustomModal, CustomModalTitle } from "../../../../components/Modal/customModal";
import { modalTitle, warningGray } from "../../../../assets/styles/Text/text";
import { SecondaryButton } from "../../../../components/Buttons/Button";
import { CustomInput } from "../../../../assets/styles/Input/input";
import { useState } from "react";
import BotonCarga from "../../../../components/Buttons/LoadingButton";
import { useMutation } from "@apollo/client";
import { asignaCanasta } from "../../../../services/administracionCM/valorizacionService";
import { checkStatus } from "../../utils/checkStatus";


export const ModalAddCanasta = ({
    onOpen,
    onClose,
    prestaciones,
    muestraMensaje,
    clearPrestaciones,
    refetch
}: any) => {
    const [codCanasta, setCodCanasta] = useState<string | null>(null);

    const [asignaCan, { loading }] = useMutation(asignaCanasta, {
        onCompleted: () => {
            muestraMensaje("Canasta asignada correctamente.", 'success');
            clearPrestaciones();
            refetch();
            const tableRefetch = document.getElementsByName("btnRefetchCobro");
            if (tableRefetch.length > 0) {
                tableRefetch.forEach((btn) => {
                    btn.click();
                })
            }
            clearClose();
        },
        onError: (err:any) => {
            console.log(err.message);
            muestraMensaje("Ocurrió un error al asignar la canasta", 'error');
            checkStatus(err.graphQLErrors[0]?.code || "");
        }
    });

    const onChange = ({ target }: any) => {
        setCodCanasta(target.value);
    }

    const asignaCanastas = () => {
        asignaCan({
            variables: {
                prestaciones: prestaciones,
                canasta: codCanasta
            }
        })
    }

    const clearClose = () => {
        setCodCanasta(null);
        onClose();
    }

    return (
        <CustomModal
            fullWidth
            maxWidth="sm"
            open={onOpen}
            sx={{
                "& .MuiBackdrop-root": {
                    backgroundColor: "rgba(0, 0, 0, 0.3)",
                },
            }}
        >
            <CustomModalTitle>
                <Typography sx={modalTitle} paddingTop="16px">
                    Añadir canasta
                </Typography>
            </CustomModalTitle>
            <DialogContent
                dividers
                sx={{ pl: "24px", pr: "24px" }}
            >
                <Grid container pb="16px">
                    <Grid item xs={12} pb="24px">
                        <Typography sx={warningGray}>Ingrese la información correspondiente
                            a la cobertura GES de las prestaciones dentro del documento de cobro.   </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h6" pb="6px">
                            Código canasta
                        </Typography>
                        <CustomInput
                            fullWidth
                            data-testid="codCanastaInput"
                            placeholder="Ingrese"
                            value={codCanasta || ""}
                            onChange={onChange}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions sx={{ textAlign: "center", margin: "5px 0 5px 0" }}>
                <Grid
                    container
                    direction="row"
                    justifyContent={"space-between"}
                >
                    <SecondaryButton
                        sx={{ marginRight: "24px", p: "15px" }}
                        onClick={clearClose}
                        disabled={loading}
                    >
                        Cancelar
                    </SecondaryButton>
                    <BotonCarga
                        toDo={asignaCanastas}
                        label="Añadir canasta"
                        disabled={!codCanasta}
                        loading={loading}
                        testid="addCanasta"
                        sx={{
                            width: "175px",
                            height: "50px",
                        }}
                    />
                </Grid>
            </DialogActions>
        </CustomModal>
    );
};