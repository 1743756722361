import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';

export const SecondaryButton = styled(Button)({
  color: "#00837A",
  fontStyle: 'normal',
  fontWeight: 'bold',
  boxSizing: 'border-box',
  border: '1px solid #00837A',
  left: '0%',
  right: '0%',
  top: '0%',
  bottom: '0%',
  letterSpacing: '0.0021em',
  alignItems: 'center',
  textAlign: 'center',
  borderRadius: '8px',
  backgroundColor: "#FFFFFF",
  lineHeight: '20px',
  textTransform: 'none',
  fontFamily: 'interstate',
  fontSize: "16px",
  height: "48px",
  padding: "15px 22px",

  '&:hover': {
    border: '1px solid #00837A',
    boxSizing: 'border-box',
    color: "#00837A",
    backgroundColor: '#E6F6F5',
  },
  '&:active': {
    color: "#FFFFFF",
    backgroundColor: "#00736B"
  },
  "&:disabled": {
    boxSizing: 'border-box',
    border: '1px solid #909AA1',
    color: "#909AA1",
    boxShadow: 'none',
    backgroundColor: "#FFFFFF"
  }
});

export const LittleSecondary = styled(Button)({
  color: "#00837A",
  fontStyle: 'normal',
  fontWeight: 'bold',
  boxSizing: 'border-box',
  border: '1px solid #00837A',
  left: '0%',
  right: '0%',
  top: '0%',
  bottom: '0%',
  letterSpacing: '0.0021em',
  alignItems: 'center',
  textAlign: 'center',
  borderRadius: '8px',
  backgroundColor: "#FFFFFF",
  lineHeight: '20px',
  textTransform: 'none',
  fontFamily: 'interstate',
  fontSize: "16px",
  height: "48px",

  '&:hover': {
    border: '1px solid #00837A',
    boxSizing: 'border-box',
    color: "#00837A",
    backgroundColor: '#E6F6F5',
  },
  '&:active': {
    color: "#FFFFFF",
    backgroundColor: "#00736B"
  },
  "&:disabled": {
    boxSizing: 'border-box',
    border: '1px solid #909AA1',
    color: "#909AA1",
    boxShadow: 'none',
    backgroundColor: "#FFFFFF"
  }
});

export const PurpleButton = styled(Button)({
  color: "#FFFFFF",
  fontStyle: 'normal',
  fontWeight: 'bold',
  letterSpacing: '0.0021em',
  alignItems: 'center',
  textAlign: 'center',
  borderRadius: '8px',
  backgroundColor: "#CBC7F2",
  lineHeight: '20px',
  textTransform: 'none',
  fontFamily: 'interstate',
  fontSize: "16px",

  '&:hover': {
    border: '1px solid #CBC7F2',
    boxSizing: 'border-box',
    color: "#CBC7F2",
    backgroundColor: '#FFFFFF',
  },
  '&:active': {
    color: "#FFFFFF",
    backgroundColor: "#CBC7F2"
  },
  "&:disabled": {
    boxSizing: 'border-box',
    border: '1px solid #909AA1',
    color: "#909AA1",
    boxShadow: 'none',
    backgroundColor: "#FFFFFF"
  }
});

export const LineButton = styled(Button)({
  color: "#4DBFB8",
  fontStyle: 'normal',
  fontWeight: 'bold',
  left: '0%',
  right: '0%',
  top: '0%',
  bottom: '0%',
  letterSpacing: '0.0021em',
  alignItems: 'center',
  textAlign: 'center',
  borderRadius: '8px',
  backgroundColor: "none",
  lineHeight: '20px',
  textTransform: 'none',
  fontFamily: 'interstate',
  fontSize: "16px",
  textDecoration: "underline",
  textDecorationThickness: "1px",

  '&:hover': {
    border: 'none',
    color: "#4DBFB8",
    backgroundColor: 'transparent',
  },
  '&:active': {
    color: "#4DBFB8",
    backgroundColor: "transparent",
    boxShadow: "none"
  },
  "&:disabled": {
    boxSizing: 'border-box',
    border: '1px solid #909AA1',
    color: "#909AA1",
    boxShadow: 'none',
    backgroundColor: "#FFFFFF"
  }
});

export const GreenAltButton = styled(Button)({
  color: "#FFFFFF",
  fontStyle: 'normal',
  fontWeight: 'bold',
  boxSizing: 'border-box',
  left: '0%',
  right: '0%',
  top: '0%',
  bottom: '0%',
  letterSpacing: '0.0021em',
  alignItems: 'center',
  textAlign: 'center',
  borderRadius: '8px',
  backgroundColor: "#4DBFB8",
  lineHeight: '20px',
  textTransform: 'none',
  fontFamily: 'interstate',
  fontSize: "16px",
  height: "48px",

  '&:hover': {
    border: '1px solid #4DBFB8',
    boxSizing: 'border-box',
    color: "#FFFFFF",
    backgroundColor: '#FFFFF',
  },
  '&:active': {
    color: "#FFFFFF",
    backgroundColor: "#4DBFB8"
  },
  "&:disabled": {
    boxSizing: 'border-box',
    border: '1px solid #909AA1',
    color: "#909AA1",
    boxShadow: 'none',
    backgroundColor: "#FFFFFF"
  }
});