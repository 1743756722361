import { useState } from "react";
import { CustomSelect2 } from "../../../../components/Selects/customSelect";
import { useQuery } from "@apollo/client";
import { Skeleton } from "@mui/material";
import { obtenerEstadosCausalesPorSiguienteEstado } from "../../../../services/administracionCM/administracionCMServices";

type Props = {
    value: any;
    onChange: Function;
    disabled?: boolean;
}
export const SelectCausalUsers = ({ value, onChange, disabled }: Props) => {
    const [reasons, setReasons] = useState<{ label: string, value: string }[]>([]);

    const { loading } = useQuery(obtenerEstadosCausalesPorSiguienteEstado, {
        variables: {
            idProximoEstadoCuentaMedica: 0
        },
        onCompleted(data) {
            if (data.obtenerEstadosCausalesPorSiguienteEstado) {
                setReasons(
                    data.obtenerEstadosCausalesPorSiguienteEstado.map(
                        (reason: any) => ({
                            value: reason.idCausal,
                            label: reason.descripcionCausal,
                        })
                    )
                );
            }
        },
        onError: (err) => {
            console.log(err);
            setReasons([{ label: "ERROR", value: "" }]);
        }
    });
    return (
        <>
            {
                loading ?
                    <Skeleton data-testid="loaderSCU" height={80} sx={{ width: '100%', p: 0, m:0 }} /> :
                    <CustomSelect2
                        onChange={onChange}
                        titulo={"Seleccione  una opción"}
                        name="causal"
                        data={reasons}
                        placeholder={"Seleccione una opción"}
                        value={value}
                        disabled={disabled}
                    />

            }

        </>
    )
}