import { ApolloClient, InMemoryCache, createHttpLink, from } from "@apollo/client";
import { RetryLink } from "@apollo/client/link/retry";
import 'cross-fetch/polyfill';
import { setContext } from '@apollo/client/link/context';
import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "../authConfig";
import { GetAccessToken } from "../utils/azureADTokens";

const abortCon = new AbortController();

const retryLink = new RetryLink({  
  delay: {
    initial: 1000,
    max: 2,
    jitter: true
  },

  attempts: {
    max: 2,
    retryIf: (error, _operation) => !!error
  }
});

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_URL_CTA,
  fetchOptions: {
    mode: 'cors',
    signal: abortCon.signal,
  },
});

const authLink = setContext(async (_, { headers }) => {
  const msalInstance = process.env.JEST_WORKER_ID !== undefined ? '' : new PublicClientApplication(msalConfig);
  // get the authentication token from local storage if it exists
  const token = msalInstance ? await GetAccessToken(msalInstance) : '';  // sessionStorage.getItem('token');
  // return the headers to the context so httpLink can read them
  // console.log("token: " + token);
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    }
  }
});

const client = new ApolloClient({
  connectToDevTools: true,
  cache: new InMemoryCache({
    addTypename: false
  }),
  link: authLink.concat(from([retryLink, httpLink])),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'cache-and-network',
      errorPolicy: 'ignore',
    },
  }
});

export default client