import { useEffect, useRef, useState } from "react";
import {
    Box,
    DialogActions,
    DialogContent,
    Grid,
    Typography
} from "@mui/material";
import { DatePicker } from "../../../../components/DatePicker/DatePicker";
import { CustomModal, CustomModalTitle } from "../../../../components/Modal/customModal";
import { boldTitle, modalSubTittle, modalTextBold, warningGray } from "../../../../assets/styles/Text/text";
import { CustomInput } from "../../../../assets/styles/Input/input";
import { SecondaryButton } from "../../../../components/Buttons/Button";
import LittleDoc from "../../../../assets/icons/docLittle.svg";
import { CustomSelect, CustomSelect2 } from "../../../../components/Selects/customSelect";
import PrestadorIcon from "../../../../assets/icons/prestadorIcon.svg";
import { useMutation } from "@apollo/client";
import { mutationCrearDocumentoCobroCobertura, mutationEditarDocumentoCobroCobertura, mutationDuplicarDocumentoCobroCobertura } from "../../../../services/administracionCM/docCobroServices";
import BuscaPrestador from "../../../../components/BuscaPrestador/BuscaPrestador";
import { hasEmptyValues } from "../../../../utils";
import { parseMiles } from "../../../../utils/monedaUtils";
import { checkStatus } from "../../utils/checkStatus";
import BotonCarga from "../../../../components/Buttons/LoadingButton";

type valueDocCobro = {
    tipoDocumento: number | null,
    tipoVoucher: string | null,
    tipoCobertura: number | null,
    folioDocumento: number | null,
    folioCobertura: number | null,
    tieneConvenio: boolean,
    razonSocial: string | null,
    rutPrestador: string,
    direccionAtencion: string | null,
    codigoPaquete: number | null,
    paquete: string | null,
    luat: number,
    monto: number | null,
    luatDireccion: string | null,
}

export const ModalNuevoDocumento = ({
    dataCM,
    accion,
    data,
    onOpen,
    onClose,
    muestraMensaje,
    refetch
}: any) => {
    const { idCuentaMedica } = dataCM;
    const idDocumento = data?.id || 0;
    const isCrear = accion === 'crear';
    const isEditar = accion === 'editar';
    const isDuplicar = accion === 'duplicar';
    const isVerDetalle = accion === 'verDetalle';
    const today = useRef(new Date());
    const [minDate] = useState<Date>(dataCM.fechaHospitalizacion.fechaInicio);
    const [maxDate] = useState<Date>(today.current);
    const [fechaDocumento, setFechaDocumento] = useState<Date | null>(!isCrear && data?.fechaDocumento ? new Date(data.fechaDocumento) : null);
    const tiposDocumentos = [{ value: 1, label: "Prefactura" }, { value: 2, label: "Boleta" }, { value: 3, label: "Factura" }];
    const tiposCobertura = [{ value: 1, label: "GES" }, { value: 2, label: "CAEC" }, { value: 3, label: "PLAN" }].filter(
        (option) => { return (!isDuplicar || option.value !== data?.cobertura?.tipoCobertura) });
    const tiposVoucher = ["Bono", "Reembolso", "Reembolso"];
    const submitButtonText = isCrear ? "Crear Documento" :
        isEditar ? "Editar Documento" :
            isDuplicar ? "Duplicar Documento" : ""
    const tituloModal = isCrear ? "Nuevo Documento" :
        isEditar ? "Editar Documento" :
            isDuplicar ? "Duplicar Documento" :
                isVerDetalle ? "Ver Detalle Documento" : "";
    const [disableSubmit, setDisableSubmit] = useState<boolean>(false);
    const [valuesSelect, setValuesSelect] = useState<valueDocCobro>({
        tipoDocumento: !isCrear && data?.tipoDocumento ? data.tipoDocumento : null,
        tipoVoucher: !isCrear && data?.tipoDocumento ? tiposVoucher[data.tipoDocumento - 1] : null,
        tipoCobertura: !isCrear && !isDuplicar && data?.cobertura?.tipoCobertura ? data.cobertura.tipoCobertura : null,
        folioDocumento: !isCrear && data?.folioDocumento ? data.folioDocumento : null,
        folioCobertura: !isCrear && data?.cobertura?.folioCobertura ? data.cobertura.folioCobertura : null,        
        tieneConvenio: data?.codigoLugarAtencionIsapre !== 888,
        razonSocial: !isCrear && data?.razonSocial ? data.razonSocial : null,
        rutPrestador: !isCrear && data?.rutPrestador ? data.rutPrestador : "",
        direccionAtencion: !isCrear && data?.lugarAtencion ? data.lugarAtencion : null,
        codigoPaquete: null,
        paquete: null,
        luat: !isCrear && data?.codigoLugarAtencionIsapre ? data.codigoLugarAtencionIsapre : 888,
        monto: !isCrear && data?.monto ? data.monto : 0,
        luatDireccion: !isCrear && data?.codigoLugarAtencionIsapre ? data.codigoLugarAtencionIsapre + " - " + data.lugarAtencion : 
        !isCrear && data?.lugarAtencion ? data.lugarAtencion : null
    });
    const [showExtraFields, setShowExtraFields] = useState({
        folioCobertura: valuesSelect.tipoCobertura === 1 || valuesSelect.tipoCobertura === 2
    });
    const [errores, setErrores] = useState({
        tipoDocumento: {
            error: false,
            message: ""
        },
        tipoCobertura: {
            error: false,
            message: ""
        },
        folioDocumento: {
            error: false,
            message: ""
        },
        folioCobertura: {
            error: false,
            message: ""
        },
        razonSocial: {
            error: false,
            message: ""
        },
        rutPrestador: {
            error: false,
            message: ""
        },
        monto: {
            error: false,
            message: ""
        }
    });

    useEffect(() => {
        let excepciones = ['codigoPaquete', 'paquete', 'luat'];
        if (!showExtraFields.folioCobertura) {
            excepciones.push('folioCobertura');
        }

        setDisableSubmit(hasEmptyValues(valuesSelect, excepciones) || !fechaDocumento);
    }, [valuesSelect, fechaDocumento]);

    const asignaPrestador = (dir: string, luat: number, rz: string, rut: string, luatDir: string) => {
        setValuesSelect({
            ...valuesSelect,
            rutPrestador: rut,
            luat: luat,
            razonSocial: rz,
            direccionAtencion: dir,
            luatDireccion: luatDir
        })
    }

    const setConvenio = () => {
        setValuesSelect({
            ...valuesSelect,
            tieneConvenio: !valuesSelect.tieneConvenio,
            rutPrestador: valuesSelect.luat === 888 ? valuesSelect.rutPrestador : "",
            luat: 888,
            razonSocial: "",
            direccionAtencion: valuesSelect.luat === 888 ? valuesSelect.direccionAtencion : "",
            luatDireccion: "",
        })
    }

    const [fnCrearDocument, { loading: loadingCrearDoc }] = useMutation(mutationCrearDocumentoCobroCobertura);
    const [fnEditarDocument, { loading: loadingEditarDoc }] = useMutation(mutationEditarDocumentoCobroCobertura);
    const [fnDuplicarDocument, { loading: loadingDuplicarDoc }] = useMutation(mutationDuplicarDocumentoCobroCobertura);

    const onChangeFolio = (e: any) => {
        const value = e.target.value.replace(/[^0-9]/g, '');
        setValuesSelect((prevState) => {
            return { ...prevState, [e.target.name]: value };
        });
    }

    const handleOnchangeNumbers = (e: any) => {
        const value = e.target.value.replace(/[^0-9]/g, '');
        setValuesSelect((prevState) => {
            return { ...prevState, [e.target.name]: (value && parseInt(value)) || "" };
        });

    };

    const handleOnChangeTipoDocumento = (e: any) => {
        const selected = e.target.value;

        setValuesSelect((prevState) => {
            return { ...prevState, tipoVoucher: tiposVoucher[selected - 1], [e.target.name]: e.target.value };
        });
    }

    const handleOnChangeTipoCobertura = (e: any) => {
        const selected = e.target.value;

        let toClean = {}
        if (selected === 1) {
            setShowExtraFields({ folioCobertura: true });
        } else if (selected === 2) {
            setShowExtraFields({ folioCobertura: true });
            setErrores({ ...errores })
        } else if (selected === 3) {
            setShowExtraFields({ folioCobertura: false });
            toClean = { folioCobertura: "" }
            setErrores({ ...errores, folioCobertura: { error: false, message: "" } })
        }

        setValuesSelect((prevState) => {
            return {
                ...prevState,
                [e.target.name]: e.target.value,
                ...toClean
            };
        });
    };

    const handleGuardar = () => {
        if (Number(valuesSelect.monto) > 2000000000) {
            muestraMensaje("Campo monto excede límite ($2.000.000.000)", 'error');
            return;
        }
        setDisableSubmit(true);
        if (isCrear) {
            fnCrearDocument({
                variables: {
                    idCuentaMedica: Number(idCuentaMedica),
                    tipoDocumento: Number(valuesSelect.tipoDocumento),
                    tipoCobertura: Number(valuesSelect.tipoCobertura),
                    fechaDocumento: fechaDocumento,
                    folioDocumento: Number(valuesSelect.folioDocumento),
                    folioCobertura: Number(valuesSelect.folioCobertura),
                    convenioPrestador: valuesSelect.tieneConvenio,
                    razonSocial: valuesSelect.razonSocial,
                    rutPrestador: valuesSelect.rutPrestador.replace(/[^0-9kK-]/g, "").trim(),
                    codigoLugarAtencion: Number(valuesSelect.luat || 888),
                    codigoLugarAtencionIsapre: Number(valuesSelect.luat || 888),
                    lugarAtencion: valuesSelect.direccionAtencion,
                    codigoPaquete: valuesSelect.codigoPaquete,
                    descPaquete: "",
                    monto: Number(valuesSelect.monto)
                },
                onCompleted: (res) => {
                    if (res.crearDocumentoCobroCobertura?.estado === 1) {
                        muestraMensaje(`El Documento de Cobro se Creó Exitosamente`, 'success');
                        refetch();
                    } else {
                        muestraMensaje(`No se pudo Crear el Documento de Cobro. Por favor, inténtelo nuevamente.`, 'error');
                    }
                    onClose();
                },
                onError: (err: any) => {
                    const [, errorMessage] = err.message.split('Error:');
                    muestraMensaje(errorMessage || err.message, 'error');
                    checkStatus(err.graphQLErrors[0]?.code || "");
                }
            })
        }

        if (isEditar) {
            fnEditarDocument({
                variables: {
                    id: Number(idDocumento),
                    idCuentaMedica: Number(idCuentaMedica),
                    tipoDocumento: Number(valuesSelect.tipoDocumento),
                    tipoCobertura: Number(valuesSelect.tipoCobertura),
                    fechaDocumento: fechaDocumento,
                    folioDocumento: Number(valuesSelect.folioDocumento),
                    folioCobertura: Number(valuesSelect.folioCobertura),
                    convenioPrestador: valuesSelect.tieneConvenio,
                    razonSocial: valuesSelect.razonSocial,
                    rutPrestador: valuesSelect.rutPrestador.replace(/[^0-9kK-]/g, "").trim(),
                    codigoLugarAtencion: Number(valuesSelect.luat || 888),
                    codigoLugarAtencionIsapre: Number(valuesSelect.luat || 888),
                    lugarAtencion: valuesSelect.direccionAtencion,
                    codigoPaquete: valuesSelect.codigoPaquete,
                    descPaquete: "",
                    monto: Number(valuesSelect.monto)
                },
                onCompleted: (res) => {
                    if (res.editarDocumentoCobroCobertura?.estado === 1) {
                        muestraMensaje("El Documento de Cobro se Editó Exitosamente", 'success');
                        refetch();
                    } else {
                        muestraMensaje("No se pudo Editar el Documento de Cobro. Por favor, inténtelo nuevamente.", 'error');
                    }

                    onClose();
                },
                onError: (err: any) => {
                    const [, errorMessage] = err.message.split('Error:');
                    muestraMensaje(errorMessage || err.message, 'error');
                    checkStatus(err.graphQLErrors[0]?.code || "");
                }
            })
        }

        if (isDuplicar) {
            fnDuplicarDocument({
                variables: {
                    id: Number(idDocumento),
                    idCuentaMedica: Number(idCuentaMedica),
                    tipoDocumento: Number(valuesSelect.tipoDocumento),
                    tipoCobertura: Number(valuesSelect.tipoCobertura),
                    fechaDocumento: fechaDocumento,
                    folioDocumento: Number(valuesSelect.folioDocumento),
                    folioCobertura: Number(valuesSelect.folioCobertura),
                    convenioPrestador: valuesSelect.tieneConvenio,
                    razonSocial: valuesSelect.razonSocial,
                    rutPrestador: valuesSelect.rutPrestador.replace(/[^0-9kK-]/g, "").trim(),
                    codigoLugarAtencion: Number(valuesSelect.luat || 888),
                    codigoLugarAtencionIsapre: Number(valuesSelect.luat || 888),
                    lugarAtencion: valuesSelect.direccionAtencion,
                    codigoPaquete: valuesSelect.codigoPaquete,
                    descPaquete: "",
                    monto: Number(valuesSelect.monto)
                },
                onCompleted: (res) => {
                    if (res.duplicarDocumentoCobroCobertura?.estado === 1) {
                        muestraMensaje(`El Documento de Cobro se Duplicó Exitosamente`, 'success');
                        refetch();
                    } else {
                        muestraMensaje(`No se pudo Duplicar el Documento de Cobro. Por favor, inténtelo nuevamente.`, 'error');
                    }
                    onClose();
                },
                onError: (err: any) => {
                    console.error("mutationDuplicarDocumentoCobroCobertura", err);
                    const [, errorMessage] = err.message.split('Error:');
                    muestraMensaje(errorMessage || err.message, 'error');
                    checkStatus(err.graphQLErrors[0]?.code || "");
                }
            })
        }

        setDisableSubmit(false)
    };

    return (
        <CustomModal
            fullWidth
            maxWidth="sm"
            open={onOpen}
            //onClose={onClose}
            sx={{
                "& .MuiBackdrop-root": {
                    backgroundColor: "rgba(0, 0, 0, 0.3)",
                },
            }}
        >
            <CustomModalTitle>
                <Typography sx={boldTitle}>{tituloModal}</Typography>
            </CustomModalTitle>
            <DialogContent dividers className="grayScroll">
                {/* <LoadValorizacionModal /> */}
                <Grid container>
                    {!isVerDetalle ? (
                        <Grid item xs={12} pr="16px" pb="16px">
                            <Typography sx={warningGray}>Para poder guardar la información del documento de cobro</Typography><Typography sx={modalTextBold}  > debes completar todos los campos</Typography><Typography sx={warningGray}> correctamente.</Typography>
                        </Grid>
                    ) : null}
                    <Grid item xs={12} pb="16px">
                        <Box alignItems={"center"} display={"flex"}>
                            <img src={LittleDoc} />
                            <Typography sx={modalSubTittle}>Información del documento</Typography>
                        </Box>

                    </Grid>
                    <Grid item xs={6} pr="16px" pb="16px">
                        <Typography variant="h6" pb="6px">Tipo de documento de cobro</Typography>
                        <CustomSelect2
                            disabled={isVerDetalle || valuesSelect.tipoCobertura === 4}
                            onChange={handleOnChangeTipoDocumento}
                            value={valuesSelect.tipoDocumento}
                            data={tiposDocumentos}
                            titulo={"Seleccione una opción"}
                            name="tipoDocumento"
                            error={errores.tipoDocumento.error}
                        />
                    </Grid>
                    <Grid item xs={6} pb="16px">
                        <Typography variant="h6" pb="6px">Tipo de voucher a generar</Typography>
                        <CustomInput
                            disabled={true}
                            value={valuesSelect.tipoVoucher}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={6} pr="16px" pb="16px">
                        <Typography variant="h6" pb="6px">Tipo Cobertura</Typography>
                        <CustomSelect2
                            disabled={isVerDetalle || valuesSelect.tipoCobertura === 4}
                            onChange={handleOnChangeTipoCobertura}
                            value={valuesSelect.tipoCobertura}
                            data={valuesSelect.tipoCobertura === 4 ? [{ value: 4, label: "GES-CAEC" }] : tiposCobertura}
                            placeholder={"Seleccione"}
                            name="tipoCobertura"
                            error={errores.tipoCobertura.error}
                        />
                    </Grid>
                    <Grid item xs={6} pb="16px">
                        <Typography variant="h6" pb="6px">Fecha del documento</Typography>
                        <DatePicker
                            disabled={isVerDetalle}
                            value={fechaDocumento}
                            onChange={(newValue: any) => setFechaDocumento(newValue)}
                            maxDate={maxDate}
                            minDate={minDate}
                            placeholder="DD / MM / AAAA"
                        />
                    </Grid>
                    <Grid item xs={6} pr="16px" pb="16px">
                        <Typography variant="h6" pb="6px">Folio documento</Typography>
                        <CustomInput
                            disabled={isVerDetalle || valuesSelect.tipoCobertura === 4}
                            onChange={(e) => onChangeFolio(e)}
                            value={valuesSelect.folioDocumento}
                            name="folioDocumento"
                            placeholder="Ej: 9999999999"
                            error={errores.folioDocumento.error}
                            fullWidth
                        />
                    </Grid>
                    {showExtraFields.folioCobertura ? (
                        <Grid item xs={6} pb="16px">
                            <Typography variant="h6" pb="6px">Folio Cobertura</Typography>
                            <CustomInput
                                disabled={isVerDetalle}
                                onChange={(e) => onChangeFolio(e)}
                                value={valuesSelect.folioCobertura}
                                name="folioCobertura"
                                placeholder="Ej: 9999999999"
                                error={errores.folioCobertura.error}
                                fullWidth
                            />
                        </Grid>
                    ) : null}
                    <Grid item xs={12} pb="16px">
                        <Box alignItems={"center"} display={"flex"}>
                            <img src={PrestadorIcon} />
                            <Typography sx={modalSubTittle}>Información del prestador</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} pb="16px">
                        <Grid container>
                            <BuscaPrestador
                                disabled={isVerDetalle}
                                rutInsti={valuesSelect.rutPrestador}
                                luat={valuesSelect.luat}
                                razonSocial={valuesSelect.razonSocial}
                                direccion={valuesSelect.direccionAtencion}
                                label={valuesSelect.luatDireccion}
                                convenio={!valuesSelect.tieneConvenio}
                                error={{}}
                                asignaPrestador={asignaPrestador}
                                setConvenio={setConvenio}
                            />
                            <Grid item xs={6} pr="16px" pt="24px">
                                <Typography variant="h6" pb="6px">Paquete (Isapre)</Typography>
                                <CustomSelect
                                    disabled={true}
                                    titulo={"Seleccione"}
                                />
                            </Grid>
                            <Grid item xs={6} pt="24px">
                                <Typography variant="h6" pb="6px">Cod. Paquete</Typography>
                                <CustomInput
                                    disabled={true}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={6} pt="24px">
                                <Typography variant="h6" pb="6px">Monto</Typography>
                                <CustomInput
                                    disabled={isVerDetalle}
                                    onChange={handleOnchangeNumbers}
                                    value={parseMiles(valuesSelect.monto)}
                                    name="monto"
                                    placeholder="Ej: 99.999.999"
                                    error={errores.monto.error}
                                    fullWidth
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions >

                {!isVerDetalle ? (
                    <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                    >
                        <SecondaryButton
                            color="primary"
                            onClick={onClose}
                            sx={{ p: "15px 41px" }}
                            disabled={loadingCrearDoc || loadingEditarDoc || loadingDuplicarDoc}
                        >
                            Cancelar
                        </SecondaryButton>
                        <BotonCarga
                            testid="warningMC"
                            toDo={handleGuardar!}
                            disabled={disableSubmit || loadingCrearDoc || loadingEditarDoc || loadingDuplicarDoc || isVerDetalle}
                            loading={loadingCrearDoc || loadingEditarDoc || loadingDuplicarDoc}
                            label={submitButtonText}
                            sx={{ marginLeft: "24px", p: "15px 41px" }}
                        />
                    </Grid>
                ) :
                    <Grid
                        container
                        direction="row"
                        justifyContent='end'
                    >
                        <SecondaryButton
                            color="primary"
                            onClick={onClose}
                            sx={{ p: "15px 41px", right: "0px" }}
                        >
                            Cerrar
                        </SecondaryButton>
                    </Grid>}

            </DialogActions>
        </CustomModal>
    );
};
