import { IconButton, Menu, MenuItem, Grid } from "@mui/material"
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useState } from "react";
import { AsignacionEvento } from "./AsignacionEvento";
import { AperturaEvento } from "./AperturaEvento";

type MenuEventoTypes = {
    row: any,
    dataCM: any,
    refetch: Function,
    setMensajero: Function,
    loadingCuenta: boolean,
    readOnly: boolean
}

export const MenuEventos = ({
    row,
    dataCM,
    refetch,
    setMensajero,
    loadingCuenta,
    readOnly }: MenuEventoTypes) => {
    const [anchorEO, setAnchorEO] = useState<null | HTMLElement>(null);
    const menuOpen = Boolean(anchorEO);
    const [modalAsignar, setModalAsignar] = useState<boolean>(false);
    const [modalApertura, setModalApertura] = useState<boolean>(false);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEO(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEO(null);
    };

    const Asignacion = () => {
        setModalAsignar(true);
        handleClose();
    };

    const Apertura = () => {
        setModalApertura(true);
        handleClose();
    };
    return (
        <Grid>
            <AsignacionEvento
                open={modalAsignar}
                close={() => setModalAsignar(false)}
                row={row}
                dataCM={dataCM}
                refetch={refetch}
                setMensajero={setMensajero}
            />
            <AperturaEvento
                open={modalApertura}
                close={() => setModalApertura(false)}
                row={row}
                dataCM={dataCM}
                refetch={refetch}
                setMensajero={setMensajero}
            />
            <IconButton
                onClick={handleClick}
                data-testid="dotMenu"
            >
                <MoreVertIcon />
            </IconButton>
            <Menu
                className="menuForm"
                id="basic-menu"
                anchorEl={anchorEO}
                open={menuOpen}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                <MenuItem onClick={Asignacion} disabled={readOnly || loadingCuenta}>{dataCM.idEventoHospitalario === row.id ? "Desasignar" : "Asignar"}</MenuItem>
                {/* <MenuItem onClick={click} disabled={disabled} >Editar</MenuItem> */}
                {/* <MenuItem onClick={click} disabled={disabled} >Eliminar</MenuItem> */}
                <MenuItem onClick={Apertura} disabled={readOnly}>{row.estado === 1 ? "Cerrar" : "Abrir"}</MenuItem>
            </Menu>
        </Grid>
    )
}