import { DialogActions, DialogContent, Grid, Typography } from "@mui/material";
import { CustomModal, CustomModalTitle } from "../../../components/Modal/customModal";
import { boldTitle, lineText, purpleLine } from "../../../assets/styles/Text/text";
import { SecondaryButton } from "../../../assets/styles/Button/Button";
import BotonCarga from "../../../components/Buttons/LoadingButton";
import { CustomInput } from "../../../assets/styles/Input/input";
import { useState } from "react";

type DatosUsuario = { id: number | null, rut: string | null, nombre: string | null, apellidoPaterno: string | null, apellidoMaterno: string | null }

interface Props {
  open: boolean;
  hancleOnClose: () => void;
  datosUsuario: DatosUsuario
  handleOnClickEmitir: (nota: string) => void;
  loading: boolean;
}

export const ModalEmitirLiquidada = ({ open, hancleOnClose, datosUsuario, handleOnClickEmitir, loading }: Props) => {
  const [note, setNote] = useState<string>("");
  return (
    <CustomModal
      fullWidth
      maxWidth="sm"
      open={open}
      sx={{
        "& .MuiBackdrop-root": {
          backgroundColor: "rgba(0, 0, 0, 0.3)",
        },
      }}
    >
      <CustomModalTitle>
        <Typography sx={boldTitle}>Emitir</Typography>
      </CustomModalTitle>

      <DialogContent dividers>
        <div style={{ textAlign: "center", paddingBottom: "16px" }}>
          <div style={{ marginTop: "24px" }}>
            <Typography sx={lineText}>
              ¿Estás seguro/a de emitir la cuenta{" "}
            </Typography>
            <Typography sx={purpleLine}> Nº{datosUsuario.id} </Typography>
            <Typography sx={lineText}> con el RUT</Typography> <br></br>
            <Typography sx={purpleLine}> {datosUsuario.rut}</Typography>
            <Typography sx={lineText}> a nombre de</Typography>
            <Typography sx={purpleLine}> {datosUsuario.nombre} {datosUsuario.apellidoPaterno} {datosUsuario.apellidoMaterno}</Typography>
            <Typography sx={lineText}> ?</Typography>
            <br></br>
          </div>
        </div>
        <Grid item xs={12} pt="16px">
          <label className="observacionBold">
            Nota resolutiva{" "}
            <label className="modalObservacion">(opcional)</label>
          </label>
          <CustomInput
            fullWidth
            multiline
            inputProps={{
              maxLength: 300,
            }}
            onChange={(e: any) => setNote(e.target.value)}
            value={note}
            disabled={loading}
          />
          <div className="contador">
            <label className="textoContador"></label>
            <label className="textoContador">{note.length}/300</label>
          </div>
        </Grid>
      </DialogContent>

      <DialogActions>
        <Grid container direction="row" justifyContent="space-between">
          <SecondaryButton
            color="primary"
            sx={{ p: "15px 41px" }}
            onClick={hancleOnClose}
            disabled={loading}
          >
            Cancelar
          </SecondaryButton>
          <BotonCarga
            testid="warningMC"
            toDo={()=>handleOnClickEmitir(note)}
            loading={loading}
            label={"Sí, Emitir"}
            sx={{ marginLeft: "24px", p: "15px 41px" }}
          />
        </Grid>
      </DialogActions>
    </CustomModal>
  );
};
