import { gql } from "@apollo/client";

export const PreexistenciasQuery = gql`
  query ($rutBeneficiario: String, $fechaInicioHospitalizacion: String) {
    obtenerPreexistencias(
      rutBeneficiario: $rutBeneficiario
      fechaInicioHospitalizacion: $fechaInicioHospitalizacion
    ) {
      causal
      fechaInicioExclusion
      fechaFinExclusion
      plazoExclusion
    }
  }
`;
