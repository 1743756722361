import { Box, Typography } from "@mui/material";
import { inProcessStatusStyle, pendingStatusStyle, notSendStatusStyle, rejectedStatusStyle, receivedStatusStyle, issuedStatusStyle, ObsoleteStatusStyle } from "../../../assets/styles/Box/statusHistorialResTable";

export const EtiquetaEstado = (state: string) => {
  let styleButtonState = {};
  switch (state) {
    case "Entregada":
      styleButtonState = receivedStatusStyle;
      break;
    case "Recepcionada":
      styleButtonState = receivedStatusStyle;
      break;
    case "En Proceso":
      styleButtonState = inProcessStatusStyle;
      break;
    case "Visación":
      styleButtonState = inProcessStatusStyle;
      break;
    case "Valorización":
      styleButtonState = inProcessStatusStyle;
      break;
    case "Bonificación":
      styleButtonState = inProcessStatusStyle;
      break;
    case "Liquidada":
      styleButtonState = inProcessStatusStyle;
      break;
    case "Pendiente":
      styleButtonState = pendingStatusStyle;
      break;
    case "Devuelta":
      styleButtonState = pendingStatusStyle;
      break;
    case "Emitida Manual":
      styleButtonState = inProcessStatusStyle;
      break;
    case "Emitida":
      styleButtonState = issuedStatusStyle;
      break;
    case "Rechazada":
      styleButtonState = rejectedStatusStyle;
      break;
    case "Anulada":
      styleButtonState = rejectedStatusStyle;
      break;
    case "No Vigente":
      styleButtonState = ObsoleteStatusStyle;
      break;
    default:
      styleButtonState = notSendStatusStyle;
      break;
  }

  return (
    <Box sx={styleButtonState}>
      <Typography variant="h6" fontSize="12px">
        {state}
      </Typography>
    </Box>
  );
};