
const regex = {
  emptyinput: /^$/,
  emptyinputNumber: /^(?:[a-zA-z]+(?:\.[a-zA-Z]+)?)?$/,
  numbers: /^[0-9]\B/,
  validacionSi: /^si$/i,
}

const getFilterList = (id: Number, listAux: any) =>
  listAux.filter((elem: any) => elem.profileId === id);

const validarRut = (rut: string) => {

  const rutCompleto = rut.replace(/\./g, '')
  const regex = /^[d0-9]+[-|-]{1}[0-9kK]{1}$/;
  if (!regex.test(rutCompleto)) {
    return false;
  }

  const tmp = rutCompleto.split("-");
  return validaDv(parseInt(tmp[0])).toString() === tmp[1].toLowerCase();
};

const validaDv = (dv: number) => {
  let M = 0, S = 1;
  for (; dv; dv = Math.floor(dv / 10))
    S = (S + (dv % 10) * (9 - (M++ % 6))) % 11;
  return S ? S - 1 : "k";
};

const formatRut = (rut: string) => {
  if (!rut) {
    return '';
  }
  let sRut;
  let sRutFormateado = '';
  let strRut = rut.replace(/^0+/, '');
  strRut = strRut.replace(/[-|.]/g, '');
  sRut = strRut;
  const sDV = sRut.charAt(sRut.length - 1);
  sRut = sRut.substring(0, sRut.length - 1);
  while (sRut.length > 3) {
    sRutFormateado = "." + sRut.substr(sRut.length - 3) + sRutFormateado;
    sRut = sRut.substring(0, sRut.length - 3);
  }
  sRutFormateado = sRut + sRutFormateado;
  sRutFormateado += "-" + sDV;
  return sRutFormateado;
}

const rutFormatNumber = (params: string) => {
  return params === (null || undefined) ? "" : params.replace(/[.]/g, '');
}

const numbersWithDots = (x: string) => {
  const pattern = /(-?\d+)(\d{3})/;
  while (pattern.test(x))
    x = x.replace(pattern, "$1.$2");
  return x;
}

const numbersWithOutDots = (number: string) => {
  return number.replace(/[.]/g, '')
}

const stringToNumber = (num: string) => {
  return Number(numbersWithOutDots(num))
}

// Devuelve true en caso de faltar un valor(null, vacio, 0) en el objeto, recibe array de claves que no se validarán (exceptions)
const hasEmptyValues = (values: object, exceptions: any) => {

  let result = false;
  for (const item of Object.entries(values)) {
    const key = item[0]
    const value = item[1]
    if ([0, "", null, undefined].includes(value) && !exceptions.includes(key)) {
      result = true;
      break;
    } else {
      result = false;
      continue;
    }
  }
  return result;
}

const openFromBase64 = (contentType: string, base64Str: string) => {
  const byteCharacters = window.atob(base64Str);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);
  const file = new Blob([byteArray], { type: contentType });
  const fileURL = URL.createObjectURL(file);
  window.open(fileURL);
}

const openExcelFromBase64 = (base64Str: string, fileName: string) => {
  const contentType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
  const linkSource = `data:${contentType};base64,${encodeURI(base64Str)}`;
  const downloadLink = document.createElement("a");
  downloadLink.href = linkSource;
  downloadLink.download = fileName;
  downloadLink.click();
}

const downloadFromBase64 = (contentType: String, base64Str: string, fileName: string) => {
  const linkSource = `data:${contentType};base64,${encodeURI(base64Str)}`;
  const downloadLink = document.createElement("a");
  downloadLink.href = linkSource;
  downloadLink.download = fileName;
  downloadLink.click();
}
const isNotUndefinedAndNull = (value: any) => {
  return (value !== null && value !== undefined);
}

const cutDateAndFormat = (date: string) => (date.replace(/ .+/, '').replace(/T.+/, '').replace(/-/g, '/'));

const formatoCompletoNombrePaciente = (nombre: string, apellidoPaternoBeneficiario: string | null, apellidoMaternoBeneficiario: string | null) => {
  let nombreCompleto = `${nombre || ""} ${apellidoPaternoBeneficiario || ""} ${apellidoMaternoBeneficiario || ""}`;
  return nombreCompleto
}

export {
  getFilterList,
  validarRut,
  formatRut,
  rutFormatNumber,
  numbersWithDots,
  hasEmptyValues,
  openFromBase64,
  downloadFromBase64,
  isNotUndefinedAndNull,
  cutDateAndFormat,
  stringToNumber,
  openExcelFromBase64,
  regex,
  formatoCompletoNombrePaciente
}
