const inProcessStatusStyle = {
    width: '95px',
    bgcolor: '#EEECFB',
    padding: '4px 8px',
    border: '1px solid #5143D5',
    borderRadius: '8px',
    textAlign: 'center'
}

const notSendStatusStyle = {
    width: '90px',
    bgcolor: '#EEECFB',
    padding: '4px 8px',
    border: '1px solid #5143D5',
    borderRadius: '8px',
    textAlign: 'center'
}

const receivedStatusStyle = {
    width: '95px',
    bgcolor: '#CCEDEB',
    padding: '4px 8px',
    border: '1px solid #01A49A',
    borderRadius: '8px',
    textAlign: 'center'
}

const pendingStatusStyle = {
    width: '75px',
    bgcolor: '#FFF8E1',
    padding: '4px 8px',
    border: '1px solid #FFB300',
    borderRadius: '8px',
    textAlign: 'center'
}

const sendingStatusStyle = {
    bgcolor: '#DCEDC8',
    padding: '4px 8px',
    border: '1px solid #33691E',
    borderRadius: '8px',
    textAlign: 'center'
}

const rejectedStatusStyle = {
    width: '82px',
    bgcolor: '#FFEBEE',
    padding: '4px 8px',
    border: '1px solid #E53935',
    borderRadius: '8px',
    textAlign: 'center'
}

const returnStatusStyle = {
    width: '67px',
    bgcolor: '#FFF8E1',
    padding: '4px 8px',
    border: '1px solid #FFB300',
    borderRadius: '8px',
    textAlign: 'center'
}

const liquidatedStatusStyle = {
    width: '72px',
    bgcolor: '#EEECFB',
    padding: '4px 8px',
    border: '1px solid #5143D5',
    borderRadius: '8px',
    textAlign: 'center'
}

const issuedStatusStyle = {
    width: '72px',
    bgcolor: '#F1F8E9',
    padding: '4px 8px',
    border: '1px solid #7CB342',
    borderRadius: '8px',
    textAlign: 'center'
}

const issuedManualStatusStyle = {
    width: '85px',
    bgcolor: '#EEECFB',
    padding: '4px 8px',
    border: '1px solid #5143D5',
    borderRadius: '8px',
    textAlign: 'center'
}

const ObsoleteStatusStyle = {
    width: '85px',
    bgcolor: '#F2F5F9',
    padding: '4px 8px',
    border: '1px solid #93999D',
    borderRadius: '8px',
    textAlign: 'center'
}

export {
    liquidatedStatusStyle,
    inProcessStatusStyle,
    pendingStatusStyle,
    returnStatusStyle,
    rejectedStatusStyle,
    notSendStatusStyle,
    receivedStatusStyle,
    sendingStatusStyle,
    issuedStatusStyle,
    issuedManualStatusStyle,
    ObsoleteStatusStyle
}