import { Box, Button, Grid, Typography, Divider, Menu, MenuItem } from "@mui/material";
import WhiteDoc from "../../../assets/icons/buttonEditIcon.svg";
import WhiteTrash from "../../../assets/icons/trashwhite.svg";
import Arrow from "../../../assets/icons/arrowMenu.svg";
import { useState } from "react";
export const title = {
  fontFamily: "Rubik",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "16px",
  lineHeight: "24px",
  letterSpacing: "0.0120588em",
  color: "#364855",
};

export const regis = {
  fontFamily: "Rubik",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "14px",
  lineHeight: "16px",
  letterSpacing: "0.0120588em",
  color: "#4D5D68",
  display: "inline",
};

export const BoxStyle = {
  backgroundColor: "#857BE2",
  borderRadius: "8px",
  width: "546px",

  margin: " 0 auto",
};
const LetterStyle = {
  fontFamily: "Interstate !important",
  fontSize: "16px !important",
  fontWeight: "700 !important",
  lineHeight: "20px !important",
  letterSpacing: "0.0021em  !important",
  textAlign: "center !important",
  color: "#FFFFFF !important",
};
interface Props {
  handleOnCLickEmitirCuentas?: () => void;
  count: number
  eliminarType?: boolean
  menu?: boolean
  loading: boolean
}
export const ButtonEdit = ({ handleOnCLickEmitirCuentas, count, eliminarType, menu, loading }: Props) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  return (
    <Box
      sx={{
        position: "fixed",
        alignItems: "center",
        textAlign: "center",
        display: "flex",
        top: "78%",
        left: "35%",
        zIndex: 100,
      }}
    >
      <Box sx={BoxStyle}>
        <Grid container>
          <Grid
            item
            xs={5}
            textAlign={"right"}
            sx={{ placeSelf: "center", float: "right" }}
          >
            <Typography sx={LetterStyle} paddingLeft="40%">
              Seleccionado: {count}
            </Typography>
          </Grid>
          <Grid xs={1} pt="10px" pb="10px">
            <Divider
              sx={{ borderColor: "#FFFFFF !important" }}
              orientation="vertical"
            />
          </Grid>
          {eliminarType ?
            <Grid xs={5}>
              <Button color="inherit" onClick={handleOnCLickEmitirCuentas} disabled={loading}>
                <img src={WhiteTrash} alt="Emitir Cuentas" />{" "}
                <Typography sx={LetterStyle} paddingLeft={"12px"}>
                  Eliminar
                </Typography>
              </Button>
            </Grid>
            : menu ?
              <>
                <Button
                  color="inherit"
                  onClick={(event: any) => setAnchorEl(event.currentTarget)}
                  disabled={loading}
                >

                  <Typography
                    paddingLeft={"12px"}
                    pr={"60px"}
                    sx={LetterStyle}
                  >
                    {" "}
                    Acciones
                  </Typography>
                  <img src={Arrow} alt="arrow" />
                </Button>
                <Menu
                  sx={{ zIndex: 100 }}
                  slotProps={{ paper: { sx: { width: 300 } } }}
                  anchorEl={anchorEl}
                  open={open}
                  onClose={() => setAnchorEl(null)}
                  className="menuFormAlt"
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                >
                  <MenuItem
                    className="menuFormAlt"
                    disabled={loading}
                    onClick={() => {
                      setAnchorEl(null);
                    }}
                  >
                    Eliminar
                  </MenuItem>
                  <MenuItem
                    className="menuFormAlt"
                    disabled={loading}
                    onClick={() => {
                      setAnchorEl(null);
                    }}
                  >
                    Añadir canasta
                  </MenuItem>
                </Menu>
              </> :
              <Grid xs={5}>
                <Button color="inherit" onClick={handleOnCLickEmitirCuentas} disabled={loading}>
                  <img src={WhiteDoc} alt="Emitir Cuentas" />{" "}
                  <Typography sx={LetterStyle} paddingLeft={"12px"}>
                    Emitir Cuentas
                  </Typography>
                </Button>
              </Grid>}
        </Grid>
      </Box>
    </Box>
  );
};