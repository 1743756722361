import { Typography } from "@mui/material"
import { WarningModalCustom } from "../../../../components/Modal/warningModalCustom"
import { useMutation } from "@apollo/client";
import { aperturaEventoHospitalario } from "../../../../services/administracionCM/eventoHospitalarioServices";

export const AperturaEvento = ({ open, close, row, refetch, setMensajero }: any) => {
    const [AperturaEventoHO, { loading }] = useMutation(aperturaEventoHospitalario);
    const texto = row.estado === 1 ? 'Cerrado' : 'Abierto';
    const textoModal = row.estado === 1 ? 'Cerrar' : 'Abrir';
    const apertura = () => {
        AperturaEventoHO({
            variables: {
                idEventoHospitalario: row.id
            },
            onCompleted: (res) => {
                if (res.aperturaEventoHospitalario.estado === 2) {
                    setMensajero(res.aperturaEventoHospitalario.mensaje, 'error');
                } else {
                    const btnRefetchCuenta = document.getElementById('btnRefetchCuenta');
                    if (btnRefetchCuenta) {
                        btnRefetchCuenta.click();
                    }
                    setMensajero(`El Evento ha sido ${texto} exitosamente`, 'success');
                    refetch();
                }
                close();
            },
            onError: () => setMensajero(`Ocurrió un error al ${textoModal} el evento. por favor, inténtalo de nuevo.`, 'error')
        })
    };

    return (
        <WarningModalCustom
            secondaryButton="Cancelar"
            primaryButton={`${textoModal} evento`}
            contentText={<Typography>¿Estás seguro de {textoModal} este evento hospitalario?</Typography>}
            title={`${textoModal} evento hospitalario`}
            onOpen={open}
            loading={loading}
            onConfirm={apertura}
            onClose={() => close()}
            loadingWidth={"120px"}
        />
    )
}