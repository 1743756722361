import { useMsal } from "@azure/msal-react";

export interface DataUser {
    email: String,
    nombre: String,
    rol: string[],
    id: String
}

export const useGetUser = () => {
    const { accounts } = useMsal();
    const emptyDataUser = {email: '', nombre: '', rol: [''], id: ''}

    if (accounts[0]?.name) {
        const { name, username, idTokenClaims, localAccountId } = accounts[0];
        const dataUser: DataUser = {
            email: username,
            nombre: name,
            rol: idTokenClaims?.roles !== undefined ? idTokenClaims.roles : [''],
            id: localAccountId
        }
        return dataUser;
    } else {
        return emptyDataUser
    }
}