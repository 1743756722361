import { Box, CssBaseline, Grid, Typography } from "@mui/material";
import login_illus from "../../assets/icons/login_illus.svg";

export const TextCard = ({ isLogin, isUnautorize, isNotFound }: any) => {
    return (
        <Box>
            <CssBaseline>
                <Grid container sx={{
                    marginTop: "6%",
                    marginLeft: '4%',
                    width: "86% ",
                    padding: "26px 0",
                    fontFamily: "Interstate",
                    fontStyle: "normal",
                    fontSize: "16px",
                    boxShadow: "0px 2px 5px rgba(201, 207, 227, 0.75)",
                    borderRadius: "8px",
                    background: "#FFFFFF",
                }}>
                    <Grid item xs={isUnautorize ? 5 : 7}>
                        <Typography sx={{
                            fontStyle: "normal",
                            fontSize: "40px",
                            letterSpacing: "0.0120588em",
                            color: "#00A499",
                            padding: "74px 0px 0px 74px",
                            fontFamily: 'Rubik',
                            fontWeight: "700",
                            lineHeight: "47px"
                        }}>
                            {isLogin ? "Te damos la bienvenida" : ""}
                            {isUnautorize ? "No estás autorizado" : ""}
                            {isNotFound ? "Error 404" : ""}
                        </Typography>
                        <Typography sx={{
                            fontFamily: 'Rubik',
                            fontStyle: "normal",
                            fontWeight: "400",
                            fontSize: "40px",
                            lineHeight: "47px",
                            letterSpacing: "0.0120588em",
                            color: "#4DBFB8",
                            padding: "0px 0px 10px 74px",
                        }}>
                            {isLogin ? "al sistema de Cuentas Médicas" : ""}
                            {isUnautorize ? "para visualizar esta página" : ""}
                            {isNotFound ? "Página no encontrada" : ""}
                        </Typography>
                        <Typography sx={{
                            fontFamily: 'Interstate',
                            fontStyle: "normal",
                            fontWeight: "400",
                            fontSize: "18px",
                            lineHeight: "28px",
                            letterSpacing: "0.0120588em",
                            color: "#203442",
                            padding: "10px 0px 10px 74px"
                        }}>
                            {isLogin ? "Aquí podrás ingresar y gestionar las cuentas médicas de nuestros beneficiarios." : ""}
                            {isUnautorize ? "La cuenta seleccionada no posee el permiso correspondiente." : ""}
                            {isNotFound ? "La URL a la que intentaste acceder no existe." : ""}
                        </Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <img style={{}} alt="imagenBienvenida" src={login_illus}></img>
                    </Grid>
                </Grid>
            </CssBaseline>
        </Box>
    );
}