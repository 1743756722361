import { useState } from "react";
import { useQuery } from "@apollo/client";
import { PreexistenciasQuery } from "../../../services/administracionCM/preexistenciasServices";
import { ModalPreexistencias } from "./ModalPreexistencias/modalPreexistencias";
import { IPreexistingCondition } from "../../../interfaces/preexistingCondition";
import BotonCarga from "../../../components/Buttons/LoadingButton";
import { CustomTooltip } from "../../../components/Tooltip/tooltipbs";

const PreExistingConditionsButton = ({ rut, fechaInicio }: any) => {
  const [
    showModalPreexistingConditionsModal,
    setShowModalPreexistingConditionsModal,
  ] = useState<boolean>(false);
  const [preexistingConditions, setPreexistingConditions] = useState<
    IPreexistingCondition[]
  >([]);

  const { loading } = useQuery(PreexistenciasQuery, {
    variables: {
      rutBeneficiario: rut,
      fechaInicioHospitalizacion:fechaInicio,
    },
    onCompleted: (data) => {
      setPreexistingConditions(
        data.obtenerPreexistencias ? data.obtenerPreexistencias : []
      );
    },
    onError: (err) => {
      console.log(err);
    },
  });

  const handleModalUpdate = () => {
    setShowModalPreexistingConditionsModal(
      !showModalPreexistingConditionsModal
    );
  };

  return (
    <>
      <CustomTooltip
        title="No hay preexistencias declaradas"
        arrow
        placement="bottom"
        data-testid="toastNoPreexistingConditions"
        disableHoverListener={
          preexistingConditions.length > 0 || loading || !rut
        }
      >
        <span>
          <BotonCarga
            testid="btnPreexistingConditions"
            toDo={handleModalUpdate}
            disabled={!rut || preexistingConditions.length === 0}
            loading={loading}
            type="secondary"
            label="Ver preexistencias"
            sx={{whiteSpace:"pre"}}
          />
        </span>
      </CustomTooltip>
      <ModalPreexistencias
        onOpen={showModalPreexistingConditionsModal}
        preexistingConditions={preexistingConditions}
        handleOnClose={handleModalUpdate}
      />
    </>
  );
};

export default PreExistingConditionsButton;
