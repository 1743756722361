import { useMutation } from "@apollo/client";
import { bonificarDocumentos, valorizarDocumentos } from "../../../../services/administracionCM/valorizacionService";
import BotonCarga from "../../../../components/Buttons/LoadingButton";
import { checkStatus } from "../../utils/checkStatus";

type inputBtnValo = {
    idDocumentos: number[],
    idCuentaMedica: number,
    refetch: Function,
    muestraMensaje: Function,
    setLockDocs: Function,
    valorizacionData: any[],
    isDisabled: boolean,
    isBonif: boolean,
    clean: () => void
}

export const AccionModulo = ({ idDocumentos, idCuentaMedica, refetch, muestraMensaje, setLockDocs, valorizacionData, isDisabled, isBonif, clean }: inputBtnValo) => {
    const [valorizar, { loading: loadingValo }] = useMutation(valorizarDocumentos, {
        onCompleted: () => {
            setLockDocs(false);
            muestraMensaje("Se han enviado a valorizar correctamente.", 'success');
            clean();
            refetch();
        },
        onError: (error: any) => {
            setLockDocs(false);
            muestraMensaje("Hubo un error al valorizar, intente nuevamente", 'error');
            checkStatus(error.graphQLErrors[0]?.code || "");
        }
    });
    const [bonificar, { loading: loadingBonif }] = useMutation(bonificarDocumentos, {
        onCompleted: () => {
            setLockDocs(false);
            muestraMensaje("Se han enviado a bonificar correctamente.", 'success');
            clean();
            refetch();
        },
        onError: (error: any) => {
            setLockDocs(false);
            muestraMensaje("Hubo un error al bonificar, intente nuevamente.", 'error');
            checkStatus(error.graphQLErrors[0]?.code || "");
        }
    });
    const onClick = () => {
        setLockDocs(true);
        if (isBonif) {
            bonificar({
                variables: {
                    idDocs: idDocumentos,
                    idCuentaMedica: idCuentaMedica
                }
            });
        } else {
            valorizar({
                variables: {
                    idDocumentos: idDocumentos,
                    idCuentaMedica: idCuentaMedica
                }
            });
        }

    }

    const validaDoc = () => {
        const docsValo = valorizacionData.filter((doc) =>
            idDocumentos.includes(doc.id) && (
                !doc.valorizarHabilitado ||
                doc.folioDocumento === 0 ||
                doc.estadoValorizacion === 52 ||
                doc.detallePrestaciones.contador === 0)
        );
        const docsBonif = valorizacionData.filter((doc) =>
            idDocumentos.includes(doc.id) && (
                doc.estadoBonificacion === 52
            )
        );
        if ((isBonif && docsBonif.length > 0) || docsValo.length > 0) {
            return true;
        } else {
            return false;
        }
    }
    return (
        <BotonCarga
            sx={{ p: "15px 16px" }}
            toDo={onClick}
            label={isBonif ? "Bonificar" : "Valorizar"}
            testid="valoBTN"
            loading={loadingValo || loadingBonif}
            disabled={idDocumentos.length === 0 || validaDoc() || isDisabled}
        />
    )
}