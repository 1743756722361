import { TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody, Grid } from "@mui/material";
import { NoResult } from "../../../../components/Tables/noResult";
import { verificaLargo } from "../../../../components/Tooltip/Tooltip";
import { parseMiles } from "../../../../utils/monedaUtils";
import { ForzarBonif } from "./forzarBonif";

export const TablaAutorizacion = ({
    dataTabla,
    tipoCobertura,
    tipoDoc,
    muestraMensaje,
    refetch,
    refetchDoc,
    disabled,
    isCuentaAuth,
    authFilter
}: any) => {
    return (
        <TableContainer className="tContainer-padding-0" component={Paper} sx={{ borderTop: "1px solid #E9EBEC", }}>
            <Table sx={{ minWidth: 'auto' }} aria-label="simple table">
                <TableHead className="color-head-table" >
                    <TableRow>
                        <TableCell className="headAccordion" align="center" width={"190px"}>Código Isapre</TableCell>
                        <TableCell className="headAccordion" align="center" width={"200px"}>Item Isapre</TableCell>
                        <TableCell className="headAccordion" align="center" width={"400px"}>Glosa Isapre</TableCell>
                        <TableCell className="headAccordion" align="center" width={"139px"}>Estado</TableCell>
                        <TableCell className="headAccordion" align="center" width={"400px"}>Código Prestador</TableCell>
                        <TableCell className="headAccordion" align="center" width={"139px"}>Cantidad de Prestaciones</TableCell>
                        <TableCell className="headAccordion" align="center" width={"400px"}>Monto Bonificado Plan</TableCell>
                        <TableCell className="headAccordion" align="center" width={"80px"}>Monto Bonificado Topado</TableCell>
                        <TableCell className="headAccordion" align="center" width={"100px"}>Monto Bonificado Forzado</TableCell>
                        <TableCell className="headAccordion" align="center" width={"160px"}>Monto Mínimo</TableCell>
                        <TableCell className="headAccordion" align="center" width={"139px"}>Monto Copago</TableCell>
                        <TableCell className="headAccordion" align="center" width={"400px"}>Diferencia Montos</TableCell>
                        <TableCell className="headerTableEspecial columnAccionAuth" align="center" width={"139px"}>Acciones</TableCell>
                    </TableRow>
                </TableHead>
                {dataTabla.length > 0 ?
                    <TableBody>
                        {dataTabla.map((pres: any) => {
                            return (
                                <TableRow key={pres.id} sx={{ backgroundColor: pres.beneficioComplementario ? "#E6F6F5 !important" : "" }}>
                                    <TableCell className="headAccordion" align="center">
                                        {pres.codigoPrestacionIsapre}
                                    </TableCell>
                                    <TableCell className="headAccordion" align="center">
                                        {pres.idItemIsapre}
                                    </TableCell>
                                    <TableCell className="headAccordion" align="center">
                                        {verificaLargo(pres.glosaPrestacionIsapre || "", 25)}
                                    </TableCell>
                                    <TableCell className="headAccordion" align="center">
                                        {pres.glosaEstadoAutorizacion || '-'}
                                    </TableCell>
                                    <TableCell className="headAccordion" align="center">
                                        {pres.codigoPrestacionPrestador || '-'}
                                    </TableCell>
                                    <TableCell className="headAccordion" align="center">
                                        {pres.frecuenciaAtencion}
                                    </TableCell>
                                    <TableCell className="headAccordion" align="center">
                                        {parseMiles(pres.montoBonificadoPlan || 0)}
                                    </TableCell>
                                    <TableCell className="headAccordion" align="center">
                                        {parseMiles(pres.montoBonificacionTopada || 0)}
                                    </TableCell>
                                    <TableCell className="headAccordion" align="center">
                                        {parseMiles(pres.montoBonificacionForzado || 0)}
                                    </TableCell>
                                    <TableCell className="headAccordion" align="center">
                                        {parseMiles(pres.montoBonificacionMinima || 0)}
                                    </TableCell>
                                    <TableCell className="headAccordion" align="center">
                                        {parseMiles(pres.montoCopago || 0)}
                                    </TableCell>
                                    <TableCell className="headAccordion" align="center">
                                        {parseMiles(pres.diferenciaForzadoMinimo || 0)}
                                    </TableCell>
                                    <TableCell className={"tableBodyEspecial columnAccionAuth"} align="center">
                                        <ForzarBonif
                                            row={pres}
                                            tipoCobertura={tipoCobertura}
                                            tipoDoc={tipoDoc}
                                            showMessage={muestraMensaje}
                                            refetch={refetch}
                                            refetchDoc={refetchDoc}
                                            disabled={disabled}
                                            montoForzado={pres.montoBonificacionForzado}
                                            isCuentaAuth={isCuentaAuth}
                                            authFilter={authFilter}
                                        />
                                    </TableCell>
                                </TableRow>)
                        })}
                    </TableBody> : null
                }
            </Table>
            {dataTabla.length === 0 ?
                <Grid item xs={12} pt={"32px"}>
                    <NoResult
                        noDivider={true}
                        newDontSearch
                        titulo={"No hay prestaciones Pte de autorización para visualizar"}
                        subtitulo=""
                        error={true}
                    />
                </Grid>
                : null}
        </TableContainer>
    )
}