import {
    DialogActions,
    DialogContent,
    Grid,
    Typography
} from "@mui/material";
import { CustomModal, CustomModalTitle } from "../../../components/Modal/customModal";
import { boldTitle } from "../../../assets/styles/Text/text";
import { SecondaryButton } from "../../../components/Buttons/Button";
import { TableHistorial } from "./tableHistorial";

export const ModalHistorial = ({ open, onClose, row }: any) => {
    return (
        <CustomModal
            fullWidth
            maxWidth="lg"
            open={open}
            onClose={onClose}
            sx={{
                "& .MuiBackdrop-root": {
                    backgroundColor: "rgba(0, 0, 0, 0.3)",
                },
            }}
        >
            <CustomModalTitle>
                <Typography sx={boldTitle}>Historial resolutivo</Typography>
                <Typography className="modalText">
                    Aquí puedes ver todos los cambios realizados a la cuenta.
                </Typography>
            </CustomModalTitle>

            <DialogContent dividers sx={{ textAlign: "center" }}>
                <Grid container textAlign={"left"} pb="16px">
                    <Grid item xs={3}>
                        <Typography className='purpleLittle'  >
                            NºCuenta
                        </Typography>
                        <Typography className='blackType'  >
                            {row.id}
                        </Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography className='purpleLittle'    >
                            Nº C. Extranet
                        </Typography>
                        <Typography className='blackType'  >
                            {row.idCMD}
                        </Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography className='purpleLittle'    >
                            Nº C. Prestador
                        </Typography>
                        <Typography className='blackType'  >
                            {row.prestador.numeroCuenta}
                        </Typography>
                    </Grid>
                </Grid>
                <TableHistorial open={open} row={row}/>
            </DialogContent>

            <DialogActions>
                <Grid container direction="row" justifyContent="end">
                    <SecondaryButton
                        color="primary"
                        sx={{ p: "15px 41px" }}
                        onClick={onClose}>
                        Cerrar
                    </SecondaryButton>

                </Grid>
            </DialogActions>
        </CustomModal>
    );
};
