import { DialogActions, DialogContent, Grid, Typography } from "@mui/material";
import { CustomModal, CustomModalTitle } from "../../../../components/Modal/customModal";
import { boldTitle } from "../../../../assets/styles/Text/text";
import { SecondaryButton } from "../../../../assets/styles/Button/Button";
import { TablePreexistencias } from "./tablePreexistencias";
import { IPreexistingCondition } from "../../../../interfaces/preexistingCondition";

interface IModalPreexistenciasProps {
  onOpen: boolean;
  handleOnClose: () => void;
  preexistingConditions: IPreexistingCondition[];
}

export const ModalPreexistencias = ({
  onOpen,
  handleOnClose,
  preexistingConditions,
}: IModalPreexistenciasProps) => {
  return (
    <CustomModal
      fullWidth
      maxWidth="md"
      open={onOpen}
      onClose={handleOnClose}
      sx={{
        "& .MuiBackdrop-root": {
          backgroundColor: "rgba(0, 0, 0, 0.3)",
        },
      }}
    >
      <CustomModalTitle>
        <Typography sx={boldTitle}>Preexistencias</Typography>
        <Typography className="modalText">
          Aquí puedes ver el detalle de las preexistencias declaradas de un
          beneficiario.
        </Typography>
      </CustomModalTitle>

      <DialogContent dividers sx={{ textAlign: "center" }}>
        <TablePreexistencias preexistingConditions={preexistingConditions} />
      </DialogContent>

      <DialogActions>
        <Grid container direction="row" justifyContent="end">
          <SecondaryButton
            color="primary"
            sx={{ p: "15px 52px" }}
            onClick={handleOnClose}
          >
            Cerrar
          </SecondaryButton>
        </Grid>
      </DialogActions>
    </CustomModal>
  );
};
