import { useState } from "react";
import { TableBody, TableRow, TableCell, Button, CircularProgress } from "@mui/material";
import LaunchIcon from '@mui/icons-material/Launch';
import { NormalModal } from "../../../../components/Modal/normalModal";
import { queryDeleteDocumento, queryGetBase64File } from "../../../../services/administracionCM/loadFIlesServices";
import { useLazyQuery } from "@apollo/client";
import { openFromBase64 } from "../../../../utils";
import { checkStatus } from "../../utils/checkStatus";

function LoadFilesTable(props: any) {
  const { data, setData, revisarCuentaMedica } = props;
  const [openDeleteDocModal, setDeleteDocModal] = useState<boolean>(false);
  const [fileSelect, setFileSelected] = useState<number>(0);
  const [msjModal, setmsjModal] = useState({ title: "", content: "" });
  const [loadingFile, setLoadingFile] = useState<number[]>([]);

  const [deleteFile] = useLazyQuery(
    queryDeleteDocumento
  );

  const [getFile] = useLazyQuery(
    queryGetBase64File
  );

  const deleteDocumentData = (id: number) => {
    setData(data.filter((item: any) => item.id !== id));
  };

  const handleDeleteFile = (id: number) => {
    deleteFile({
      variables: { id }
    })
      .then((resp) => {
        deleteDocumentData(id);
        setDeleteDocModal(false);
      })
      .catch((err) => {
        console.error(err);
        setmsjModal({ title: "Hubo un error al eliminar el Documento", content: "Por favor, Inténtelo nuevamente." });
        setDeleteDocModal(true);
        checkStatus(err.graphQLErrors[0].code);
      })
  };

  const handleClickViewDocument = (document: any) => {
    setLoadingFile((prev:number[]) => ([...prev, document.id]));
    getFile({ variables: { idDocumento: document.idSharePoint } })
      .then(resp => {
        if (resp.data.obtenerDocumento) {
          const base64String = resp.data.obtenerDocumento.contenidoDelArchivo;

          const MATCH_ALLOWED_TYPES: any = {
            '/': 'image/jpeg',
            'i': 'image/png',
            'J': 'application/pdf'
          };

          const type = MATCH_ALLOWED_TYPES[base64String[0]];
          openFromBase64(type, base64String);

          setLoadingFile((prev:number[]) => prev.filter((val:any) =>(val !== document.id)));
        } else {
          setLoadingFile((prev:number[]) => prev.filter((val:any) => (val !== document.id)));
          throw new Error('No se pudo obtener el archivo')
        }
      })
      .catch(err => {
        setLoadingFile((prev:number[]) => prev.filter((val:any) => (val !== document.id)));
        console.error(err)
      })
  };
  
  return (
    <>
      <NormalModal
        open={openDeleteDocModal}
        onClose={() => setDeleteDocModal(false)}
        title={msjModal.title}
        content={msjModal.content}
        primaryButton={{
          text: "Eliminar Documento",
          onClick: () => handleDeleteFile(fileSelect),
        }}
        secondaryButton={{
          text: "Cancelar",
          onClick: () => setDeleteDocModal(false),
        }}
      />

      <TableBody>
        {data.map((row: any) => (
          <TableRow key={row.id} hover={true}>
            <TableCell>{row.folioDocumento}</TableCell>
            <TableCell>{row.nombreDocumento}</TableCell>
            <TableCell>{row.tipoDocumento}</TableCell>
            <TableCell>
              <Button
                variant="contained"
                className="medium-button"
                startIcon={loadingFile.includes(row.id) ? <CircularProgress size="24px" color="primary"/> : <LaunchIcon />}
                onClick={() => handleClickViewDocument(row)}
                disabled={loadingFile.includes(row.id)}
              >
                Ver
              </Button>
            </TableCell>
            <TableCell>
              <Button
                variant="outlined"
                className="medium-button"
                onClick={() => {
                  setmsjModal({ title: "Eliminar Documento", content: "¿Estás seguro/a que deseas eliminar este documento ?" });
                  setDeleteDocModal(true);
                  setFileSelected(row.id);
                }}
                disabled={revisarCuentaMedica}
              >
                Eliminar
              </Button>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </>
  );
}

export default LoadFilesTable;
