import { isBoolean } from "lodash";

export const parseData = (dataCM) => {
    let parsedData = JSON.parse(JSON.stringify(dataCM));
    if (parsedData.cuentaComplementaria) {
        if (parsedData.cuentaComplementaria === "true") {
            parsedData.cuentaComplementaria = "S";
        } else {
            parsedData.cuentaComplementaria = "N";
        }
    }
    if (parsedData.datosBeneficiario && parsedData.datosBeneficiario.diagnosticoPrimario && parsedData.datosBeneficiario.diagnosticoPrimario.codigo) {
        parsedData.datosBeneficiario.diagnosticoPrimario.codigo = parsedData.datosBeneficiario.diagnosticoPrimario.codigo.trim();
    }
    if (parsedData.datosBeneficiario && parsedData.datosBeneficiario.diagnosticoSecundario && parsedData.datosBeneficiario.diagnosticoSecundario.codigo) {
        parsedData.datosBeneficiario.diagnosticoSecundario.codigo = parsedData.datosBeneficiario.diagnosticoSecundario.codigo.trim();
    }
    if (isBoolean(parsedData.declaracionPreexistencia)) {
        if (parsedData.declaracionPreexistencia) {
            parsedData.declaracionPreexistencia = "S";
        } else {
            parsedData.declaracionPreexistencia = "N";
        }
    }
    if (parsedData.fechaCobro.fechaInicio) {
        parsedData.fechaCobro.fechaInicio = new Date(parsedData.fechaCobro.fechaInicio)
    }
    if (parsedData.fechaCobro.fechaTermino) {
        parsedData.fechaCobro.fechaTermino = new Date(parsedData.fechaCobro.fechaTermino)
    }
    if (parsedData.fechaHospitalizacion.fechaInicio) {
        parsedData.fechaHospitalizacion.fechaInicio = new Date(parsedData.fechaHospitalizacion.fechaInicio + " " + parsedData.fechaHospitalizacion.horaInicio.replace(' ', ''));
    }
    if (parsedData.fechaHospitalizacion.fechaEgreso) {
        parsedData.fechaHospitalizacion.fechaEgreso = new Date(parsedData.fechaHospitalizacion.fechaEgreso + " " + parsedData.fechaHospitalizacion.horaEgreso.replace(' ', ''));
    }
    if (parsedData.fechaRecepcion) {
        parsedData.fechaRecepcion = new Date(parsedData.fechaRecepcion)
    }
    if (parsedData.fechaProbableLiquidacion) {
        const [year, month, day] = dataCM.fechaProbableLiquidacion.split("/");
        parsedData.fechaProbableLiquidacion = `${day}-${month}-${year}`;
    }
    if (parsedData.graduacionParto) {
        parsedData.graduacionParto = new Date(parsedData.graduacionParto)
    }
    if (isBoolean(parsedData.restriccionCobertura)) {
        if (parsedData.restriccionCobertura) {
            parsedData.restriccionCobertura = "S";
        } else {
            parsedData.restriccionCobertura = "N";
        }
    }

    return parsedData;
}