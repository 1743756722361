import { useState, useEffect } from "react";
import { Box, Button, Collapse, Stack, Typography } from "@mui/material";
import { formLabel, messageAlertFull } from "../../../assets/styles/Box/boxs";
import { LocalizationProvider } from "@mui/x-date-pickers";
import DataPrestador from "./dataPrestador/dataPrestador";
import DataBeneficiario from "./dataBeneficiario/dataBeneficiario";
import DataFechaCobro from "./dataFechaCobro/dataFechaCobro";
import DataCuentaMedica from "./dataCuentaMedica/dataCuentaMedica";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import es from "date-fns/locale/es";
import { mutationIngresarCuentaMedica, setCuentaMedicanQuery, verificaDupe } from "../../../services/administracionCM/administracionCMServices";
import { useLazyQuery, useMutation } from "@apollo/client";
import { NormalModal } from "../../../components/Modal/normalModal";
import alert from '../../../assets/icons/alert-message.svg';
import { formTitleFont, modalTextBold } from "../../../assets/styles/Text/text";
import { format } from "date-fns";
import { useNavigate } from "react-router-dom";
import { WarningModalCustom } from "../../../components/Modal/warningModalCustom";
import { checkStatus } from "../utils/checkStatus";

const TabcuentaMedica = ({ dataCM, isDataSaved, createMsg, refreshCuentaMedica, setDataCM, readOnly }: any) => {
  const navigate = useNavigate();
  const [isDisable, setisDisable] = useState(true);
  const [lockDupe, setLockDupe] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [isDupe, setIsDupe] = useState<any>(null);
  const [dupeCheck, setDupeCheck] = useState<any>(dataCM && dataCM.idCuentaMedica ? true : null);
  const [openWarning, setOpenWarning] = useState(false);
  const [modalConfirmacionGuardarFormulario, setModalConfirmacionGuardarFormulario] = useState<boolean>(false);
  const [modalVigenciaNoValida, setModalVigenciaNoValida] = useState<boolean>(false)
  const [contentVigenciaNoValida, setContentVigenciaNoValida] = useState<string>("")
  const [fnsetCuentaMedicanQuery, { loading: loadingActualizarCuentaMedica }] = useMutation(setCuentaMedicanQuery);
  const [fnCreateCuentaMedicanQuery, { loading: loadingCrearCuentaMedica }] = useMutation(mutationIngresarCuentaMedica, { fetchPolicy: "no-cache" });
  const [findDupes] = useLazyQuery(verificaDupe, {
    onCompleted: (res) => {
      setDupeCheck(true);
      if (res.verificaCuentaCobroCM.idCuenta !== dataCM.idCuentaMedica) {
        if (res.verificaCuentaCobroCM.status !== 0) {
          setIsDupe(true);
          setOpenWarning(true);
          if (res.verificaCuentaCobroCM.status === 2) {
            setIsError(true);
            setLockDupe(true);
          } else {
            setIsError(false);
            setLockDupe(false);
          }
        } else {
          setIsDupe(false);
          setIsError(false);
          setLockDupe(false);
        }
      } else {
        setIsDupe(false);
        setIsError(false);
        setLockDupe(false);
      }
    }
  });
  const handleSave = () => {
    setModalConfirmacionGuardarFormulario(true);
  }

  const confirmarCambios = () => {
    crearRequestSave();
  }

  const handleOnCloseVigenciaNoValida = () => {
    setContentVigenciaNoValida("")
    setModalVigenciaNoValida(false)
  }

  const crearRequestSave = async () => {
    let request = creaObjetReq();
    if (dataCM.idCuentaMedica) {
      await fnsetCuentaMedicanQuery({ variables: { id: Number(dataCM.idCuentaMedica), antecedentes: request } })
        .then(({ data }: any) => {
          setModalConfirmacionGuardarFormulario(false);
          const { estado, codigo, mensaje } = data.setCuentaMedica.respuesta
          if (estado === 1 && codigo === "BENEFICIARIO_NO_VIGENTE") {
            // validación fallida vigencia beneficiario
            setModalVigenciaNoValida(true)
            setContentVigenciaNoValida(mensaje)
          }
          if (estado === 0) {
            createMsg('success', "Los cambios en el formulario han sido guardados correctamente.");
            refreshCuentaMedica(undefined);
          }
        })
        .catch((err) => {
          setModalConfirmacionGuardarFormulario(false);
          console.error("fnsetCuentaMedicanQuery" + err);
          createMsg('error', "Ha ocurrido un error al intentar guardar los cambios en el formulario. Por favor, inténtelo de nuevo.");
          checkStatus(err.graphQLErrors[0].code);
        });
    } else {
      fnCreateCuentaMedicanQuery({ variables: { cuentaMedica: request } })
        .then(async (response) => {
          setModalConfirmacionGuardarFormulario(false);
          if (response.data.ingresarCuentaMedica !== null) {
            const { id } = response.data.ingresarCuentaMedica;
            const { estado, codigo, mensaje } = response.data.ingresarCuentaMedica.respuesta
            if (estado === 0) {
              navigate(`/Escritorio/AdministracionCM`, {
                state: {
                  idCuentaMedica: id,
                  ...dataCM
                }
              });
              setDataCM({ ...dataCM, idCuentaMedica: id });
              createMsg('success', "Los cambios en el formulario han sido guardados correctamente.");
              refreshCuentaMedica(id);
            }

            if (estado === 1 && codigo === "BENEFICIARIO_NO_VIGENTE") {
              setModalVigenciaNoValida(true)
              setContentVigenciaNoValida(mensaje)
            }
          }
        })
        .catch((err) => {
          setModalConfirmacionGuardarFormulario(false);
          console.error("fnInsertCuentaMedicanQuery" + err);
          createMsg('error', "Ha ocurrido un error al intentar guardar los cambios en el formulario. Por favor, inténtelo de nuevo.");
        });
    }
  }

  const creaObjetReq = () => {
    let fechaprob: string | null = null;
    if (dataCM.fechaProbableLiquidacion) {
      const [day, month, year] = dataCM.fechaProbableLiquidacion.split("-");
      fechaprob = `${year}-${month}-${day}`;
    }
    const { id, numero, version, fechaInicioVigencia, fechaTerminoVigencia, codigoPlanSalud, glosaPlanSalud } = dataCM.poliza || null;
    const response = {
      rutBeneficiario: dataCM.datosBeneficiario.rut.replace(/[^0-9kK-]/g, ""),
      nombreBeneficiario: dataCM.datosBeneficiario.nombre,
      apellidoPaternoBeneficiario: dataCM.datosBeneficiario.apellidoPaterno,
      apellidoMaternoBeneficiario: dataCM.datosBeneficiario.apellidoMaterno,

      cie10Primario: dataCM.datosBeneficiario.diagnosticoPrimario.codigo,
      descCie10Primario: dataCM.datosBeneficiario.diagnosticoPrimario.descripcion,
      cie10Secundario: dataCM.datosBeneficiario.diagnosticoSecundario.codigo,
      descCie10Secundario: dataCM.datosBeneficiario.diagnosticoSecundario.descripcion,

      diagnosticoMedico: dataCM.datosBeneficiario.diagnosticoMedico,

      rutMedicoTratante: dataCM.datosPrestador.rutMedicoTratante ? dataCM.datosPrestador.rutMedicoTratante.replace(/[^0-9kK-]/g, "") : null,
      nombreMedicoTratante: dataCM.datosPrestador.nombreMedicoTratante,

      rutPrestador: dataCM.datosPrestador.rutPrestador.replace(/[^0-9kK-]/g, ""),
      numeroCuenta: dataCM.datosPrestador.numeroCuenta,
      numeroCobro: dataCM.datosPrestador.numeroCobro.toString(),
      razonSocial: dataCM.datosPrestador.razonSocial,
      tieneConvenio: false,
      canalOrigen: dataCM.canalOrigen,

      codigoPrestacionPrincipalPrestador: dataCM.prestacionPrincipal ? dataCM.prestacionPrincipal.codigo : null,
      descripcionPrestacionPrincipalPrestador: dataCM.prestacionPrincipal ? dataCM.prestacionPrincipal.descripcion : null,
      fechaRecepcion: dataCM.fechaRecepcion ? format(dataCM.fechaRecepcion!, "yyyy-MM-dd") : null,
      fechaProbableLiquidacion: fechaprob,

      fechaInicioHospitalizacion: format(dataCM.fechaHospitalizacion.fechaInicio!, "yyyy-MM-dd"),
      fechaEgresoHospitalizacion: dataCM.fechaHospitalizacion.fechaEgreso ? format(dataCM.fechaHospitalizacion.fechaEgreso, "yyyy-MM-dd") : null,

      horaInicioHospitalizacion: format(dataCM.fechaHospitalizacion.fechaInicio!, "HH : mm"),
      horaEgresoHospitalizacion: dataCM.fechaHospitalizacion.fechaEgreso ? format(dataCM.fechaHospitalizacion.fechaEgreso, "HH : mm") : null,
      condicionAltaPaciente: dataCM.fechaHospitalizacion.condicionEgreso,

      fechaInicioCobro: format(dataCM.fechaCobro.fechaInicio!, "yyyy-MM-dd"),
      fechaTerminoCobro: format(dataCM.fechaCobro.fechaTermino!, "yyyy-MM-dd"),
      montoTotalCobro: parseInt(dataCM.montoCobro),

      tipoCobertura: dataCM.tipoCobertura.active.toString(),
      tipoRegimen: dataCM.tipoRegimen.active.toString(),
      tipoHabitacion: dataCM.tipoHabitacion.active.toString(),
      tipoAtencion: dataCM.tipoAtencion,

      numeroIntervenciones: parseInt(dataCM.cantidadIntervenciones),
      graduacionDeParto: dataCM.graduacionParto ? format(dataCM.graduacionParto!, "yyyy-MM-dd") : null,


      leyUrgenciaVital: dataCM.leyUrgencia,
      esCuentaComplementaria: dataCM.cuentaComplementaria === "S",
      soap: dataCM.soap,
      esCuentaPaquetizada: null,

      declaracionPreexistencia: dataCM.declaracionPreexistencia === "S",
      restriccionCobertura: dataCM.restriccionCobertura === "S",
      tipoCuentaMedica: parseInt(dataCM.tipoCuentaMedica),

      idPoliza: dataCM.poliza.id,
      poliza: dataCM.poliza.id ? {
        id,
        numero,
        version,
        fechaInicioVigencia,
        fechaTerminoVigencia,
        codigoPlanSalud,
        glosaPlanSalud
      } : null,

      reliquidacion: dataCM.reliquidacion === "S",
      cuentaReliquidacionAsociada: dataCM.cuentaReliquidacionAsociada,
    }
    return response;
  }

  useEffect(() => {
    if (dataCM) {
      if (!dataCM.datosPrestador.rutPrestador ||
        !dataCM.datosPrestador.razonSocial ||
        !dataCM.datosPrestador.numeroCuenta ||
        !dataCM.datosPrestador.numeroCobro ||
        !dataCM.datosBeneficiario.rut ||
        !dataCM.datosBeneficiario.nombre ||
        !dataCM.datosBeneficiario.diagnosticoPrimario ||
        !dataCM.datosBeneficiario.diagnosticoPrimario.codigo ||
        !dataCM.datosBeneficiario.diagnosticoSecundario ||
        !dataCM.datosBeneficiario.diagnosticoSecundario.codigo ||
        !dataCM.fechaHospitalizacion.fechaInicio ||
        (dataCM.fechaHospitalizacion.fechaEgreso &&
          (!dataCM.fechaHospitalizacion.condicionEgreso ||
            dataCM.fechaHospitalizacion.fechaInicio >
            dataCM.fechaHospitalizacion.fechaEgreso)) ||
        !dataCM.fechaProbableLiquidacion ||
        !dataCM.fechaCobro.fechaInicio ||
        !dataCM.fechaCobro.fechaTermino ||
        !dataCM.tipoCobertura.active ||
        !dataCM.tipoRegimen.active ||
        !dataCM.tipoHabitacion.active ||
        !(Number(dataCM.cantidadIntervenciones) >= 0) ||
        !dataCM.montoCobro ||
        !dataCM.leyUrgencia ||
        !dataCM.tipoAtencion ||
        !dataCM.cuentaComplementaria ||
        !dataCM.soap ||
        !dataCM.tipoCuentaMedica ||
        !dataCM.declaracionPreexistencia ||
        !dataCM.restriccionCobertura) {
        setisDisable(true);
      } else {
        setTimeout(() => setisDisable(false), 1000);
      }
    }
  }, [dataCM]);

  return (
    <>
      {modalVigenciaNoValida && (
        <NormalModal
          open={modalVigenciaNoValida}
          onClose={handleOnCloseVigenciaNoValida}
          title="Vigencia del beneficiario No Valida"
          content={contentVigenciaNoValida}
          primaryButton={{
            text: "Entendido",
            onClick: handleOnCloseVigenciaNoValida
          }}
        />
      )}

      <WarningModalCustom
        secondaryButton="Cancelar"
        primaryButton="Guardar"
        contentText={
          <>
            <br/>
            <Typography sx={modalTextBold} >
              ¿Estás seguro de guardar estos cambios?
            </Typography>
          </>
        }
        mensajeAlert={dataCM.fechaHospitalizacion.fechaEgreso ? undefined: `Estás dejando la cuenta ${dataCM.idCuentaMedica || ""} sin fecha, hora y condición de egreso`}
        title="Guardar antecedentes generales"
        disabled={!dupeCheck || isDisable}
        onOpen={modalConfirmacionGuardarFormulario}
        onConfirm={confirmarCambios}
        onClose={() => setModalConfirmacionGuardarFormulario(false)}
        loading={loadingCrearCuentaMedica || loadingActualizarCuentaMedica}
      />
      <Collapse in={isDisable}>
        <Box
          width={"89.5%"} ml={"4.5%"}
          display="flex"
          alignItems="flex-start"
          mb="16px"
          sx={messageAlertFull}
        >
          <Box height="20px" mr="18px" mt="6px" sx={{ bgcolor: "#FFFFFF", borderRadius: '100%' }}>
            <img height="20px" src={alert} alt="alert"></img>
          </Box>
          <Box>
            <Typography variant="h5" fontSize="12px" display={'inline'}>Deben completarse todos los campos </Typography>
            <Typography variant="h5" fontSize="12px" fontWeight={700} display={'inline'}>obligatorios*  </Typography>
            <Typography variant="h5" fontSize="12px" display={'inline'}>para </Typography>
            <Typography variant="h5" fontSize="12px" fontWeight={700} display={'inline'}> guardar cambios</Typography>
            <Typography variant="h5" fontSize="12px" display={'inline'}>{dataCM.canalOrigen === "CMD" ? ", debes seleccionar la cobertura para visualizar las prestaciones en módulo de valorización." : "."}</Typography>
          </Box>
        </Box>
      </Collapse>
      <Stack sx={formLabel} width={"89.5%"} ml={"4.5%"} direction={"row"} justifyContent={'space-between'} alignItems={'center'}>
        <Typography sx={formTitleFont}>Formulario</Typography>
        <Button
          variant="contained"
          sx={{ backgroundColor: '#4DBFB8' }}
          onClick={() => { handleSave() }}
          disabled={isDisable || readOnly || isDataSaved || lockDupe || !dupeCheck}
        >
          Guardar Cambios
        </Button>
      </Stack>
      <DataPrestador
        dataCM={dataCM}
        setDataCM={setDataCM}
        readOnly={readOnly}
        setLockDupe={setLockDupe}
        findDupes={findDupes}
        setDupeCheck={setDupeCheck}
        isError={isError}
        isDupe={isDupe}
        setIsDupe={setIsDupe}
        openWarning={openWarning}
        setOpenWarning={setOpenWarning}
      />
      <DataBeneficiario
        dataCM={dataCM}
        setDataCM={setDataCM}
        readOnly={readOnly || lockDupe}
        findDupes={findDupes}
        setDupeCheck={setDupeCheck}
      />
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={es}>
        <DataCuentaMedica dataCM={dataCM} setDataCM={setDataCM} readOnly={readOnly || lockDupe} />
        <DataFechaCobro dataCM={dataCM} setDataCM={setDataCM} readOnly={readOnly || lockDupe} />
      </LocalizationProvider>
    </>
  );
}

export default TabcuentaMedica;