import Modal from "@mui/material/Modal";
import { Box } from "@mui/material";
import { Divider, Typography } from "@mui/material";
import {
  modalAsignarme,
  modalAsignarmeBotones
} from "../../../../assets/styles/Modal/modal";
import { SecondaryButton } from "../../../../assets/styles/Button/Button";
import BotonCarga from "../../../../components/Buttons/LoadingButton";

function ModalAssignMe({ cuentaMedica, open, handleOnClose, handleOnClickAsignarme, loading }: any) {

  return (
    <Modal
      className="background-modal"
      open={open}
    >
      <Box sx={modalAsignarme}>
        <Box sx={{ padding: "32px 32px" }}>
          <Typography variant="h3">
            Asignación de la Cuenta Médica {cuentaMedica.id}{cuentaMedica.idCMD && ` - ${cuentaMedica.idCMD}`}
          </Typography>
          <Typography variant="h6" sx={{ paddingTop: "16px" }}>
            ¿Estás seguro/a de asignarte esta Cuenta Médica?
          </Typography>
        </Box>

        <Divider></Divider>

        <Box sx={modalAsignarmeBotones}>
          <SecondaryButton
            sx={{ width: "153px", height: "48px" }}
            disabled={loading}
            onClick={handleOnClose}
          >
            Cancelar
          </SecondaryButton>
          <BotonCarga
            testid="warningMC"
            toDo={handleOnClickAsignarme}
            loading={loading}
            label={"Asignarme el caso"}
            sx={{ width: "188px" }}
          />
        </Box>
      </Box>
    </Modal>
  );
}

export default ModalAssignMe;
