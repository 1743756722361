import React from "react";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../authConfig";
import { Button } from "@mui/material";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';


export const SignInButton = () => {

const { instance } = useMsal();


const handleLogin = (instance : any) => {
    instance.loginRedirect(loginRequest)
    .then((e : any)=>{
    }) 
    .catch((e : any) => {
        console.error(e);
    });
}
    return (       
        <Button 
            variant="contained"
            onClick={() => handleLogin(instance) }
            startIcon={<AccountCircleIcon />}
            sx={{ boxShadow:'none'}}
            disableRipple>
            Iniciar Sesión
        </Button>
    );
}