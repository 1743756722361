import {
    DialogActions,
    DialogContent,
    Grid,
    Typography,
} from "@mui/material";
import icon_warningOrange from "../../assets/icons/icon_warningOrange.svg";
import infoSize from "../../assets/icons/infoSize.svg";
import checkBig from "../../assets/icons/checkBig.svg";
import iconError from "../../assets/icons/errorModalIcon.svg";
import { CustomModal, CustomModalTitle } from "./customModal";
import { modalText, modalTitle } from "../../assets/styles/Text/text";
import { SecondaryButton } from "../../assets/styles/Button/Button";
import BotonCarga from "../Buttons/LoadingButton";
import Aviso from "../Alert/Aviso";

type WarningModalProps = {
    onOpen: boolean;
    onClose: () => void;
    onConfirm?: () => void;
    disabled?: boolean;
    title: string;
    subTitle?: any;
    primaryButton: string;
    secondaryButton?: string;
    loading?: boolean;
    noSecondAction?: boolean;
    headWarning?: boolean;
    contentText?: React.JSX.Element;
    loadingWidth?: string;
    errorModal?: boolean;
    separateButtons?: boolean;
    isSuccess?: any
    mensajeAlert?: string
    noIcon?: boolean
    isInfo?: boolean
};

export const WarningModalCustom = ({
    onOpen,
    onClose,
    onConfirm,
    disabled,
    title,
    subTitle,
    primaryButton,
    secondaryButton,
    noSecondAction,
    loading,
    headWarning,
    contentText,
    loadingWidth,
    errorModal,
    separateButtons,
    isSuccess,
    mensajeAlert,
    noIcon,
    isInfo
}: WarningModalProps) => {
    const iconIf = isInfo ? infoSize : icon_warningOrange;
    return (
        <CustomModal
            fullWidth
            maxWidth="sm"
            open={onOpen}
            data-testid="customModal"
            sx={{
                "& .MuiBackdrop-root": {
                    backgroundColor: "rgba(0, 0, 0, 0.3)",
                },
            }}
        >
            {headWarning ? (
                <>
                    <CustomModalTitle>
                        <Typography sx={modalTitle} paddingTop="16px">
                            {title}
                        </Typography>
                    </CustomModalTitle>

                    <DialogContent
                        dividers
                        sx={{ textAlign: "center", pl: "24px", pr: "24px" }}
                    >
                        {errorModal ? (
                            <img
                                alt="error"
                                src={iconError}
                                style={{ marginBottom: "16px" }}
                            />
                        ) :
                            <img
                                alt="alert"
                                src={icon_warningOrange}
                                style={{ marginBottom: "16px" }}
                            />}

                        <Typography sx={modalText}>{subTitle}</Typography>
                        {contentText}
                    </DialogContent>

                    <DialogActions sx={{ textAlign: "center", margin: "5px 0 5px 0" }}>

                        <Grid
                            container
                            direction="row"
                            justifyContent={separateButtons ? "space-between" : "center"}
                        >


                            {noSecondAction ? null :
                                <SecondaryButton
                                    onClick={onConfirm}
                                    disabled={loading || disabled}
                                    sx={{ marginRight: "24px", p: "15px 42px" }}
                                >
                                    {secondaryButton}
                                </SecondaryButton>
                            }
                            <BotonCarga
                                testid="warningMC"
                                toDo={onClose}
                                disabled={disabled}
                                loading={loading}
                                label={primaryButton}
                                sx={{ width: loadingWidth ? loadingWidth : "175px", height: "50px" }}
                            />
                        </Grid>
                    </DialogActions>
                </>
            ) : (
                <>
                    <CustomModalTitle>
                        <Typography sx={modalTitle} paddingTop="16px">
                            {title}
                        </Typography>
                    </CustomModalTitle>

                    <DialogContent
                        dividers
                        sx={{ textAlign: "center", pl: "24px", pr: "24px" }}
                    >
                        {mensajeAlert &&
                            <Grid container display={'inline-block'} justifyContent={'space-around'} width={'85%'} sx={{ marginBottom: "16px" }}>
                                <Aviso
                                    sx={{ justifyContent: 'center' }}
                                    open={!!mensajeAlert}
                                    severidad={'warning'}
                                    titulo={mensajeAlert}
                                />
                            </Grid>}
                        {!noIcon &&
                            <Grid>
                                <img
                                    alt="img"
                                    src={isSuccess ? checkBig : iconIf}
                                />
                            </Grid>
                        }
                        <Typography sx={modalText}>{subTitle}</Typography>
                        {contentText}
                    </DialogContent>

                    <DialogActions sx={{ textAlign: "center", margin: "5px 0 15px 0" }}>
                        <Grid
                            container
                            direction="row"
                            justifyContent='space-around'
                            alignItems="center"
                        >
                            {noSecondAction ? null :
                                <SecondaryButton
                                    data-testid="sndBTN"
                                    sx={{ minWidth: "175px", marginRight: "24px" }}
                                    onClick={onClose}
                                    disabled={loading}
                                >
                                    {secondaryButton}
                                </SecondaryButton>
                            }
                            <BotonCarga
                                testid="warningMC"
                                toDo={onConfirm!}
                                disabled={disabled}
                                loading={loading}
                                label={primaryButton}
                                sx={{ width: loadingWidth ? loadingWidth : "175px", height: "50px" }}
                            />
                        </Grid>
                    </DialogActions>
                </>
            )}
        </CustomModal>
    );
};