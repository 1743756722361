import { Typography } from "@mui/material"
import { formatInTimeZone } from "date-fns-tz"

export const CondicionEgreso = ({ condicion }: { condicion: number | null | undefined }) => {
    if (Number.isInteger(condicion)) {
        return (
            <Typography variant="h6" fontSize="14px" >
                {condicion === 0 ? "Vivo" : "Fallecido"}
            </Typography>)
    } else {
        return (
            <Typography variant="h6" fontSize="14px" className="disabled-text">
                Sin Condición Egreso
            </Typography>
        )
    }
}

export const FechaCell = ({ fecha, isEgreso }: { fecha: string | number | Date, isEgreso?: boolean }) => {
    if (fecha) {
        return (
            <Typography variant="h6" fontSize="14px">
                {formatInTimeZone(
                    fecha,
                    process.env.REACT_APP_TIME_ZONE || '',
                    "dd-MM-yyyy")}
            </Typography>
        )
    } else {
        return (
            <Typography variant="h6" fontSize="14px" className="disabled-text">
                Sin Fecha {isEgreso ? "Egreso" : "Inicio"}
            </Typography>
        )
    }
}