import { gql } from "@apollo/client";

export const mutationUploadDocumento = gql`
mutation mutationUploadDocumento(
  $tipoDocumento: String
  $contenidoDocumento: String
  $idCuentaMedica: Int
  $numeroCuenta: String
  $usuario: String
  $rutBeneficiario: String
  $nombreDocumento: String
){
  uploadDocumento (
    tipoDocumento: $tipoDocumento
    contenidoDocumento: $contenidoDocumento
    idCuentaMedica: $idCuentaMedica
    numeroCuenta : $numeroCuenta
    usuario: $usuario
    rutBeneficiario: $rutBeneficiario
    nombreDocumento: $nombreDocumento)
    {
      id
      url
      nombreDocumento
      usuario
      folioDocumento
      createdAt
      idSharePoint
      tipoDocumento
    }
}`;

export const queryGetDocumentoById = gql`
query ($idDocumento: Int){
  getDocumentoById(idDocumento : 1){
    id
    url
    nombreDocumento
    usuario
    folioDocumento
    createdAt
    tipoDocumento
  }
}`;

export const queryObtenerDocumentoIdCuentaMedica = gql`
query queryObtenerDocumentoIdCuentaMedica($id: Int){
  obtenerDocumentoIdCuentaMedica(idCuentaMedica: $id){
    id
    url
    nombreDocumento
    usuario
    folioDocumento
    createdAt
    idSharePoint
    tipoDocumento
  }
}`;

export const queryDeleteDocumento = gql`
query queryDeleteDocumento($id: Int){
    deleteDocumento(idDocumento : $id){
      estado
      mensaje
    }
}`;

export const queryGetBase64File = gql`
query queryGetBase64File($idDocumento: String){
  obtenerDocumento(idDocumento: $idDocumento) {
    contenidoDelArchivo
  }
}
`;