import { Typography, Box, Grid, Stack } from "@mui/material";
import { DatePicker } from "../../../components/DatePicker/DatePicker";
import { boxCard } from "../../../assets/styles/Box/boxs";
import { boldTitle, lineBold, lineText } from "../../../assets/styles/Text/text";
import { PrimaryButton } from "../../../assets/styles/Button/Button";
import { ChipsCuentas } from "../components/chipsCuentas";
import { TableCuentas } from "../tableCuentas/tableCuentas";
import useEmisionCuentaMedica from "../hook/useEmisionCuentaMedica";
import { ModalEmitir } from "../modalEmitir/modalEmitir";
import { ButtonEdit } from "./buttonEdit";
import { ModalEmitirLiquidada } from "../modalEmitirLiquidada/modalEmitirLiquidada";
import { ModalFiltros } from "../components/modalFiltros";
import BotonCarga from "../../../components/Buttons/LoadingButton";

const TabCuentas = ({ tabIndex, setSnackData }: any) => {
  const {
    isReversa,
    refetch,
    pageCuenta, linesCuentas, changePage, setLineasCuentas,
    dataFiltro,
    handleSelectAllClick,
    buscarCuentasMedicas,
    loading,
    orderDir,
    orderByCol,
    aplicarOrderBy,
    handleItemChecked,
    selectedCuentaMedica,
    isSelected,
    isEmptyState,
    handleOnCLickEmitirCuentas,
    modalEmitirMultiplesCuentas,
    handleOnCloseModalEmitirMultiplesCuentas,
    modalEmitirCuentaMedica,
    handleEmitirCuentasMedica,
    hancleOnCloseEmitirCuentaMedica,
    emisionDatosUsuario,
    listaCuentasLiquidadas,
    count,
    handleOnClickSeleccionar,
    reiniciarFechas,
    fechaHasta,
    fechaDesde,
    handleOnChangeFechaDesde,
    handleOnChangeFechaHasta,
    handleEmitirListadoCuentas,
    loadingEmitirCuentas,
    disabledBusqueda,
    handleEmitirCuenta,
    isDisabledReversar,
    isDisabledFechaHasta,
    exportarCuentasMedicas,
    loadingExport,
    networkStatus
  } = useEmisionCuentaMedica(tabIndex, setSnackData);

  return (
    <>
      {selectedCuentaMedica.length > 0 && (
        <ButtonEdit
          handleOnCLickEmitirCuentas={handleOnCLickEmitirCuentas}
          count={selectedCuentaMedica.length}
          loading={loading}
        />
      )}

      {modalEmitirMultiplesCuentas && (
        <ModalEmitir
          open={modalEmitirMultiplesCuentas}
          handleOnClose={handleOnCloseModalEmitirMultiplesCuentas}
          handleOnClickEmitir={handleEmitirListadoCuentas}
          loading={loadingEmitirCuentas}
        />
      )}

      {modalEmitirCuentaMedica && (
        <ModalEmitirLiquidada
          open={modalEmitirCuentaMedica}
          hancleOnClose={hancleOnCloseEmitirCuentaMedica}
          datosUsuario={emisionDatosUsuario}
          handleOnClickEmitir={handleEmitirCuenta}
          loading={loadingEmitirCuentas}
        />
      )}

      <Box sx={boxCard}>
        <Grid container p="24px">
          <Grid item xs={12}>
            <Typography sx={boldTitle}>Cuentas {tabIndex === "2" ? "Emitidas" : "Liquidadas"}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography sx={lineText}>Selecciona un rango de </Typography>
            <Typography sx={lineBold}> fecha de recepción </Typography>
            <Typography sx={lineText}>
              para ver la información correspondiente.
            </Typography>
          </Grid>
        </Grid>
        <Stack
          pl="24px"
          pr="24px"
          pb="16px"
          direction={"row"}
          justifyContent={"space-between"}
        >
          <Stack
            direction={"row"}
            spacing={"16px"}
          >
            <DatePicker
              value={fechaDesde}
              onChange={handleOnChangeFechaDesde}
              placeholder="Desde"
            />
            <DatePicker
              value={fechaHasta}
              onChange={handleOnChangeFechaHasta}
              minDate={fechaDesde}
              disabled={isDisabledFechaHasta}
              placeholder="Hasta"
            />
            <PrimaryButton
              sx={{ p: "15px 61px ", height: "50px" }}
              onClick={() => handleOnClickSeleccionar(tabIndex)}
              disabled={disabledBusqueda}
            >
              Seleccionar
            </PrimaryButton>
          </Stack>
          <Stack
            direction={"row"}
            justifyContent={"end"}
            spacing={"16px"}
            pl={"16px"}
          >
            <ModalFiltros
              dataFiltro={dataFiltro}
              disabled={loading}
              tabIndex={tabIndex}
              findMD={buscarCuentasMedicas}
            />
            <BotonCarga
              loading={loadingExport}
              disabled={loading || listaCuentasLiquidadas.length < 1}
              testid="exportarCM"
              download
              type="secondary"
              toDo={() => exportarCuentasMedicas()}
            >
              Exportar
            </BotonCarga>
          </Stack>
        </Stack>
        <Grid container paddingLeft="24px" paddingRight="24px" paddingBottom="15px">
          <ChipsCuentas
            filtros={dataFiltro}
            tabIndex={tabIndex}
            findMD={buscarCuentasMedicas}
            fechas={fechaHasta || fechaDesde}
            resetFechas={reiniciarFechas}
          />
        </Grid>
        {
          <TableCuentas
            loading={loading}
            refetch={refetch}
            isReversa={isReversa}
            orderDir={orderDir}
            orderByCol={orderByCol}
            aplicarOrderBy={aplicarOrderBy}
            tabIndex={tabIndex}
            setSnackData={setSnackData}
            selectedCuentaMedica={selectedCuentaMedica}
            listaCuentasLiquidadas={listaCuentasLiquidadas}
            handleSelectAllClick={handleSelectAllClick}
            isSelected={isSelected}
            handleItemChecked={handleItemChecked}
            handleEmitirCuentasMedica={handleEmitirCuentasMedica}
            count={count}
            rowsPerPage={linesCuentas}
            page={pageCuenta}
            handleNewChangePage={changePage}
            handleNewChangeRowsPerPage={setLineasCuentas}
            isEmptyState={isEmptyState}
            isDisabledReversar={isDisabledReversar}
            networkStatus={networkStatus}
          />
        }
      </Box>
    </>
  );
}

export default TabCuentas;
