import {
    Box,
    Grid,
    Paper,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@mui/material";
import SkeletonBar from "../../../components/Skeletons/skeletonBar";
import { stickyBodyLeft, stickyHead, styleHead, styleHeadAlt } from "../../../assets/styles/Table/table";
import { boxCard } from "../../../assets/styles/Box/boxs";

export const LoadHeadHistorial = () => (
    <Grid container textAlign={"left"} pb="16px">
        <Grid item xs={3}>
            <Box pb="8px"><SkeletonBar width={52} height={14} />   </Box>
            <SkeletonBar width={124} height={14} />
        </Grid>
        <Grid item xs={3}>
            <Box pb="8px"><SkeletonBar width={52} height={14} />   </Box>
            <SkeletonBar width={124} height={14} />
        </Grid>
        <Grid item xs={3}>
            <Box pb="8px"><SkeletonBar width={52} height={14} />   </Box>
            <SkeletonBar width={124} height={14} />
        </Grid>
    </Grid>
);

export const LoadHeadConsulta = () => (
    <Grid container p="24px 15px" data-testid="loaderConsulta">
        <Grid item xs={1} >
            <SkeletonBar width={98} height={91} />
        </Grid>
        <Grid item xs={7} pl="24px" alignSelf={"center"}>
            <Box pb="8px"><SkeletonBar width={406} height={32} />   </Box>
            <SkeletonBar width={634} height={14} />
        </Grid>
        <Grid item xs={4} alignSelf={"end"} textAlign={"end"} >
            <Stack display={"flex"} direction={"row"} spacing={"16px"} alignSelf={"end"} textAlign={"end"} justifyContent={"end"}>
                <SkeletonBar width={104} height={50} />
                <SkeletonBar width={142} height={50} />
            </Stack>

        </Grid>

    </Grid>

);

export const LoadTableConsulta = () => (
    <Box sx={boxCard}>
        <Paper className="paper-tables" sx={{ border: " none", borderRadius: "0 !important" }}>
            <TableContainer sx={{ pl: "0 !important" }}>
                <Table aria-label="customized table" >
                    <TableHead>
                        <TableRow >
                            <TableCell sx={styleHeadAlt}   >
                                <SkeletonBar width={113} height={14} />
                            </TableCell>
                            <TableCell sx={styleHeadAlt} >
                                <SkeletonBar width={113} height={14} />
                            </TableCell>
                            <TableCell sx={styleHeadAlt} >
                                <SkeletonBar width={113} height={14} />
                            </TableCell>
                            <TableCell sx={styleHeadAlt} >
                                <SkeletonBar width={113} height={14} />
                            </TableCell>
                            <TableCell sx={styleHeadAlt} >
                                <SkeletonBar width={113} height={14} />
                            </TableCell>
                            <TableCell sx={styleHeadAlt} >
                                <SkeletonBar width={113} height={14} />
                            </TableCell>
                            <TableCell sx={styleHeadAlt}  >
                                <SkeletonBar width={113} height={14} />
                            </TableCell>
                            <TableCell sx={styleHeadAlt}  >
                                <SkeletonBar width={113} height={14} />
                            </TableCell>
                            <TableCell sx={{ backgroundColor: '#F8F9F9', }} align="right" className="headerFirstSticky"  >
                                <SkeletonBar width={113} height={14} />
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow  >
                            <TableCell  >
                                <SkeletonBar width={100} height={14} />
                            </TableCell>

                            <TableCell>
                                <SkeletonBar width={100} height={14} />
                            </TableCell>

                            <TableCell  >
                                <SkeletonBar width={100} height={14} />
                            </TableCell>

                            <TableCell  >
                                <SkeletonBar width={100} height={14} />
                            </TableCell>

                            <TableCell>
                                <SkeletonBar width={100} height={14} />
                            </TableCell>

                            <TableCell>
                                <SkeletonBar width={100} height={14} />
                            </TableCell>

                            <TableCell>
                                <SkeletonBar width={100} height={14} />
                            </TableCell>
                            <TableCell    >
                                <SkeletonBar width={100} height={14} />
                            </TableCell>
                            <TableCell align="center" className="tableBodyAcciones">
                                <Box display={"flex"} justifyContent={"center"}><SkeletonBar width={50} height={40} /></Box>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
            <Stack display={"flex"} justifyContent={"center"} pt="32px" pb="32px" direction={"row"} alignItems={"center"} spacing={"24px"}>
                <SkeletonBar width={108} height={14} />
                <SkeletonBar width={68} height={32} />
                <SkeletonBar width={88} height={32} />
            </Stack>

        </Paper>
    </Box>
);

export const LoadHistorialCtas = () => (
    <Paper className="paper-tables" sx={{ border: " 0.5px solid #4DC0B8 !important", borderRadius: "16px" }}>
        <TableContainer sx={{ pl: "0 !important" }} data-testid="loadingHistorialCta">
            <Table aria-label="customized table" >
                <TableHead>
                    <TableRow >
                        <TableCell sx={styleHeadAlt}   >
                            <SkeletonBar width={80} height={14} />
                        </TableCell>
                        <TableCell sx={styleHeadAlt} >
                            <SkeletonBar width={80} height={14} />
                        </TableCell>
                        <TableCell sx={styleHeadAlt} >
                            <SkeletonBar width={80} height={14} />
                        </TableCell>
                        <TableCell sx={styleHeadAlt} >
                            <SkeletonBar width={80} height={14} />
                        </TableCell>
                        <TableCell sx={styleHeadAlt} >
                            <SkeletonBar width={80} height={14} />
                        </TableCell>
                        <TableCell sx={styleHeadAlt} >
                            <SkeletonBar width={80} height={14} />
                        </TableCell>
                        <TableCell sx={styleHeadAlt}  >
                            <SkeletonBar width={80} height={14} />
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow  >
                        <TableCell  >
                            <SkeletonBar width={80} height={14} />
                        </TableCell>

                        <TableCell>
                            <SkeletonBar width={80} height={14} />
                        </TableCell>

                        <TableCell component="th" scope="row">
                            <SkeletonBar width={80} height={14} />
                        </TableCell>

                        <TableCell component="th" scope="row">
                            <SkeletonBar width={80} height={14} />
                        </TableCell>

                        <TableCell>
                            <SkeletonBar width={80} height={14} />
                        </TableCell>

                        <TableCell>
                            <SkeletonBar width={80} height={14} />
                        </TableCell>

                        <TableCell>
                            <SkeletonBar width={80} height={14} />
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
        <Box display={"flex"} justifyContent={"center"} pt="32px" pb="32px">
            <SkeletonBar width={371} height={16} />
        </Box>

    </Paper>
);

export const LoadEmitidas = () => (
    <Box  >
        <TableContainer sx={{ p: 0, pt: "18px" }}>
            <Table sx={{ minWidth: "1500px" }}>
                <TableHead>
                    <TableRow>
                        <TableCell
                            padding="checkbox"
                            className="tCell-p"
                            sx={stickyHead}
                        >
                            <div style={{ paddingLeft: "20px", paddingRight: "20px" }}>
                                <SkeletonBar width={20} height={20} />
                            </div>
                        </TableCell>

                        <TableCell
                            sx={styleHead}
                        >
                            <SkeletonBar width={80} height={14} />
                        </TableCell>
                        <TableCell
                            sx={styleHead}
                        >
                            <SkeletonBar width={80} height={14} />
                        </TableCell>
                        <TableCell
                            sx={styleHead}
                        >
                            <SkeletonBar width={80} height={14} />
                        </TableCell>
                        <TableCell
                            sx={styleHead}
                        >
                            <SkeletonBar width={80} height={14} />
                        </TableCell>
                        <TableCell
                            sx={styleHead}
                        >
                            <SkeletonBar width={80} height={14} />
                        </TableCell>
                        <TableCell
                            sx={styleHead}
                        >
                            <SkeletonBar width={80} height={14} />
                        </TableCell>
                        <TableCell
                            sx={styleHead}
                        >
                            <SkeletonBar width={80} height={14} />
                        </TableCell>
                        <TableCell
                            className="headerFirstSticky"
                            sx={styleHead}
                        >
                            <SkeletonBar width={80} height={14} />
                        </TableCell>
                    </TableRow>
                </TableHead>

                <TableBody>

                    <TableRow >
                        <TableCell
                            padding="checkbox"
                            className="tCell-p"
                            sx={stickyBodyLeft("0")}
                        >
                            <div style={{ paddingLeft: "20px", paddingRight: "20px" }}>
                                <SkeletonBar width={20} height={20} />
                            </div>
                        </TableCell>

                        <TableCell className="tCell-p" component="th" scope="row">
                            <SkeletonBar width={100} height={14} />
                        </TableCell>
                        <TableCell className="tCell-p" component="th" scope="row">
                            <SkeletonBar width={100} height={14} />
                        </TableCell>
                        <TableCell className="tCell-p" component="th" scope="row">
                            <SkeletonBar width={100} height={14} />
                        </TableCell>
                        <TableCell className="tCell-p" component="th" scope="row">
                            <SkeletonBar width={100} height={14} />
                        </TableCell>
                        <TableCell className="tCell-p" component="th" scope="row">
                            <SkeletonBar width={128} height={40} />
                        </TableCell>
                        <TableCell className="tCell-p" >
                            <SkeletonBar width={92} height={24} />
                        </TableCell>
                        <TableCell className="tCell-p">
                            <SkeletonBar width={92} height={40} />
                        </TableCell>
                        <TableCell className="tableBodyAcciones" sx={{ right: "-2px" }}>
                            <SkeletonBar width={28} height={14} />
                        </TableCell>
                    </TableRow>
                    <TableRow >
                        <TableCell
                            padding="checkbox"
                            className="tCell-p"
                            sx={stickyBodyLeft("0")}
                        >
                            <div style={{ paddingLeft: "20px", paddingRight: "20px" }}>
                                <SkeletonBar width={20} height={20} />
                            </div>
                        </TableCell>

                        <TableCell className="tCell-p" component="th" scope="row">
                            <SkeletonBar width={100} height={14} />
                        </TableCell>
                        <TableCell className="tCell-p" component="th" scope="row">
                            <SkeletonBar width={100} height={14} />
                        </TableCell>
                        <TableCell className="tCell-p" component="th" scope="row">
                            <SkeletonBar width={100} height={14} />
                        </TableCell>
                        <TableCell className="tCell-p" component="th" scope="row">
                            <SkeletonBar width={100} height={14} />
                        </TableCell>
                        <TableCell className="tCell-p" component="th" scope="row">
                            <SkeletonBar width={128} height={40} />
                        </TableCell>
                        <TableCell className="tCell-p" >
                            <SkeletonBar width={92} height={24} />
                        </TableCell>
                        <TableCell className="tCell-p">
                            <SkeletonBar width={92} height={40} />
                        </TableCell>
                        <TableCell className="tableBodyAcciones" sx={{ right: "-2px" }}>
                            <SkeletonBar width={28} height={14} />
                        </TableCell>
                    </TableRow>
                    <TableRow >
                        <TableCell
                            padding="checkbox"
                            className="tCell-p"
                            sx={stickyBodyLeft("0")}
                        >
                            <div style={{ paddingLeft: "20px", paddingRight: "20px" }}>
                                <SkeletonBar width={20} height={20} />
                            </div>
                        </TableCell>

                        <TableCell className="tCell-p" component="th" scope="row">
                            <SkeletonBar width={100} height={14} />
                        </TableCell>
                        <TableCell className="tCell-p" component="th" scope="row">
                            <SkeletonBar width={100} height={14} />
                        </TableCell>
                        <TableCell className="tCell-p" component="th" scope="row">
                            <SkeletonBar width={100} height={14} />
                        </TableCell>
                        <TableCell className="tCell-p" component="th" scope="row">
                            <SkeletonBar width={100} height={14} />
                        </TableCell>
                        <TableCell className="tCell-p" component="th" scope="row">
                            <SkeletonBar width={128} height={40} />
                        </TableCell>
                        <TableCell className="tCell-p" >
                            <SkeletonBar width={92} height={24} />
                        </TableCell>
                        <TableCell className="tCell-p">
                            <SkeletonBar width={92} height={40} />
                        </TableCell>
                        <TableCell className="tableBodyAcciones" sx={{ right: "-2px" }}>
                            <SkeletonBar width={28} height={14} />
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
        <Box justifyContent={"center"} p={"24px"}>
            <Stack direction={"row"} spacing={"24px"} justifyContent={"center"} alignItems={"center"}>
                <SkeletonBar width={108} height={14} />
                <SkeletonBar width={68} height={32} />
                <SkeletonBar width={88} height={32} />
            </Stack>

        </Box>
    </Box>
);
