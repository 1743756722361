import { Box, CircularProgress, Modal, Typography } from '@mui/material';

import { styledLoaderModal } from '../../assets/styles/Modal/modal';

export const ModalLoader = ({ open, onClose, title }: any) => {
    return (
        <Modal
            open={open}
            onClose={onClose}
            className="background-modal"
        >
            <Box sx={styledLoaderModal}>
                <Box>
                    <CircularProgress color="primary" thickness={5} size='24px' sx={{ marginRight: '10px' }} />
                    <Typography
                        variant="caption"
                        sx={{ verticalAlign:'super' }}
                    >
                        {title}
                    </Typography>
                </Box>
            </Box>
        </Modal>
    )
}
