export enum NuevosEstadosCuentaMedica {
    Recepcionada = 41,
    Visación = 42,
    Valorización = 43,
    Bonificación = 44,
    Liquidada = 45,
    Emitida = 46,
    Anulada = 47,
    Rechazada = 48,
    Devuelta = 49,
    EmitidaManual = 50,
    Obsoleta = 10,
    PendienteAutorizacion = 52
}