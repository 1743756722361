import {
  AlertColor,
  Paper,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import TableEventoHospitalario from "./components/tableEventoHospitalario";
import { boxCardStyleCMWhitoutP } from "../../../assets/styles/Box/boxs";
import { HeaderEventoHospitalario } from "./components/HeaderEventoHospitalario";
import SnackBar, { stateProps } from "../../../components/Snackbar/Snackbar";
import { useState } from "react";

const EventoHospitalario = ({ dataCM, readOnly, loadingCuenta }: any) => {
  const [snackData, setSnackData] = useState<stateProps>({
    abrir: false,
    severidad: 'info',
    mensaje: ''
  });

  const setMensajero = (mensaje: string, severidad: AlertColor) => {
    setSnackData({
      abrir: true,
      mensaje,
      severidad
    })
  }

  return (
    <>
      <SnackBar state={snackData} setState={setSnackData} />
      <HeaderEventoHospitalario idEventoHospitalario={dataCM.idEventoHospitalario} idCuenta={dataCM.idCuentaMedica}/>
      <Box sx={boxCardStyleCMWhitoutP}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          p="15px 24px"
        >
          <Typography variant="h5">Listado de Eventos Hospitalarios</Typography>
          {/* <CreateEventoHO
            readOnly={readOnly}
            dataCM={dataCM}
            setMensajero={setMensajero}
          /> */}
        </Box>
        <Paper className="paper-tables-2" sx={{ marginBottom: '32px' }}>
          <TableEventoHospitalario
            dataCM={dataCM}
            readOnly={readOnly}
            setMensajero={setMensajero}
            loadingCuenta={loadingCuenta}
          />
        </Paper>
      </Box>
    </>
  );
}

export default EventoHospitalario;