import { useState } from "react";
import { SelectPersonalizadoNuevosEstados } from "../../../../components/Selects/SelectPersonalizado";
import { useQuery } from "@apollo/client";
import { getNewMedicalRecordStatusByFilter } from "../../../../services/cm-services/escritorio-cm/escritorio-services";
import { isNotUndefinedAndNull } from "../../../../utils";
import { newStatusFiltered } from "../../../../types";
import ModalReasons from "./modalReasons";
import { useGetUser } from "../../../../hooks/getUser";

interface IButtonStatusActionProps {
  idCM: number;
  currentStatus: number;
  channel: string;
  refreshCuentaMedica: () => void;
  CMUser: string;
  readOnly: boolean
}

export const ButtonStatusAction = ({
  idCM,
  currentStatus,
  channel,
  refreshCuentaMedica,
  CMUser,
  readOnly
}: IButtonStatusActionProps) => {
  const [statusAction] = useState<string>("");
  const [selectedNextStatus, setSelectedNextStatus] = useState<number | null>(
    null
  );
  const [statusOptions, setStatusOptions] = useState<newStatusFiltered[]>([]);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const user = useGetUser();

  useQuery(getNewMedicalRecordStatusByFilter, {
    variables: {
      currentStatus,
      channel,
    },
    onCompleted: (data) => {
      if (isNotUndefinedAndNull(data.getNewMedicalRecordStatusByFilter)) {
        setStatusOptions(data.getNewMedicalRecordStatusByFilter);
      }
    },
    onError: (err) => {
      console.error(err);
    },
  });

  const handleSetNextStatus = (nextStatus: number) => {
    setSelectedNextStatus(nextStatus);
    setIsModalOpen(true);
  }

  return (
    <>
      <SelectPersonalizadoNuevosEstados
        inputTest="statusActionSelect"
        id="statusActionSelect"
        value={statusAction}
        data-testid="statusActionSelect"
        setValue={(nextStatus: string) => handleSetNextStatus(Number(nextStatus))}
        options={statusOptions}
        isAcciones={true}
        placeholder="Acciones"
        placeholderHidden
        // disabled={user.email !== CMUser} TODO: SE QUITA RESTRICCIÓN PARA VALIDAR ACCIONES INDEPENDIENTE SI EL USUARIO FUE ASOCIADO A CM O NO
        disabled={readOnly}
        user={user}
      />
      {selectedNextStatus !== null && (
        <ModalReasons
          handleClose={() => setIsModalOpen(false)}
          isOpen={isModalOpen}
          idCM={idCM}
          currentStatus={currentStatus}
          nextStatus={selectedNextStatus}
          refreshCuentaMedica={refreshCuentaMedica}
        />
      )}
    </>
  );
};
