import ReactDOM from "react-dom";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./theme/themeConfig";
import "./index.css";
import { BrowserRouter } from "react-router-dom";
import { App } from "./app";
import { ApolloProvider } from "@apollo/client";
import client from "./configApollo/config";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./authConfig";

const msalInstance = new PublicClientApplication(msalConfig);

ReactDOM.render(
  <>
    <MsalProvider instance={msalInstance}>
      <ThemeProvider theme={theme}>
        <ApolloProvider client={client}>
            <BrowserRouter>
              <App />
            </BrowserRouter>
        </ApolloProvider>
      </ThemeProvider>
    </MsalProvider>
  </>,

  document.getElementById("root")
);
