import { useMutation } from "@apollo/client";
import { mutationGenerarPDF } from "../../../services/emisionCuentas/emisionCuentas";
import { useState } from "react";

function useGenerarPdf(loadMessage: Function) {
  const [loading, setLoading] = useState<boolean>(false);
  const [fnGenerarPDF] = useMutation(mutationGenerarPDF, {
    fetchPolicy: "no-cache"
  });

  const handleOnClickGenerarPdf = ({ idCuentaMedica, tipoDocumento }: any) => {
    loadMessage("Generando documento espere por favor...", 'info');
    setLoading(true);
    fnGenerarPDF({
      variables: { idCuentaMedica, tipoDocumento },
      onCompleted: (res) => {
        switch (res.generarFormulario.estado) {
          case 1:
            loadMessage("Se ha generado su documento exitosamente", 'success');
            setLoading(false);
            window.open(res.generarFormulario.message, '_blank', 'noopener,noreferrer');
            break;
          case 2:
            setTimeout(() => handleOnClickGenerarPdf({ idCuentaMedica, tipoDocumento }), 10000);
            break;
          case 4:
            loadMessage(res.generarFormulario.message, 'info');
            setLoading(false);
            break;
          case 3:
          default:
            loadMessage("No se ha podido generar el documento, por favor intente nuevamente", 'error');
            setLoading(false);
            break;
        }
      },
      onError: () => {
        loadMessage("No se ha podido generar el documento, por favor intente nuevamente", 'error');
        setLoading(false);
      }
    });
  }

  return {
    handleOnClickGenerarPdf,
    loadingGenerarPDF: loading,
  }
}

export default useGenerarPdf