import { TableCell } from "@mui/material";
import { EnhancedTableHead } from "../../../../components/Tables/enhancedTableHead";
import { styleHead } from '../../../../assets/styles/Table/table';

const headReport = [
  {
    id: "folioDocumento",
    label: "Período",
  },
  {
    id: "nombreArchivo",
    label: "Nombre del archivo",
  },
  {
    id: "fechaGeneracion",
    label: "Fecha generación",
  },
];

export const HeadReportes = ({
  onSelectAllClick,
  order,
  orderBy,
  numSelected,
  rowCount,
  onRequestSort,
}: any) => {
  return (
    <EnhancedTableHead
      numSelected={numSelected}
      order={order}
      orderBy={orderBy}
      onSelectAllClick={onSelectAllClick}
      onRequestSort={onRequestSort}
      rowCount={rowCount}
      headCells={headReport}
      isEmision={true}
    >
      <TableCell sx={styleHead}>Acciones</TableCell>
    </EnhancedTableHead>
  );
};
