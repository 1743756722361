import { Fragment } from "react";
import { Grid, Chip, Button } from "@mui/material";
import Cruz from "../../../assets/icons/closeFiltro.svg";

export function ChipsCuentas({ filtros, rowsPerPage, pageChange, findMD }: any) {
    const handleDelete = (filter: number) => {
        let filters = filtros;
        switch (filter) {
            case 1: {
                filters.id = undefined;
                break;
            }
            case 2: {
                filters.idCMD = undefined;
                break;
            }
            case 3: {
                filters.numeroCuentaPrestador = undefined;
                break;
            }
            case 4: {
                filters.rutPaciente = undefined;
                break;
            }
            case 5: {
                filters.rutPrestador = undefined;
                break;
            }
            case 6: {
                filters.causal = undefined;
                break;
            }
            case 7: {
                filters.estado = undefined;
                break;
            }
            case 8: {
                filters.fechaRecepcion = undefined;
                break;
            }
            case 9: {
                filters.fechaAsignacionUsuario = undefined;
                break;
            }
            case 10: {
                filters.fechaProbableLiquidacion = undefined;
                break;
            }
            case 11: {
                filters.diasRestantes = undefined;
                break;
            }
            case 12: {
                filters.montoTotalCobro = undefined;
                break;
            }
            case 13: {
                filters.canalOrigen = undefined;
                break;
            }
            case 14: {
                filters.idUsuario = undefined;
                break;
            }
            default: return
        }
        pageChange(0);
        findMD(0, rowsPerPage, null, filters)
    };

    const clearFiltros = () => {
        findMD(0, rowsPerPage, null, {});
        pageChange(0);
    };

    return (
        <Fragment>
            {(filtros.id ||
                filtros.idCMD ||
                filtros.numeroCuentaPrestador ||
                filtros.rutPaciente ||
                filtros.rutPrestador ||
                filtros.causal ||
                filtros.estado ||
                filtros.fechaRecepcion ||
                filtros.fechaAsignacionUsuario ||
                filtros.fechaProbableLiquidacion ||
                filtros.diasRestantes ||
                filtros.montoTotalCobro ||
                filtros.canalOrigen || 
                filtros.idUsuario) && (
                    <Fragment>
                        <Grid item xs={12} pt="15px">
                            <label className="filtroFont">Resultados filtrados por: </label>{" "}
                        </Grid>
                        <Grid item xs={12} pt="4px" >
                            {filtros.id && (
                                <Chip
                                    sx={{ mr: "16px" }}
                                    label="Nº Cuenta"
                                    variant="outlined"
                                    onDelete={() => handleDelete(1)}
                                    deleteIcon={
                                        <img
                                            src={Cruz}
                                            alt="cruz"
                                            data-testid="icon-delete-id"
                                        />
                                    }
                                />
                            )}
                            {filtros.idCMD && (
                                <Chip
                                    sx={{ mr: "16px" }}
                                    label="Nº Cuenta Extranet"
                                    variant="outlined"
                                    onDelete={() => handleDelete(2)}
                                    deleteIcon={
                                        <img
                                            src={Cruz}
                                            alt="cruz"
                                            data-testid="icon-delete-idCMD"
                                        />
                                    }
                                />
                            )}
                            {filtros.numeroCuentaPrestador && (
                                <Chip
                                    sx={{ mr: "16px" }}
                                    label="Nº Cuenta Prestador"
                                    variant="outlined"
                                    onDelete={() => handleDelete(3)}
                                    deleteIcon={
                                        <img src={Cruz} alt="cruz" data-testid="icon-delete-numeroCuentaPrestador" />
                                    }
                                />
                            )}
                            {filtros.rutPaciente && (
                                <Chip
                                    sx={{ mr: "16px" }}
                                    label="RUT Beneficiario"
                                    variant="outlined"
                                    onDelete={() => handleDelete(4)}
                                    deleteIcon={
                                        <img
                                            src={Cruz}
                                            alt="cruz"
                                            data-testid="icon-delete-rutPaciente"
                                        />
                                    }
                                />
                            )}
                            {filtros.rutPrestador && (
                                <Chip
                                    sx={{ mr: "16px" }}
                                    label="RUT Prestador"
                                    variant="outlined"
                                    onDelete={() => handleDelete(5)}
                                    deleteIcon={
                                        <img
                                            src={Cruz}
                                            alt="cruz"
                                            data-testid="icon-delete-rutPrestador"
                                        />
                                    }
                                />
                            )}
                            {filtros.causal && (
                                <Chip
                                    sx={{ mr: "16px" }}
                                    label="Causal"
                                    variant="outlined"
                                    onDelete={() => handleDelete(6)}
                                    deleteIcon={
                                        <img
                                            src={Cruz}
                                            alt="cruz"
                                            data-testid="icon-delete-causal"
                                        />
                                    }
                                />
                            )}
                            {filtros.estado && (
                                <Chip
                                    sx={{ mr: "16px" }}
                                    label="Estado"
                                    variant="outlined"
                                    onDelete={() => handleDelete(7)}
                                    deleteIcon={
                                        <img
                                            src={Cruz}
                                            alt="cruz"
                                            data-testid="icon-delete-estado"
                                        />
                                    }
                                />
                            )}
                            {filtros.fechaRecepcion && (
                                <Chip
                                    sx={{ mr: "16px" }}
                                    label="Fecha Recepción"
                                    variant="outlined"
                                    onDelete={() => handleDelete(8)}
                                    deleteIcon={
                                        <img
                                            src={Cruz}
                                            alt="cruz"
                                            data-testid="icon-delete-fechaRecepcion"
                                        />
                                    }
                                />
                            )}
                            {filtros.fechaAsignacionUsuario && (
                                <Chip
                                    sx={{ mr: "16px" }}
                                    label="Fecha de Asignación"
                                    variant="outlined"
                                    onDelete={() => handleDelete(9)}
                                    deleteIcon={
                                        <img
                                            src={Cruz}
                                            alt="cruz"
                                            data-testid="icon-delete-fechaAsignacion"
                                        />
                                    }
                                />
                            )}
                            {filtros.fechaProbableLiquidacion && (
                                <Chip
                                    sx={{ mr: "16px" }}
                                    label="Fecha de Compromiso"
                                    variant="outlined"
                                    onDelete={() => handleDelete(10)}
                                    deleteIcon={
                                        <img
                                            src={Cruz}
                                            alt="cruz"
                                            data-testid="icon-delete-fechaProbableLiquidacion"
                                        />
                                    }
                                />
                            )}
                            {filtros.diasRestantes && (
                                <Chip
                                    sx={{ mr: "16px" }}
                                    label="Días restantes"
                                    variant="outlined"
                                    onDelete={() => handleDelete(11)}
                                    deleteIcon={
                                        <img
                                            src={Cruz}
                                            alt="cruz"
                                            data-testid="icon-delete-diasRestantes"
                                        />
                                    }
                                />
                            )}
                            {filtros.montoTotalCobro && (
                                <Chip
                                    sx={{ mr: "16px" }}
                                    label="Monto total de cobro"
                                    variant="outlined"
                                    onDelete={() => handleDelete(12)}
                                    deleteIcon={
                                        <img
                                            src={Cruz}
                                            alt="cruz"
                                            data-testid="icon-delete-montoTotalCobro"
                                        />
                                    }
                                />
                            )}
                            {filtros.canalOrigen && (
                                <Chip
                                    sx={{ mr: "16px" }}
                                    label="Canal de origen"
                                    variant="outlined"
                                    onDelete={() => handleDelete(13)}
                                    deleteIcon={
                                        <img
                                            src={Cruz}
                                            alt="cruz"
                                            data-testid="icon-delete-canalOrigen"
                                        />
                                    }
                                />
                            )}
                            {filtros.idUsuario && (
                                <Chip
                                    sx={{ mr: "16px" }}
                                    label="Usuario"
                                    variant="outlined"
                                    onDelete={() => handleDelete(14)}
                                    deleteIcon={
                                        <img
                                            src={Cruz}
                                            alt="cruz"
                                            data-testid="icon-delete-canalOrigen"
                                        />
                                    }
                                />
                            )}
                            <Button
                                color="inherit"
                                sx={{
                                    textDecoration: "underline",
                                    textDecorationThickness: "from-font",
                                    pl: "12px",
                                }}
                                data-testid="cleanFilters"
                                onClick={() => clearFiltros()}
                            >
                                Limpiar filtros
                            </Button>
                        </Grid>
                    </Fragment>
                )}
        </Fragment>
    );
}
