export async function parseToExcel(data: any) {
  const parsedData = [];
  parsedData.push([
    "Cuenta clínica",
    "Número de cobro",
    "Número de envío",
    "Folio Solicitud CMD",
    "Folio Cuenta Medica",
    "N° de Cuenta x Cobertura",
    "Desc. Estado",
    "Email Usuario",
    "Fecha Emisión",
    "Hora estado Emitida",
    "Usuario estado Liquidada",
    "Fecha liquidación",
    "Hora estado Liquidada",
    "Folio Bono KNC",
    "RUT Beneficiario",
    "Nombre Beneficiario",
    "Rut Titular",
    "Codigo Plan Salud",
    "Desc. Plan de Salud",
    "Código Prestación Isapre",
    "Glosa Prestación Isapre",
    "Item de prestación Isapre",
    "Glosa Item Isapre",
    "Tipo Atención",
    "RUT Prestador Cobro",
    "Razon Social - Nombre Prestador",
    "Tipo de Documento",
    "Cantidad",
    "$ Total Prestación",
    "Total Prestación Isapre",
    "% Cobro",
    "$ Bonificación Plan",
    "$ Bonificación Ges",
    "$ Bonificación Ges/CAEC",
    "$ Bonificación CAEC",
    "$ Bonificación Forzada",
    "$ Bonificacion Complementaria",
    "$ Total bonificación complementaria",
    "$ Bonificación Topada",
    "$ Copago Afiliado",
    "Bonificación Esencial",
    "Bonificacion Total",
    "Cobertura",
    "Cobertura PLAN comercialización",
    "Cuenta Complementaria",
    "SOAP",
    "Cobertura Restringida",
    "Código Canasta",
    "Número caso (GES / CAEC)",
    "Código Prestación Principal Isapre",
    "Glosa Prestación Principal Isapre",
    "Codigo CIE10",
    "Glosa CIE10",
    "Fecha Recepción",
    "Canal recepción",
    "Fecha Compromiso liquidacion",
    "Fecha Inicio Hospitalización",
    "Hora Inicio Hosp",
    "Fecha Término Hospitalización",
    "Hora Egreso Hosp",
    "Fecha Inicio Cobro",
    "Fecha Termino Cobro",
    "Código Paquete",
    "Ley de Urgencia",
    "Tipo Cobertura Informada",
    "Tipo Cobertura",
  ]);

  for (const reg of data) {
    parsedData.push([
      reg.numeroCuentaPrestador,
      reg.numeroCobroPrestador,
      reg.numeroEnvio,
      reg.idCMD,
      reg.id,
      reg.nroCuentaCobertura,
      reg.estado,
      reg.usuarioEmision,
      reg.fechaEmision,
      reg.horaEmision,
      reg.usuarioLiquidacion,
      reg.fechaLiquidacion,
      reg.horaLiquidacion,
      reg.folioBonificacion,
      reg.rutPaciente,
      reg.nombrePaciente,
      reg.rutTitular,
      reg.codigoPlanSalud,
      reg.glosaPlanSalud,
      reg.codigoPrestacionIsapre,
      reg.glosaPrestacionIsapre,
      reg.idItemIsapre,
      reg.glosaItemIsapre,
      reg.tipoAtencion,
      reg.rutPrestador,
      reg.razonSocial,
      reg.tipoDocumento,
      reg.frecuenciaAtencion,
      reg.montoPrestacionCobro || 0,
      reg.montoPrestacionConvenio || 0,
      reg.porcentajeCobro || 0,
      reg.bonificacionPlan || 0,
      reg.bonificacionGES || 0,
      reg.bonificacionGESCAEC || 0,
      reg.bonificacionCAEC || 0,
      reg.montoBonificacionForzado || 0,
      reg.BonificacionComplementaria,
      reg.bonificacionComplementariaTotal,
      reg.montoBonificacionTopada || 0,
      reg.montoCopago || 0,
      reg.bonificacionEscencial,
      reg.bonificacionComplementariaTotal + reg.bonificacionEscencial,
      reg.cobertura,
      reg.idPlan,
      reg.cuentaComplementaria,
      reg.soap,
      reg.restriccionCobertura,
      reg.codigoCanasta,
      reg.nroGESCAEC,
      reg.codigoPrestacionPrincipalPrestador,
      reg.descripcionPrestacionPrincipalPrestador,
      reg.cie10Primario,
      reg.descCie10Primario,
      reg.fechaRecepcion,
      reg.canalOrigen,
      reg.fechaProbableLiquidacion,
      reg.fechaInicioHospitalizacion,
      reg.horaInicioHospitalizacion,
      reg.fechaEgresoHospitalizacion,
      reg.horaAlta,
      reg.fechaCobroDesde,
      reg.fechaCobroHasta,
      reg.codigoPaquete,
      reg.leyUrgenciaVital,
      reg.tipoCoberturaCuenta,
      reg.tipoCoberturaCobro,
    ]);
  }

  return parsedData;
}
