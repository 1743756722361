import { Typography, Box, Grid } from "@mui/material";
import {
  boxCardFilterAlt,
  boxCardNormal,
  boxCardNormalAlt,
} from "../../assets/styles/Box/boxs";
import { IconReport } from "../../assets/icons/Icons";
import { SecondaryButton } from "../../components/Buttons/Button";
import { cardSubTitle, cardTitle } from "../../assets/styles/Text/text";
import { useNavigate } from "react-router-dom";

export const Reporteria = () => {
  const navigate = useNavigate();

  const handleNavigate = (path: string) => {
    navigate(path);
  };

  const MasterDataTemplate = () => {
    return (
      <>
        <Grid item md={6} lg={6} sm={6} pr="32px">
          <Box sx={boxCardNormalAlt}>
            <IconReport
              sx={{ marginTop: "63px", height: "52px", width: "52px" }}
            />
            <Typography sx={cardTitle}>Planilla datos maestros</Typography>
            <Typography sx={cardSubTitle}>
              Informe por periodos para cuentas emitidas
            </Typography>
            <SecondaryButton
              sx={{ marginBottom: "32px" }}
              onClick={() => handleNavigate("PlanillaDatosMaestros")}
            >
              Ir al módulo
            </SecondaryButton>
          </Box>
        </Grid>
      </>
    );
  };
  const ManagementReport = () => {
    return (
      <>
        <Grid item md={6} lg={6} sm={6} pr="32px">
          <Box sx={boxCardNormalAlt} textAlign="center">
            <IconReport
              sx={{ marginTop: "63px", height: "52px", width: "52px" }}
            />
            <Typography sx={cardTitle}>Reporte Gestión</Typography>
            <Typography sx={cardSubTitle}>
              Informe de Reporte de Gestión
            </Typography>
            <SecondaryButton
              sx={{ marginBottom: "32px" }}
              onClick={() => handleNavigate("ReporteGestion")}
            >
              Ir al módulo
            </SecondaryButton>
          </Box>
        </Grid>
      </>
    );
  };

  return (
    <>
      <Box sx={boxCardNormal}>
        <Typography pt={7} pb={4} variant="h2">
          Reportería
        </Typography>
      </Box>
      <Box sx={boxCardFilterAlt}>
        <Grid container spacing={3}>
          <ManagementReport />
          <MasterDataTemplate />
        </Grid>
      </Box>
    </>
  );
};

export default Reporteria;
