import { TableCell, TableHead, TableRow, TableSortLabel } from "@mui/material";
import { DropdownIndicator } from "../../../components/Tables/dropdownIndicator";

const headCellCuentas = [
    {
        id: 'id', label: 'Nº Cuenta', width: '124px !important', sorted: true
    },
    {
        id: 'idCMD',
        label: 'Nº C. Extranet',
        width: '160px', sorted: true
    },
    {
        id: 'numeroCuentaPrestador',
        label: 'Nº C. Prestador',
        width: '180px', sorted: true

    },
    {
        id: 'rutPaciente',
        label: 'Beneficiario',
        width: '150px', sorted: true

    },
    {
        id: 'prestador',
        label: 'Prestador',
        width: '150px', sorted: true
    },
    {
        id: 'estado',
        label: 'Estado',
        width: '117px', sorted: true
    },
    {
        id: 'causal',
        label: 'Causal',
        width: '100px', sorted: true
    },
    {
        id: 'fechaRecepcion',
        label: 'Fecha Recepción',
        width: '150px', sorted: true
    },
    {
        id: 'fechaAsignacionUsuario',
        label: 'Fecha Asignación',
        width: '150px', sorted: true
    },
    {
        id: 'fechaProbableLiquidacion',
        label: 'Fecha compromiso',
        width: '200px', sorted: true
    },

    {
        id: 'diasRestantes',
        label: 'Días Rest.',
        width: '140px', sorted: true
    },
    {
        id: 'montoTotalCobro',
        label: 'Monto de cobro',
        width: '170px', sorted: true
    },
    {
        id: 'canal',
        label: 'Canal',
        width: '124px', sorted: true
    },
    {
        id: 'usuario',
        label: 'Usuario asignado',
        width: '124px'
    },
    {
        id: 'acciones',
        label: 'Acciones',
        width: '121px'
    },
];


export const HeadConsultaCtas = ({
    order,
    orderBy,
    onRequestSort,
}: any) => {

    const createSortHandler = (id: any, label: string) => (event: any) => {
        onRequestSort(event, id, label);
    };

    return (
        <TableHead>
            <TableRow >
                {
                    headCellCuentas.map((headCell: any, i: number) => {
                        if (headCell.id === 'acciones') {
                            return <TableCell key={headCell.id} className="headerFirstSticky escritorioTrabajo" >{headCell.label}</TableCell>
                        } else {
                            if (headCell.sorted) {
                                return (
                                    <TableCell
                                        className={
                                            orderBy === headCell.id
                                                ? "escritorioTrabajo activeSort"
                                                : "escritorioTrabajo headerSort"
                                        }
                                        key={headCell.id}
                                        sortDirection={
                                            orderBy === headCell.id ? order.toLowerCase() : false
                                        }
                                        width={headCell.width}
                                        sx={{ minWidth: headCell.width }}
                                    >
                                        <TableSortLabel
                                            active={orderBy === headCell.id}
                                            direction={
                                                orderBy === headCell.id ? order.toLowerCase() : "desc"
                                            }
                                            onClick={createSortHandler(headCell.id, headCell.label)}
                                            IconComponent={() => (
                                                <DropdownIndicator
                                                    active={orderBy === headCell.id}
                                                    order={orderBy === headCell.id ? order.toLowerCase() : "desc"}
                                                />
                                            )}
                                        >
                                            {headCell.label}
                                        </TableSortLabel>
                                    </TableCell>
                                )
                            } else {
                                return (
                                    <TableCell
                                        className={"escritorioTrabajo headerSort"}
                                        key={headCell.id}
                                        width={headCell.width}
                                    >
                                        {headCell.label}
                                    </TableCell>)
                            }

                        }

                    })
                }

            </TableRow>
        </TableHead>
    );
}