import { Box, Typography } from "@mui/material";
import {
    inProcessStatusStyle,
    receivedStatusStyle,
    rejectedStatusStyle,
} from "../../../assets/styles/Box/statusHistorialResTable";
import { CustomTooltip } from "../../../components/Tooltip/tooltipbs";

const estados = ["Exitoso", "Reversando", "Error al Reversar"];
export const EstadosReversa = ({ estado }: any) => {
    let styleButtonState = {};

    switch (estado) {
        case 1:
            styleButtonState = receivedStatusStyle;
            break;
        case 2:
            styleButtonState = inProcessStatusStyle;
            break;
        case 3:
            styleButtonState = rejectedStatusStyle;
            break;
        default:
            styleButtonState = {};
            break;
    }

    return (
        <CustomTooltip
            title={`Esta cuenta no ha podido ser reversada 
            Por favor vuelve a intentarlo más tarde.`}
            arrow
            placement="left"
            sx={{maxWidth: "320px"}}
            disableHoverListener={estado&&estado!==3}
        >
            <Box sx={styleButtonState} data-testid="tagReversa">
                <Typography variant="h6" fontSize="12px">
                    {estado ? estados[estado - 1] : ""}
                </Typography>
            </Box>
        </CustomTooltip>

    )
};