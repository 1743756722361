import Box from "@mui/material/Box";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Typography,
  Button
} from "@mui/material";
import { CustomInput } from "../../../../assets/styles/Input/input";
import { useTableWithOrder } from "../../../../hooks/table/useTableWithOrder";
import { stableSort, getComparator } from "../../../../utils/bonificaciones";
import { format } from "date-fns";
import { HeadReportes } from "./headReportes";

export const TableReportes = ({
  anchorEl,
  handleClickDescargar,
  data
}: any) => {

  const filterListBy = (list: any, filterBy: string, orderBy: string) => {
    const re = new RegExp(filterBy, "i");
    if (orderBy === "fechaRecepcion") {
      return list?.filter((p: any) => {
        const date = p[orderBy]
          ? format(
              new Date(p[orderBy].replace(/T.+/, "").replace(/-/g, "/")),
              "dd-MM-yyyy"
            )
          : "";
        return re.test(date);
      });
    }

    return list?.filter((p: any) => {
      switch (orderBy) {
        case "rut":
          return re.test(p.prestador[orderBy]);
        default:
          return re.test(p[orderBy]);
      }
    });
  };

  const {
    order,
    orderBy,
    page,
    rowsPerPage,
    orderColumn,
    handleChangeFilterBy,
    filterList,
    handleRequestSort,
    handleChangePage,
    handleChangeRowsPerPage,
  } = useTableWithOrder({
    list: data,
    initialRowsPerPage: 5,
    initialOrder: "desc",
    initialOrderBy: "",
    filterListBy,
  });

  const formatPeriodo = (periodo: string) => {
    if(periodo !== null && periodo !== undefined && periodo !== ""){
      const anio = periodo.substring(0,4)
      const mes = periodo.substring(4,6)
      return `${anio}-${mes}`
    }else{
      return ""
    }
  }

  return (
    <>
      <Box width="100%">
        <Paper className="paper-tables-2">
          <CustomInput
            placeholder={`Filtrar prestaciones por ${orderColumn}`}
            onChange={(e: any) => handleChangeFilterBy(e.target.value)}
            sx={{ pr: "24px", pl: "24px", pb: "24px" }}
            fullWidth
          />
          <TableContainer sx={{ p: 0 }}>
            <Table sx={{ minWidth: "1500px" }}>
              <HeadReportes
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                onSelectAllClick={(e: any) => { }}
                rowCount={12}
                numSelected={12}
              />
              <TableBody>
                {stableSort(
                  filterList,
                  getComparator(
                    order,
                    orderBy,
                  )
                )
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row: any, index: any) => {
                    return (
                      <TableRow hover tabIndex={-1} key={`${row.folioDocumento}-${row.fechaGeneracion}`}>
                        <TableCell className="tCell-p">
                          <Typography>{formatPeriodo(row.folioDocumento)}</Typography>
                        </TableCell>
                        <TableCell className="tCell-p">
                          <Typography>{row.nombreArchivo}</Typography>
                        </TableCell>
                        <TableCell className="tCell-p">
                          <Typography>{row.fechaGeneracion}</Typography>
                        </TableCell>
                        <TableCell className="tCell-p">
                          <Button onClick={() => handleClickDescargar(row)} variant="contained">Descargar</Button>
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 15, 20, 25]}
            component="div"
            count={data.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage={"Mostrar por página"}
            sx={{ pt: "24px" }}
            labelDisplayedRows={(e) => {
              return "" + (page + 1) + " de " + Math.ceil(data.length / rowsPerPage);
            }}
          />
        </Paper>
      </Box>
    </>
  );
};
