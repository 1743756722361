import styled from "@emotion/styled";
import { AppBar, Divider } from "@mui/material";

const StyledAppBar = styled(AppBar)({
    boxShadow: 'none',
    height: '72px'
});

const StyledDivider = styled(Divider)({
  height: '56px', 
  border: '0.5px solid rgba(255, 255, 255, 0.6)'
});

const appBarName = {
  fontWeight: 700,
  fontSize: '14px',
  letterSpacing: '0.0120588em',
  color: '#FFFFFF',
};

const appBarJob = {
  fontWeight: 400,
  fontSize: '12px',
  letterSpacing: '0.0120588em',
  color: '#FFFFFF',
  textTransform: 'capitalize'
};

const appBarLogout = {
  paddingLeft: '33px', 
  paddingRight: '28px',
  fontWeight: 400,
  fontSize: '16px',
  letterSpacing: '0.0120588em',
  color: '#FFFFFF',
}

export {
    StyledAppBar,
    StyledDivider,
    appBarName,
    appBarJob,
    appBarLogout
}