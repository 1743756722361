import { Box, Typography } from "@mui/material";
import {
  inProcessStatusStyle,
  pendingStatusStyle,
  receivedStatusStyle,
  sendingStatusStyle,
} from "../../../assets/styles/Box/statusHistorialResTable";
import { CustomTooltip } from "../../../components/Tooltip/tooltipbs";
interface Props {
  estado: string | null;
  idCuentaMedica: number;
}

const estados: any = {
  1: "Exitoso",
  2: "Procesando",
  3: "Error",
  4: "Pendiente",
};

export const FilterButtonState = ({ estado, idCuentaMedica }: Props) => {
  let styleButtonState = {};
  const estadoInt =
    (estado !== null && estado !== undefined) ? parseInt(estado) : 4;
  switch (estadoInt) {
    case 1:
      styleButtonState = receivedStatusStyle;
      break;
    case 2:
      styleButtonState = sendingStatusStyle;
      break;
    case 3:
      styleButtonState = pendingStatusStyle;
      break;
    case 4:
      styleButtonState = inProcessStatusStyle;
      break;
    default:
      styleButtonState = receivedStatusStyle;
      break;
  }

  return estado === "3" ? (
    <CustomTooltip
    title={`Ocurrió un error al emitir la cuenta Nº ${idCuentaMedica}. 
    Por favor, contacta a tu supervisor para 
    solucionar el problema.`}
    arrow
    placement="left">
      <Box sx={styleButtonState}>
        <Typography variant="h6" fontSize="12px">
          {estados[estadoInt]}
        </Typography>
      </Box>
    </CustomTooltip>
  ) : (
    <>
      <Box sx={styleButtonState}>
        <Typography variant="h6" fontSize="12px">
          {estados[estadoInt]}
        </Typography>
      </Box>
    </>
  );
};
