import { useState } from "react";
import {
  CuentasMedicasQuery,
  getMedicalRecordStatus,
  queryObtenerNuevasCausales,
} from "../../../services/cm-services/escritorio-cm/escritorio-services";
import { mutationAsignarUsuario } from "../../../services/administracionCM/administracionCMServices";
import { useQuery, useLazyQuery, useMutation } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import { useGetUser } from "../../../hooks/getUser";
import { exportCuentasEscritorio } from "../components/exportToExcelCuentas";
import { medicalRecordStatus } from "../../../types";
import { NuevosEstadosCuentaMedica } from "../../../utils/enums";

type Filtro = {
  id?: number;
  idCMD?: number;
  numeroCuentaPrestador?: String;
  rutPaciente?: string;
  rutPrestador?: string;
  causal?: number;
  estado?: number;
  fechaRecepcion?: Date;
  fechaAsignacionUsuario?: Date;
  fechaProbableLiquidacion?: Date;
  diasRestantes?: number;
  montoTotalCobro?: number;
  canalOrigen?: string;
  idUsuario?: string;
} | null;

type CuentaMedicaAsignacion = {
  id: number | null;
  idCMD: number | null;
};

const emptyCuentaMedicaAsignacion = { id: null, idCMD: null };

function useEscritorio(onlyAssigned: boolean, statusType: string, sendMessage: Function, isAuth?: any) {
  const [listaEscritorio, setListaEscritorio] = useState([]);
  const [count, setCount] = useState<number>(0);
  const [openModalFiltro, setOpenModalFiltro] = useState<boolean>(false);
  const [filtros, setFiltros] = useState<Filtro>(null);
  const [errorResults, setErrorResults] = useState<boolean>(false);
  const [openModalAsigarme, setOpenModalAsignarme] = useState<boolean>(false);
  const [cuentaMedicaAsigancion, setCuentaMedicaAsignacion] =
    useState<CuentaMedicaAsignacion>(emptyCuentaMedicaAsignacion);
  const [causales, setCausales] = useState([]);
  const [medicalRecordStatusList, setMedicalRecordStatusList] = useState<
    medicalRecordStatus[]
  >([]);
  const [selectCausales, setSelectCausales] = useState([]);
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(25);
  const [order, setOrder] = useState(null);

  const { email, id, nombre, rol } = useGetUser();

  const { loading, refetch } = useQuery(CuentasMedicasQuery, {
    variables: {
      offset: offset,
      limit: limit,
      filterBy: onlyAssigned ? { ...filtros, idUsuario: email } : isAuth ? { ...filtros, estado: NuevosEstadosCuentaMedica.PendienteAutorizacion } : filtros,
      orderBy: order,
    },
    onCompleted: (data) => {
      setListaEscritorio(data.obtenerCuentasMedicas.cuentasMedicas);
      setCount(data.obtenerCuentasMedicas.count);
    },
    onError: (err) => {
      console.log(err);
      setListaEscritorio([]);
      setCount(0);
      setErrorResults(true);
    },
  });

  useQuery(queryObtenerNuevasCausales, {
    onCompleted: (dataCausales) => {
      setCausales(dataCausales.obtenerNuevasCausales);
      setSelectCausales(
        dataCausales.obtenerNuevasCausales.map((element: any) => element.descripcion)
      );
    },
    onError: (err) => {
      console.log(err);
    },
  });

  useQuery(getMedicalRecordStatus, {
    variables: {
      statusType
    },
    onCompleted: (dataMedicalRecordStatus) => {
      const cleanMedicalRecordStatusList = dataMedicalRecordStatus.getMedicalRecordStatus.map(
        (status: medicalRecordStatus) => ({
          value: status.idParametroInterno,
          label: status.valorParametro,
        })
      );
      setMedicalRecordStatusList(cleanMedicalRecordStatusList);
    },
    onError: (err) => {
      console.log(err);
    },
  });

  const [fnGetCuentasExportarExcel, { loading: loadingCuentasExportar, fetchMore }] =
    useLazyQuery(CuentasMedicasQuery, {
      onCompleted: (res) => procesaDataCuentas(res),
      onError: () => {
        sendMessage('error', "No se ha podido asignar la Cuenta Medica, por favor intente nuevamente");
      }
    });
  const [fnAsignarUsuario, { loading: loadingAsignarUsuario }] = useMutation(
    mutationAsignarUsuario
  );

  const navigate = useNavigate();

  const handleOnOpenModalFiltro = () => {
    setOpenModalFiltro(true);
  };

  const handleOnCloseModalFiltro = () => {
    setOpenModalFiltro(false);
  };

  const handleOpenModalAsignarme = (
    id: number | null,
    idCMD: number | null
  ) => {
    setCuentaMedicaAsignacion({ id, idCMD });
    setOpenModalAsignarme(true);
  };

  const handleOnCloseModalAsignarme = () => {
    setCuentaMedicaAsignacion(emptyCuentaMedicaAsignacion);
    setOpenModalAsignarme(false);
  };

  const handleOnClickAsignarme = () => {
    const toastMessageFailed = "No se ha podido asignar la Cuenta Medica, por favor intente nuevamente"
    const toastMessageSuccess = "Se ha asignado la cuenta correctamente"
    const toastMessageAsignacionInvalida = "No se ha podido asignar la cuenta debido a que ya la tiene asignada otro usuario"

    fnAsignarUsuario({
      variables: {
        idCuentaMedica: cuentaMedicaAsigancion.id,
        usuario: { id: id, nombre: nombre, email: email },
      },
    })
      .then(({ data }: any) => {
        if (data.asignarUsuario.estado === 0) {
          setOpenModalAsignarme(false);
          sendMessage('success', toastMessageSuccess);
        } else if (data.asignarUsuario.estado === 3) {
          setOpenModalAsignarme(false);
          sendMessage('warning', toastMessageAsignacionInvalida);
        } else {
          setOpenModalAsignarme(false);
          sendMessage('error', toastMessageFailed);
        }
        refetch();
      })
      .catch((error: any) => {
        console.warn(error);
        setOpenModalAsignarme(false);
        sendMessage('error', toastMessageFailed)
        refetch();
      });
  };

  const handleOnClickGestionarCuenta = (row: any) => {
    navigate(`/Escritorio/AdministracionCM`, {
      state: {
        idCuentaMedica: row.id,
        datosBeneficiario: {
          rut: row.rutPaciente,
          nombre: row.nombrePaciente,
          apellidoPaterno: row.apellidoPaternoPaciente,
          apellidoMaterno: row.apellidoMaternoPaciente,
        },
        canalOrigen: row.canalOrigen,
        isAuth: row.estado === "Pendiente de Autorización" ? true : false
      }
    });
  };

  const handleRevisarCuenta = (row: any) => {

    const verifyLiq = rol.includes('LIQUIDADOR');

    navigate(`/Escritorio/AdministracionCM`, {
      state: {
        idCuentaMedica: row.id,
        datosBeneficiario: {
          rut: row.rutPaciente,
          nombre: row.nombrePaciente,
          apellidoPaterno: row.apellidoPaternoPaciente,
          apellidoMaterno: row.apellidoMaternoPaciente,
        },
        canalOrigen: row.canalOrigen,
        readOnly: true,
        habilitaLiq: verifyLiq
      }
    });
  };

  async function procesaDataCuentas(result: any) {
    let temp = result.obtenerCuentasMedicas;
    if (temp) {
      while (temp.cuentasMedicas.length < temp.count) {
        const { data } = await fetchMore({
          variables: {
            offset: temp.cuentasMedicas.length,
            limit: 500,
            filterBy: onlyAssigned ? { ...filtros, idUsuario: email } : isAuth ? { ...filtros, estado: NuevosEstadosCuentaMedica.PendienteAutorizacion } : filtros,
            orderBy: null,
          },
        });
        const mergeSol = [
          ...temp.cuentasMedicas,
          ...data.obtenerCuentasMedicas.cuentasMedicas,
        ];
        temp = {
          ...temp,
          cuentasMedicas: mergeSol,
        };
      }

      const reporte = temp.cuentasMedicas;
      setListaEscritorio(reporte);
      exportCuentasEscritorio(reporte, sendMessage, onlyAssigned);
    }
  }

  const handleOnClickExportCuentas = () => {
    fnGetCuentasExportarExcel({
      variables: {
        offset: 0,
        limit: 500,
        filterBy: onlyAssigned ? { ...filtros, idUsuario: email } : isAuth ? { ...filtros, estado: NuevosEstadosCuentaMedica.PendienteAutorizacion } : filtros,
        orderBy: null,
      },
    });
  };

  const reloadTableEscritorio = (
    offs: number,
    lim: number,
    orderBy?: any,
    filter?: any
  ) => {
    let order = orderBy ? orderBy : null;
    const filtersBy = filter ? filter : filtros;
    setOffset(offs);
    setLimit(lim);
    setOrder(order);
    setFiltros(filtersBy);
    refetch();
  };

  return {
    refetch,
    listaEscritorio,
    count,
    loading,
    loadingAsignarUsuario,
    reloadTableEscritorio,
    handleOnClickGestionarCuenta,
    openModalAsigarme,
    handleOnClickAsignarme,
    handleOpenModalAsignarme,
    handleOnCloseModalAsignarme,
    handleOnClickExportCuentas,
    loadingCuentasExportar,
    openModalFiltro,
    handleOnOpenModalFiltro,
    handleOnCloseModalFiltro,
    filtros,
    errorResults,
    handleRevisarCuenta,
    cuentaMedicaAsigancion,
    rol,
    causales,
    selectCausales,
    medicalRecordStatusList,
  };
}

export default useEscritorio;
