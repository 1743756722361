import {
    Button,
    DialogActions,
    DialogContent,
    FormControl,
    FormControlLabel,
    FormHelperText,
    Grid,
    RadioGroup,
    Stack,
    Typography,
} from "@mui/material";
import { CustomModal, CustomModalTitle } from "../../../../components/Modal/customModal";
import { modalTextBold, modalTitle, warningGray } from "../../../../assets/styles/Text/text";
import { CustomInput } from "../../../../assets/styles/Input/input";
import { GreenRadio } from "../../../../components/radioButton/CustomRadio";
import { useState } from "react";
import { SelectPrestaciones } from "../../../../components/Prestaciones/SelectPrestaciones";
import { SelectTipoProfesional } from "../../../../components/TipoProfesional/SelectTipoProfesional";
import { parseMiles } from "../../../../utils/monedaUtils";
import { SelectTipoAtencion } from "../../../../components/TiposAtencion/SelectTipoAtencion";
import { useMutation } from "@apollo/client";
import { crearPrestacion } from "../../../../services/administracionCM/administracionCMServices";
import BotonCarga from "../../../../components/Buttons/LoadingButton";
import { checkStatus } from "../../utils/checkStatus";
import Aviso from "../../../../components/Alert/Aviso";

export const ModalPrestacion = ({ onOpen, onClose, idCuentaMedica, fechaHospitalizacion, idCobro, idCobertura, refetch, muestraMensaje, isGES, totalDoc, totalPres }: any) => {
    const [dataPrestacion, setDataPrestacion] = useState<any>({
        itemPrestacion: 0
    });
    const [loaders, setLoaders] = useState({
        loaderA: false,
        loaderB: false
    });
    const [guardaPrestacion, { loading }] = useMutation(crearPrestacion);

    const cambiaPrestacion = (value: any) => {
        const glosa = value ? value.descripcion : "";
        setDataPrestacion({
            ...dataPrestacion,
            prestacion: value,
            glosaPrestacion: glosa
        });
    }

    const onChange = (e: { target: { name: string; value: any; }; }) => {
        const name = e.target.name;
        let value = e.target.value;        
        if (["porcentajeCobro", "montoUnidad", "montoCobro", "cantidadAtencion", "intervencion"].includes(name)) {
            value = value.replace(/[^0-9]/g, '');
        }
        if (name === "porcentajeCobro" && (parseInt(value) > 100 || parseInt(value) < 0)) {
            return;
        }
        if (name === "montoUnidad" && (parseInt(value) > 2000000000 || parseInt(value) < 0)) {            
            return;
        }

        const newDataPrestacion = { ...dataPrestacion, [name]: value };

        if (name === "montoUnidad") {
            newDataPrestacion.montoCobro = value * dataPrestacion?.cantidadAtencion;
        }        
        if (name === "cantidadAtencion") {
            newDataPrestacion.montoCobro = value * dataPrestacion?.montoUnidad;
        }      
        
        setDataPrestacion(newDataPrestacion);          
    }

    const checkDisabled = () => {
        if (!dataPrestacion || !dataPrestacion.prestacion || !dataPrestacion.prestacion.codigo || !dataPrestacion.glosaPrestacion ||
            !dataPrestacion.cantidadAtencion || !dataPrestacion.montoCobro ||
            !dataPrestacion.tipoAtencion || !dataPrestacion.intervencion || !dataPrestacion.porcentajeCobro ||
            !dataPrestacion.urgencia || !dataPrestacion.recargoHorario) {
            return true;
        } else {
            return false;
        }
    }

    const guardarPrestacion = (reopen: boolean) => {
        if (reopen) {
            setLoaders({ ...loaders, loaderA: true });
        } else {
            setLoaders({ ...loaders, loaderB: true });
        }
        guardaPrestacion({
            variables: {
                idCuenta: idCuentaMedica,
                fechaInicioHospitalizacion: fechaHospitalizacion,
                idCobro: idCobro,
                idCobertura: idCobertura,
                dataPrestacion: {
                    idItemIsapre: dataPrestacion.itemPrestacion,
                    frecuenciaAtencion: parseInt(dataPrestacion.cantidadAtencion),
                    montoPrestacionCobro: parseInt(dataPrestacion.montoCobro),
                    montoPrestacionDefinitivo: parseInt(dataPrestacion.montoCobro),
                    TipoAtencion: dataPrestacion.tipoAtencion,
                    numeroOrdenIntervencion: dataPrestacion.intervencion,
                    porcentajeCobro: dataPrestacion.porcentajeCobro,
                    atencionUrgencia: dataPrestacion.urgencia,
                    prestacionRecargoHorario: dataPrestacion.recargoHorario,
                    codigoPrestacionIsapre: dataPrestacion.prestacion.codigo,
                    glosaPrestacionIsapre: dataPrestacion.glosaPrestacion,
                    codigoCanasta: dataPrestacion.codigoCanasta
                }
            },
            onCompleted: () => {
                muestraMensaje("Prestación añadida con éxito.", 'success');
                if (reopen) {
                    setDataPrestacion({ itemPrestacion: 0 });
                    setLoaders({ ...loaders, loaderA: false });
                } else {
                    clearAndClose();
                }
                refetch();
            },
            onError: (err: any) => {
                console.log(err);
                setLoaders({ loaderA: false, loaderB: false });
                muestraMensaje("Ocurrió un error al añadir la prestación. Por favor, inténtalo de nuevo.", 'error');
                checkStatus(err.graphQLErrors[0]?.code || "");
            }
        })
    }

    const clearAndClose = () => {
        setLoaders({ loaderA: false, loaderB: false });
        setDataPrestacion({ itemPrestacion: 0 });
        onClose();
    }

    return (
        <CustomModal
            fullWidth
            maxWidth="sm"
            open={onOpen}
            sx={{
                "& .MuiBackdrop-root": {
                    backgroundColor: "rgba(0, 0, 0, 0.3)",
                },
            }}
        >

            <CustomModalTitle>
                <Typography sx={modalTitle} paddingTop="16px">
                    Nueva prestación
                </Typography>
            </CustomModalTitle>

            <DialogContent
                dividers
                sx={{ pl: "16px", }}
                className="grayScroll"
            >
                <Typography sx={warningGray}>
                    Para poder guardar la información del documento de cobro
                </Typography>{" "}
                <Typography sx={modalTextBold} >
                    debes completar todos los campos
                </Typography>{" "}
                <Typography sx={warningGray}>
                    correctamente.
                </Typography>{" "}
                <Grid container pt="24px">
                    <Grid item xs={6} pr="16px">
                        <Typography variant="h6" pb="6px">
                            Código prestación (Isapre)
                        </Typography>
                        <SelectPrestaciones
                            value={dataPrestacion.prestacion}
                            onChange={cambiaPrestacion}
                            disabled={loading}
                            isPrestacion
                        />
                    </Grid>
                    <Grid item xs={6} >
                        <Typography variant="h6" pb="6px">
                            Glosa prestación (Isapre)
                        </Typography>
                        <CustomInput
                            fullWidth
                            placeholder="Ej: Biopsia renal"
                            name="glosaPrestacion"
                            value={dataPrestacion.glosaPrestacion || ""}
                            disabled={!(["6100001", "4000001", "3900001"].includes((dataPrestacion.prestacion && dataPrestacion.prestacion.codigo) || "")) || loading}
                            onChange={onChange}
                            inputProps={{ maxLength: 255 }}
                        />
                        <FormHelperText
                            sx={{ textAlign: 'right' }}
                        >
                            {dataPrestacion.glosaPrestacion && dataPrestacion.glosaPrestacion.length === 255 ? "Máximo alcanzado " : null}
                            {dataPrestacion.glosaPrestacion ? dataPrestacion.glosaPrestacion.length : 0}/255
                        </FormHelperText>
                    </Grid>
                    <Grid item xs={6} pr="16px" pt="16px">
                        <Typography variant="h6" pb="6px">
                            Ítem prestación (Isapre)
                        </Typography>
                        <SelectTipoProfesional
                            onChange={(val: any) => setDataPrestacion({ ...dataPrestacion, itemPrestacion: val.target.value })}
                            value={dataPrestacion.itemPrestacion}
                            disabled={loading}
                        />
                    </Grid>
                    <Grid item xs={6} pt="16px">
                        <Typography variant="h6" pb="6px">
                            Cantidad atención:
                        </Typography>
                        <CustomInput
                            fullWidth
                            placeholder="Ej: 1"
                            name="cantidadAtencion"
                            data-testid="cantidadAtencion"
                            value={dataPrestacion.cantidadAtencion || ""}
                            onChange={onChange}
                            disabled={loading}
                        />
                    </Grid>
                    <Grid item xs={6} pr="16px" pt="16px">
                        <Typography variant="h6" pb="6px">
                            Monto unitario
                        </Typography>
                        <CustomInput
                            fullWidth
                            placeholder="Ej: 10.000"
                            name="montoUnidad"
                            data-testid="montoUnidad"
                            value={parseMiles(dataPrestacion.montoUnidad || "")}
                            onChange={onChange}
                            disabled={loading}                            
                        />
                    </Grid>
                    <Grid item xs={6} pt="16px">
                        <Typography variant="h6" pb="6px">
                            Total prestación
                        </Typography>
                        <CustomInput
                            fullWidth
                            placeholder="Ej: 10.000"
                            name="montoCobro"
                            data-testid="montoCobro"
                            value={parseMiles(dataPrestacion.montoCobro || "")}                            
                            disabled
                        />
                    </Grid>
                    <Aviso
                        open={totalDoc < (totalPres + parseInt(dataPrestacion.montoCobro || 0))}
                        severidad={'warning'}
                        titulo="El monto ingresado supera el monto declarado en el documento de Cobro"
                    />
                    <Grid item xs={6} pt="16px" pr="16px">
                        <Typography variant="h6" pb="6px">
                            Tipo de atención
                        </Typography>
                        <SelectTipoAtencion                        
                            onChange={(val: any) => setDataPrestacion({ ...dataPrestacion, tipoAtencion: val.target.value })}
                            value={dataPrestacion.tipoAtencion}
                            disabled={loading}
                        />
                    </Grid>
                    <Grid item xs={6} pr="16px" pt="16px">
                        <Typography variant="h6" pb="6px">
                            Número de intervención
                        </Typography>
                        <CustomInput
                            fullWidth
                            placeholder="Ej: 1"
                            name="intervencion"
                            value={dataPrestacion.intervencion || ""}
                            disabled={loading}
                            onChange={onChange}
                        />
                    </Grid>
                    {isGES ? (
                        <Grid item xs={12} pt="16px">
                            <Typography variant="h6" pb="6px">Código canasta</Typography>
                            <CustomInput
                                disabled={loading}
                                onChange={onChange}
                                value={dataPrestacion.codigoCanasta || ""}
                                name="codigoCanasta"
                                placeholder="Ej: 1T13"
                                fullWidth
                            />
                        </Grid>
                    ) : null}
                    <Grid item xs={4} pt="16px">
                        <Typography variant="h6" pb="6px">
                            % Cobro
                        </Typography>
                        <CustomInput
                            fullWidth
                            placeholder="Ej: 80%"
                            name="porcentajeCobro"
                            data-testid="porcentajeCobro"
                            type="number"
                            InputProps={{ inputProps: { min: 0, max: 100 } }}
                            value={dataPrestacion.porcentajeCobro || ""}
                            disabled={loading}
                            onChange={onChange}
                        />
                    </Grid>

                    <Grid item xs={4} pt="16px" pl="16px">
                        <Typography variant="h6" pb="6px">
                            Urgencia
                        </Typography>
                        <FormControl>
                            <RadioGroup
                                row
                                value={dataPrestacion.urgencia || ""}
                                name="urgencia"
                                onChange={onChange}
                                sx={{ paddingTop: "8px" }}
                            >
                                <FormControlLabel
                                    value="S"
                                    control={<GreenRadio />}
                                    label="Sí"
                                    disabled={loading}
                                />
                                <FormControlLabel
                                    sx={{ paddingLeft: "16px" }}
                                    value="N"
                                    control={<GreenRadio />}
                                    label="No"
                                    disabled={loading}
                                />
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                    <Grid item xs={4} pt="16px">
                        <Typography variant="h6" pb="6px">
                            Recargo horario
                        </Typography>
                        <FormControl>
                            <RadioGroup
                                row
                                value={dataPrestacion.recargoHorario || null}
                                name="recargoHorario"
                                onChange={onChange}
                                sx={{ paddingTop: "8px" }}
                            >
                                <FormControlLabel
                                    value="S"
                                    control={<GreenRadio />}
                                    label="Sí"
                                    disabled={loading}
                                />
                                <FormControlLabel
                                    sx={{ paddingLeft: "16px" }}
                                    value="N"
                                    control={<GreenRadio />}
                                    label="No"
                                    disabled={loading}
                                />
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                </Grid>
            </DialogContent>

            <DialogActions sx={{ margin: "5px 0 5px 0" }}>
                <Stack
                    spacing={"16px"}
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                >
                    <Button
                        variant="text"
                        color="inherit"
                        sx={{ p: "15px" }}
                        disabled={loading}
                        onClick={clearAndClose}
                    >
                        Cancelar
                    </Button>
                    <BotonCarga
                        type="secondary"
                        sx={{ p: "15px", minWidth: "223px" }}
                        disabled={checkDisabled() || loading}
                        loading={loaders.loaderA}
                        toDo={() => guardarPrestacion(true)}
                    >
                        Añadir y crear una nueva
                    </BotonCarga>
                    <BotonCarga
                        variant="contained"
                        sx={{ p: "15px" }}
                        disabled={checkDisabled() || loading}
                        loading={loaders.loaderB}
                        toDo={() => guardarPrestacion(false)}
                    >
                        Añadir
                    </BotonCarga>
                </Stack>
            </DialogActions>
        </CustomModal>
    );
};
