import { gql } from "@apollo/client";
export const CuentasMedicasQuery = gql`
  query (
    $offset: Int!
    $limit: Int!
    $filterBy: CuentaMedicaFilterInput
    $orderBy: CuentaMedicaOrderInput
    $buscadorCuentasFlag: Boolean
  ) {
    obtenerCuentasMedicas(
      offset: $offset
      limit: $limit
      filterBy: $filterBy
      orderBy: $orderBy
      buscadorCuentasFlag: $buscadorCuentasFlag
    ) {
      cuentasMedicas {
        id
        idCMD
        numeroCuentaPrestador
        idEventoHospitalario
        estado
        fechaAsignacionUsuario
        fechaProbableLiquidacion
        diasRestantes
        usuarioAsignado
        prestador {
          rutPrestador
          numeroCuenta
          numeroCobro
          razonSocial
        }
        causal {
          idCausal
          descripcionCausal
        }
        slaPendiente
        canalOrigen
        fechaDerivacion
        fechaRecepcion
        rutPaciente
        nombrePaciente
        apellidoMaternoPaciente
        apellidoPaternoPaciente
        montoTotalCobro
        historial{
          nota
          idUsuario
          tituloNota
        }
      }
      count
    }
  }
`;

export const cambiarEspecialCuenta = gql`
  query CuentaEsEspecial($idCuentaMedica: Int, $esEspecial: Boolean) {
    cambiarEsEspecial(
      idCuentaMedica: $idCuentaMedica
      esEspecial: $esEspecial
    ) {
      estado
      mensaje
    }
  }
`;

export const getHistorialCuentaMedicaQuery = gql`
  query getHistorialCuentaMedicaQuery(
    $idSolicitud: Int
    $canalOrigen: String
    $offset: Int!
    $limit: Int!
  ) {
    getHistorialCuentaMedica(
      idSolicitud: $idSolicitud
      canalOrigen: $canalOrigen
      offset: $offset
      limit: $limit
    ) {
      count
      historialCuentaMedica {
        idSolicitud
        estado
        nota
        causal {
          idCausal
          descripcionCausal
        }
        entidad
        usuario
        idUsuario
        fechaDerivacion
        entidadDerivacion
        folioCuentaMedica
        prestador
        fechaInicioCobro
        fechaTerminoCobro
        montoTotalCobro
        canalOrigen
        fechaTransaccion
        tituloNota
        estadoEtl
      }
    }
  }
`;

export const queryListadoCuentasMedicasEmision = gql`
  query (
    $offset: Int!
    $limit: Int!
    $filterBy: CuentaMedicaEmisionFilterInput
    $orderBy: CuentaMedicaEmisionOrderInput
    $rangoFechaRecepcion: DateRange
  ) {
    obtenerCuentasMedicasModuloEmision(
      offset: $offset
      limit: $limit
      filterBy: $filterBy
      orderBy: $orderBy
      rangoFechaRecepcion: $rangoFechaRecepcion
    ) {
      cuentasMedicas {
        id
        idCMD
        canalOrigen
        numeroCuentaPrestador
        rutPaciente
        nombrePaciente
        apellidoPaternoPaciente
        apellidoMaternoPaciente
        estado
        prestador {
          rutPrestador
          razonSocial
          numeroCuenta
        }
        totalFacturado
        totalBonificado
        totalCopago
        fechaRecepcion
        fechaRealLiquidacion
        fechaEmision
        emision {
          estado
        }
        reversa {
          estado
        }
      }
      count
    }
  }
`;

export const queryObtenerCausales = gql`
  query {
    obtenerCausales {
      id
      descripcion
    }
  }
`;

export const queryObtenerNuevasCausales = gql`
  query {
    obtenerNuevasCausales {
      id
      descripcion
    }
  }
`;

export const queryObtenerUsuarios = gql`
  query {
    obtenerUsuarios {
      id
      nombre
      email
    }
  }
`;

export const getMedicalRecordStatus = gql`
  query ($statusType: String) {
    getMedicalRecordStatus(statusType: $statusType) {
      idParametroInterno
      valorParametro
    }
  }
`;

export const getNewMedicalRecordStatusByFilter = gql`
  query ($currentStatus: Int, $channel: String) {
    getNewMedicalRecordStatusByFilter(currentStatus: $currentStatus, channel: $channel){
        idParametroInterno
        valorParametro
        isEnabled
    }
}
`;
