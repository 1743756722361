import { Fragment } from "react";
import { Divider, Grid } from "@mui/material";
import Lupa from "../../assets/icons/lupa.svg";
import NewLight from "../../assets/icons/noSearchIcon.svg";
import ErrorIcon from "../../assets/icons/errorRedIcon.svg";
import OficinaIcon from "../../assets/icons/oficina.svg";
interface Props {
  titulo: string;
  subtitulo: string;
  noDivider: boolean;
  error?: boolean;
  newDontSearch?: boolean;
  newFound?: boolean;
}
export const NoResult = ({
  titulo,
  subtitulo,
  noDivider,
  error = false,
  newDontSearch = false,
  newFound = false,
}: Props) => {
  const noIcons = !newDontSearch && !newFound && !error;
  return (
    <Fragment>
      {noDivider ? null : (
        <Grid item xs={12}>
          <Divider orientation="horizontal" />
        </Grid>
      )}
      <div className="box-resultados">
        <Grid
          pb="22px"
          pt="70px"
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          {newDontSearch ? <img src={NewLight} alt="idea" /> : null}
          {newFound ? <img src={OficinaIcon} alt="lupa" /> : null}
          {error ? <img src={ErrorIcon} alt="error" /> : null}
          {noIcons ? <img src={Lupa} alt="lupa" /> : null}
        </Grid>
        <Grid
          pb="4px"
          sx={{ margin: "auto 0" }}
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <label className="TituloResultadoTabla">{titulo}</label>
        </Grid>
        <Grid
          pb="70px"
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <label className="subtituloResultadoTabla">{subtitulo}</label>
        </Grid>
      </div>
    </Fragment>
  );
}
