import {
    Box,
    Grid,
    Paper,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@mui/material";
import SkeletonBar from "../../../../components/Skeletons/skeletonBar";

export const LoadValorizacionModal = () => (
    <>
        <Grid container>
            <Grid item xs={12}>
                <SkeletonBar width={'auto'} height={14} />
            </Grid>
            <Grid item xs={12} pt="12px">
                <SkeletonBar width={243} height={14} />
            </Grid>
            <Grid item xs={12} pt="12px">
                <SkeletonBar width={215} height={14} />
            </Grid>
            <Grid item xs={6} pt="24px" pr="16px">
                <Box pb="4px">
                    <SkeletonBar width={169} height={14} />
                </Box>
                <SkeletonBar width={'auto'} height={50} />
            </Grid>
            <Grid item xs={6} pt="24px">
                <Box pb="4px">
                    <SkeletonBar width={169} height={14} />
                </Box>
                <SkeletonBar width={'auto'} height={50} />
            </Grid>
            <Grid item xs={6} pt="24px" pr="16px">
                <Box pb="4px">
                    <SkeletonBar width={169} height={14} />
                </Box>
                <SkeletonBar width={'auto'} height={50} />
            </Grid>
            <Grid item xs={6} pt="24px">
                <Box pb="4px">
                    <SkeletonBar width={169} height={14} />
                </Box>
                <SkeletonBar width={'auto'} height={50} />
            </Grid>
            <Grid item xs={12} pt="24px">
                <Box pb="4px">
                    <SkeletonBar width={169} height={14} />
                </Box>
                <SkeletonBar width={'auto'} height={50} />
            </Grid>
            <Grid item xs={12} pt="32px">
                <SkeletonBar width={'auto'} height={14} />
            </Grid>
            <Grid item xs={2} pt="32px" pr="40px">
                <SkeletonBar width={'auto'} height={14} />
            </Grid>
            <Grid item xs={2} pt="32px">
                <SkeletonBar width={'auto'} height={14} />
            </Grid>
            <Grid item xs={8} pt="32px">
            </Grid>
            <Grid item xs={1} pt="32px">
                <SkeletonBar width={20} height={20} />
            </Grid>
            <Grid item xs={11} pt="32px" display={"flex"} alignItems={"center"}>
                <SkeletonBar width={298} height={14} />
            </Grid>
        </Grid>
    </>
);

export const LoadNuevaPrestacion = () => (
    <>
        <Grid container>
            <Grid item xs={12}>
                <SkeletonBar width={'auto'} height={14} />
            </Grid>
            <Grid item xs={12} pt="12px">
                <SkeletonBar width={243} height={14} />
            </Grid>
            <Grid item xs={12} pt="29px">
                <SkeletonBar width={215} height={14} />
            </Grid>
            <Grid item xs={6} pt="24px" pr="16px">
                <Box pb="4px">
                    <SkeletonBar width={169} height={14} />
                </Box>
                <SkeletonBar width={'auto'} height={50} />
            </Grid>
            <Grid item xs={6} pt="24px">
                <Box pb="4px">
                    <SkeletonBar width={169} height={14} />
                </Box>
                <SkeletonBar width={'auto'} height={50} />
            </Grid>
            <Grid item xs={6} pt="24px" pr="16px">
                <Box pb="4px">
                    <SkeletonBar width={169} height={14} />
                </Box>
                <SkeletonBar width={'auto'} height={50} />
            </Grid>
            <Grid item xs={6} pt="24px">
                <Box pb="4px">
                    <SkeletonBar width={169} height={14} />
                </Box>
                <SkeletonBar width={'auto'} height={50} />
            </Grid>
            <Grid item xs={6} pt="24px" pr="16px">
                <Box pb="4px">
                    <SkeletonBar width={169} height={14} />
                </Box>
                <SkeletonBar width={'auto'} height={50} />
            </Grid>
            <Grid item xs={6} pt="24px">
                <Box pb="4px">
                    <SkeletonBar width={169} height={14} />
                </Box>
                <SkeletonBar width={'auto'} height={50} />
            </Grid>
            <Grid item xs={6} pt="24px" pr="16px">
                <Box pb="4px">
                    <SkeletonBar width={122} height={14} />
                </Box>
                <SkeletonBar width={124} height={50} />
            </Grid>
            <Grid item xs={6} pt="24px">
                <Box pb="4px">
                    <SkeletonBar width={122} height={14} />
                </Box>
                <SkeletonBar width={124} height={50} />
            </Grid>
        </Grid>
    </>
);

export const LoadValorizacionMain = () => (
    <Grid container p="24px" data-testid="loaderValorizacion">
        <Grid item xs={12} >
            <SkeletonBar width={236} height={18} />
        </Grid>

        <Grid xs={12} pt="36px">
            <Stack direction="row" justifyContent={"space-between"}>
                <Stack direction="row" spacing={"16px"}>
                    <SkeletonBar width={400} height={50} />
                    <SkeletonBar width={150} height={50} />
                </Stack>
                <Stack direction="row" spacing={"16px"}>

                    <SkeletonBar width={101} height={50} />
                </Stack>
            </Stack>
        </Grid>
        <Grid item xs={12} pt={"32px"}>
            <Box border={"2px solid #EEECFB"} borderRadius={"8px"} p="16px">
                <Grid container>
                    <Grid item xs={3} pr="16px">
                        <SkeletonBar width={'auto'} height={30} />
                    </Grid>
                    <Grid item xs={3} pr="16px">
                        <SkeletonBar width={'auto'} height={30} />
                    </Grid>
                    <Grid item xs={3} pr="16px">
                        <SkeletonBar width={'auto'} height={30} />
                    </Grid>
                    <Grid item xs={3}>
                        <SkeletonBar width={'auto'} height={30} />
                    </Grid>
                </Grid>
            </Box>
        </Grid>
        <Grid item xs={12} pt="16px">
            <Paper
                className="paper-tables"
                sx={{ border: " 0.5px solid #F8F9F9 !important", borderRadius: "16px" }}
            >

                <LoaderTable rows={25} />
            </Paper>
        </Grid>
    </Grid>
);

export const LoaderTable = ({ rows = 25 }) => {
    return (
        <TableContainer sx={{ pl: "0 !important", boxShadow: "0px 2px 5px 0px #C9CFE3BF", border: "1px solid #E9EBEC" }} data-testid="LoaderTablaValo">
            <Table aria-label="customized table">
                <TableHead sx={{ backgroundColor: "#E6F6F5" }}>
                    <TableRow>
                        <TableCell> <SkeletonBar width={16} height={16} /></TableCell>
                        {Array.from({ length: 4 }, (v, i) =>
                            <TableCell>
                                <Stack direction={"column"} spacing={"2px"}>
                                    <SkeletonBar width={69} height={14} />
                                    <SkeletonBar width={97} height={14} />
                                </Stack>
                            </TableCell>
                        )}
                        <TableCell align="right">
                            <Stack direction={"row"} spacing={"17px"}>
                                <SkeletonBar width={36} height={14} />
                                <SkeletonBar width={36} height={14} />
                            </Stack>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow sx={{ backgroundColor: "#F8F9F9" }}>
                        <TableCell sx={{ borderRight: "1px solid #D2D6D9" }}>
                            <SkeletonBar width={16} height={16} />
                        </TableCell>
                        <TableCell sx={{ pl: "16px !important" }}>
                            <SkeletonBar width={107} height={14} />
                        </TableCell>
                        <TableCell>
                            <SkeletonBar width={96} height={14} />
                        </TableCell>
                        <TableCell>
                            <SkeletonBar width={40} height={14} />
                        </TableCell>
                        <TableCell sx={{ borderRight: "1px solid #D2D6D9" }}>
                            <SkeletonBar width={69} height={14} />
                        </TableCell>
                        <TableCell sx={{ pl: "16px !important" }}>
                            <SkeletonBar width={69} height={14} />
                        </TableCell>
                    </TableRow>
                    {Array.from({ length: rows }, (v, i) =>
                        <TableRow key={i}>
                            <TableCell sx={{ borderRight: "1px solid #D2D6D9" }}>
                                <SkeletonBar width={16} height={16} />
                            </TableCell>
                            <TableCell sx={{ pl: "16px !important" }}>
                                <SkeletonBar width={107} height={14} />
                            </TableCell>
                            <TableCell>
                                <SkeletonBar width={96} height={14} />
                            </TableCell>
                            <TableCell>
                                <SkeletonBar width={40} height={14} />
                            </TableCell>
                            <TableCell sx={{ borderRight: "1px solid #D2D6D9" }}>
                                <SkeletonBar width={69} height={14} />
                            </TableCell>
                            <TableCell sx={{ pl: "16px !important" }}>
                                <SkeletonBar width={69} height={14} />
                            </TableCell>
                        </TableRow>)}
                </TableBody>
            </Table>
            <Stack
                direction={"row"}
                spacing={"24px"}
                justifyContent={"center"}
                p="24px"
                alignItems={"center"}
            >
                <SkeletonBar width={108} height={14} />
                <SkeletonBar width={68} height={32} />
                <SkeletonBar width={88} height={32} />
            </Stack>
        </TableContainer>
    )
}
