import { Grid } from "@mui/material";
import { CustomTooltip } from "./tooltipbs";

export const verificaLargo = (texto: string, largo: number) => {
    if (texto.length > largo) {
        const textoCorto = texto.slice(0, largo);
        return (
            <Grid display={"inline-flex"} className="widthMax" height={"24px"} minWidth={"40px !important"}>
                <CustomTooltip title={texto} arrow placement="right">
                    <label>{textoCorto}...</label>
                </CustomTooltip>
            </Grid>
        );
    } else {
        return texto;
    }
};
