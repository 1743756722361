import { useState, useEffect } from "react";
import { useQuery, useLazyQuery, useMutation } from "@apollo/client";
import { queryListadoCuentasMedicasEmision } from "../../../services/cm-services/escritorio-cm/escritorio-services";
import {
  mutationEmitirCuentas,
} from "../../../services/emisionCuentas/emisionCuentas";
import { isNotUndefinedAndNull } from "../../../utils";
import { format, isValid } from "date-fns";
import { NuevosEstadosCuentaMedica } from "../../../utils/enums";
import { exportCM } from "../tabCuentas/exportToExcel";

type rangoFechasArgs = { from: any; to: any } | null;
type EstadosCuentas = { 1: string; 2: string; 3: string; 4: string };
type DatosUsuario = {
  id: number | null;
  rut: string | null;
  nombre: string | null;
  apellidoPaterno: string | null;
  apellidoMaterno: string | null;
};
type filterArgs = {
  id?: string;
  idCMD?: string;
  numeroCuentaPrestador?: String;
  rutPaciente?: String;
  rutPrestador?: String;
  fechaRecepcion?: Date;
  fechaRealLiquidacion?: Date;
  fechaEmision?: Date;
  estado?: Number;
  estadoEmision?: Number | null;
};
type varFilterArgs = {
  id?: number;
  idCMD?: number;
  numeroCuentaPrestador?: String;
  rutPaciente?: String;
  rutPrestador?: String;
  fechaRecepcion?: string;
  fechaRealLiquidacion?: string;
  fechaEmision?: string;
  estado?: Number[];
  estadoEmision?: Number | null;
};

type filterByArgs = {
  id?: filterInputArgs;
  idCMD?: filterInputArgs;
  numeroCuentaPrestador?: filterInputArgs;
  rutPaciente?: filterInputArgs;
  rutPrestador?: filterInputArgs;
  fechaRecepcion?: filterInputArgs;
  fechaRealLiquidacion?: filterInputArgs;
  fechaEmision?: filterInputArgs;
  estado?: filterInputArgs;
  estadoEmision?: filterInputArgs;
};

type filterInputArgs = {
  position: number;
  direction: "ASC" | "DESC";
};

const datosUsuarioEmpty = {
  id: null,
  rut: "",
  nombre: "",
  apellidoPaterno: "",
  apellidoMaterno: "",
};

function useEmisionCuentaMedica(tabIndex: string, setSnackData: Function) {
  const [fechaDesde, setFechaDesde] = useState<any>(null);
  const [fechaHasta, setFechaHasta] = useState<any>(null);
  const [disabledBusqueda, setDisabledBusqueda] = useState<boolean>(true);
  const [isDisabledFechaHasta, setIsDisabledFechaHasta] =
    useState<boolean>(true);
  const [listaCuentasLiquidadas, setListaCuentasLiquidadas] = useState<any[]>(
    []
  );
  const [isReversa, setIsReversa] = useState<boolean>(false);
  const [count, setCount] = useState<number>(0);
  const [selectedCuentaMedica, setSelectedCuentaMedica] = useState<number[]>([]);
  const [modalEmitirMultiplesCuentas, setModalEmitirMultiplesCuentas] =
    useState<boolean>(false);
  const [modalEmitirCuentaMedica, setModalEmitirCuentaMedica] =
    useState<boolean>(false);
  const [emisionDatosUsuario, setEmisionDatosUsuario] =
    useState<DatosUsuario>(datosUsuarioEmpty);
  const [cuentasMedicasEmision, setCuentasMedicasEmision] = useState([]);
  const [isEmptyState, setIsEmptyState] = useState<boolean>(true);
  const [orderBy, setOrberBy] = useState<filterByArgs>({
    id: {
      position: 1,
      direction: "DESC",
    },
  });
  const [orderDir, setOrderDir] = useState<"ASC" | "DESC">("DESC");
  const [orderByCol, setOrderByCol] = useState("id");
  const [dataFiltro, setDataFiltro] = useState<filterArgs>({
    id: undefined,
    idCMD: undefined,
    numeroCuentaPrestador: undefined,
    rutPaciente: undefined,
    rutPrestador: undefined,
    fechaRecepcion: undefined,
    fechaRealLiquidacion: undefined,
    estadoEmision: undefined,
    estado: undefined
  });
  const [variableFiltros, setVariableFiltros] = useState<varFilterArgs>({
    estado: tabIndex === "1" ? [NuevosEstadosCuentaMedica.Liquidada] : [NuevosEstadosCuentaMedica.Emitida, NuevosEstadosCuentaMedica.EmitidaManual]
  });
  const [rangoFechas, setRangoFechas] = useState<rangoFechasArgs>(null);
  const [observacionUsuarioModal, setObservacionUsuarioModal] =
    useState<string>("");
  const [pageCuenta, setPageCuenta] = useState(0);
  const [linesCuentas, setLinesCuentas] = useState(10);

  const estadosCuentas: EstadosCuentas = {
    1: "Exitoso",
    2: "Procesando",
    3: "Error",
    4: "Pendiente",
  };

  const [openAlert, setOpenAlert] = useState(false);
  const [contentAlert, setContentAlert] = useState({
    content: "",
    isSuccess: false,
  });
  const { loading, startPolling, stopPolling, networkStatus, refetch } =
    useQuery(queryListadoCuentasMedicasEmision, {
      variables: {
        offset: pageCuenta * linesCuentas,
        limit: linesCuentas,
        filterBy: variableFiltros,
        orderBy: orderBy,
        rangoFechaRecepcion: rangoFechas,
      },
      fetchPolicy: "no-cache",
      notifyOnNetworkStatusChange: true,
      onCompleted: (res) => {
        if (res.obtenerCuentasMedicasModuloEmision.count === 0) {
          setListaCuentasLiquidadas([]);
          setCount(0);
          setSelectedCuentaMedica([]);
        } else {
          setListaCuentasLiquidadas(
            res.obtenerCuentasMedicasModuloEmision.cuentasMedicas
          );
          const ids: number[] = res.obtenerCuentasMedicasModuloEmision.cuentasMedicas.map((cta: { id: number }) => cta.id);
          setSelectedCuentaMedica(selectedCuentaMedica.filter(id => ids.includes(id)));
          setCount(res.obtenerCuentasMedicasModuloEmision.count);
          let reversa =
            res.obtenerCuentasMedicasModuloEmision.cuentasMedicas.find(
              (cta: any) => cta.reversa && cta.reversa.estado
            );
          if (tabIndex === "2" && reversa) {
            setIsReversa(true);
          } else {
            setIsReversa(false);
          }
        }
      },
      onError: (err) => {
        console.warn("Error getCuentasMedicas", err);
        setListaCuentasLiquidadas([]);
        setCount(0);
        setSelectedCuentaMedica([]);
      },
    });
  const [fnExportarCuentasMedicas, { loading: loadingExport, fetchMore }] =
    useLazyQuery(queryListadoCuentasMedicasEmision, {
      onCompleted: (res) => procesaData(res),
      onError: () => showMessage("Error al exportar cuentas", "error"),
    });

  const exportarCuentasMedicas = () => {
    setIsEmptyState(false);
    fnExportarCuentasMedicas({
      variables: {
        offset: 0,
        limit: 500,
        filterBy: variableFiltros,
        orderBy: orderBy,
        rangoFechaRecepcion: rangoFechas,
      },
    });
  };

  async function procesaData(result: any) {
    let temp = result.obtenerCuentasMedicasModuloEmision;
    if (temp) {
      while (temp.cuentasMedicas.length < temp.count) {
        const { data } = await fetchMore({
          variables: {
            offset: temp.cuentasMedicas.length,
            limit: 500,
            filterBy: variableFiltros,
            orderBy: orderBy,
            rangoFechaRecepcion: rangoFechas,
          },
        });
        const mergeSol = [
          ...temp.cuentasMedicas,
          ...data.obtenerCuentasMedicasModuloEmision.cuentasMedicas,
        ];
        temp = {
          ...temp,
          cuentasMedicas: mergeSol,
        };
      }

      const reporte = temp.cuentasMedicas;
      exportCM(
        reporte,
        showMessage,
        tabIndex
      );
    }
  }

  const [
    fnEmitirCuentas,
    { loading: loadingEmitirCuentas, error: errorEmitirCuentas },
  ] = useMutation(mutationEmitirCuentas, { fetchPolicy: "no-cache" });

  const handleSelectAllClick = (event: any) => {
    if (event.target.checked) {
      const newSelecteds = listaCuentasLiquidadas
        .filter((n: any) =>
          !n.emision || !n.emision.estado || n.emision.estado === "3"
        )
        .map((item: any) => item.id);
      setSelectedCuentaMedica(newSelecteds);
      return;
    } else {
      setSelectedCuentaMedica([]);
    }
  };

  const handleOnClickSeleccionar = (tabindex: string) => {
    buscarCuentasMedicas(dataFiltro, tabindex);
  };

  const handleOnChangeFechaDesde = (newDate: any) => {
    setFechaDesde(newDate);
  };

  const handleOnChangeFechaHasta = (newDate: any) => {
    setFechaHasta(newDate);
  };

  const reiniciarFechas = () => {
    setFechaHasta(null);
    setFechaDesde(null);
  };

  const handleEmitirListadoCuentas = (nota: string) => {
    const toastContentInitialProcess =
      "La emisión de la cuenta está siendo procesada, puede tomar algunos minutos.";
    const toastContentFail =
      "Ocurrió un error al emitir las cuentas. Por favor, contacta a tu supervisor para solucionar el problema.";
    fnEmitirCuentas({
      variables: { id: selectedCuentaMedica, nota: nota },
      onCompleted: (res) => {
        if (res !== null) {
          if (res.emitirCuentasMedicas !== null) {
            if (res.emitirCuentasMedicas.estado === 0) {
              setSelectedCuentaMedica([]);
              setModalEmitirMultiplesCuentas(false);
              showMessage(toastContentInitialProcess, "info");
              buscarCuentasMedicas(dataFiltro);
            }
          }
        } else {
          showMessage(toastContentFail, "error");
        }
      },
      onError: (error: any) => {
        console.warn(error);
        showMessage(toastContentFail, "error");
      },
    });
  };

  const handleEmitirCuenta = (nota: string) => {
    const toastContentInitialProcess =
      "La emisión de la cuenta está siendo procesada, puede tomar algunos minutos.";
    const toastContentFail =
      "Ocurrió un error al emitir las cuentas. Por favor, contacta a tu supervisor para solucionar el problema.";
    fnEmitirCuentas({
      variables: { id: [emisionDatosUsuario.id], nota: nota },
      onCompleted: (res) => {
        if (res !== null) {
          if (res.emitirCuentasMedicas !== null) {
            if (res.emitirCuentasMedicas.estado === 0) {
              setModalEmitirCuentaMedica(false);
              showMessage(toastContentInitialProcess, "info");
              buscarCuentasMedicas(dataFiltro);
            }
          }
        } else {
          setModalEmitirCuentaMedica(false);
          showMessage(toastContentFail, "error");
        }
      },
      onError: (error) => {
        console.warn(error);
        setModalEmitirCuentaMedica(false);
        showMessage(toastContentFail, "error");
      },
    });
  };

  const handleOnChangeObservacionModal = (e: any) => {
    setObservacionUsuarioModal(e.target.value);
  };

  const buscarCuentasMedicas = (
    filterBy: any,
    tabIndex = "1",
    resetFecha = false
  ) => {
    setIsEmptyState(false);
    setIsReversa(false);
    let cuentaType = tabIndex === "1" ? [NuevosEstadosCuentaMedica.Liquidada] : [NuevosEstadosCuentaMedica.Emitida, NuevosEstadosCuentaMedica.EmitidaManual];
    let filters: any = { ...filterBy, estado: cuentaType };
    if (filterBy.estado) {
      filters = { ...filters, estado: parseInt(filterBy.estado) };
    } else {
      filters = { ...filters, estado: tabIndex === "1" ? [NuevosEstadosCuentaMedica.Liquidada] : [NuevosEstadosCuentaMedica.Emitida, NuevosEstadosCuentaMedica.EmitidaManual] }
    }
    if (filterBy.id) {
      filters = { ...filters, id: parseInt(filterBy.id) };
    }
    if (filterBy.idCMD) {
      filters = { ...filters, idCMD: parseInt(filterBy.idCMD) };
    }
    if (filterBy.rutPaciente) {
      filters = {
        ...filters,
        rutPaciente: filterBy.rutPaciente.replace(/[^0-9kK-]/g, ""),
      };
    }
    if (filterBy.rutPrestador) {
      filters = {
        ...filters,
        rutPrestador: filterBy.rutPrestador.replace(/[^0-9kK-]/g, ""),
      };
    }
    if (filterBy.fechaRecepcion) {
      filters = {
        ...filters,
        fechaRecepcion: format(filterBy.fechaRecepcion, "yyyy-MM-dd"),
      };
    }
    if (filterBy.fechaRealLiquidacion) {
      filters = {
        ...filters,
        fechaRealLiquidacion: format(
          filterBy.fechaRealLiquidacion,
          "yyyy-MM-dd"
        ),
      };
    }
    if (filterBy.fechaEmision) {
      filters = {
        ...filters,
        fechaEmision: format(filterBy.fechaEmision, "yyyy-MM-dd"),
      };
    }
    let dataFechas: rangoFechasArgs = null;
    if (resetFecha) {
      reiniciarFechas();
    } else {
      if (fechaDesde && fechaHasta) {
        dataFechas = {
          from: fechaDesde && format(fechaDesde!, "yyyy-MM-dd"),
          to: fechaHasta && format(fechaHasta!, "yyyy-MM-dd"),
        };
      }
    }

    setRangoFechas(dataFechas);
    setDataFiltro(filterBy);
    setVariableFiltros(filters);
    changePage(0);
    refetch();
  };

  const aplicarOrderBy = (id: string) => {
    let orderVar: any = {};
    if (id === orderByCol) {
      const direction = orderDir === "ASC" ? "DESC" : "ASC";
      orderVar[id] = {
        position: 1,
        direction: direction,
      };
      setOrderDir(direction);
    } else {
      orderVar[id] = {
        position: 1,
        direction: "DESC",
      };
      setOrderDir("DESC");
    }
    setOrderByCol(id);
    setOrberBy(orderVar);
  };

  const showMessage = (message: string, severidad: string) => {
    setSnackData({
      abrir: true,
      mensaje: message,
      severidad: severidad,
    });
  };

  const isSelected = (name: number) =>
    selectedCuentaMedica.indexOf(name) !== -1;

  const handleOnCLickEmitirCuentas = () => {
    setModalEmitirMultiplesCuentas(true);
  };

  const isDisabledReversar = (emision: any, estado: number | null) => {
    if (estado || emision) {
      if (estado! in estadosCuentas) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const handleItemChecked = (newElement: any) => {
    if (!selectedCuentaMedica.includes(newElement.id)) {
      if (!newElement.emision || !newElement.emision.estado || newElement.emision.estado === "3") {
        setSelectedCuentaMedica((prevState: any) => [
          ...prevState,
          newElement.id,
        ]);
      }
    } else {
      const filtered = selectedCuentaMedica.filter(item => item !== newElement.id);
      setSelectedCuentaMedica(filtered);
    }
  };

  const handleOnCloseModalEmitirMultiplesCuentas = () => {
    setModalEmitirMultiplesCuentas(false);
  };

  const handleEmitirCuentasMedica = (
    id: number,
    rut: string | null,
    nombre: string | null,
    apellidoPaterno: string | null,
    apellidoMaterno: string | null
  ) => {
    setEmisionDatosUsuario({
      id,
      rut,
      nombre,
      apellidoPaterno,
      apellidoMaterno,
    });
    setModalEmitirCuentaMedica(true);
  };

  const hancleOnCloseEmitirCuentaMedica = () => {
    setEmisionDatosUsuario(datosUsuarioEmpty);
    setModalEmitirCuentaMedica(false);
  };
  const changePage = (val: number) => {
    setPageCuenta(val);
  };
  const setLineasCuentas = (val: number) => {
    setLinesCuentas(val);
  };
  useEffect(() => {
    if (isNotUndefinedAndNull(errorEmitirCuentas)) {
      if (errorEmitirCuentas) {
        setOpenAlert(true);
        setContentAlert({
          content:
            "Alguna(s) de la(s) cuenta(s) medica(s) no se lograron emitir. Por favor, Inténtelo nuevamente.",
          isSuccess: false,
        });
        setSelectedCuentaMedica([]);
      }
    }
  }, [errorEmitirCuentas]);

  useEffect(() => {
    setIsEmptyState(true);
  }, []);

  useEffect(() => {
    if (fechaDesde !== null && isValid(fechaDesde)) {
      setIsDisabledFechaHasta(false);
    } else {
      setIsDisabledFechaHasta(true);
      setFechaHasta(null);
    }

    if (fechaDesde !== null && fechaHasta !== null) {
      setDisabledBusqueda(false);
    } else {
      setDisabledBusqueda(true);
    }
  }, [fechaDesde, fechaHasta]);

  useEffect(() => {
    if (listaCuentasLiquidadas.length > 0) {
      startPolling(20000);
      return () => stopPolling();
    }
  }, [startPolling, stopPolling, listaCuentasLiquidadas]);

  return {
    isReversa,
    refetch,
    pageCuenta,
    linesCuentas,
    orderDir,
    orderByCol,
    aplicarOrderBy,
    changePage,
    setLineasCuentas,
    openAlert,
    setOpenAlert,
    contentAlert,
    setContentAlert,
    cuentasMedicasEmision,
    dataFiltro,
    setDataFiltro,
    exportarCuentasMedicas,
    setCuentasMedicasEmision,
    loading,
    loadingExport,
    handleItemChecked,
    handleSelectAllClick,
    selectedCuentaMedica,
    setSelectedCuentaMedica,
    observacionUsuarioModal,
    handleOnChangeObservacionModal,
    buscarCuentasMedicas,
    isEmptyState,
    isSelected,
    handleOnCLickEmitirCuentas,
    modalEmitirMultiplesCuentas,
    handleOnCloseModalEmitirMultiplesCuentas,
    modalEmitirCuentaMedica,
    handleEmitirCuentasMedica,
    hancleOnCloseEmitirCuentaMedica,
    emisionDatosUsuario,
    isDisabledReversar,
    listaCuentasLiquidadas,
    count,
    handleOnClickSeleccionar,
    fechaDesde,
    fechaHasta,
    handleOnChangeFechaDesde,
    handleOnChangeFechaHasta,
    reiniciarFechas,
    handleEmitirListadoCuentas,
    loadingEmitirCuentas,
    disabledBusqueda,
    handleEmitirCuenta,
    isDisabledFechaHasta,
    networkStatus,
  };
}

export default useEmisionCuentaMedica;
