import { NuevosEstadosCuentaMedica } from "../../../../../utils/enums";

const actionReasons: Record<number, string> = {
  [NuevosEstadosCuentaMedica.Emitida]: "Emitir",
  [NuevosEstadosCuentaMedica.Anulada]: "Anular",
  [NuevosEstadosCuentaMedica.Rechazada]: "Rechazar",
  [NuevosEstadosCuentaMedica.Devuelta]: "Devolver",
  [NuevosEstadosCuentaMedica.EmitidaManual]: "Emitir Manualmente",
};

const gointToActionReasons: Record<number, string> = {
  [NuevosEstadosCuentaMedica.Emitida]: "Emitirás",
  [NuevosEstadosCuentaMedica.Anulada]: "Anularás",
  [NuevosEstadosCuentaMedica.Rechazada]: "Rechazarás",
  [NuevosEstadosCuentaMedica.Devuelta]: "Devolverás",
  [NuevosEstadosCuentaMedica.EmitidaManual]: "Emitirás Manualmente",
};

export const actionReasonsByCurrentStatus = (
  currentStatus: number,
  nextStatus: number
) => {
  if (
    [
      NuevosEstadosCuentaMedica.Emitida,
      NuevosEstadosCuentaMedica.Anulada,
      NuevosEstadosCuentaMedica.Rechazada,
      NuevosEstadosCuentaMedica.Devuelta,
      NuevosEstadosCuentaMedica.EmitidaManual,
    ].includes(nextStatus)
  ) {
    return actionReasons[nextStatus];
  }
  if (
    currentStatus === NuevosEstadosCuentaMedica.Recepcionada &&
    nextStatus === NuevosEstadosCuentaMedica.Visación
  )
    return "Visar";
  if (
    currentStatus === NuevosEstadosCuentaMedica.Visación &&
    nextStatus === NuevosEstadosCuentaMedica.Valorización
  )
    return "Valorizar";
  if (
    currentStatus === NuevosEstadosCuentaMedica.Valorización &&
    nextStatus === NuevosEstadosCuentaMedica.Bonificación
  )
    return "Bonificar";
  if (
    currentStatus === NuevosEstadosCuentaMedica.Bonificación &&
    nextStatus === NuevosEstadosCuentaMedica.Liquidada
  )
    return "Liquidar";
  if (
    [
      NuevosEstadosCuentaMedica.Valorización,
      NuevosEstadosCuentaMedica.Bonificación,
    ].includes(currentStatus) &&
    nextStatus === NuevosEstadosCuentaMedica.Visación
  )
    return "Regresar a Visación";
  if (
    [
      NuevosEstadosCuentaMedica.Visación
    ].includes(currentStatus) &&
    nextStatus === NuevosEstadosCuentaMedica.Recepcionada
  )
    return "Regresar a Recepcionada";
  if (
    currentStatus === NuevosEstadosCuentaMedica.Bonificación &&
    nextStatus === NuevosEstadosCuentaMedica.Valorización
  )
    return "Regresar a Valorización";
};

export const gointToActionReasonsByCurrentStatus = (
  currentStatus: number,
  nextStatus: number
) => {
  if (
    [
      NuevosEstadosCuentaMedica.Emitida,
      NuevosEstadosCuentaMedica.Anulada,
      NuevosEstadosCuentaMedica.Rechazada,
      NuevosEstadosCuentaMedica.Devuelta,
      NuevosEstadosCuentaMedica.EmitidaManual,
    ].includes(nextStatus)
  ) {
    return gointToActionReasons[nextStatus];
  }
  if (
    currentStatus === NuevosEstadosCuentaMedica.Recepcionada &&
    nextStatus === NuevosEstadosCuentaMedica.Visación
  )
    return "Visarás";
  if (
    currentStatus === NuevosEstadosCuentaMedica.Visación &&
    nextStatus === NuevosEstadosCuentaMedica.Valorización
  )
    return "Valorizarás";
  if (
    currentStatus === NuevosEstadosCuentaMedica.Valorización &&
    nextStatus === NuevosEstadosCuentaMedica.Bonificación
  )
    return "Bonificarás";
  if (
    currentStatus === NuevosEstadosCuentaMedica.Bonificación &&
    nextStatus === NuevosEstadosCuentaMedica.Liquidada
  )
    return "Liquidarás";
  if (
    [
      NuevosEstadosCuentaMedica.Valorización,
      NuevosEstadosCuentaMedica.Bonificación,
    ].includes(currentStatus) &&
    nextStatus === NuevosEstadosCuentaMedica.Visación
  )
    return "Regresarás a Visación";
  if (
    currentStatus === NuevosEstadosCuentaMedica.Bonificación &&
    nextStatus === NuevosEstadosCuentaMedica.Valorización
  )
    return "Regresarás a Valorización";

  if (
    currentStatus === NuevosEstadosCuentaMedica.Visación &&
    nextStatus === NuevosEstadosCuentaMedica.Recepcionada
  )
    return "Regresarás a Recepcionada";
};

export const nounActionReasonsByCurrentStatus = (
  currentStatus: number,
  nextStatus: number
) => {
  if (
    [
      NuevosEstadosCuentaMedica.Emitida,
      NuevosEstadosCuentaMedica.Anulada,
      NuevosEstadosCuentaMedica.Rechazada,
      NuevosEstadosCuentaMedica.Devuelta,
      NuevosEstadosCuentaMedica.EmitidaManual,
    ].includes(nextStatus)
  ) {
    return gointToActionReasons[nextStatus];
  }
  if (
    currentStatus === NuevosEstadosCuentaMedica.Visación &&
    nextStatus === NuevosEstadosCuentaMedica.Valorización
  )
    return "valorización";
  if (
    currentStatus === NuevosEstadosCuentaMedica.Valorización &&
    nextStatus === NuevosEstadosCuentaMedica.Bonificación
  )
    return "bonificación";
  if (
    currentStatus === NuevosEstadosCuentaMedica.Bonificación &&
    nextStatus === NuevosEstadosCuentaMedica.Liquidada
  )
    return "Liquidación";
};
