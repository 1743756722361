import { useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import {
  SlimInput,
} from "../../../../assets/styles/Input/input";
import { DatePicker } from "../../../../components/DatePicker/DatePicker";
import { TimePicker } from "../../../../components/TimePicker/TimePicker";
import { SlimValueSelect2 } from "../../../../components/Selects/valueSelect";
import { format } from "date-fns";
import { rutFormatNumber } from "../../../../utils/index";
import { getPolizaByRutBeneficiario, queryObtenerFechaLiquidacion } from "../../../../services/administracionCM/administracionCMServices";
import { useLazyQuery } from "@apollo/client";
import { NormalModal } from "../../../../components/Modal/normalModal";
import { boxCard, formHeader } from "../../../../assets/styles/Box/boxs";
import { formTextHeader, inputForm } from "../../../../assets/styles/Text/text";
import MedicForm from "../../../../assets/icons/formCtaIcon.svg";
import { SelectPrestaciones } from "../../../../components/Prestaciones/SelectPrestaciones";
import { initialState } from "../../utils/estadoCero";

const DataCuentaMedica = ({ dataCM, setDataCM, readOnly }: any) => {
  const [modalErrorPoliza, setModalErrorPoliza] = useState<boolean>(false);
  const todayDate = new Date();
  const [fngetdatosPolizaQuery, { loading: verificaPoliza }] = useLazyQuery(getPolizaByRutBeneficiario, { fetchPolicy: "no-cache" });
  const [fnGetFechaLiquidacion, { loading }] = useLazyQuery(queryObtenerFechaLiquidacion, {
    fetchPolicy: "no-cache",
    onCompleted: (res) => {
      if (res.obtenerFechaLiquidacion !== null) {
        const { fechaLiquidacion } = res.obtenerFechaLiquidacion;

        if (fechaLiquidacion) {
          setDataCM({ ...dataCM, fechaProbableLiquidacion: fechaLiquidacion });
        } else {
          setDataCM({ ...dataCM, fechaProbableLiquidacion: null });
        }
      }
    }
  });

  const getVigenciaPolizaBenef = (fecha: Date) => {
    fngetdatosPolizaQuery({
      variables: { rut: rutFormatNumber(dataCM.datosBeneficiario.rut), fecha: format(fecha, "yyyy/MM/dd") },
      onCompleted: (res) => {
        if (res.datosBeneficiario !== null) {
          const { poliza } = res.datosBeneficiario;
          if (poliza) {
            setDataCM({
              ...dataCM,
              fechaHospitalizacion: {
                ...dataCM.fechaHospitalizacion,
                fechaInicio: fecha
              },
              poliza: poliza,
              vigenciaPoliza: true
            })
          } else {
            setDataCM({
              ...dataCM,
              fechaHospitalizacion: {
                ...dataCM.fechaHospitalizacion,
                fechaInicio: fecha
              },
              poliza: initialState.poliza,
              vigenciaPoliza: false
            });
            setModalErrorPoliza(true);
          }
        }
      }, onError: (err) => {
        console.log(err)
        setDataCM({
          ...dataCM,
          fechaHospitalizacion: {
            ...dataCM.fechaHospitalizacion,
            fechaInicio: fecha
          },
          vigenciaPoliza: false
        });
        setModalErrorPoliza(true);
      }
    })
  }

  const handleOnChangeCodigoPrestacion = (e: any) => {
    setDataCM({
      ...dataCM,
      prestacionPrincipal: e
    });
  };

  const handleFechaRecepcion = (fechaRecepcion: Date | null) => {
    setDataCM({
      ...dataCM,
      fechaRecepcion: fechaRecepcion,
    });
    fechaRecepcion && fnGetFechaLiquidacion({ variables: { fecha: format(fechaRecepcion!, "yyyy-MM-dd") } });
  };

  const handleOnChangeFechaInicio = (fechaInicio: Date | null) => {
    setDataCM({
      ...dataCM,
      fechaHospitalizacion: {
        ...dataCM.fechaHospitalizacion,
        fechaInicio: fechaInicio
      }
    });
    if (fechaInicio) {
      getVigenciaPolizaBenef(fechaInicio);
    }

  };

  const handleOnChangeHoraInicio = (fechaInicio: Date | null) => {
    setDataCM({
      ...dataCM,
      fechaHospitalizacion: {
        ...dataCM.fechaHospitalizacion,
        fechaInicio: fechaInicio
      }
    });
  };

  const handleOnChangeFechaEgreso = (fechaEgreso: Date | null) => {
    const condicionEgreso = dataCM.fechaHospitalizacion.condicionEgreso;
    setDataCM({
      ...dataCM,
      fechaHospitalizacion: {
        ...dataCM.fechaHospitalizacion,
        fechaEgreso: fechaEgreso,
        condicionEgreso: fechaEgreso ? condicionEgreso : null
      }
    });
  };
  
  const handleOnChangeCondicionEgreso = (e: any) => {
    setDataCM({
      ...dataCM,
      fechaHospitalizacion: {
        ...dataCM.fechaHospitalizacion,
        condicionEgreso: e.target.value
      }
    });
  };

  const handleChangeStartDateCobro = (newDate: any) => {
    setDataCM({
      ...dataCM,
      fechaCobro: {
        ...dataCM.fechaCobro,
        fechaInicio: newDate
      }
    });
  };

  const handleChangeEndDateCobro = (newDate: any) => {
    setDataCM({
      ...dataCM,
      fechaCobro: {
        ...dataCM.fechaCobro,
        fechaTermino: newDate
      }
    });
  };

  const condicionEgreso = [{ label: "Vivo", value: "0" }, { label: "Fallecido", value: "1" }];
  return (
    <>
      {modalErrorPoliza
        ? (<NormalModal
          open={modalErrorPoliza}
          onClose={() => setModalErrorPoliza(false)}
          title="Hubo un Error con la Poliza del Beneficiario"
          content="Beneficiario no está vigente a la fecha de inicio de Hospitalización informada."
          primaryButton={{
            text: "Entendido",
            onClick: () => setModalErrorPoliza(false),
          }}
        />)
        : null}

      <Box sx={boxCard} width={"89.5%"} ml={"4.5%"} mb={"24px"} >
        <Box sx={formHeader}>
          <Typography sx={formTextHeader}>
            <img src={MedicForm} alt="" />{' '}Datos de la cuenta médica
          </Typography>
        </Box>
        <Grid mb="32px" container p="24px">
          <Grid item xs={6} pr="24px">
            <Typography sx={inputForm}  >
              Código prestación
            </Typography>
            <Typography display={'inline'} color={'#E53935 !important'}>
              *
            </Typography>
            <SelectPrestaciones
              value={dataCM?.prestacionPrincipal}
              onChange={handleOnChangeCodigoPrestacion}
              disabled={readOnly}
            />
          </Grid>
          <Grid item xs={3} pr="24px">
            <Typography sx={inputForm}  >
              Fecha recepción cuenta
            </Typography>
            <Typography display={'inline'} color={'#E53935 !important'}>
              *
            </Typography>
            <DatePicker
              value={dataCM.fechaRecepcion}
              maxDate={todayDate}
              onChange={(newValue: any) => handleFechaRecepcion(newValue)}
              disabled={readOnly || loading}
            />
          </Grid>
          <Grid item xs={3} >
            <Typography sx={inputForm}  >
              Fecha probable liquidación
            </Typography>
            <Typography display={'inline'} color={'#E53935 !important'}>
              *
            </Typography>
            <SlimInput
              fullWidth
              value={dataCM.fechaProbableLiquidacion}
              disabled
            />
          </Grid>
          <Grid item xs={12} pr="24px" borderBottom={"1px solid #4DBFB8"}>
            <Typography sx={formTextHeader} pt="24px" pb="15px">
              Fecha de Hospitalización
            </Typography>
          </Grid>
          <Grid item xs={3} pr="24px" pt="24px">
            <Typography sx={inputForm}  >
              Fecha  inicio
            </Typography>
            <Typography display={'inline'} color={'#E53935 !important'}>
              *
            </Typography>
            <DatePicker
              value={dataCM.fechaHospitalizacion.fechaInicio}
              onChange={(newValue: any) => handleOnChangeFechaInicio(newValue)}
              maxDate={todayDate}
              disabled={!dataCM.datosBeneficiario.rut || readOnly || verificaPoliza}
              noClear={true}
            />
          </Grid>
          <Grid item xs={3} pr="24px" pt="24px">
            <Typography sx={inputForm}  >
              Hora inicio
            </Typography>
            <Typography display={'inline'} color={'#E53935 !important'}>
              *
            </Typography>
            <TimePicker
              value={dataCM.fechaHospitalizacion.fechaInicio || null}
              maxTime={todayDate}
              onChange={(newValue: any) => handleOnChangeHoraInicio(newValue)}
              disabled={
                !dataCM.datosBeneficiario.rut ||
                !dataCM.vigenciaPoliza ||
                readOnly ||
                verificaPoliza
              }
            />
          </Grid>
          <Grid item xs={3} pr="24px" pt="24px">
            <Typography sx={inputForm}  >
              Fecha egreso
            </Typography>
            {dataCM.fechaHospitalizacion.fechaEgreso
              || dataCM.fechaHospitalizacion.condicionEgreso
              ? <Typography display={'inline'} color={'#E53935 !important'}>
                *
              </Typography> : null}
            <DatePicker
              value={dataCM.fechaHospitalizacion.fechaEgreso}
              minDate={dataCM.fechaHospitalizacion.fechaInicio || null}
              maxDate={todayDate}
              onChange={(newValue: any) => handleOnChangeFechaEgreso(newValue)}
              disabled={!dataCM.fechaHospitalizacion.fechaInicio || !dataCM.vigenciaPoliza || readOnly || verificaPoliza}
            />
          </Grid>
          <Grid item xs={3} pr="24px" pt="24px">
            <Typography sx={inputForm}  >
              Hora egreso
            </Typography>
            {dataCM.fechaHospitalizacion.fechaEgreso
              || dataCM.fechaHospitalizacion.condicionEgreso
              ? <Typography display={'inline'} color={'#E53935 !important'}>
                *
              </Typography> : null}
            <TimePicker
              value={dataCM.fechaHospitalizacion.fechaEgreso}
              minTime={dataCM.fechaHospitalizacion.fechaInicio || null}
              maxTime={todayDate}
              onChange={(newValue: any) => handleOnChangeFechaEgreso(newValue)}
              disabled={
                !dataCM.fechaHospitalizacion.fechaInicio ||
                !dataCM.vigenciaPoliza ||
                readOnly ||
                verificaPoliza
              }
            />
          </Grid>
          <Grid item xs={3} pr="24px" pt="24px">
            <Typography sx={inputForm}  >
              Condición de egreso
            </Typography>
            {dataCM.fechaHospitalizacion.fechaEgreso
              || dataCM.fechaHospitalizacion.condicionEgreso
              ? <Typography display={'inline'} color={'#E53935 !important'}>
                *
              </Typography> : null}
            <SlimValueSelect2
              onChange={(e: any) => handleOnChangeCondicionEgreso(e)}
              disabled={!dataCM.fechaHospitalizacion.fechaEgreso || !dataCM.vigenciaPoliza || readOnly}
              value={dataCM.fechaHospitalizacion.condicionEgreso}
              data={condicionEgreso}
              titulo={"Seleccione una condición"}
              name="condicion"
            />
          </Grid>
          <Grid item xs={12} pr="24px" borderBottom={"1px solid #4DBFB8"}>
            <Typography sx={formTextHeader} pt="24px" pb="15px">
              Fecha de cobro
            </Typography>
          </Grid>
          <Grid item xs={3} pr="24px" pt="24px">
            <Typography sx={inputForm}  >
              Fecha  inicio
            </Typography>
            <Typography display={'inline'} color={'#E53935 !important'}>
              *
            </Typography>
            <DatePicker
              value={dataCM.fechaCobro.fechaInicio}
              minDate={dataCM.fechaHospitalizacion.fechaInicio}
              maxDate={dataCM.fechaHospitalizacion.fechaEgreso || todayDate}
              onChange={(newValue: any) => handleChangeStartDateCobro(newValue)}
              disabled={!dataCM.vigenciaPoliza || readOnly}
            />
          </Grid>
          <Grid item xs={3} pr="24px" pt="24px" pb="24px">
            <Typography sx={inputForm}  >
              Fecha  término
            </Typography>
            <Typography display={'inline'} color={'#E53935 !important'}>
              *
            </Typography>
            <DatePicker
              value={dataCM.fechaCobro.fechaTermino}
              maxDate={dataCM.fechaHospitalizacion.fechaEgreso || todayDate}
              minDate={dataCM.fechaCobro.fechaInicio}
              onChange={(newValue: any) => handleChangeEndDateCobro(newValue)}
              disabled={!dataCM.fechaCobro.fechaInicio || !dataCM.vigenciaPoliza || readOnly}
            />
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

export default DataCuentaMedica;
