import { useQuery } from "@apollo/client"
import { getUsuarios } from "../services/escritorio/escritorioServices";
import { useState } from "react";
import { ValueSelect } from "../components/Selects/valueSelect";

type selectUsuarios = {
    onChange: Function,
    disabled?: boolean | undefined,
    value: string | undefined | null,
}

export const SelectUsuarios = ({ onChange, disabled, value }: selectUsuarios) => {
    const [usuarios, setUsuarios] = useState<any[]>([]);
    useQuery(getUsuarios, {
        onCompleted: (res) => {
            let parser = res.obtenerUsuarios.map((user: any) => user.email);
            setUsuarios(parser);
        },
        onError: (err) => {
            setUsuarios([]);
            console.log(err)
        }
    });
    return (
        <ValueSelect
            onChange={onChange}
            disabled={disabled}
            value={value}
            name="idUsuarios"
            titulo={"Usuario Asignado"}
            data={usuarios}
        />
    )
}