import {
  Typography,
  Box,
  Tab,
  Tabs
} from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import { boxCardNormal } from "../../assets/styles/Box/boxs";
import {
  TabContext,
  TabPanel,
} from "@mui/lab";

import { useState } from "react";
import { TabsEmisionValues } from "./tabsEmisionValues";
import TabCuentas from "./tabCuentas/tabCuentas";
import SnackBar, { stateProps } from "../../components/Snackbar/Snackbar";

export default function EmisionCuentaMedica() {
  const [tabIndex, setTabIndex] = useState<string>(TabsEmisionValues.liquidada);
  const [snackData, setSnackData] = useState<stateProps>({
    abrir: false,
    mensaje: "",
    severidad: 'info'
  });
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setTabIndex(newValue);
  };

  return (
    <Box>
      <SnackBar state={snackData} setState={setSnackData} vertical="top"/>
      <CssBaseline>
        <Box sx={boxCardNormal}>
          <Typography pt={7} pb={4} variant="h2">
            Emisión de Cuentas Médicas
          </Typography>
          <TabContext value={tabIndex}>
            <Tabs value={tabIndex} onChange={handleChange}>
              <Tab
                label="Liquidadas"
                value={TabsEmisionValues.liquidada}
                className="tabGreen"
              />
              <Tab
                label="Emitidas"
                value={TabsEmisionValues.emitida}
                className="tabGreen"
              />
            </Tabs>
            <TabPanel
              value={TabsEmisionValues.liquidada}
              sx={{ p: 0, mt: "16px", mb: "16px" }}
            >
              <TabCuentas tabIndex={tabIndex} setSnackData={setSnackData}/>
            </TabPanel>
            <TabPanel
              value={TabsEmisionValues.emitida}
              sx={{ p: 0, mt: "16px", mb: "16px" }}
            >
              <TabCuentas tabIndex={tabIndex} setSnackData={setSnackData}/>
            </TabPanel>
          </TabContext>
        </Box>
      </CssBaseline>
    </Box>
  );
}
