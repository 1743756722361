import { Workbook } from "exceljs";
import { saveAs } from "file-saver";

export const createExcel = async (data: any[], filename: string) => {
  try {
    const workbook = new Workbook();

    workbook.creator = "CUENTAS";
    workbook.created = new Date();
    workbook.modified = new Date();
    workbook.lastPrinted = new Date();

    workbook.addWorksheet("Información");
    const worksheet = workbook.getWorksheet(1);

    worksheet!.addRows(data);

    //worksheet!.fillFormula(`AP2:AP${data.length}`, "=(AO2+AL2)");//=(AO2+AN2+AL2) para cuadratura
    //worksheet!.fillFormula(`AQ2:AQ${data.length}`, `=(SI(AP2=AC2;"OK";SI(AC2=(AP2+1);"OK";"NOTOK")))`); //if para cuadratura

    const documentType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";

    workbook.xlsx.writeBuffer().then((dataBuffer: any) => {
      const blob = new Blob([dataBuffer], {
        type: documentType,
      });
      saveAs(blob, filename);
    });
    return {
      status: 200,
      message: "Archivo creado con éxito",
    };
  } catch (error) {
    //debug
    console.log(error);
    return {
      status: 500,
      message: "Error al crear el archivo",
    };
  }
};
