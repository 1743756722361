import { styled } from "@mui/material";


const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{open?: boolean;}>(
    ({ theme, open }) => ({
        flexGrow: 1,
        marginTop: '52px',
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        // marginLeft: `-${drawerWidth}px`,
        ...(open && {
            transition: theme.transitions.create(['width', 'margin'], {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
            marginLeft: '240px',
            width: 'calc(100% - 240px)',
        }),
        ...(!open && {
            marginLeft: '60px',
            width: 'calc(100% - 60px)',
            transition: theme.transitions.create(['width', 'margin'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen
            }),
        }),
    }),
);

export {
    Main
}