/** El componente existe en emisionCuentaMedica, pero son distintos en la acción y en diseño.
 * Si corresponde, refactorizar y fusionar ambos
 */

import { useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import WhiteArrow from "../../../../assets/icons/downArrowWhite.svg";
import { useValorizationContext } from "../../../../context/valorizationContext";

const BoxStyle = {
  backgroundColor: "#857BE2",
  borderRadius: "8px",
  width: "600px",
  zIndex: 10,
  margin: " 0 auto",
};

const ButtonEditStyle = {
  position: "fixed",
  alignItems: "center",
  textAlign: "center",
  display: "flex",
  top: "75%",
  left: "42%",
  zIndex: 10,
};

const LetterStyle = {
  fontFamily: "Interstate !important",
  fontSize: "16px !important",
  fontWeight: "700 !important",
  lineHeight: "20px !important",
  letterSpacing: "0.0021em  !important",
  textAlign: "center !important",
  color: "#FFFFFF !important",
};

type ButtonEditProps = {
  isLoading: boolean;
  handleBulkDelete: () => void;
  handleBulkBasket: () => void;
};
export const ButtonEdit = ({
  isLoading,
  handleBulkDelete,
  handleBulkBasket,
}: ButtonEditProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const { selectedDetailItem, selectedDocTypes } = useValorizationContext();

  const isNOTGES = (selectedDocTypes.CAEC > 0 || selectedDocTypes.PLAN > 0);
  return (
    <Box sx={ButtonEditStyle}>
      <Box sx={BoxStyle}>
        <Grid container>
          <Grid
            item
            xs={5}
            textAlign={"right"}
            sx={{ placeSelf: "center", float: "right" }}
          >
            <Typography sx={LetterStyle} paddingLeft="40%">
              Seleccionado: {selectedDetailItem.length}
            </Typography>
          </Grid>
          <Grid item xs={1} pt="10px" pb="10px">
            <Divider
              sx={{ borderColor: "#FFFFFF !important" }}
              orientation="vertical"
            />
          </Grid>
          <Grid item xs={5}>
            {isLoading && (
              <Button color="inherit">
                <CircularProgress size="24px" sx={{ color: "#FFFFFF" }} data-testid="loadingCircularButton" />
              </Button>
            )}
            <>
              <Button
                color="inherit"
                onClick={(event: any) => setAnchorEl(event.currentTarget)}
              >
                <img src={WhiteArrow} alt="Descargar" />{" "}
                <Typography sx={LetterStyle} paddingLeft={"12px"}>
                  {" "}
                  Acciones
                </Typography>
              </Button>
              <Menu
                sx={{ zIndex: 10 }}
                slotProps={{ paper: { sx: { width: 300 } } }}
                anchorEl={anchorEl}
                open={open}
                onClose={() => setAnchorEl(null)}
                className="menuFormAlt"
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
              >
                <MenuItem
                  className="menuFormAlt"
                  onClick={() => {
                    setAnchorEl(null);
                    handleBulkDelete()
                  }}
                >
                  Eliminar
                </MenuItem>
                <MenuItem
                  disabled={isNOTGES}
                  className="menuFormAlt"
                  onClick={() => {
                    setAnchorEl(null);
                    handleBulkBasket();
                  }}
                >
                  Añadir Canasta
                </MenuItem>
              </Menu>
            </>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};
