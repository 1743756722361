import { Box, Typography } from "@mui/material"
import lightbulb from "../../assets/icons/lightbulb.svg";
import infoSize from "../../assets/icons/infoSize.svg";

export const EmptyStateMessage = ({title, subtitle, isResolucion=false}: any) => {
    return (
        <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            height="320px"
        >
            <Box pb="30px">
                <img src={ isResolucion=== true ?  infoSize :  lightbulb   } alt={title}></img> 
            </Box>

            <Typography variant="h4" sx={{ color: "#4C4C4C" }}>
                {title}
            </Typography>
            <Typography variant="h6" sx={{ color: "#4C4C4C" }}>
                {subtitle}
            </Typography>
        </Box>
    )
}
