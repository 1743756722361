import { MenuItem, Select, Typography } from '@mui/material';
import { CustomSelectInput, menuFirstStyle, menuItemStyle, menuSelectStyle } from '../../assets/styles/Select/Select';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';


export const CustomSelect = (props: any) => {
    return (
        <Select
            onChange={props.onChange}
            disabled={props.disabled}
            value={props.value || 0}
            sx={{ width: '100%', textTransform: 'capitalize' }}
            input={<CustomSelectInput className={props.borderColor ? "border-color" : ""} />}
            MenuProps={menuSelectStyle}
            name={props.name}
            IconComponent={KeyboardArrowDownRoundedIcon}
        >
            <MenuItem disabled key={0} value={0} className="display-none" sx={menuItemStyle}> {props.titulo} </MenuItem>
            {props.data?.map((elem: any, idx: any) => (
                <MenuItem
                    key={idx + 1}
                    value={idx + 1}
                    sx={menuItemStyle}
                >
                    {elem.nombre ? elem.nombre : elem}
                </MenuItem>
            ))}
        </Select >
    )
}

export const CustomSelect2 = ({
    onChange,
    disabled,
    value,
    defaultValue,
    borderColor,
    name,
    phvalue,
    placeholder,
    data,
    inputTest,
    id
}: any) => {
    return (
        <Select
            onChange={onChange}
            disabled={disabled}
            value={value || defaultValue || 0}
            sx={{ width: '100%', textTransform: 'capitalize' }}
            input={<CustomSelectInput className={borderColor ? "border-color" : ""} />}
            MenuProps={menuSelectStyle}
            name={name}
            IconComponent={KeyboardArrowDownRoundedIcon}
            inputProps={{ 'aria-label': 'Without label', "data-testid": inputTest, "id": id }}
        >
            <MenuItem disabled key={0} value={phvalue || 0} className="FirstMenu" sx={menuItemStyle}> <Typography sx={menuFirstStyle}>{placeholder}</Typography> </MenuItem>
            {data?.map((elem: any, idx: any) => (
                <MenuItem
                    key={idx + 1}
                    value={elem.value}
                    sx={menuItemStyle}
                >
                    {elem.label}
                </MenuItem>
            ))}
        </Select >
    )
}

