import { IconButton, Menu, MenuItem } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import useGenerarPdf from "../hook/useGenerarPdf";
import { useGetUser } from "../../../hooks/getUser";
import { Fragment, useState } from "react";
import { ModalHistorial } from "../../escritorioTrabajo/components/modalHistorial";
import { ReversarCuenta } from "./reversarCuenta";

export const MenuTableCuentas = ({
  setSnackData,
  row,
  tabIndex,
  refetch,
}: any) => {
  const loadMessage = (msg: string, svt: string) => {
    setSnackData({
      abrir: true,
      mensaje: msg,
      severidad: svt,
    });
  };
  const [anchorEl, setAnchorEl] = useState(null);
  const [openHistorial, setOpenHistorial] = useState<boolean>(false);
  const [openReversar, setOpenReversar] = useState<boolean>(false);
  const openMenu = Boolean(anchorEl);
  const { loadingGenerarPDF, handleOnClickGenerarPdf } =
    useGenerarPdf(loadMessage);
  const { email, rol } = useGetUser();

  const handleOpen = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const generaPDF = (tipoForm: string) => {
    handleClose();
    handleOnClickGenerarPdf({
      usuario: email,
      idCuentaMedica: row.id,
      tipoDocumento: tipoForm,
    });
  };

  const reversar = () => {
    handleClose();
    setOpenReversar(true);
  };

  const handleOnOpenHistorial = () => {
    setOpenHistorial(true);
    setAnchorEl(null);
  };

  const handleOnCloseHistorial = () => {
    setOpenHistorial(false);
  };

  return (
    <Fragment>
      <ModalHistorial
        open={openHistorial}
        onClose={handleOnCloseHistorial}
        row={row}
        idCuentaMedica={row.canalOrigen === "CMD" ? row.idCMD : row.id}
      />
      <ReversarCuenta
        open={openReversar}
        close={() => setOpenReversar(false)}
        row={row}
        type={tabIndex}
        showMessage={loadMessage}
        refetch={refetch}
      />
      <IconButton
        data-testid="openMenuItem"
        id="basic-button"
        aria-haspopup="true"
        onClick={handleOpen}
      >
        <MoreVertIcon sx={{ color: "#203442" }} />
      </IconButton>
      <Menu
        id={`menu-${row.numeroCuentaPrestador}`}
        anchorEl={anchorEl}
        open={openMenu}
        onClose={handleClose}
        className="menuForm"
      >
        {tabIndex === "2"
          ? [
              <MenuItem
                key={"formLiq"}
                className="menuForm"
                disabled={loadingGenerarPDF || row.estado === "Emitida Manual"}
                onClick={() => generaPDF("FORMULARIO LIQUIDACION")}
              >
                Ver Formulario Liq.
              </MenuItem>,
              <MenuItem
                key={"formEmi"}
                className="menuForm"
                disabled={loadingGenerarPDF || row.estado === "Emitida Manual"}
                onClick={() => generaPDF("BONOS EMITIDOS")}
              >
                Ver Bono
              </MenuItem>,
            ]
          : null}
        <MenuItem
          key={"RevFunct"}
          onClick={reversar}
          disabled={
            ((tabIndex === "1" &&
              (row.emision === null || row.emision.estado === null)) ||
            (tabIndex === "2" &&
              !(row.reversa && row.reversa.estado === 2) &&
              row.estado === "Emitida")) && !rol.includes('AUDITOR')
              ? false
              : true
          }
        >
          {tabIndex === "2" ? "Reversar a Liquidada" : "Reversar a Bonificación"}
        </MenuItem>
        <MenuItem
          key={"hitCam"}
          className="menuForm"
          onClick={() => handleOnOpenHistorial()}
        >
          Historial de cambios
        </MenuItem>
      </Menu>
    </Fragment>
  );
};
