import { Suspense } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import {
  AdministracionCM,
  EscritorioTrabajo,
  Bienvenida,
  TextCard
} from "../pages/pages";
import ProtectedRoutes from "./protectedRoutes";
import EmisionCuentaMedica from "../pages/emisionCuentaMedica/emisionCuentaMedica";
import { useIsAuthenticated } from "@azure/msal-react";
import Reporteria from "../pages/report/report";
import ReportesMaestros from "../pages/report/reportesMaestros/reportesMaestros";

import { useGetUser } from "../hooks/getUser";
import { accessEM, accessET, accessREP } from "./verifyAccess";
import ConsultaCuenta from "../pages/consultaCuenta/consultaCuenta";
import ReporteGestion from "../pages/report/reporteGestion/reporteGestion";

const RoutesConfig = () => {
  const isAuthenticated = useIsAuthenticated();
  const user = useGetUser();

  const redirect = () => {
    if (
      ["JEFE", "VISADOR", "ADMINISTRATIVO", "VALORIZADOR", "AUDITOR"].some(
        (role) => user.rol.includes(role)
      )
    )
      return <Navigate to={"/Escritorio"} />;
    if (user.rol.includes("LIQUIDADOR")) return <Navigate to={"/Emision"} />;
    return <Navigate to={"/Error"} />;
  };

  return (
    <Suspense fallback={null}>
      <Routes>
        <Route
          path="/"
          element={isAuthenticated ? redirect() : <Bienvenida isAuthorized={false} />}
        />
        <Route element={<ProtectedRoutes />}>
          <Route path="/Escritorio" element={accessET(user) ? <EscritorioTrabajo /> : <Bienvenida isAuthorized={false} />} />
          <Route
            path="/Escritorio/AdministracionCM"
            element={<AdministracionCM />}
          />
          <Route path="/Emision" element={accessEM(user) ? <EmisionCuentaMedica /> : <Bienvenida isAuthorized={false} />} />
          <Route path="/Reportes">
            <Route index element={accessREP(user) ? <Reporteria /> : <Bienvenida isAuthorized={false} />} />
            <Route path="PlanillaDatosMaestros" element={<ReportesMaestros />} />
            <Route path="ReporteGestion" element={<ReporteGestion />} />
          </Route>
          <Route path="/ConsultaCuentas" element={<ConsultaCuenta />} />
        </Route>
        <Route path="/Error" element={<Bienvenida isAuthorized={false} />} />
        <Route
          path="*"
          element={<TextCard isNotFound />}
        />
      </Routes>
    </Suspense>
  );
};

export default RoutesConfig;
