import { Fragment, useState } from "react"
import { useMutation } from "@apollo/client"
import { updateCMStatus } from "../../../../services/administracionCM/administracionCMServices"
import { NuevosEstadosCuentaMedica } from "../../../../utils/enums"
import { useGetUser } from "../../../../hooks/getUser"
import { SecondaryButton } from "../../../../components/Buttons/Button"
import { WarningModalCustom } from "../../../../components/Modal/warningModalCustom"
import { Grid, Typography } from "@mui/material"

interface PropsBtnAuth {
    requiresAuth: boolean,
    idCuentaMedica: number,
    showMessage: Function,
    refetch: Function,
    disabled?: boolean
}

export const BtnSolicitarAutorizacion = ({ requiresAuth, idCuentaMedica, showMessage, refetch, disabled }: PropsBtnAuth) => {
    const [modalWarning, setModalWarning] = useState<boolean>(false);
    const { email } = useGetUser();
    const [updateCMRequest, { loading }] = useMutation(updateCMStatus, {
        onCompleted: () => {
            showMessage("Cuenta enviada a autorización correctamente.", 'success');
            setModalWarning(false);
            refetch();
            const refetchCuenta = document.getElementById("btnRefetchCuenta");
            if (refetchCuenta) {
                refetchCuenta.click();
            }
        },
        onError: (err) => showMessage(err.message, 'error')
    });

    const onClick = () => {
        setModalWarning(true);
    }

    const updateCM = () => {
        updateCMRequest({
            variables: {
                updateData: {
                    idCuentaMedica: idCuentaMedica,
                    estado: NuevosEstadosCuentaMedica.PendienteAutorizacion,
                    causal: null,
                    notaResolutiva: "Solicita autorización de prestaciones con mínimo obligatorio",
                    idUsuario: email,
                },
            },
        })
    }
    return (
        <Fragment>
            <WarningModalCustom
                onClose={() => setModalWarning(false)}
                title="Solicitar autorización"
                contentText={
                    <Grid>
                        <Typography>
                            Al solicitar autorizacion la cuenta quedará en el estado<br />
                            "Pendiente de autorización" por lo que se<br />
                            deshabilitaran las acciones hasta que sea autorizada.<br />
                            ¿deseas continuar con esta acción?
                        </Typography>
                    </Grid>
                }
                primaryButton="Aceptar"
                secondaryButton='Cancelar'
                onConfirm={updateCM}
                loading={loading}
                onOpen={modalWarning}
            />
            <SecondaryButton
                onClick={onClick}
                sx={{ ml: '15px', minWidth: "168px", height: '48px' }}
                disabled={!requiresAuth || disabled}
            >
                Solicitar Autorización
            </SecondaryButton>
        </Fragment>
    )
}