import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import { HeadCuentasMedicas } from "./headCuentasMedicas";
import { grayName } from "../../../assets/styles/Text/text";
import { LoadHeadCtas } from "./skeletonCuentas";
import {
  formatoCompletoNombrePaciente,
  formatRut,
  numbersWithDots,
  regex,
} from "../../../utils/index";
import { NoResult } from "../../../components/Tables/noResult";
import { MenuCuentasMedicas } from "./menuCuentasMedicas";
import { verificaLargo } from "../../../components/Tooltip/Tooltip";
import { EtiquetaEstado } from "./EtiquetaEstado";
import { AsignacionUsuario } from "./AsignacionUsuario";

export const TableCuentasMedicas = ({
  listaEscritorio,
  count,
  rowsPerPage,
  page,
  filtros,
  handleNewChangePage,
  handleNewChangeRowsPerPage,
  loading,
  order,
  orderBy,
  handleRequestSort,
  handleOnClickGestionarCuenta,
  handleOpenModalAsignarme,
  errorResults,
  handleRevisarCuenta,
  rol,
  isAssignedTab,
  setSnackData,
  refetch,
  isAuth
}: any) => {
  const enum titleNoResults {
    FILTER = "No se encontró información de cuentas médicas",
    ALL_ACCOUNTS = "No hay información de cuentas médicas para visualizar",
    MY_ACCOUNTS = "No posees cuentas asignadas para visualizar",
  }

  const enum subTitleNoResults {
    FILTER = "Inténtelo nuevamente usando otro criterio",
    ALL_ACCOUNTS = "Para visualizar o gestionar la información en la tabla recepcione una cuenta",
    MY_ACCOUNTS = "necesitas tener asignada una cuenta para ver la información",
  }

  const formatDate = (date: string) => {
    if (
      date !== null &&
      date !== undefined &&
      regex.emptyinput.test(date) === false
    ) {
      const [year, month, day] = date.split("-");
      return `${day}-${month}-${year}`;
    } else {
      return "";
    }
  };

  const IsNoInformation = ({ data }: any) => {
    return data !== null && data !== "" ? (
      data
    ) : (
      <Typography className="disabled-text">Sin Información</Typography>
    );
  };

  return (
    <>
      <Paper className="paper-tables" sx={{ borderRadius: "0 !important" }}>
        {loading ? (
          <LoadHeadCtas />
        ) : errorResults ? (
          <NoResult
            titulo={"No se pudo cargar la tabla"}
            subtitulo={
              "No fue posible mostrar la información debido a un problema de conexión. Intenta recargar la página para solucionar el problema."
            }
            noDivider={false}
            error={true}
          />
        ) : count > 0 ? (
          <>
            <TableContainer sx={{ pl: "0 !important" }}>
              <Table aria-label="customized table">
                <HeadCuentasMedicas
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                  isAssignedTab={isAssignedTab}
                  rol={rol}
                />
                <TableBody>
                  {listaEscritorio.map((row: any, index: number) => {
                    const nombrePaciente = formatoCompletoNombrePaciente(
                      row.nombrePaciente,
                      row.apellidoPaternoPaciente,
                      row.apellidoMaternoPaciente
                    );
                    return (
                      <TableRow key={row.id} hover>
                        <TableCell>{row.id}</TableCell>

                        <TableCell>
                          <IsNoInformation data={row.idCMD} />
                        </TableCell>

                        <TableCell component="th" scope="row">
                          <IsNoInformation data={row.numeroCuentaPrestador} />
                        </TableCell>

                        <TableCell component="th" scope="row">
                          <Typography>{formatRut(row.rutPaciente)}</Typography>
                          <Typography sx={grayName}>
                            {verificaLargo(nombrePaciente, 10)}
                          </Typography>
                        </TableCell>

                        <TableCell>
                          <Typography>
                            {formatRut(row.prestador.rutPrestador)}
                          </Typography>
                          <Typography sx={grayName}>
                            {verificaLargo(row.prestador.razonSocial, 10)}
                          </Typography>
                        </TableCell>

                        <TableCell>{EtiquetaEstado(row.estado)}</TableCell>

                        <TableCell>
                          {row.causal.descripcionCausal === "null" ||
                            row.causal.descripcionCausal === null
                            ? "N/A"
                            : verificaLargo(row.causal.descripcionCausal, 10)}
                        </TableCell>

                        <TableCell>
                          <IsNoInformation data={row.fechaRecepcion} />
                        </TableCell>

                        <TableCell>
                          <IsNoInformation
                            data={formatDate(row.fechaAsignacionUsuario)}
                          />
                        </TableCell>

                        <TableCell>
                          <IsNoInformation
                            data={formatDate(row.fechaProbableLiquidacion)}
                          />
                        </TableCell>

                        <TableCell>{row.diasRestantes}</TableCell>

                        <TableCell>
                          {row.montoTotalCobro !== null ? (
                            <Typography>
                              ${numbersWithDots(row.montoTotalCobro.toString())}
                            </Typography>
                          ) : (
                            <Typography className="disabled-text">
                              {"Sin información"}
                            </Typography>
                          )}
                        </TableCell>

                        <TableCell>{row.canalOrigen}</TableCell>
                        <TableCell>{row.usuarioAsignado}</TableCell>
                        <TableCell
                          className="tableBodyEspecial"
                          align="center"
                          sx={{ p: "0 !important", minWidth: "210px" }}
                        >
                          <AsignacionUsuario
                            usuarioAsignado={row.usuarioAsignado}
                            row={row}
                            isAuth={isAuth}
                            handleOnClickGestionarCuenta={handleOnClickGestionarCuenta}
                            handleOpenModalAsignarme={handleOpenModalAsignarme}
                            handleRevisarCuenta={handleRevisarCuenta}
                          />
                        </TableCell>
                        <TableCell className="tableBodyAcciones">
                          <MenuCuentasMedicas
                            setSnackData={setSnackData}
                            row={row}
                            refetch={refetch}
                            isAssignedTab={isAssignedTab}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[25, 50, 100]}
              component="div"
              count={count}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleNewChangePage}
              onRowsPerPageChange={handleNewChangeRowsPerPage}
              sx={{ mt: "32px" }}
              labelRowsPerPage={"Mostrar por página"}
              labelDisplayedRows={(e) => {
                return (
                  "" + (page + 1) + " de " + Math.ceil(count / rowsPerPage)
                );
              }}
            />
          </>
        ) : (
          count === 0 && (
            <NoResult
              titulo={
                filtros && Object.keys(filtros).length > 0
                  ? titleNoResults.FILTER
                  : !isAssignedTab
                    ? titleNoResults.ALL_ACCOUNTS
                    : titleNoResults.MY_ACCOUNTS
              }
              subtitulo={
                filtros && Object.keys(filtros).length > 0
                  ? subTitleNoResults.FILTER
                  : !isAssignedTab
                    ? subTitleNoResults.ALL_ACCOUNTS
                    : subTitleNoResults.MY_ACCOUNTS
              }
              noDivider={false}
              newFound={true}
            />
          )
        )}
      </Paper>
    </>
  );
};
