import { useState } from "react";
import { SlimValueSelect2 } from "../../../../components/Selects/valueSelect"
import { queryObtenerTipoHabitacion } from "../../../../services/administracionCM/administracionCMServices";
import { useQuery } from "@apollo/client";

export const SelectHabitacion = ({ disabled, onChange, value }:any) => {
    const [dataTipoHabitacion, setDataTipoHabitacion] = useState<any[]>([]);
    useQuery(queryObtenerTipoHabitacion, {
        fetchPolicy: "no-cache",
        onCompleted: (res) => {
            setDataTipoHabitacion(res.obtenerValoresParametros);
        }
    });
    return (
        <SlimValueSelect2
            onChange={onChange}
            disabled={disabled}
            value={value}
            data={dataTipoHabitacion}
            titulo={"Seleccione un Tipo"}
            name="tipoHabitacion"
        />
    )
}