import { createContext, useContext, useMemo, useState } from "react";
import { IValorizationContextInterface } from "../interfaces/valorizationContextInterface";
import { ReactJSXElement } from "@emotion/react/types/jsx-namespace";

const ValorizationContext = createContext<IValorizationContextInterface>(
  {} as IValorizationContextInterface
);

interface ValorizationProviderProps {
  children: ReactJSXElement | ReactJSXElement[];
}

const ValorizationProvider = ({ children }: ValorizationProviderProps) => {
  const [selectedDetailItem, setSelectedDetailItem] = useState<number[]>([]);
  const [selectedDocTypes, setSelectedDocTypes] = useState<{
    [key: string]: number;
  }>({
    GES: 0,
    CAEC: 0,
    PLAN: 0,
    "GES-CAEC": 0,
  });

  const contextValue = useMemo(
    () => ({
      selectedDetailItem,
      setSelectedDetailItem,
      selectedDocTypes,
      setSelectedDocTypes,
    }),
    [selectedDocTypes, selectedDetailItem]
  );

  return (
    <ValorizationContext.Provider value={contextValue}>
      {children}
    </ValorizationContext.Provider>
  );
};

const useValorizationContext = () => useContext(ValorizationContext);

export { ValorizationProvider, ValorizationContext, useValorizationContext };
