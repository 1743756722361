import { styled } from '@mui/material';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';

const BarTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
        color: '#FFFFFF',
    },
    [`& .${tooltipClasses.tooltip}`]: {
        fontSize: '14px',
        fontFamily: 'Interstate',
        padding: '8px 12px 8px 12px'
    },
}));

export  {
 BarTooltip
}