const openState = {
    bgcolor: '#EEECFB',
    padding: '4px 8px',
    border: '1px solid #5143D5',
    borderRadius: '8px',
    maxWidth: '60px'
}

const closeState = {
    bgcolor: '#FFF8E1',
    padding: '4px 8px',
    border: '1px solid #FFB300',
    borderRadius: '8px',
    maxWidth: '64px'
}

export {
    openState,
    closeState
}