export const GetAccessToken = async (msalInstance: any) => {
    const redirectResponse = await msalInstance.handleRedirectPromise();

    if (redirectResponse !== null) {
        // Acquire token silent success
        return redirectResponse.accessToken;
    } else {
        // MSAL.js v2 exposes several account APIs, logic to determine which account to use is the responsibility of the developer
        const account = msalInstance.getAllAccounts()[0];

        if (account) {
            const accessTokenRequest = {
                scopes: [process.env.REACT_APP_AZURE_SPA_API_SCOPE],
                account: account,
            };
        
            // Use the same msalInstance instance provided to MsalProvider
            return await msalInstance
                .acquireTokenSilent(accessTokenRequest)
                .then(function (accessTokenResponse: any) {
                    // Acquire token silent success
                    return accessTokenResponse.accessToken;
                })
                .catch(function (error: any) {
                    //Acquire token silent failure
                    // console.log(error);
                });
        }
    }
};