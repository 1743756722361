import {
    DialogActions,
    DialogContent,
    Grid,
    Typography,
} from "@mui/material";
import { CustomModal, CustomModalTitle } from "../../../components/Modal/customModal";
import { boldTitle, lineBold, lineText, purpleLine } from "../../../assets/styles/Text/text";
import { SecondaryButton } from "../../../assets/styles/Button/Button";
import { CustomInput } from "../../../assets/styles/Input/input";
import BotonCarga from "../../../components/Buttons/LoadingButton";
import Aviso from "../../../components/Alert/Aviso";

interface Props {
    open: boolean;
    onClose: () => void;
    handleOnClick: (e: any) => void;
    idCuentaMedica: string;
    valorTexto: string;
    type: string;
    handleOnChange: (e: any) => void;
    error: boolean;
    isLoading: boolean;
}

export const ModalReversar = ({
    open,
    onClose,
    handleOnClick,
    idCuentaMedica,
    type,
    valorTexto,
    handleOnChange,
    error,
    isLoading
}: Props) => {

    const obtenerLongitudTexto = (value: string) => {
        return value !== undefined && value !== null ? value.length : "0"
    }

    return (
        <CustomModal
            fullWidth
            maxWidth="sm"
            open={open}
            sx={{
                "& .MuiBackdrop-root": {
                    backgroundColor: "rgba(0, 0, 0, 0.3)",
                },
            }}
        >
            <CustomModalTitle>
                <Typography sx={boldTitle}>Reversar </Typography>
            </CustomModalTitle>

            <DialogContent dividers  >
                <div style={{ textAlign: "center", paddingBottom: "16px" }}>
                    <Aviso open={error} severidad={'error'} titulo={"Ocurrió un error al reversar la cuenta. Por favor, inténtalo de nuevo."}/>
                    <div style={{ marginTop: "24px" }}>
                        <Typography sx={lineText} >¿Estás seguro/a de reversar el estado de la cuenta </Typography>
                        <Typography sx={purpleLine}>  Nº{idCuentaMedica} </Typography>
                        <Typography sx={lineText}> de</Typography> <br></br>
                        <Typography sx={lineBold}> {type === "1" ? "Liquidada" : "Emitida"}</Typography>
                        <Typography sx={lineText}> a</Typography>
                        <Typography sx={lineBold}> {type === "1" ? "En Proceso" : "Liquidada"}</Typography>
                        <Typography sx={lineText}> para su revisión?</Typography><br></br>
                    </div>
                </div>
                <label className="observacionBold">
                    Observación <label className="modalObservacion">(Opcional)</label>
                </label>
                <CustomInput
                    fullWidth
                    multiline
                    value={valorTexto}
                    onChange={handleOnChange}
                    disabled={isLoading}
                    inputProps={{
                        maxLength: 300,
                    }}
                />
                <div className="contador">
                    <label className="textoContador">Texto</label>
                    <label className="textoContador">{obtenerLongitudTexto(valorTexto)}/300</label>
                </div>

            </DialogContent>

            <DialogActions >
                <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                >

                    <SecondaryButton color="primary" sx={{ p: "15px 41px" }} onClick={onClose} disabled={isLoading}>
                        Cancelar
                    </SecondaryButton>
                    <BotonCarga
                        testid="warningMC"
                        toDo={handleOnClick}
                        loading={isLoading}
                        label={"Sí, reversar"}
                        sx={{ marginLeft: "24px", p: "15px 41px" }}
                    />
                </Grid>
            </DialogActions>
        </CustomModal>
    );
};
