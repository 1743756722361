import {
    Button,
    DialogActions,
    DialogContent,
    Grid,
    Typography,
} from "@mui/material";
import { CustomModal, CustomModalTitle } from "../../../../components/Modal/customModal";
import { modalTextBold, modalTitle, warningGray, warningPurple } from "../../../../assets/styles/Text/text";
import { useMutation } from "@apollo/client";
import { mutationEliminarDocumentoCobroCobertura } from "../../../../services/administracionCM/docCobroServices";
import { checkStatus } from "../../utils/checkStatus";
import BotonCarga from "../../../../components/Buttons/LoadingButton";

export const ModalDeleteValorizar = ({ idCuentaMedica, data, onOpen, onClose, refetch, muestraMensaje }: any) => {
    const mensajeRutFolio = `RUT ${data?.rutPrestador || ""} - Folio ${data?.folioDocumento || ""}`
    const [fnEliminarDocument, { loading: loadingEliminarDoc }] = useMutation(mutationEliminarDocumentoCobroCobertura);
    const eliminarDocumento = (e: any) => {
        fnEliminarDocument({
            variables: {
                id: Number(data.id),
                idCob: data.cobertura ? Number(data.cobertura.id) : 0,
                idCuentaMedica: Number(idCuentaMedica)
            },
            onCompleted: (res) => {
                if (res.eliminarDocumentoCobroCobertura?.estado === 1) {
                    muestraMensaje("El Documento de Cobro se Eliminó Exitosamente", 'success');
                    refetch();
                } else {
                    muestraMensaje("No se pudo Eliminar el Documento de Cobro. Por favor, inténtelo nuevamente.", 'error');
                }

                onClose();
            },
            onError: (err: any) => {
                console.error("mutationEliminarDocumentoCobroCobertura", err);
                muestraMensaje("No se pudo Eliminar el Documento de Cobro. Por favor, inténtelo nuevamente.", 'error');
                checkStatus(err.graphQLErrors[0]?.code || "");
            }
        })
    }

    return (
        <CustomModal
            fullWidth
            maxWidth="sm"
            open={onOpen}
            sx={{
                "& .MuiBackdrop-root": {
                    backgroundColor: "rgba(0, 0, 0, 0.3)",
                },
            }}
        >

            <CustomModalTitle>
                <Typography sx={modalTitle} paddingTop="16px">
                    Eliminar documento
                </Typography>
            </CustomModalTitle>

            <DialogContent
                dividers
                sx={{ textAlign: "center", pl: "24px", pr: "24px" }}
            >

                <Typography sx={warningGray}>
                    ¿Quieres eliminar el documento con
                </Typography>{" "}
                <Typography sx={warningPurple} pb="16px">
                    {mensajeRutFolio}
                </Typography>
                <Typography sx={warningGray}>
                    ?<br></br>
                    Al eliminar este documento, se eliminarán también
                </Typography>{" "}
                <Typography sx={modalTextBold} >
                    todas las <br></br>
                    prestaciones
                </Typography>{" "}
                <Typography sx={warningGray}>
                    asociadas.
                </Typography>{" "}
            </DialogContent>

            <DialogActions sx={{ textAlign: "center", margin: "5px 0 5px 0" }}>
                <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Button
                        variant="outlined"
                        sx={{ p: "15px" }}
                        disabled={loadingEliminarDoc}
                        onClick={onClose}
                    >
                        Cancelar
                    </Button>
                    <BotonCarga
                        testid="deleteDocBTN"
                        toDo={eliminarDocumento}
                        loading={loadingEliminarDoc}
                        label={"Eliminar"}
                        sx={{ p: "15px" }}
                    />
                </Grid>
            </DialogActions>
        </CustomModal>
    );
};
