import {
  Typography,
  TableCell,
  TableRow,
  Box,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableBody,
} from "@mui/material";
import { closeState, openState } from "../../../../assets/styles/Status/stateEventoHO";
import { useQuery } from "@apollo/client";
import { queryObtenerEventosHospitalarios } from "../../../../services/administracionCM/eventoHospitalarioServices";
import { format } from "date-fns";
import IsLoading from "../../../../components/isLoading/isLoading";
import { useState } from "react";
import { EmptyStateMessage } from "../../../../components/message/emptyStateMessage";
import { MenuEventos } from "./MenuEventos";
import { CondicionEgreso, FechaCell } from "./TableFragments";

const TableEventoHospitalario = ({ dataCM, setMensajero, loadingCuenta, readOnly }: any) => {
  const [dataEventos, setDataEventos] = useState<any[]>([]);
  const { loading, refetch } = useQuery(queryObtenerEventosHospitalarios, {
    variables: {
      rutPaciente: dataCM.datosBeneficiario.rut,
      rutPrestador: dataCM.datosPrestador.rutPrestador,
      fechaInicioHospitalizacion: format(dataCM.fechaHospitalizacion.fechaInicio, "yyyy-MM-dd"),
    },
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    onCompleted: (res) => setDataEventos(res.obtenerEventosHospitalarios),
    onError: (err) => {
      console.log(err);
      setDataEventos([]);
    }
  });
  return (
    <TableContainer
      className="tContainer-padding-0"
      component={Paper}
    >
      <button hidden id="refetchEventoHO" onClick={() => refetch()} />
      {loading ?
        <IsLoading
          title={"Cargando Eventos Hospitalarios"}
          minHeight="250px"
        />
        :
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead className="color-head-table">
            <TableRow>
              <TableCell>Número Evento</TableCell>
              <TableCell>Estado</TableCell>
              <TableCell>Fecha Inicio HO</TableCell>
              <TableCell>Fecha Egreso HO</TableCell>
              <TableCell>Condición Egreso</TableCell>
              <TableCell>Acciones Evento</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dataEventos.length > 0 && dataEventos.map((row: any) => (
              <TableRow hover key={row.id}>
                <TableCell>
                  <Typography variant="h5" fontSize="14px">
                    {row.id}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Box sx={row.estado === 1 ? openState : closeState}>
                    <Typography variant="h6" fontSize="12px">
                      {row.estado === 1 ? "Abierto" : "Cerrado"}
                    </Typography>
                  </Box>
                </TableCell>
                <TableCell>
                  <FechaCell fecha={row.fechaInicioHospitalizacion} />
                </TableCell>
                <TableCell>
                  <FechaCell fecha={row.fechaAltaHospitalizacion} />
                </TableCell>
                <TableCell>
                  <CondicionEgreso condicion={row.condicionEgreso} />
                </TableCell>
                <TableCell>
                  <MenuEventos
                    row={row}
                    dataCM={dataCM}
                    refetch={refetch}
                    setMensajero={setMensajero}
                    loadingCuenta={loadingCuenta}
                    readOnly={readOnly}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>}
      {!loading && dataEventos.length === 0 &&
        <EmptyStateMessage
          title="Aún no ingresas un Evento Hospitalario"
          subtitle="Ingresa un Evento Hospitalario para empezar"
        />}
    </TableContainer>);
}

export default TableEventoHospitalario;
