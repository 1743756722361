import { useQuery } from "@apollo/client"
import { getCuentasEvento } from "../../../../services/administracionCM/eventoHospitalarioServices"
import { Grid, Skeleton, Stack, Typography } from "@mui/material"
import { useState } from "react";
import { Box } from "@mui/system";

export const CuentasEventos = ({ idEventoHospitalario, idCuenta }: { idEventoHospitalario: number, idCuenta: number }) => {
    const [cuentasEH, setCuentasEH] = useState<any[]>([]);
    const { loading } = useQuery(getCuentasEvento, {
        variables: {
            idEvento: idEventoHospitalario || 0,
            idCuenta: idCuenta
        },
        onCompleted: (res) => {
            setCuentasEH(res.getCuentasEH)
        },
        onError: () => setCuentasEH([])
    })
    return (
        <Grid container sx={{ p: '25px' }}>
            <Stack direction={"column"}>
                {
                    loading ?
                        <Skeleton width={800} height={25} data-testid="loaderCtasEvento" />
                        :
                        <Box>
                            {cuentasEH.length > 0 && cuentasEH.map((cta) =>
                                <Stack key={cta.id}>
                                    <Typography>
                                        Cuenta: {cta.id} - N° de Cobro: {cta.numeroCobroPrestador} - N° de envío: {cta.numeroEnvio} - Fecha Ini: {cta.fechaCobroDesde} Fecha Ter: {cta.fechaCobroHasta || "-"}
                                    </Typography>
                                </Stack>
                            )}
                            {cuentasEH.length === 0 && <Stack>No hay cuentas adicionales para este evento.</Stack>}
                        </Box>

                }
            </Stack>
        </Grid>
    )
}