import * as React from 'react';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import "../../assets/styles/Modal/modalStyle.css";

const CustomModal = styled(Dialog)(() => ({
  '& .MuiDialogContent-root': {
    padding: "24px",
  },
  '& .MuiDialogTitle-root': {
    paddingTop: "24px",
  },
  '& .MuiDialogActions-root': {
    padding: "24px",
  },
}));

export interface DialogTitleProps {
  children?: React.ReactNode;
}

const CustomModalTitle = ({ children }: DialogTitleProps) => {

  return (
    <DialogTitle sx={{ textAlign: "center" }}>
      {children}
    </DialogTitle>
  );
}

export {
  CustomModal,
  CustomModalTitle
}