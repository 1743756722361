//Escritorio de Trabajo
const modalAsignarme = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '480px',
    bgcolor: 'background.paper',
    borderRadius: '8px',
    boxShadow: '0px 2px 10px #364855',
    outline: 'none'
};

const modalAsignarmeIcon = {
    width: '100%', 
    display: 'flex', 
    position: 'fixed', 
    padding: '16px 24px',
    justifyContent: 'flex-end',
    outline: 'none'
}

const modalAsignarmeBotones = {
    height: '88px',
    display: 'flex',
    justifyContent: 'space-between',
    padding: '24px 32px 24px 83px',
    outline: 'none'
}

// Evento Hospitalario administración
const styledModalIncompleteInfo= {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "623px",
    bgcolor: "background.paper",
    borderRadius: "8px",
    boxShadow: "0px 2px 10px #364855",
    p: '30px 40px',
    outline: 'none'
};

const styledNormalModal = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "540px",
    bgcolor: "#FFF",
    borderRadius: "8px",
    boxShadow: "0px 2px 10px #364855",
    outline: 'none'
};

const styledLoaderModal = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "540px",
    bgcolor: "background.paper",
    borderRadius: "8px",
    boxShadow: "0px 2px 10px #364855",
    outline: 'none',
    textAlign: 'center',
    padding: '100px'
};


export {
    modalAsignarme,
    modalAsignarmeIcon,
    modalAsignarmeBotones,
    styledModalIncompleteInfo,
    styledNormalModal,
    styledLoaderModal
}
