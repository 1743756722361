import { DataUser } from "../hooks/getUser";
import { NuevosEstadosCuentaMedica } from "../utils/enums";

export function accessET(user: DataUser) {
  return ["JEFE", "VISADOR", "ADMINISTRATIVO", "VALORIZADOR", "AUDITOR", "LIQUIDADOR"].some((rol) =>
    user.rol.includes(rol)
  );
}

export function accessREPCM(user: DataUser) {
  return ["JEFE", "ADMINISTRATIVO"].some((rol) =>
    user.rol.includes(rol)
  );
}

export function isRolJ(user: DataUser) {
  return ["JEFE"].some((rol) =>
    user.rol.includes(rol)
  );
}

export function accessEM(user: DataUser) {
  return ["JEFE", "LIQUIDADOR", "AUDITOR", 'ADMINISTRATIVO'].some((rol) => user.rol.includes(rol));
}

export function accessREP(user: DataUser) {
  return ["JEFE", "AUDITOR"].some((rol) => user.rol.includes(rol));
}

export function canCMActionByRol(userRol: string[], action: number): boolean {
  const rolesConfig: {[key: number]: string[]} = {
    [NuevosEstadosCuentaMedica.Recepcionada]: ['JEFE', 'VISADOR'] ,
    [NuevosEstadosCuentaMedica.Visación]: ['JEFE', 'ADMINISTRATIVO', 'LIQUIDADOR', 'VALORIZADOR'],
    [NuevosEstadosCuentaMedica.Valorización]: ['JEFE', 'VALORIZADOR', 'VISADOR', 'LIQUIDADOR'],
    [NuevosEstadosCuentaMedica.Bonificación]: ['JEFE', 'ADMINISTRATIVO', 'VALORIZADOR', 'LIQUIDADOR'],
    [NuevosEstadosCuentaMedica.Liquidada]: ['JEFE', 'ADMINISTRATIVO', 'LIQUIDADOR'],
    [NuevosEstadosCuentaMedica.Emitida]: ['JEFE', 'ADMINISTRATIVO'],
    [NuevosEstadosCuentaMedica.Anulada]: ['JEFE', 'ADMINISTRATIVO', 'VISADOR', 'LIQUIDADOR'],
    [NuevosEstadosCuentaMedica.Rechazada]: ['JEFE', 'VISADOR', 'LIQUIDADOR'],
    [NuevosEstadosCuentaMedica.Devuelta]: ['JEFE', 'VALORIZADOR', 'VISADOR'],
    [NuevosEstadosCuentaMedica.EmitidaManual]: ['JEFE', 'VISADOR'],
  };
  if (rolesConfig.hasOwnProperty(action)) {

    return userRol.filter((userRol) => rolesConfig[action].includes(userRol)).length > 0
  }
  return false;
}