import { NuevosEstadosCuentaMedica } from "./enums";

export function canEditVisacion(userRoles: string[]) {
  return userRoles.some((userRol: string) =>
    !['JEFE', 'ADMINISTRATIVO', 'VISADOR', 'VALORIZADOR'].includes(userRol)
  );
}

export function validateACMPerms(
  estado: number,
  rol: string[],
  setCanEditAG: Function,
  setCanEditEH: Function,
  setCanEditDA: Function,
  setCanEditVal: Function,
  setCanEditBonf: Function) {
  setCanEditAG([NuevosEstadosCuentaMedica.Recepcionada, NuevosEstadosCuentaMedica.Visación].includes(estado) && rol.filter((userRol) => ['JEFE', 'ADMINISTRATIVO', 'VISADOR'].includes(userRol)).length > 0);
  setCanEditEH(NuevosEstadosCuentaMedica.Visación === estado && rol.filter((userRol) => ['JEFE', 'VISADOR'].includes(userRol)).length > 0);  
  setCanEditDA([NuevosEstadosCuentaMedica.Recepcionada, NuevosEstadosCuentaMedica.Visación, NuevosEstadosCuentaMedica.Valorización, NuevosEstadosCuentaMedica.Bonificación].includes(estado) &&
   rol.filter((userRol) => ['JEFE', 'VISADOR', 'ADMINISTRATIVO', 'VALORIZADOR', 'LIQUIDADOR'].includes(userRol)).length > 0);
  setCanEditVal(NuevosEstadosCuentaMedica.Valorización === estado && rol.filter((userRol) => ['JEFE', 'VALORIZADOR'].includes(userRol)).length > 0);
  setCanEditBonf((NuevosEstadosCuentaMedica.Bonificación === estado && rol.filter((userRol) => ['JEFE', 'LIQUIDADOR'].includes(userRol)).length > 0)
    || (NuevosEstadosCuentaMedica.PendienteAutorizacion === estado && rol.filter((userRol) => ['JEFE'].includes(userRol)).length > 0));
}