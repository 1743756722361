import { useState } from "react";
import {
    DialogActions,
    DialogContent,
    FormControl,
    FormHelperText,
    Grid,
    Typography,
} from "@mui/material";
import { CustomModal, CustomModalTitle } from "../../../components/Modal/customModal";
import { boldTitle, cardSubT } from "../../../assets/styles/Text/text";
import { PrimaryButton, SecondaryButton } from "../../../assets/styles/Button/Button";
import { CustomInput } from "../../../assets/styles/Input/input";
import { CustomSelect2 } from "../../../components/Selects/customSelect";
import { DatePicker } from "../../../components/DatePicker/DatePicker";
import { formatRut, validRut } from "chilean-rutify";
import { ValueSelect } from "../../../components/Selects/valueSelect";
import { SelectUsuarios } from "../../../utils/SelectUsuarios";
import { useQuery } from "@apollo/client";
import { getMedicalRecordStatus, queryObtenerNuevasCausales } from "../../../services/cm-services/escritorio-cm/escritorio-services";
import { medicalRecordStatus } from "../../../types";


export const ModalFiltrosConsulta = ({
    modal,
    close,
    dataConsultaFiltro,
    actualizaFiltros,
    disabled
}: any) => {
    const [localData, setLocalData] = useState<any>({});
    const [localError, setLocalError] = useState<any>({});
    const [dataCausales, setDataCausales] = useState<any[]>([]);
    const [medicalRecordStatusList, setMedicalRecordStatusList] = useState<
        medicalRecordStatus[]
    >([]);

    if (dataConsultaFiltro !== localData && !modal) {
        setLocalData(dataConsultaFiltro);
    }
    useQuery(queryObtenerNuevasCausales, {
        onCompleted: (res) => {
            let temp: any[] = [];
            res.obtenerNuevasCausales.forEach((causal: any) => {
                temp.push({ ...causal, nombre: causal.descripcion });
            });
            setDataCausales(temp);
        },
        onError: (err) => {
            console.log(err)
        }
    })

    useQuery(getMedicalRecordStatus, {
        variables: {
            statusType: "ConsultaCuentasFiltro",
        },
        onCompleted: (dataMedicalRecordStatus) => {
            const cleanMedicalRecordStatusList =
                dataMedicalRecordStatus.getMedicalRecordStatus.map(
                    (status: medicalRecordStatus) => ({
                        value: status.idParametroInterno,
                        label: status.valorParametro,
                    })
                );
            setMedicalRecordStatusList(cleanMedicalRecordStatusList);
        },
        onError: (err) => {
            console.log(err);
        },
    });

    const onChange = (e: { target: { id: any; value: any; }; }) => {
        const id: string = e.target.id;
        let value = e.target.value;
        if (id === "id" || id === "idCMD" || id === "estadoEmision" || id === "montoTotalCobro" || id === "diasRestantes") {
            value = value.replace(/[^0-9]/g, '');
        }
        if (id === "rutPaciente" || id === "rutPrestador") {
            let rut = e.target.value.replace(/[^0-9kK-]/g, "").trim();
            value = rut.length > 2 ? formatRut(rut)!.toUpperCase() : rut;
            if (value && !validRut(value)) {
                setLocalError({ ...localError, [id]: "Rut inválido" });
            } else {
                let err = localError;
                delete err[id];
                setLocalError(err);
            }
        }
        if (!value) {
            value = undefined
        }
        setLocalData({ ...localData, [id]: value })
    }

    const closeModalEsc = () => {
        close();
    }

    const cleanFilters = () => {
        setLocalError({});
        setLocalData(dataConsultaFiltro);
        closeModalEsc();
    }

    const triggerSearch = () => {
        closeModalEsc();
        actualizaFiltros(localData);
    }

    const dataSelectcanalOrigen = ["CMD", "EMAIL"];
    return (
        <CustomModal
            fullWidth
            maxWidth="md"
            open={modal}
            onClose={close}
            sx={{
                "& .MuiBackdrop-root": {
                    backgroundColor: "rgba(0, 0, 0, 0.3)",
                },
            }}
        >
            <CustomModalTitle>
                <div style={{ textAlign: "left" }}>
                    <Typography sx={boldTitle}>Selección de filtros </Typography>
                    <Typography sx={cardSubT}>Seleccione los criterios de búsqueda para encontrar emisiones especificas.</Typography>
                </div>
            </CustomModalTitle>
            <DialogContent dividers  >
                <Grid container>
                    <Grid item xs={6} pr="24px" pb="16px">
                        <CustomInput
                            label="Nº Cuenta"
                            fullWidth
                            id="id"
                            data-testid="id"
                            value={localData.id || ""}
                            onChange={onChange}
                            InputLabelProps={{ style: { color: "#909AA1" } }}
                        />
                    </Grid>
                    <Grid item xs={6} pb="16px">
                        <CustomInput
                            label="Nº C. Extranet"
                            fullWidth
                            id="idCMD"
                            data-testid="idCMD"
                            value={localData.idCMD || ""}
                            onChange={onChange}
                            InputLabelProps={{ style: { color: "#909AA1" } }}
                        />
                    </Grid>
                    <Grid item xs={6} pr="24px" pb="16px">
                        <CustomInput
                            label="Nº C. Prestador"
                            fullWidth
                            id="numeroCuentaPrestador"
                            data-testid="numeroCuentaPrestador"
                            value={localData.numeroCuentaPrestador || ""}
                            onChange={onChange}
                            InputLabelProps={{ style: { color: "#909AA1" } }}
                        />
                    </Grid>
                    <Grid item xs={6} pb="16px">
                        <FormControl error={localError.rutPaciente || false} fullWidth>
                            <CustomInput
                                label="RUT Beneficiario"
                                fullWidth
                                error={localError.rutPaciente}
                                inputProps={{ maxLength: 12, style: { textTransform: "uppercase" } }}
                                id="rutPaciente"
                                data-testid="rutPaciente"
                                value={localData.rutPaciente || ""}
                                onChange={onChange}
                                InputLabelProps={{ style: { color: "#909AA1" } }}
                            />
                            <FormHelperText>{localError.rutPaciente || ""}</FormHelperText>
                        </FormControl>
                    </Grid>
                    <Grid item xs={6} pr="24px" pb="16px">
                        <FormControl error={localError.rutPrestador || false} fullWidth>
                            <CustomInput
                                label="RUT Prestador"
                                fullWidth
                                id="rutPrestador"
                                data-testid="rutPrestador"
                                error={localError.rutPrestador}
                                inputProps={{ maxLength: 12, style: { textTransform: "uppercase" } }}
                                value={localData.rutPrestador || ""}
                                onChange={onChange}
                                InputLabelProps={{ style: { color: "#909AA1" } }}
                            />
                            <FormHelperText>{localError.rutPrestador || ""}</FormHelperText>
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        <ValueSelect
                            defaultValue={0}
                            titulo={"Causal"}
                            name="descripcionCausal"
                            data={dataCausales}
                            value={localData.causal}
                            onChange={(e: any) => setLocalData({ ...localData, causal: e?.target?.value || undefined })}
                        />
                    </Grid>
                    <Grid item xs={6} pr="24px">
                        <CustomSelect2
                            defaultValue={0}
                            phvalue={0}
                            id="estadoEmision"
                            value={localData.estadoEmision || null}
                            onChange={(e: any) => setLocalData({ ...localData, estadoEmision: e?.target?.value || undefined })}
                            data={medicalRecordStatusList}
                            placeholder={"Estado"}
                        />
                    </Grid>
                    <Grid item xs={6} pb="16px">
                        <DatePicker
                            value={localData.fechaRecepcion || null}
                            onChange={(date: Date | null | undefined) => setLocalData({ ...localData, fechaRecepcion: date || undefined })}
                            placeholder="Fecha Recepción"
                        />
                    </Grid>
                    <Grid item xs={6} pr="24px" pb="16px">
                        <DatePicker
                            value={localData.fechaAsignacion || null}
                            onChange={(date: Date | null | undefined) => setLocalData({ ...localData, fechaAsignacion: date || undefined })}
                            placeholder="Fecha Asignación"
                        />
                    </Grid>
                    <Grid item xs={6} pb="16px">
                        <DatePicker
                            value={localData.fechaCompromiso || null}
                            onChange={(date: Date | null | undefined) => setLocalData({ ...localData, fechaCompromiso: date || undefined })}
                            placeholder="Fecha Compromiso"
                        />
                    </Grid>
                    <Grid item xs={6} pr="24px" pb="16px">
                        <FormControl fullWidth>
                            <CustomInput
                                label="Días restantes"
                                fullWidth
                                id="diasRestantes"
                                value={localData.diasRestantes || null}
                                onChange={onChange}
                                InputLabelProps={{ style: { color: '#909AA1' }, }}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={6} pb="16px">
                        <FormControl fullWidth>
                            <CustomInput
                                label="Monto de cobro"
                                fullWidth
                                id="montoTotalCobro"
                                value={localData.montoTotalCobro || null}
                                onChange={onChange}
                                InputLabelProps={{ style: { color: '#909AA1' }, }}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={6} pr="24px">
                        <ValueSelect
                            defaultValue={0}
                            id="canalOrigen"
                            value={localData.canalOrigen || null}
                            onChange={(e: any) => setLocalData({
                                ...localData,
                                canalOrigen: e?.target?.value || undefined,
                            })}
                            data={dataSelectcanalOrigen}
                            titulo={"Canal Origen"}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <SelectUsuarios
                            onChange={(e: any) => setLocalData({
                                ...localData,
                                idUsuario: e?.target?.value || undefined,
                            })}
                            value={localData.idUsuario}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions >
                <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                >
                    <SecondaryButton
                        color="primary"
                        sx={{ p: "15px 41px" }}
                        onClick={cleanFilters}
                    >
                        Cancelar
                    </SecondaryButton>
                    <PrimaryButton
                        sx={{ marginLeft: "24px", p: "15px 41px" }}
                        disabled={disabled || Object.keys(localError).length > 0 || localData === dataConsultaFiltro}
                        onClick={triggerSearch}
                    >
                        Filtrar
                    </PrimaryButton>
                </Grid>
            </DialogActions>
        </CustomModal>
    );
};
