import React from "react";
import NumberFormat, { InputAttributes } from "react-number-format";

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

const NumberFormatCustom = React.forwardRef<NumberFormat<InputAttributes>, CustomProps>(function NumberFormatCustom(props, ref) {
  const { onChange, ...other } = props;
  const MAX_VAL = 2000000000;

  const  maxLength = (inputObj: any) => {
    const { value } = inputObj;
    return value <= MAX_VAL ? true :  false
  }

  return (
    <NumberFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator={"."}
      decimalSeparator={","}
      isAllowed={maxLength}
      isNumericString
    />
  );
});

export default NumberFormatCustom