import {
  DialogActions,
  DialogContent,
  Grid,
  Typography
} from "@mui/material";
import { CustomModal, CustomModalTitle } from "../../../components/Modal/customModal";
import { boldTitle } from "../../../assets/styles/Text/text";
import { SecondaryButton } from "../../../assets/styles/Button/Button";
import BotonCarga from "../../../components/Buttons/LoadingButton";
import { CustomInput } from "../../../assets/styles/Input/input";
import { useState } from "react";

interface Props {
  open: boolean;
  handleOnClose: () => void;
  handleOnClickEmitir: (nota: string) => void;
  loading: boolean;
}

export const ModalEmitir = ({ open, handleOnClose, handleOnClickEmitir, loading }: Props) => {
  const [nota, setNota] = useState<string>("");
  return (
    <CustomModal
      fullWidth
      maxWidth="sm"
      open={open}
      sx={{
        "& .MuiBackdrop-root": {
          backgroundColor: "rgba(0, 0, 0, 0.3)",
        },
      }}
    >
      <CustomModalTitle>
        <Typography sx={boldTitle}>Emitir</Typography>
      </CustomModalTitle>

      <DialogContent dividers sx={{ textAlign: "center" }}>
        <Typography className="modalText">
          ¿Estás seguro/a de emitir las cuentas seleccionadas?
        </Typography>
        <Grid item xs={12} pt="16px">
          <label className="observacionBold">
            Nota resolutiva{" "}
            <label className="modalObservacion">(opcional)</label>
          </label>
          <CustomInput
            fullWidth
            multiline
            inputProps={{
              maxLength: 300,
            }}
            onChange={(e: any) => setNota(e.target.value)}
            value={nota}
            disabled={loading}
          />
          <div className="contador">
            <label className="textoContador"></label>
            <label className="textoContador">{nota.length}/300</label>
          </div>
        </Grid>
      </DialogContent>

      <DialogActions>
        <Grid container direction="row" justifyContent="space-between">
          <SecondaryButton
            color="primary"
            sx={{ p: "15px 41px" }}
            onClick={handleOnClose}
            disabled={loading}
          >
            Cancelar
          </SecondaryButton>
          <BotonCarga
            toDo={()=>handleOnClickEmitir(nota)}
            sx={{ marginLeft: "24px", p: "15px 41px" }}
            loading={loading}
            label="Sí, Emitir"
          />
        </Grid>
      </DialogActions>
    </CustomModal>
  );
};
