import {
  IconCheckSnack,
  IconErrorSnack,
  IconInfoSnack,
  IconWarningSnack,
} from "../../icons/iconosEscencial";

export function style(value) {
  switch (value) {
    case "info":
      return {
        width: "100%",
        border: "1px solid #978EE6",
        backgroundColor: "#EEECFB",
      };
    case "error":
      return {
        width: "100%",
        border: "1px solid #E57373",
        backgroundColor: "#FFEBEE",
      };
    case "success":
      return {
        width: "100%",
        border: "1px solid #7CB342",
        backgroundColor: "#F1F8E9",
      };
    case "warning":
      return {
        width: "100%",
        border: "1px solid #FFB300",
        borderRadius: "8px",
        backgroundColor: "#FFF8E1",
        color: "#203442",
        fontSize: "12px"
      };
    default:
      return {};
  }
}
export function iconStyle(value) {
  switch (value) {
    case "info":
      return {
        info: <IconInfoSnack fontSize="inherit" />,
      };
    case "error":
      return {
        error: <IconErrorSnack fontSize="inherit" />,
      };
    case "success":
      return {
        success: <IconCheckSnack fontSize="inherit" />,
      };
    case "warning":
      return {
        warning: <IconWarningSnack fontSize="inherit" />,
      };
    default:
      return;
  }
}
