import { Fragment, useState, useEffect } from "react";
import {
  DialogActions,
  DialogContent,
  FormControl,
  FormHelperText,
  Grid,
  Typography,
} from "@mui/material";
import { CustomModal, CustomModalTitle } from "../../../components/Modal/customModal";
import { boldTitle, cardSubT } from "../../../assets/styles/Text/text";
import { PrimaryButton, SecondaryButton } from "../../../assets/styles/Button/Button";
import { CustomInput } from "../../../assets/styles/Input/input";
import { ValueSelect } from "../../../components/Selects/valueSelect";
import { DatePicker } from "../../../components/DatePicker/DatePicker";
import {
  validarRut,
  stringToNumber,
  formatRut,
  rutFormatNumber,
} from "../../../utils";
import { format, isValid } from "date-fns";
import NumberFormatCustom from "../../../components/NumberFormatCustom/NumberFormatCustom";
import { SelectUsuarios } from "../../../utils/SelectUsuarios";
import { CustomSelect2 } from "../../../components/Selects/customSelect";
import { medicalRecordStatus } from "../../../types";

type modalArgs = {
  dataFiltro: any;
  findMD: Function;
  rowsPerPage: Number;
  pageChange: Function;
  openModal: boolean;
  handleOnClose: any;
  causales: any;
  selectCausales: any;
  isAssignedTab: boolean;
  medicalRecordStatusList: medicalRecordStatus[];
  isAuth?: any
};

type ErrorArgs = {
  rutPaciente?: boolean;
  rutPrestador?: boolean;
};

const dataSelectcanalOrigen = ["CMD", "EMAIL"];

export const ModalFiltros = ({
  dataFiltro,
  findMD,
  rowsPerPage,
  pageChange,
  openModal,
  handleOnClose,
  causales,
  selectCausales,
  isAssignedTab,
  medicalRecordStatusList,
  isAuth
}: modalArgs) => {
  const validateCausalIdToString = (causalId: number | undefined | string) => {
    if (causalId !== undefined) {
      const causalFinded = causales.find(
        (element: any) => element.id === causalId
      );
      return causalFinded.descripcion;
    } else {
      return undefined;
    }
  };

  const validateStringToDate = (date: any) => {
    if (date !== undefined) {
      const parts = date.split("-");
      const year = parseInt(parts[0], 10);
      const month = parseInt(parts[1], 10) - 1;
      const day = parseInt(parts[2], 10);
      return new Date(year, month, day);
    } else {
      return undefined;
    }
  };

  const validateRut = (rut: any) => {
    if (rut !== undefined) {
      return formatRut(rut);
    } else {
      return undefined;
    }
  };

  const formatLocalFilters = () => {
    return {
      ...dataFiltro,
      causal: validateCausalIdToString(dataFiltro.causal),
      estado: dataFiltro.estado,
      fechaAsignacionUsuario: validateStringToDate(
        dataFiltro.fechaAsignacionUsuario
      ),
      fechaRecepcion: validateStringToDate(dataFiltro.fechaRecepcion),
      fechaProbableLiquidacion: validateStringToDate(
        dataFiltro.fechaProbableLiquidacion
      ),
      rutPaciente: validateRut(dataFiltro.rutPaciente),
      rutPrestador: validateRut(dataFiltro.rutPrestador),
    };
  };

  const [localFilters, setLocalFilters] = useState<any>(
    formatLocalFilters()
  );
  const [error, setError] = useState<ErrorArgs>({});
  const [disabledFiltrarButton, setDisabledFiltarButton] =
    useState<boolean>(true);

  if (!openModal && localFilters !== dataFiltro) {
    setLocalFilters(formatLocalFilters());
  }

  const validateMontoTotalCobro = (montoTotalCobro: string | undefined) => {
    return montoTotalCobro !== undefined
      ? stringToNumber(montoTotalCobro)
      : undefined;
  };

  const validateDate = (newDate: Date | undefined) => {
    if (newDate !== undefined) {
      if (isValid(newDate)) {
        return format(newDate, "yyyy-MM-dd");
      } else {
        return undefined;
      }
    } else {
      return undefined;
    }
  };

  const findCausal = (causal: string | undefined) => {
    if (causal !== undefined) {
      const causalFinded = causales.find(
        (element: any) => element.descripcion === localFilters.causal
      );
      return causalFinded.id;
    } else {
      return undefined;
    }
  };

  const validateUndefined = (filtros: any) => {
    for (const key in filtros) {
      if (filtros.hasOwnProperty(key) && filtros[key] !== undefined) {
        return false;
      }
    }
    return true;
  };

  const onChange = (e: { target: { id: any; value: any } }) => {
    const id: string = e.target.id;
    let value = e.target.value;
    if (id === "id" || id === "idCMD" || id === "diasRestantes") {
      value = parseInt(value.replace(/[^0-9]/g, ""));
    }

    if (!value || value === "") {
      value = undefined;
    }
    setLocalFilters((prevState: any) => ({ ...prevState, [id]: value }));
  };

  const onChangeRut = (e: any) => {
    const id: string = e.target.id;
    let value = e.target.value;
    if (id === "rutPaciente" || id === "rutPrestador") {
      if (value && !validarRut(formatRut(value))) {
        setError({ ...error, [id]: "Rut inválido" });
      } else {
        let err = error;
        delete err[id];
        setError(err);
      }
    }
    if (!value || value === "") {
      value = undefined;
    }
    setLocalFilters((prevState: any) => ({
      ...prevState,
      [id]: value !== undefined ? formatRut(value) : undefined,
    }));
  };

  const onChangeFechaRecepcion = (newDate: any) => {
    setLocalFilters((prevState: any) => ({
      ...prevState,
      fechaRecepcion: newDate || undefined,
    }));
  };

  const onChangeFechaAsignacion = (newDate: any) => {
    setLocalFilters(
      (prevState: any) =>
        ({ ...prevState, fechaAsignacionUsuario: newDate } || undefined)
    );
  };

  const onChangeFechaCompromiso = (newDate: any) => {
    setLocalFilters((prevState: any) => ({
      ...prevState,
      fechaProbableLiquidacion: newDate || undefined,
    }));
  };

  const onChangeEstado = (e: any) => {
    setLocalFilters((prevState: any) => ({
      ...prevState,
      estado: e.target.value || undefined,
    }));
  };

  const onChangeCanalOrigen = (e: any) => {
    setLocalFilters((prevState: any) => ({
      ...prevState,
      canalOrigen: e.target.value || undefined,
    }));
  };

  const onChangeUsuarioAsignado = (e: any) => {
    setLocalFilters((prevState: any) => ({
      ...prevState,
      idUsuario: e.target.value || undefined,
    }));
  };

  const onChangeCausal = (e: any) => {
    setLocalFilters((prevState: any) => ({
      ...prevState,
      causal: e.target.value || undefined,
    }));
  };

  const onChangeMontoCobro = (e: any) => {
    setLocalFilters((prevState: any) => ({
      ...prevState,
      montoTotalCobro: e.target.value || undefined,
    }));
  };

  const onChangeDiasRestantes = (e: any) => {
    setLocalFilters((prevState: any) => ({
      ...prevState,
      diasRestantes:
        parseInt(e.target.value.replace(/[^0-9]/g, "")) || undefined,
    }));
  };

  const triggerSearch = () => {
    const newFiltros = {
      id: localFilters.id || undefined,
      idCMD: localFilters.idCMD || undefined,
      numeroCuentaPrestador: localFilters.numeroCuentaPrestador || undefined,
      rutPaciente: localFilters.rutPaciente
        ? rutFormatNumber(localFilters.rutPaciente)
        : undefined,
      rutPrestador: localFilters.rutPrestador
        ? rutFormatNumber(localFilters.rutPrestador)
        : undefined,
      fechaRecepcion: validateDate(localFilters.fechaRecepcion),
      fechaAsignacionUsuario: validateDate(localFilters.fechaAsignacionUsuario),
      fechaProbableLiquidacion: validateDate(
        localFilters.fechaProbableLiquidacion
      ),
      causal: findCausal(localFilters.causal),
      estado: localFilters.estado || undefined,
      montoTotalCobro: validateMontoTotalCobro(localFilters.montoTotalCobro),
      diasRestantes: localFilters.diasRestantes || undefined,
      canalOrigen: localFilters.canalOrigen || undefined,
      idUsuario: localFilters.idUsuario || undefined,
    };
    pageChange(0);
    findMD(0, rowsPerPage, null, newFiltros);
    handleOnClose();
  };

  useEffect(() => {
    if (validateUndefined(localFilters)) {
      setDisabledFiltarButton(true);
    } else {
      setDisabledFiltarButton(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localFilters]);

  return (
    <Fragment>
      <CustomModal
        fullWidth
        maxWidth="md"
        open={openModal}
        onClose={handleOnClose}
        sx={{
          "& .MuiBackdrop-root": {
            backgroundColor: "rgba(0, 0, 0, 0.3)",
          },
        }}
      >
        <CustomModalTitle>
          <div style={{ textAlign: "left" }}>
            <Typography sx={boldTitle}>Selección de filtros</Typography>
            <Typography sx={cardSubT}>
              Seleccione los criterios de búsqueda para encontrar emisiones
              específicas.
            </Typography>
          </div>
        </CustomModalTitle>

        <DialogContent dividers>
          <Grid container>
            <Grid item xs={6} pr="24px" pb="16px">
              <CustomInput
                label="Nº Cuenta"
                fullWidth
                id="id"
                data-testid="id"
                value={localFilters.id || null}
                onChange={onChange}
                InputLabelProps={{ style: { color: "#909AA1" } }}
              />
            </Grid>
            <Grid item xs={6} pb="16px">
              <CustomInput
                label="Nº Cuenta Extranet"
                fullWidth
                id="idCMD"
                data-testid="idCMD"
                value={localFilters.idCMD || null}
                onChange={onChange}
                InputLabelProps={{ style: { color: "#909AA1" } }}
              />
            </Grid>
            <Grid item xs={6} pr="24px" pb="16px">
              <CustomInput
                label="Nº Cuenta Prestador"
                fullWidth
                id="numeroCuentaPrestador"
                value={localFilters.numeroCuentaPrestador || null}
                onChange={onChange}
                InputLabelProps={{ style: { color: "#909AA1" } }}
              />
            </Grid>
            <Grid item xs={6} pb="16px">
              <FormControl error={error.rutPaciente || false} fullWidth>
                <CustomInput
                  label="RUT Beneficiario"
                  fullWidth
                  error={error.rutPaciente}
                  id="rutPaciente"
                  value={localFilters.rutPaciente || null}
                  onChange={onChangeRut}
                  InputLabelProps={{ style: { color: "#909AA1" } }}
                />
                <FormHelperText>{error.rutPaciente || ""}</FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={6} pr="24px" pb="16px">
              <FormControl error={error.rutPrestador || false} fullWidth>
                <CustomInput
                  label="RUT Prestador"
                  fullWidth
                  id="rutPrestador"
                  error={error.rutPrestador}
                  value={localFilters.rutPrestador || null}
                  onChange={onChangeRut}
                  InputLabelProps={{ style: { color: "#909AA1" } }}
                />
                <FormHelperText>{error.rutPrestador || ""}</FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={6} pb="16px">
              <ValueSelect
                defaultValue={0}
                titulo={"Causal"}
                name="descripcionCausal"
                data={selectCausales}
                value={localFilters.causal}
                onChange={onChangeCausal}
              />
            </Grid>
            <Grid item xs={6} pr="24px" pb="16px">
              <CustomSelect2
                value={localFilters.estado || null}
                data={medicalRecordStatusList}
                disabled={isAuth}
                placeholder={"Estado"}
                name="estado"
                onChange={onChangeEstado}
              />
            </Grid>
              <Grid item xs={6} pb="16px">
                <DatePicker
                  value={localFilters.fechaRecepcion || null}
                  onChange={(date:any) => onChangeFechaRecepcion(date)}
                  placeholder="Fecha Recepción"
                />
              </Grid>
              <Grid item xs={6} pr="24px" pb="16px">
                <DatePicker
                  value={localFilters.fechaAsignacionUsuario || null}
                  onChange={(date: any) => onChangeFechaAsignacion(date)}
                  placeholder="Fecha Asignación"
                />
              </Grid>
              <Grid item xs={6} pb="16px">
                <DatePicker
                  value={localFilters.fechaProbableLiquidacion || null}
                  onChange={onChangeFechaCompromiso}
                  placeholder= "Fecha Compromiso"
                />
              </Grid>
            <Grid item xs={6} pb="16px" pr="24px">
              <FormControl fullWidth>
                <CustomInput
                  label="Días restantes"
                  fullWidth
                  id="diasRestantes"
                  value={localFilters.diasRestantes || null}
                  onChange={onChangeDiasRestantes}
                  InputLabelProps={{ style: { color: "#909AA1" } }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6} pb="16px">
              <FormControl fullWidth>
                <CustomInput
                  label="Monto de cobro"
                  fullWidth
                  id="montoTotalCobro"
                  value={localFilters.montoTotalCobro || null}
                  onChange={onChangeMontoCobro}
                  InputProps={{ inputComponent: NumberFormatCustom as any }}
                  InputLabelProps={{ style: { color: "#909AA1" } }}
                />
              </FormControl>
            </Grid>
            <Grid
              item
              xs={isAssignedTab ? 12 : 6}
              pb={isAssignedTab ? "" : "16px"}
              pr={isAssignedTab ? "" : "24px"}
            >
              <ValueSelect
                defaultValue={0}
                id="canalOrigen"
                value={localFilters.canalOrigen || null}
                onChange={onChangeCanalOrigen}
                data={dataSelectcanalOrigen}
                titulo={"Canal Origen"}
              />
            </Grid>
            {!isAssignedTab && (
              <Grid item xs={6} pb="16px">
                <SelectUsuarios
                  onChange={onChangeUsuarioAsignado}
                  value={localFilters.idUsuario || null}
                />
              </Grid>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid container direction="row" justifyContent="space-between">
            <SecondaryButton
              color="primary"
              sx={{ p: "15px 41px" }}
              onClick={handleOnClose}
            >
              Cancelar
            </SecondaryButton>
            <PrimaryButton
              onClick={triggerSearch}
              disabled={
                Object.keys(error).length > 0 ||
                disabledFiltrarButton
              }
              sx={{ marginLeft: "24px", p: "15px 41px" }}
            >
              Filtrar
            </PrimaryButton>
          </Grid>
        </DialogActions>
      </CustomModal>
    </Fragment>
  );
};
