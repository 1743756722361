import { ForzarBonificacion } from "./forzarBonificacion";
import { useState } from "react";
import { useMutation } from "@apollo/client";
import { mutationForzarBonificacion } from "../../../../services/administracionCM/bonificacionServices";
import BotonCarga from "../../../../components/Buttons/LoadingButton";
import { checkStatus } from "../../utils/checkStatus";
import EditIcon from '@mui/icons-material/Edit';

export const ForzarBonif = ({ row, showMessage, tipoCobertura, tipoDoc, refetch, disabled, montoForzado, isCuentaAuth, refetchDoc, authFilter }: any) => {
    const [openForce, setOpenForce] = useState<boolean>(false);
    const [fnnForzar, { loading }] = useMutation(mutationForzarBonificacion);

    const HandleForce = () => {
        if (montoForzado && !authFilter) {
            fnnForzar({
                variables: { id: row.id, montoBonificacionForzada: null },
                onCompleted: (res) => {
                    if (res.forzarBonificacion.respuesta.estado === 1) {
                        showMessage(res.forzarBonificacion.respuesta.mensaje, 'warning');
                    } else {
                        showMessage("Prestación reversada correctamente", 'success');
                    }
                    refetch();
                    refetchDoc();
                },
                onError: (err: any) => {
                    console.log(err.message);
                    showMessage("No se ha Podido reversar la prestación. Por favor, inténtelo nuevamente", 'error');
                    checkStatus(err.graphQLErrors[0]?.code || "");
                }
            });
        } else {
            setOpenForce(true);
        }
    }

    const handleClose = () => setOpenForce(false);
    const textoReversaNoAuth = row.EstadoAutorizacion === 2 ? <>Reversar<br /> Pte. de autorización</> : "Reversar F.";
    const textoReversa = (authFilter && isCuentaAuth) ? <><EditIcon />Editar Monto</> : textoReversaNoAuth;
    return (
        <>
            <ForzarBonificacion
                onOpen={openForce}
                onClose={handleClose}
                row={row}
                showMessage={showMessage}
                tipoCobertura={tipoCobertura}
                tipoDoc={tipoDoc}
                refetch={refetch}
                refetchDoc={refetchDoc}
                isCuentaAuth={isCuentaAuth}
            />
            <BotonCarga
                ripple={true}
                classname="inheritCarga"
                testid="btnForzar"
                disabled={disabled}
                toDo={HandleForce}
                loading={loading}
                variant="text"
                type="outlined"
                sx={{ height: row.EstadoAutorizacion === 2 ? '75px' : '30px' }}
            >
                { montoForzado ? textoReversa : "Forzar" }
            </BotonCarga>
        </>
    )
}