import { useState, useEffect } from "react";
import { eachYearOfInterval } from "date-fns";
import axios from "axios";
import { useGetUser } from "../../../hooks/getUser";
import { useQuery, useLazyQuery } from "@apollo/client";
import { queryObtenerReportes } from "../../../services/reportes/reportes.service";
import { openExcelFromBase64 } from "../../../utils/index";
import { GetAccessToken } from "../../../utils/azureADTokens";
import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "../../../authConfig";
import { stateProps } from "../../../components/Snackbar/Snackbar";
import { AlertColor } from "@mui/material";

function useReportesMaestros() {
  const [dataBar, setDataBar] = useState<stateProps>({
    abrir: false,
    severidad: 'info',
    mensaje: ''
  });
  const [listaReportes, setListaReportes] = useState([]);
  const [years, setYears] = useState<string[]>([])
  const [selectedValues, setSelectedValues] = useState<any>({ year: null, month: null })
  const [isDisabledGenerarArchivo, setIsDisabledGenerarArchivo] = useState<boolean>(true)
  const [isLoadingGenerarArchivo, setIsLoadingGenerarArchivo] = useState<boolean>(false)
  const [isLoadingDescargaArchivo, setIsLoadingDescargaArchivo] = useState<boolean>(false)
  const { email } = useGetUser()
  const [modalErrorGenerar, setModalErrorGenerar] = useState<boolean>(false)
  const [modalConfirmarGenerar, setModalConfirmarGenerar] = useState<boolean>(false)

  const months = [
    { id: 1, valorParametro: "Enero" },
    { id: 2, valorParametro: "Febrero" },
    { id: 3, valorParametro: "Marzo" },
    { id: 4, valorParametro: "Abril" },
    { id: 5, valorParametro: "Mayo" },
    { id: 6, valorParametro: "Junio" },
    { id: 7, valorParametro: "Julio" },
    { id: 8, valorParametro: "Agosto" },
    { id: 9, valorParametro: "Septiembre" },
    { id: 10, valorParametro: "Octubre" },
    { id: 11, valorParametro: "Noviembre" },
    { id: 12, valorParametro: "Diciembre" },
  ];
  const monthsSelects = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"]

  const { data, loading: loadingListaReportes } = useQuery(queryObtenerReportes, { fetchPolicy: "no-cache" })
  const [fnObtenerListadoReportes, { loading: loadingActualizarListadoReportes }] = useLazyQuery(queryObtenerReportes, { fetchPolicy: "no-cache" })

  const cargaMensaje = (mensaje: string, severidad: AlertColor) => {
    setDataBar({
      abrir: true,
      severidad,
      mensaje
    })
  }
  const calculateYears = () => {
    const endDate = new Date();
    const arrayYears = eachYearOfInterval({
      start: new Date(2022, 0, 1),
      end: new Date(endDate.getFullYear(), 0, 1),
    });
    return arrayYears.map((item: Date) => item.getFullYear().toString());
  };

  const fillSelects = (array: any) => {
    return array.map((item: any) => item.valorParametro)
  }

  const handleOnChangeSelectMonths = (e: any) => {
    setSelectedValues((prevState: any) => ({ ...prevState, month: e.target.value }))
  }

  const handleOnChangeSelectYear = (e: any) => {
    setSelectedValues((prevState: any) => ({ ...prevState, year: e.target.value }))
  }

  const findIdByValue = (value: string | null, array: any) => {
    return array.find((item: any) => item.valorParametro === value)
  }

  const actualizarListadoReportes = () => {
    fnObtenerListadoReportes()
      .then(({ data }: any) => {
        if (data !== null && data !== undefined) {
          if (data.obtenerReportesGH !== null && data.obtenerReportesGH !== undefined) {
            setListaReportes(data.obtenerReportesGH)
          }
        }
      })
      .catch((error: any) => {
        console.log(error)
        setListaReportes([])
      })
  }

  const agregarCeroMes = () => {
    const monthFinded = findIdByValue(selectedValues?.month, months)
    const mes = monthFinded.id.toString().replace(/^(\d)$/, '0$1');
    return `${selectedValues.year}${mes}`
  }

  const validaPeriodo = (reporte: any) => {
    const periodoConCero = agregarCeroMes()
    const monthFinded = findIdByValue(selectedValues?.month, months)
    const periodo = `${selectedValues.year}${monthFinded.id}`

    if (/^\d{6}$/.test(reporte.folioDocumento)) {
      if (reporte.folioDocumento === periodoConCero) {
        return true
      }
    } else {
      if (reporte.folioDocumento === periodo) {
        return true
      }
    }
  }

  const handleGenerarArchivo = async () => {
    if (listaReportes.some(validaPeriodo)) {
      setModalConfirmarGenerar(true)
    } else {
      requestGenerarArchivo()
    }
  }

  const requestGenerarArchivo = async () => {
    const msalInstance = process.env.JEST_WORKER_ID !== undefined ? '' : new PublicClientApplication(msalConfig);
    const token = msalInstance ? await GetAccessToken(msalInstance) : '';

    setIsLoadingGenerarArchivo(true)
    const monthFinded = findIdByValue(selectedValues?.month, months)
    axios.post(`${process.env.REACT_APP_URL_CTA_AXIOS}/reportes/crear`, { anio: Number(selectedValues.year), mes: monthFinded.id, usuario: email }, { headers: { "Authorization": `Bearer ${token}` } })
      .then(({ data }: any) => {
        const { estado } = data
        switch (estado) {
          case 0:
            setIsLoadingGenerarArchivo(false);
            actualizarListadoReportes();
            cargaMensaje("Se Genero el Archivo Exitosamente", 'success');
            break;
          case 1:
            setIsLoadingGenerarArchivo(false)
            cargaMensaje("No fue posible Generar el Archivo, Porfavor inténtelo Nuevamente", 'error');
            break
          case 2:
            setIsLoadingGenerarArchivo(false)
            setModalErrorGenerar(true)
            break
          default:
            setIsLoadingGenerarArchivo(false)
            cargaMensaje("No fue posible Generar el Archivo, Porfavor inténtelo Nuevamente", 'error');
            break
        }
      })
      .catch((error: any) => {
        console.log(error)
        setIsLoadingGenerarArchivo(false)
        cargaMensaje("No fue posible Generar el Archivo, Porfavor inténtelo Nuevamente", 'error');
      })
  }

  const handleActualizarReporte = () => {
    requestGenerarArchivo()
    setModalConfirmarGenerar(false)
  }

  const handleClickDescargar = async (dataReporte: any) => {
    const msalInstance = process.env.JEST_WORKER_ID !== undefined ? '' : new PublicClientApplication(msalConfig);
    const token = msalInstance ? await GetAccessToken(msalInstance) : '';

    setIsLoadingDescargaArchivo(true)
    const anio = dataReporte.folioDocumento.substr(0, 4)
    const mes = dataReporte.folioDocumento.substr(4)
    axios.post(`${process.env.REACT_APP_URL_CTA_AXIOS}/reportes/descargar`, { anio: Number(anio), mes: Number(mes) }, { headers: { "Authorization": `Bearer ${token}` } })
      .then(({ data }: any) => {
        console.log(data)
        setIsLoadingDescargaArchivo(false)
        const [nombre] = dataReporte.nombreArchivo.split(".")
        openExcelFromBase64(data, nombre)
        cargaMensaje("Se Ha Descargado el Archivo Exitosamente", 'success');
      })
      .catch((error: any) => {
        console.log(error)
        setIsLoadingDescargaArchivo(false)
        cargaMensaje("No fue Posible Descargar el Archivo, Porfavor Inténtelo Nuevamente", 'error');
      })
  }

  useEffect(() => {
    setYears(calculateYears());
  }, []);

  useEffect(() => {
    const { year, month } = selectedValues
    if (year !== null && month !== null) {
      setIsDisabledGenerarArchivo(false)
    } else {
      setIsDisabledGenerarArchivo(true)
    }
  }, [selectedValues])

  useEffect(() => {
    if (data !== null && data !== undefined) {
      if (data.obtenerReportesGH !== null && data.obtenerReportesGH !== undefined) {
        setListaReportes(data.obtenerReportesGH)
      }
    } else {
      setListaReportes([])
    }
  }, [data])

  return {
    years,
    months,
    fillSelects,
    selectedValues,
    handleOnChangeSelectMonths,
    handleOnChangeSelectYear,
    handleGenerarArchivo,
    isDisabledGenerarArchivo,
    isLoadingGenerarArchivo,
    listaReportes,
    loadingListaReportes,
    loadingActualizarListadoReportes,
    handleClickDescargar,
    isLoadingDescargaArchivo,
    dataBar,
    setDataBar,
    modalErrorGenerar,
    setModalErrorGenerar,
    monthsSelects,
    modalConfirmarGenerar,
    setModalConfirmarGenerar,
    handleActualizarReporte
  }
}

export default useReportesMaestros
