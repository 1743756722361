import { gql } from "@apollo/client";

export const getCuentaMedicasQuery = gql`
query getCuentaMedica($idCM: Int){ 
  admCuentaMedica(id: $idCM ){
    idUsuario
    idCuentaMedica
    datosPrestador {
      rutPrestador
        numeroCuenta
        numeroCobro
        numeroEnvio
        razonSocial
      rutMedicoTratante
      nombreMedicoTratante
    }
    datosBeneficiario {
      rut
      nombre
      apellidoPaterno
      apellidoMaterno
      diagnosticoMedico
      diagnosticoPrimario {
        codigo
        descripcion
      }
      diagnosticoSecundario {
        codigo
        descripcion
      }
    }

      prestacionPrincipal{
        codigo
        descripcion
      }
      estadoCuentaMedica{
        estado,
        nombre,
        causal,
        descripcionCausal
      }
      fechaRecepcion
      fechaProbableLiquidacion
      fechaHospitalizacion {
        fechaInicio
        fechaEgreso
        horaInicio
        horaEgreso
        condicionEgreso
      }
      fechaCobro {
        fechaInicio
        fechaTermino
      }
      tipoCuentaMedica
      declaracionPreexistencia
      restriccionCobertura
      cantidadIntervenciones
      graduacionParto
      montoCobro
      tipoAtencion
      leyUrgencia
      cuentaComplementaria
      soap
      reliquidacion
      cuentaReliquidacionAsociada
      tipoCobertura{
        active
        items {
          id
          nombre
        }
      }
      tipoHabitacion{
        active
        items {
          id
          nombre
        }
      }
      tipoRegimen{
        active
        items {
          id
          nombre
        }
      } 
  
  canalOrigen
  idEventoHospitalario
  poliza{
    id
    numero
    version
    fechaInicioVigencia
    fechaTerminoVigencia
    codigoPlanSalud
    glosaPlanSalud
  }
  }
}`;

export const getDiagnosticoQuery = gql`
query getDiagnosticoQuery($codigo: String){
    diagnostico(codigo: $codigo){
    descripcion
  }
}`;

export const getdatosBeneficiarioQuery = gql`
query getdatosBeneficiarioQuery($rut: String!, $fecha: String){
  datosBeneficiario(rut: $rut, fechaInicioHospitalizacion: $fecha){
    rut
    nombre
    apellidoPaterno
    apellidoMaterno
  }
}`;

export const getPolizaByRutBeneficiario = gql`
query ($rut: String!, $fecha: String){
  datosBeneficiario(rut: $rut, fechaInicioHospitalizacion: $fecha){
    poliza{
      id
      numero
      version
      fechaOriginalPoliza
      fechaInicioVigencia
      fechaTerminoVigencia
      codigoPlanSalud
      glosaPlanSalud
    }
  }
}`;

export const getDataPrestadorByRut = gql`
query getDataPrestadorByRut($rut: String){
  datosPrestador(rut: $rut){
      razonSocial
      tieneConvenio
  }
}`;

export const getDataCodigoPrestacionPrincipal = gql`
query getDataCodigoPrestacionPrincipal($codigo: String){ 
  prestacionPrincipal(codigo: $codigo){
    descripcion
  }
}`;

export const setCuentaMedicanQuery = gql`
mutation ($id: Int ,  $antecedentes : AntecedentesGeneralesInput ){ 
    setCuentaMedica(id:$id , antecedentes : $antecedentes ) {
      respuesta{
        estado
        mensaje
        codigo
      }
      error{
        mensaje
      }
    }
}`;

export const obtenerInformacionNecesariaQuery = gql`
query obtenerInformacionNecesariaQuery($idSolicitud: Int ){ 
  obtenerInformacionNecesaria(idSolicitud:$idSolicitud ) {
      datosBeneficiario
      datosPrestador
      datosCuentaMedica
    }
}`;

export const queryObtenerFechaLiquidacion = gql`
query queryObtenerFechaLiquidacion($fecha: Date){
  obtenerFechaLiquidacion(fechaDesde: $fecha) {
      fechaLiquidacion
  }
}`;

export const queryObtenerTipoHabitacion = gql`
query {
  obtenerValoresParametros(tipoParametro: TIPO_HABITACION){
    idParametroInterno
    valorParametro
  }
}`;

export const queryObtenerTipoCobertura = gql`
query {
  obtenerValoresParametros(tipoParametro: TIPO_COBERTURA){
    idParametroInterno
    valorParametro
  }
}`;

export const queryObtenerRegimen = gql`
query {
  obtenerValoresParametros(tipoParametro: TIPO_REGIMEN){
    idParametroInterno
    valorParametro
  }
}`;

export const mutationIngresarCuentaMedica = gql`
mutation mutationIngresarCuentaMedica($cuentaMedica: AntecedentesGeneralesInput){
  ingresarCuentaMedica(cuentaMedica: $cuentaMedica){
    id
    respuesta{
      estado
      mensaje
      codigo
    }
  }
}`;

export const mutationAsignarUsuario = gql`
mutation mutationAsignarUsuario($usuario: UsuarioInput, $idCuentaMedica: Int){
  asignarUsuario(usuario: $usuario, idCuentaMedica: $idCuentaMedica){
    estado
    mensaje
  }
}`;

export const getdatosFoliosBeneficiario = gql`
query ($idCuentaMedica: Int, $rutBeneficiario: String, $rutPrestador: String){
  obtenerFoliosCuentaMedica(idCuentaMedica: $idCuentaMedica, rutBeneficiario: $rutBeneficiario, rutPrestador: $rutPrestador){
    active
    items {
      id
      nombre
    }
  }
}
`;

export const obtenerCodigosPrestacionPrincipal = gql`
  query {
    obtenerCodigosPrestacionPrincipal{
      codigo
      descripcion
    }
  }
`

export const obtenerCodigosCIE10 = gql`
  query ($pageNumber:Int, $pageSize: Int){
    obtenerCodigosCIE10(pageNumber: $pageNumber, pageSize:$pageSize){
      codigo
      descripcion
    }
  }
`;

export const verificaDupe = gql`
  query ($numeroCobro: String!, $numeroCuenta: String!, $rutPaciente: String!) {
    verificaCuentaCobroCM(
      numeroCobro: $numeroCobro
      numeroCuenta: $numeroCuenta
      rutPaciente: $rutPaciente
    ) {
      status
      message
      idCuenta
      estado
    }
  }
`;

export const obtenerProfesionales = gql`
  query{
    obtenerTiposProfesional{
      id
      valor1
      idCodigo
    }
  }
`
export const obtenerTiposAtencion = gql`
  query{
    obtenerTiposAtencion{
      id
      nombre
      valor
    }
  }
`;

export const crearPrestacion = gql`
  mutation($idCuenta: Int!, $fechaInicioHospitalizacion: Date!, $idCobro: Int!, $idCobertura: Int!, $dataPrestacion: dataPrestacionInput){
    crearPrestacion(idCuenta: $idCuenta,fechaInicioHospitalizacion: $fechaInicioHospitalizacion, idCobro: $idCobro, idCobertura: $idCobertura,
    dataPrestacion: $dataPrestacion ){
        estado
        codigo
        mensaje
    }
  }
`

export const deshabilitaPrestacion = gql`
  mutation deshabilitarPrestaciones($idPrestacion: [Int], $type: Int) {
    deshabilitarPrestaciones(idPrestacion: $idPrestacion, type: $type) {
        estado
        mensaje
        codigo
    }
  }
`;

export const obtenerEstadosCausalesPorSiguienteEstado = gql`
  query ($idProximoEstadoCuentaMedica: Int) {
    obtenerEstadosCausalesPorSiguienteEstado(idProximoEstadoCuentaMedica: $idProximoEstadoCuentaMedica) {
      id
      idCausal
      descripcionCausal
      idEstadoCuentaMedica
      descripcionEstadoCuentaMedica
      entidadResolutiva
      proximaEntidadResolutiva
      idProximoEstadoCuentaMedica
    }
  }
`;

export const updateCMStatus = gql`
  mutation updateCMStatus($updateData: updateCMStatusInput)
  {
    updateCMStatus(updateData: $updateData)
    {
        estado
        mensaje 
        {
            eventoHospitalario 
            documentosAdjuntos 
            coberturas 
            antecedentesGenerales 
            bonificacion
            documentoCobro
            texto
        }

    }
  }
`;