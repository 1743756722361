import { Typography } from "@mui/material"
import { WarningModalCustom } from "../../../../components/Modal/warningModalCustom"
import { useMutation } from "@apollo/client";
import { queryAsignacionEventoHospitalario } from "../../../../services/administracionCM/eventoHospitalarioServices";

export const AsignacionEvento = ({ open, close, row, dataCM, refetch, setMensajero }: any) => {
    const [asignacionEventoHO, { loading }] = useMutation(queryAsignacionEventoHospitalario);
    const texto = dataCM.idEventoHospitalario === row.id ? 'Desasignado' : 'Asignado';
    const textoModal = dataCM.idEventoHospitalario === row.id ? 'Desasignar' : 'Asignar';
    const asignar = () => {
        asignacionEventoHO({
            variables: {
                idEventoHospitalario: dataCM.idEventoHospitalario === row.id ? null : row.id,
                idSolicitud: dataCM.idCuentaMedica
            },
            onCompleted: () => {
                const btnRefetchCuenta = document.getElementById('btnRefetchCuenta');
                if (btnRefetchCuenta) {
                    btnRefetchCuenta.click();
                }
                setMensajero(`Evento hospitalario ${texto} exitosamente`, 'success');
                close();
                refetch();
            },
            onError: () => setMensajero(`Ocurrió un error al ${texto} el evento hospitalario. por favor, inténtalo de nuevo.`, 'error')
        })
    };

    return (
        <WarningModalCustom
            secondaryButton="Cancelar"
            primaryButton={textoModal}
            contentText={<Typography>¿Estás seguro de {textoModal} este evento hospitalario?</Typography>}
            title={`${textoModal} evento hospitalario`}
            noIcon
            onOpen={open}
            loading={loading}
            onConfirm={asignar}
            onClose={() => close()}
            loadingWidth={"120px"}
        />
    )
}