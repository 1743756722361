import { styled } from '@mui/material/styles';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, {
    AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import ArrowUp from '../../assets/icons/Chevronup.svg';

export const Accordion = styled((props: AccordionProps) => (
    <MuiAccordion disableGutters elevation={0} {...props} />
))(({ theme }) => ({
    p: "0px !important",
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&:before': {
        display: 'none',
    },
    //marginBottom: "16px",
    backgroundColor: "transparent"
}));

export const AccordionSummary = styled((props: AccordionSummaryProps) => (
    <MuiAccordionSummary sx={{ pl: 0 }}
        expandIcon={<img src={ArrowUp} alt=""/>}
        {...props}
    />
))(({ theme }) => ({
    borderRadius: "8px",
    backgroundColor: "#CCEDEB",
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(180deg)',
    },
    '& .MuiAccordionSummary-content': {
        p: 0,
        margin: 0,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
    },
}));

export const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: 0,
    boxShadow: " 0px 2px 5px 0px #C9CFE3BF",
    borderRadius: " 0px 0px 8px 8px"
}));

