import { useEffect, useState } from "react";

interface Props {
    list?: []
    initialRowsPerPage: number
    initialOrder: string
    initialOrderBy: string
    initialOrderColumn?: string
    filterListBy?:  (list: any, filterBy: string, orderBy: string) => any
}

const initialList:[] = [];
const filterListByDefault = (list: any, filterBy: string, orderBy: string) => {
    const re = new RegExp(filterBy, "i");

    return list?.filter((p: any) => {
        return re.test(p[orderBy]);
    });
}

export const useTableWithOrder = ({ list = initialList, initialRowsPerPage, initialOrder, initialOrderBy, initialOrderColumn = '', filterListBy = filterListByDefault}: Props) => {
    // orden asc o desc
    const [order, setOrder] = useState(initialOrder);
    //OrderBy para saber que propiedad de la lista se tiene que filtrar
    const [orderBy, setOrderBy] = useState<any>(initialOrderBy);
    //Order colum se usa para mostrarle al usuario que columna esta siendo ordenada
    const [orderColumn, setOrderColumn] = useState<string>(initialOrderColumn)

    // el filtro a aplicar
    const [filterBy, setFilterBy] = useState('');
    const [filterList, setFilterList] = useState([]);

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(initialRowsPerPage);

    const handleRequestSort = (event: any, property: any, labelColumn: string) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
        setOrderColumn(labelColumn)
    };

    const handleChangePage = (event: any, newPage: any) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: any) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleChangeFilterBy = (value:string) => (setFilterBy(filterSpecialCharacters(value)));

    const filterSpecialCharacters = (value:string) => (value.replace(/[\\^$.|?*+()[{]/g, '\\$&')); 

    useEffect(() => {
        setFilterList(filterListBy(list, filterBy, orderBy));
        setPage(0);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterBy]);

    useEffect(() => {
        setFilterList(filterListBy(list, filterBy, orderBy));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [list]);
    
    return {
        filterBy,
        order,
        orderBy,
        orderColumn,
        handleChangeFilterBy,
        filterList,
        page,
        rowsPerPage,

        handleRequestSort,
        handleChangePage,
        handleChangeRowsPerPage
    }
}