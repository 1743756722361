import { useQuery } from "@apollo/client"
import { obtenerCodigosPrestacionPrincipal } from "../../services/administracionCM/administracionCMServices"
import { SlimInput } from "../../assets/styles/Input/input"
import { Autocomplete, Box } from "@mui/material"
import { useState } from "react"

export const SelectPrestaciones = ({ disabled, value, onChange, isPrestacion }: any) => {
    const [dataPrestaciones, setDataPrestaciones] = useState<any[]>([]);
    const { loading } = useQuery(obtenerCodigosPrestacionPrincipal, {
        onCompleted: (res) => {
            setDataPrestaciones(res.obtenerCodigosPrestacionPrincipal);
        },
        onError: () => setDataPrestaciones([{ codigo: "", descripcion: "ERROR" }])
    })
    return (
        <Autocomplete
            id="prestaciones"
            data-testid="prestaciones"
            loading={loading}
            disabled={disabled}
            sx={{ width: "100%" }}
            options={dataPrestaciones}
            autoHighlight
            isOptionEqualToValue={(option, value) => option.codigo === value.codigo}
            value={value || null}
            clearText={"Limpiar"}
            onChange={(event, option) => {
                onChange(option);
            }}
            getOptionLabel={(option) => (`${option.codigo}${isPrestacion ? "" : " - " + option.descripcion}`)}
            renderOption={(props, option) => (
                <Box
                    component="li"
                    sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                    {...props}
                    key={option.codigo}
                    value={option.codigo}
                >
                    {option.codigo} - {option.descripcion}
                </Box>
            )}
            renderInput={(params) => (
                <SlimInput
                    {...params}
                    placeholder="Escribe una prestación"
                    inputProps={{
                        ...params.inputProps,
                        autoComplete: "new-password", // disable autocomplete and autofill
                    }}
                />
            )}
        />
    )
}