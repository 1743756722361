import React, { useCallback } from 'react';

import { Typography, Box } from '@mui/material';
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import { useNavigate } from 'react-router-dom';

export const ButtonGo = (props: any) => {

  const dataUrl = props.dataUrl;

  const navigate = useNavigate();
  const goEscritorioTrabajo = useCallback((rutaUrl) => navigate(rutaUrl, { replace: true }),
    [navigate]);

  return (
    <Box
      onClick={() => { goEscritorioTrabajo(dataUrl) }}
      display="flex"
      pt={2}
      sx={{
        '&:hover': {
          cursor: 'pointer'
        }
      }}
    >
      <ArrowBackRoundedIcon sx={{ color: '#01736C' }} />
      <Typography variant='subtitle2' pl={1}>Volver a {props.isConsulta ? "Consulta Cuentas" : "Escritorio de trabajo"}</Typography>

    </Box>

  )
}