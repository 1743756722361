import { Fragment } from "react";
import { Grid, Chip, Button } from "@mui/material";
import Cruz from "../../../assets/icons/cancelIcon.svg";

export function ChipsCuentas({ filtros, tabIndex, findMD, fechas }: any) {
    const handleDelete = (filter: number) => {
        let filters = filtros;
        let resetFecha = false;
        switch (filter) {
            case 1: {
                filters.id = undefined;
                break;
            }
            case 2: {
                filters.idCMD = undefined;
                break;
            }
            case 3: {
                filters.numeroCuentaPrestador = undefined;
                break;
            }
            case 4: {
                filters.rutPaciente = undefined;
                break;
            }
            case 5: {
                filters.rutPrestador = undefined;
                break;
            }
            case 6: {
                filters.fechaRecepcion = undefined;
                break;
            }
            case 7: {
                filters.fechaRealLiquidacion = undefined;
                break;
            }
            case 8: {
                filters.estadoEmision = undefined;
                break;
            }
            case 9: {
                filters.fechaEmision = undefined;
                break;
            }
            case 10: {
                resetFecha = true;
                break;
            }
            case 11: {
                filters.estado = undefined;
                break;
            }
            default: return
        }
        findMD(filters, tabIndex, resetFecha);
    };

    const clearFiltros = () => {
        const filters = {
            id: undefined,
            idCMD: undefined,
            numeroCuentaPrestador: undefined,
            rutPaciente: undefined,
            rutPrestador: undefined,
            fechaRecepcion: undefined,
            fechaRealLiquidacion: undefined,
            estadoEmision: undefined,
            estado: undefined
        };
        findMD(filters, tabIndex, true);
    };

    return (
        <Fragment>
            {(filtros.id ||
                filtros.idCMD ||
                filtros.numeroCuentaPrestador ||
                filtros.rutPaciente ||
                filtros.rutPrestador ||
                filtros.fechaRecepcion ||
                filtros.fechaRealLiquidacion ||
                filtros.estadoEmision ||
                filtros.fechaEmision ||
                fechas ||
                filtros.estado) && (
                    <Fragment>
                        <Grid item xs={12} pt="15px">
                            <label className="filtroFont">Resultados filtrados por: </label>{" "}
                        </Grid>
                        <Grid item xs={12} pt="4px" >
                            {fechas && (
                                <Chip
                                    sx={{ mr: "16px" }}
                                    label="Rango Fechas"
                                    variant="outlined"
                                    onDelete={() => handleDelete(10)}
                                    deleteIcon={
                                        <img
                                            src={Cruz}
                                            alt="cruz"
                                            data-testid="icon-delete-fechas"
                                        />
                                    }
                                />
                            )}
                            {filtros.id && (
                                <Chip
                                    sx={{ mr: "16px" }}
                                    label="Nº Cuenta"
                                    variant="outlined"
                                    onDelete={() => handleDelete(1)}
                                    deleteIcon={
                                        <img
                                            src={Cruz}
                                            alt="cruz"
                                            data-testid="icon-delete-id"
                                        />
                                    }
                                />
                            )}
                            {filtros.idCMD && (
                                <Chip
                                    sx={{ mr: "16px" }}
                                    label="Nº Cuenta Extranet"
                                    variant="outlined"
                                    onDelete={() => handleDelete(2)}
                                    deleteIcon={
                                        <img
                                            src={Cruz}
                                            alt="cruz"
                                            data-testid="icon-delete-idCMD"
                                        />
                                    }
                                />
                            )}
                            {filtros.numeroCuentaPrestador && (
                                <Chip
                                    sx={{ mr: "16px" }}
                                    label="Nº Cuenta Prestador"
                                    variant="outlined"
                                    onDelete={() => handleDelete(3)}
                                    deleteIcon={
                                        <img src={Cruz} alt="cruz" data-testid="icon-delete-numeroCuentaPrestador" />
                                    }
                                />
                            )}
                            {filtros.rutPaciente && (
                                <Chip
                                    sx={{ mr: "16px" }}
                                    label="RUT Beneficiario"
                                    variant="outlined"
                                    onDelete={() => handleDelete(4)}
                                    deleteIcon={
                                        <img
                                            src={Cruz}
                                            alt="cruz"
                                            data-testid="icon-delete-rutPaciente"
                                        />
                                    }
                                />
                            )}
                            {filtros.rutPrestador && (
                                <Chip
                                    sx={{ mr: "16px" }}
                                    label="RUT Prestador"
                                    variant="outlined"
                                    onDelete={() => handleDelete(5)}
                                    deleteIcon={
                                        <img
                                            src={Cruz}
                                            alt="cruz"
                                            data-testid="icon-delete-rutPrestador"
                                        />
                                    }
                                />
                            )}
                            {filtros.fechaRecepcion && (
                                <Chip
                                    sx={{ mr: "16px" }}
                                    label="Fecha Recepción"
                                    variant="outlined"
                                    onDelete={() => handleDelete(6)}
                                    deleteIcon={
                                        <img
                                            src={Cruz}
                                            alt="cruz"
                                            data-testid="icon-delete-fechaRecepcion"
                                        />
                                    }
                                />
                            )}
                            {filtros.fechaRealLiquidacion && (
                                <Chip
                                    sx={{ mr: "16px" }}
                                    label="Fecha Liquidación"
                                    variant="outlined"
                                    onDelete={() => handleDelete(7)}
                                    deleteIcon={
                                        <img
                                            src={Cruz}
                                            alt="cruz"
                                            data-testid="icon-delete-fechaRealLiquidacion"
                                        />
                                    }
                                />
                            )}
                            {filtros.estadoEmision && (
                                <Chip
                                    sx={{ mr: "16px" }}
                                    label="Estado Emisión"
                                    variant="outlined"
                                    onDelete={() => handleDelete(8)}
                                    deleteIcon={
                                        <img
                                            src={Cruz}
                                            alt="cruz"
                                            data-testid="icon-delete-estadoEmision"
                                        />
                                    }
                                />
                            )}
                            {filtros.fechaEmision && (
                                <Chip
                                    sx={{ mr: "16px" }}
                                    label="Fecha Emisión"
                                    variant="outlined"
                                    onDelete={() => handleDelete(9)}
                                    deleteIcon={
                                        <img
                                            src={Cruz}
                                            alt="cruz"
                                            data-testid="icon-delete-fechaEmision"
                                        />
                                    }
                                />
                            )}
                            {filtros.estado && (
                                <Chip
                                    sx={{ mr: "16px" }}
                                    label="Estado"
                                    variant="outlined"
                                    onDelete={() => handleDelete(11)}
                                    deleteIcon={
                                        <img
                                            src={Cruz}
                                            alt="cruz"
                                            data-testid="icon-delete-estado"
                                        />
                                    }
                                />
                            )}
                            <Button
                                color="inherit"
                                sx={{
                                    textDecoration: "underline",
                                    textDecorationThickness: "from-font",
                                    pl: "12px",
                                }}
                                data-testid="cleanFilters"
                                onClick={clearFiltros}
                            >
                                Limpiar filtros
                            </Button>
                        </Grid>
                    </Fragment>
                )}
        </Fragment>
    );
}
