import { gql } from "@apollo/client";

export const queryObtenerReportes = gql`
  query {
    obtenerReportesGH {
      nombreArchivo
      folioDocumento
      fechaGeneracion
    }
  }
`;

export const queryObtenerReporteGestion = gql`
  query {
    obtenerReporteGestion
  }
`;

export const getManagementReportData = gql`
  query GetDataQueryMR($limit: Int, $offset: Int) {
    getDataQueryMR(limit: $limit, offset: $offset) {
      count
      data {
        numeroCuentaPrestador
        numeroCobroPrestador
        numeroEnvio
        idCMD
        id
        estado
        usuarioEmision
        fechaEmision
        horaEmision
        usuarioLiquidacion
        fechaLiquidacion
        horaLiquidacion
        folioBonificacion
        rutPaciente
        nombrePaciente
        rutTitular
        codigoPlanSalud
        glosaPlanSalud
        codigoPrestacionIsapre
        glosaPrestacionIsapre
        idItemIsapre
        glosaItemIsapre
        tipoAtencion
        rutPrestador
        razonSocial
        tipoDocumento
        frecuenciaAtencion
        montoPrestacionCobro
        montoPrestacionConvenio
        porcentajeCobro
        bonificacionPlan
        bonificacionGES
        bonificacionGESCAEC
        bonificacionCAEC
        cobertura
        idPlan
        montoBonificacionForzado
        bonificacionEscencial
        BonificacionComplementaria
        bonificacionComplementariaTotal
        cuentaComplementaria
        soap
        restriccionCobertura
        montoCopago
        codigoCanasta
        nroGESCAEC
        nroCuentaCobertura
        codigoPrestacionPrincipalPrestador
        descripcionPrestacionPrincipalPrestador
        cie10Primario
        descCie10Primario
        fechaRecepcion
        canalOrigen
        fechaProbableLiquidacion
        fechaInicioHospitalizacion
        horaInicioHospitalizacion
        fechaEgresoHospitalizacion
        horaAlta
        codigoPaquete
        leyUrgenciaVital
        tipoCoberturaCuenta
        tipoCoberturaCobro
        montoBonificacionTopada
        fechaCobroDesde
        fechaCobroHasta
      }
    }
  }
`;
