import {
  Box,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  useTheme,
} from "@mui/material";
import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import ArrowForwardRoundedIcon from "@mui/icons-material/ArrowForwardRounded";
 
import file from "./../../assets/icons/file.svg";
import report from "./../../assets/icons/report.svg";
import shape from "./../../assets/icons/shape.svg";
import { CustomDrawer, textSelectedDrawer } from "../../assets/styles/SideBar/drawer";
import { BarTooltip } from "./barTooltip";
import ctas from "./../../assets/icons/consultaIcon.svg";
import { Link, useLocation } from "react-router-dom";
import { useGetUser } from "../../hooks/getUser";
import { accessEM, accessET, accessREP } from "../../routes/verifyAccess";

const items = [
  {
    path: "/Escritorio",
    title: "Escritorio de Trabajo",
    icon: shape,
  },
  {
    path: "/Emision",
    title: "Emisión de Cuentas",
    icon: file,
  },
  
  {
    path: "/ConsultaCuentas",
    title: "Consulta de Cuentas",
    icon: ctas,
  },
  {
    path: "/Reportes",
    title: "Reportería",
    icon: report,
  },
];

export const SideBar = ({ open, handleDrawerClose, handleDrawerOpen }: any) => {
  const { pathname } = useLocation();
  const theme = useTheme();
  const user = useGetUser();

  const includePath = (path: string) => {
    return pathname === "/"
      ? path.toLowerCase().includes("/escritorio")
      : pathname.toLowerCase().includes(path.toLowerCase());
  };

  const itemsToRender = () => {
    const listItem = [];
    if(accessET(user)){
      listItem.push(items[0]);
    }
    if(accessEM(user)){
      listItem.push(items[1]);
    }
    listItem.push(items[2]);
    if(accessREP(user)){
      listItem.push(items[3]);
    }
    return listItem;
  }

  return (
    <CustomDrawer
      variant="permanent"
      anchor="left"
      open={open}
      onClose={handleDrawerClose}
      ModalProps={{ keepMounted: true }}
      color="inherit"
      sx={{ boxShadow: " 0px 4px 8px -2px rgba(201, 207, 227, 0.75)" }}
    >
      <Box display="flex" justifyContent={open ? "flex-end" : "center"}>
        {open ? (
          <IconButton onClick={handleDrawerClose} sx={{ marginRight: "21px" }}>
            {theme.direction === "ltr" ? (
              <ArrowBackRoundedIcon />
            ) : (
              <ArrowForwardRoundedIcon />
            )}
          </IconButton>
        ) : (
          <IconButton onClick={handleDrawerOpen}>
            <ArrowForwardRoundedIcon />
          </IconButton>
        )}
      </Box>
      <Box height="30px" pl="16px" pb="14px">
        {open ? <Typography>Administración de Cuentas</Typography> : null}
      </Box>
      <List>
        {itemsToRender().map((item) =>
          <BarTooltip disableHoverListener={open} title={item.title} arrow placement="right" key={item.path}>
            <ListItemButton
              disabled={item.path === "#"}
              sx={{
                backgroundColor: includePath(item.path) ? "#CCEDEB" : "",
              }}
              key={item.path}
            >
              <Link to={item.path} style={{ textDecoration: "none" }}>
                <ListItemIcon sx={{ paddingLeft: "4px" }}>
                  <img src={item.icon} alt={""} />
                </ListItemIcon>
                <ListItemText
                  className="linkMenu"
                  primaryTypographyProps={
                    includePath(item.path) ? textSelectedDrawer : {}
                  }
                  primary={item.title}
                />
              </Link>
            </ListItemButton>
          </BarTooltip>
        )}
      </List>
    </CustomDrawer>
  );
};
