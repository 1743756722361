import { Fragment } from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  Checkbox
} from "@mui/material";
import { stickyBodyLeft } from "../../../assets/styles/Table/table";
import {
  formatRut,
  numbersWithDots,
} from "../../../utils/index";
import { TableHeadLiquidadas } from "./headLiquidadas";
import { LoadLiquidadas } from "../components/skeletonsEmitir";
import { NoResult } from "../../../components/Tables/noResult";
import { EmptyStateMessage } from "../../../components/message/emptyStateMessage";
import { FilterButtonState } from "./filterButtonState";
import { SecondaryButtonTable } from "../../../assets/styles/Button/Button";
import { NetworkStatus } from "@apollo/client";
import { Paginador } from "../../../components/TablePagination/Paginador";
import { MenuTableCuentas } from "../components/MenuTableCuentas";
import { verificaLargo } from "../../../components/Tooltip/Tooltip";
import { EstadosReversa } from "../components/EstadosReversa";
import { useGetUser } from "../../../hooks/getUser";

interface Props {
  loading: boolean;
  isReversa: boolean,
  refetch: Function,
  tabIndex: string,
  orderDir: any,
  orderByCol: any
  aplicarOrderBy: Function,
  setSnackData: Function,
  selectedCuentaMedica: any;
  listaCuentasLiquidadas: any[];
  handleSelectAllClick: any;
  isSelected: any;
  handleItemChecked: any;
  handleEmitirCuentasMedica: any;
  count: any;
  rowsPerPage: any;
  page: any;
  handleNewChangePage: any;
  handleNewChangeRowsPerPage: any;
  isEmptyState: boolean;
  isDisabledReversar: any;
  networkStatus: any
}

const colorsDefault = {
  fontSize: "12px",
  fontWeight: 700,
  lineHeight: "16px",
}
const colors = {
  primary: {
    ...colorsDefault,
    color: "#01736C",
  },
  secondary: {
    ...colorsDefault,
    color: "#392F95",
  }
};

export const TableCuentas = ({
  loading,
  refetch,
  isReversa,
  tabIndex,
  orderDir,
  orderByCol,
  aplicarOrderBy,
  setSnackData,
  selectedCuentaMedica,
  listaCuentasLiquidadas,
  handleSelectAllClick,
  isSelected,
  handleItemChecked,
  handleEmitirCuentasMedica,
  count,
  rowsPerPage,
  page,
  handleNewChangePage,
  handleNewChangeRowsPerPage,
  isEmptyState,
  isDisabledReversar,
  networkStatus
}: Props) => {
  const { rol } = useGetUser();
  const selectableRows = listaCuentasLiquidadas.filter(cta => !cta.emision || !cta.emision.estado || cta.emision.estado === "3");
  return (
    <>
      {loading && !(networkStatus === NetworkStatus.poll || networkStatus === NetworkStatus.ready) ? (
        <LoadLiquidadas />
      ) : listaCuentasLiquidadas.length > 0 ? (
        <Paper
          className="paper-tables"
          sx={{
            border: "none !important",
            borderRadius: "0 !important",
          }}
        >
          <TableContainer sx={{ pl: "0 !important" }}>
            <Table aria-label="customized table">
              <TableHeadLiquidadas
                numSelected={selectedCuentaMedica.length}
                rowCount={selectableRows.length}
                onSelectAllClick={handleSelectAllClick}
                isReversa={isReversa}
                tabIndex={tabIndex}
                order={orderDir}
                orderBy={orderByCol}
                createSortHandler={aplicarOrderBy}
              />
              <TableBody>
                {listaCuentasLiquidadas.map((row: any, index: any) => {
                  const isItemSelected = isSelected(row.id);
                  const labelId = `enhanced-table-checkbox-${row.id}`;
                  const nombrePaciente = row.nombrePaciente + " " + row.apellidoPaternoPaciente + " " + row.apellidoMaternoPaciente;
                  return (
                    <TableRow key={row.id} hover>
                      {tabIndex === "1" &&
                        <TableCell
                          padding="checkbox"
                          className="tCell-p"
                          sx={stickyBodyLeft("0")}
                        >
                          <Checkbox
                            checked={isItemSelected}
                            color="primary"
                            disabled={row.emision && row.emision.estado === "2"}
                            onChange={(e: any) => {
                              handleItemChecked(row);
                            }}
                            inputProps={{
                              "aria-labelledby": labelId,
                            }}
                          />
                        </TableCell>
                      }
                      <TableCell>
                        <Typography>{row.id}</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography>{row.idCMD}</Typography>
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <Typography>{row.numeroCuentaPrestador}</Typography>
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <Typography className="rutTable">
                          {formatRut(row.rutPaciente)}
                        </Typography>
                        <Typography className="nameTable">
                          {verificaLargo(nombrePaciente, 10)}
                        </Typography>
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <Typography className="rutTable">
                          {formatRut(row.prestador?.rutPrestador)}
                        </Typography>
                        <Typography className="nameTable">
                          {verificaLargo((row.prestador?.razonSocial || ""), 10)}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography>{row.fechaRecepcion}</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography>{row.fechaRealLiquidacion}</Typography>
                      </TableCell>
                      {tabIndex === "2" ?
                        <TableCell sx={{ minWidth: '140px' }}>
                          <Typography>{row.fechaEmision}</Typography>
                        </TableCell>
                        : null}
                      <TableCell>
                        <Typography>
                          {row.totalFacturado !== null
                            ? numbersWithDots(row.totalFacturado.toString())
                            : "0"}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography>
                          {row.totalBonificado !== null
                            ? numbersWithDots(
                              row.totalBonificado.toString()
                            )
                            : "0"}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography>
                          {row.totalCopago !== null
                            ? numbersWithDots(row.totalCopago.toString())
                            : "0"}
                        </Typography>
                      </TableCell>
                      {tabIndex === "2" && isReversa ?
                        <Fragment>
                          <TableCell className="tableBodyReasignar">
                            <EstadosReversa estado={row.reversa ? row.reversa.estado : null} />
                          </TableCell>
                        </Fragment>
                        : null}
                      {tabIndex === "2" && (
                        <TableCell className="tableBodyEspecial">
                          <Typography sx={row.estado !== "Emitida Manual" ? colors.primary : colors.secondary} >
                            {row.estado !== "Emitida Manual" ? 'Electrónica' : 'Manual'}
                          </Typography>
                        </TableCell>)
                      }
                      {tabIndex !== "2" ?
                        <Fragment>
                          <TableCell className="tableBodyReasignar">
                            <FilterButtonState estado={row.emision !== null ? row.emision.estado : "4"} idCuentaMedica={row.id} />
                          </TableCell>
                          <TableCell className="tableBodyEspecial">
                            <SecondaryButtonTable
                              disabled={((row.emision === null || row.emision.estado === null || row.emision.estado === "3") && !rol.includes('AUDITOR')) ? false : true}
                              onClick={() =>
                                handleEmitirCuentasMedica(
                                  row.id,
                                  row.rutPaciente,
                                  row.nombrePaciente,
                                  row.apellidoPaternoPaciente,
                                  row.apellidoMaternoPaciente
                                )
                              }
                            >
                              Emitir
                            </SecondaryButtonTable>
                          </TableCell>
                        </Fragment> : null}
                      <TableCell
                        className="tableBodyAcciones"
                        align="center"
                      >
                        <MenuTableCuentas
                          setSnackData={setSnackData}
                          row={row}
                          tabIndex={tabIndex}
                          refetch={refetch}
                        />
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <Paginador
            opciones={[10, 20, 50]}
            data={count}
            itemPorPagina={rowsPerPage}
            pagina={page}
            setPagina={handleNewChangePage}
            setLineasPagina={handleNewChangeRowsPerPage}
          />
        </Paper>
      ) : isEmptyState ? (
        <EmptyStateMessage
          title={"Aún no ingresas los valores de búsqueda"}
          subtitle={
            "Porfavor, Ingrese las fechas desde y hasta para comenzar la búsqueda."
          }
        />
      ) : (
        <NoResult
          titulo={"No se encontró información de cuentas"}
          subtitulo={
            "Inténtelo nuevamente usando otro criterio para su filtro."
          }
          noDivider={true}
          newFound={true}
        />
      )}
    </>
  );
};
