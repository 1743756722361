import { useLazyQuery } from "@apollo/client";
import { CuentasMedicasQuery } from "../../../services/cm-services/escritorio-cm/escritorio-services";
import { exportConsultaCuentas } from "./exportToExcelConsultas";
import BotonCarga from "../../../components/Buttons/LoadingButton";
import { useState } from "react";

type exportBtnArgs = {
    filtros?: Object | {},
    showMessage: Function,
    disabled?: boolean
}

export const ExportBtn = ({ filtros, showMessage, disabled }: exportBtnArgs) => {
    const [loadingData, setLoadingData] = useState<boolean>(false);
    const [getDataCuentas, { fetchMore }] = useLazyQuery(CuentasMedicasQuery, {
        onCompleted: (res) => procesaDataCuentas(res),
        onError: () => {
            showMessage("Hubo un error al consultar datos, favor reintente.", "error")
            setLoadingData(false);
        }
    }
    );

    async function procesaDataCuentas(result: any) {
        try {
            let temp = result.obtenerCuentasMedicas;
            while (temp.cuentasMedicas.length < temp.count) {
                const { data, error } = await fetchMore({
                    variables: {
                        offset: temp.cuentasMedicas.length,
                        limit: 1000,
                        filterBy: filtros,
                        orderBy: {},
                        buscadorCuentasFlag: true
                    }
                });
                if (error) throw new Error("Error al consultar data");
                const mergeSol = [...temp.cuentasMedicas, ...data.obtenerCuentasMedicas.cuentasMedicas];
                temp = {
                    ...temp,
                    cuentasMedicas: mergeSol
                };
            }

            const reporte = temp.cuentasMedicas;
            await exportConsultaCuentas(reporte, showMessage);
            showMessage("Archivo generado, revise descargas.", "success");
            setLoadingData(false);

        } catch (error: any) {
            showMessage("Datos no son válidos, favor reintente.", "error");
            setLoadingData(false);
        }

    }
    const triggerDownload = () => {
        setLoadingData(true);
        getDataCuentas({
            variables: {
                offset: 0,
                limit: 1000,
                filterBy: filtros,
                orderBy: {},
                buscadorCuentasFlag: true
            }
        })
    }

    return (
        <BotonCarga
            testid="btnExportarConsultaCuentas"
            toDo={triggerDownload}
            disabled={disabled}
            loading={loadingData}
            type='secondary'
            download
            label="Exportar"
        />
    );
}