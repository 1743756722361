import { gql } from '@apollo/client';

// excluye tipoProfesional ya que se requiere saber sub categorias de esta
export const PRESTACION_FRAGMENT = gql`
    fragment PrestacionFragment on Prestacion {
        id
        idCuentaMedica
        idCobertura
        rutPrestador
        idConvenio
        idDireccionAtencion
        idEspecialidad
        idArancel
        idCodigoPaquete
        idTipoProfesional
        rutProfesional
        nombreProfesional
        medicoStaff
        idItemIsapre
        descripcionItemProfesional
        numeroOrdenIntervencion
        tipoMovimiento
        tipoVia
        tipoTecnica
        aplicadaIntervencionQuirurgica
        tipoAtencion
        atencionUrgencia
        horarioOtorgamientoPrestacion
        prestacionRecargoHorario
        fechaAtencion
        horaAtencion
        horasCama
        montoRecargoCobro
        frecuenciaAtencion
        montoPrestacionCobro
        montoPrestacionConvenio
        montoPrestacionForzado
        usuarioAutorizacionMontoPrestacionForzada
        montoBonificacionForzado
        porcentajeBonificacionForzada
        usuarioMontoBonificacionForzada
        prestacionTopada
        montoBonificacionTopada
        montoCopago
        prestacionMinimaFonasa
        montoMinimoFonasaBonificado
        prestacionEnPresupuesto
        observacionGeneral1
        observacionGeneral2
        
        codigoPrestacionIsapre
        glosaPrestacionIsapre
        codigoPrestacionPrestador
        glosaPrestacionPrestador
        idItemPrestador
        idCoberturaPlan
        glosaCoberturaPlan
        porcentajeCobro
        montoBonificadoPlan
        porcentajeBonificadoPlan
        porcentajeBonificacionTopada
        montoBonificacionMinima
        tipoVoucher
        secuenciaTipoVoucher
        folioVoucher
        rutAfiliado
        nombreAfiliado
        tipoBeneficiario
        sexoBeneficiario
        planSalud
        edadBeneficiario
        montoPrestacionDefinitivo
        secuenciaPreBonificacion
        folioBonificacion
        beneficioComplementario
        coberturaBonificacion
    }`;
    
export const COBRO_FRAGMENT = gql`
    fragment CobroFragment on Cobro {
        id
        tipoDocumento
}`;

export const COBERTURA_FRAGMENT = gql`
    fragment CoberturaFragment on Cobertura {
        id
        tipoCobertura
        folioCobertura
        secuencia
}`;